import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function RemoveItem(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <g id='Outline/subtract_circle'>
        <path
          id='Union'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4.00496 7.58378 7.58378 4.00496 12 4ZM17 11H7V13H17V11Z'
          fill='#0B41CD'
        />
      </g>
    </SvgIcon>
  );
}
