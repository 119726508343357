import { FilterOperator } from '../../core/models/filter/filter';
import { IdLabel } from '../../core/models/global';
import Arrow from '../icons/Arrow';
import './Select.scss';
import { Autocomplete, AutocompleteProps, TextField, TextFieldVariants } from '@mui/material';

export type AutocompleteValueT =
  | NonNullable<string | IdLabel>
  | (string | IdLabel)[]
  | { label: string; id: keyof typeof FilterOperator }
  | null;

type SelectProps = {
  variant?: TextFieldVariants;
  className?: string;
  label?: string;
  error?: boolean;
  complexOptions?: { title: string; description: string }[];
} & Omit<
  AutocompleteProps<
    IdLabel | string,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >,
  'renderInput'
>;
const IASelect = (props: SelectProps) => {
  const {
    onChange,
    placeholder = '',
    id,
    multiple = false,
    disableClearable = true,
    variant = 'outlined',
    className,
    label,
    error,
    disablePortal = true,
    complexOptions,
    ...rest
  } = props;
  return (
    <div className='ia-autocomplete'>
      {label && <label htmlFor={label}>{label}</label>}

      <Autocomplete
        {...rest}
        className={className}
        disableClearable={!rest.value || disableClearable}
        multiple={multiple}
        disablePortal={disablePortal}
        id={id}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant={variant}
            error={error}
            helperText={error ? 'This field is required' : ''}
          />
        )}
        renderOption={(optionProps, option: IdLabel | string, { index }) => {
          return complexOptions ? (
            <li {...optionProps} key={typeof option === 'string' ? index : option.id}>
              <div className='complexOptions'>
                <div className='complexOptions-title'>{option as string}</div>
                <div className='complexOptions-description'>
                  {complexOptions.find((el) => el.title === option)?.description}
                </div>
              </div>
            </li>
          ) : (
            <li {...optionProps} key={typeof option === 'string' ? index : option.id}>
              {typeof option === 'string' ? option : option.label}
            </li>
          );
        }}
        popupIcon={<Arrow />}
        onChange={onChange}
        style={props.disabled ? { backgroundColor: '#FBFBFA' } : {}}
        isOptionEqualToValue={(option, value) =>
          option === value ||
          (option as IdLabel).id === value ||
          (option as IdLabel).label === value
        }
      />
    </div>
  );
};

export default IASelect;
