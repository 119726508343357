import { LovValue } from '../../core/models/lovValue';
import { SystemDefinitionInterface } from '../../core/models/system/systemDefinition';
import { CountryGoup } from '../../core/models/template/template';

export const systemActionTypes = {
  GET_SYSTEM_DEFINITIONS: 'GET_SYSTEM_DEFINITIONS',
  GET_SYSTEM_DEFINITIONS_SUCCESS: 'GET_SYSTEM_DEFINITIONS_SUCCESS',
  GET_PROJECT_LOVS: 'GET_PROJECT_LOVS',
  GET_PROJECT_LOVS_SUCCESS: 'GET_PROJECT_LOVS_SUCCESS',
  GET_TEMPLATE_LOVS: 'GET_TEMPLATE_LOVS',
  GET_TEMPLATE_LOVS_SUCCESS: 'GET_TEMPLATE_LOVS_SUCCESS',
  GET_DOCUMENT_LOVS: 'GET_DOCUMENT_LOVS',
  GET_DOCUMENT_LOVS_SUCCESS: 'GET_DOCUMENT_LOVS_SUCCESS',
  GET_OUTPUT_LOVS: 'GET_OUTPUT_LOVS',
  GET_OUTPUT_LOVS_SUCCESS: 'GET_OUTPUT_LOVS_SUCCESS',
  UPDATE_COUNTRY_GROUP_VALUES: 'UPDATE_COUNTRY_GROUP_VALUES',
};
export const getSystemDefinitions = (
  callback: (err: unknown, systemDefinitions: SystemDefinitionInterface) => void = () => {},
) => ({
  type: systemActionTypes.GET_SYSTEM_DEFINITIONS,
  callback,
});
export const getSystemDefinitionsSuccess = (payload: SystemDefinitionInterface) => ({
  type: systemActionTypes.GET_SYSTEM_DEFINITIONS_SUCCESS,
  payload,
});
export const getProjectLovs = (payload: string[]) => ({
  type: systemActionTypes.GET_PROJECT_LOVS,
  payload,
});
export const getProjectLovsSuccess = (payload: { [key: string]: LovValue[] }) => ({
  type: systemActionTypes.GET_PROJECT_LOVS_SUCCESS,
  payload,
});
export const getTemplateLovs = (payload: string[]) => ({
  type: systemActionTypes.GET_TEMPLATE_LOVS,
  payload,
});
export const getTemplateLovsSuccess = (payload: { [key: string]: LovValue[] }) => ({
  type: systemActionTypes.GET_TEMPLATE_LOVS_SUCCESS,
  payload,
});
export const getDocumentLovs = (payload: string[]) => ({
  type: systemActionTypes.GET_DOCUMENT_LOVS,
  payload,
});
export const getDocumentLovsSuccess = (payload: { [key: string]: LovValue[] }) => ({
  type: systemActionTypes.GET_DOCUMENT_LOVS_SUCCESS,
  payload,
});
export const getOutputLovs = (payload: string[]) => ({
  type: systemActionTypes.GET_OUTPUT_LOVS,
  payload,
});

export const getOutputLovsSuccess = (payload: { [key: string]: LovValue[] }) => ({
  type: systemActionTypes.GET_OUTPUT_LOVS_SUCCESS,
  payload,
});
export const updateCountryGroupsValues = (
  callback: (err: unknown, ids: string[]) => void,
  payload: CountryGoup[],
) => ({
  type: systemActionTypes.UPDATE_COUNTRY_GROUP_VALUES,
  payload,
  callback,
});
