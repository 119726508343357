import BasicDetails from './BasicDetails';
import TemplateData from './TemplateData';
import { FieldsFilledInterface, SelectValue, FormValues } from '../../core/models/global';
import { CountryGoup } from '../../core/models/template/template';

interface ReviewProps {
  fieldsFilled: FieldsFilledInterface;
  setActiveStep?: (activeStep: number) => void;
  setResetValuesBasedOnType: (str?: string) => void;
  resetValuesBasedOnType: boolean | string;
  countryGroups: CountryGoup[];
}

const Review = (props: ReviewProps) => {
  const {
    fieldsFilled,
    setActiveStep,
    setResetValuesBasedOnType,
    resetValuesBasedOnType,
    countryGroups = [],
  } = props;
  return (
    <>
      <BasicDetails
        title='Review basic details'
        isReview
        fillStepFields={() => {}}
        fieldsFilled={(fieldsFilled[1] as FormValues) || {}}
        setActiveStep={setActiveStep}
        setResetValuesBasedOnType={setResetValuesBasedOnType}
      />
      <TemplateData
        title='Review template data'
        isReview
        type={((fieldsFilled[1] as FormValues)?.type as SelectValue)?.id}
        fillStepFields={() => {}}
        fieldsFilled={(fieldsFilled[2] as FormValues) || {}}
        setActiveStep={setActiveStep}
        countryGroups={countryGroups}
        setResetValuesBasedOnType={setResetValuesBasedOnType}
        resetValuesBasedOnType={resetValuesBasedOnType}
      />
    </>
  );
};

export default Review;
