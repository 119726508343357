import { useRef, useState } from 'react';
import { FilterOperator } from '../../core/models/filter/filter';
import Arrow from '../icons/Arrow';
import './MultiSelect.scss';
import {
  Autocomplete,
  AutocompleteProps,
  Checkbox,
  TextField,
  TextFieldVariants,
  Stack,
  Chip,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Cross from '../icons/Cross';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { IdLabel, SelectValue } from '../../core/models/global';
import { getInitials } from '../../core/services/userService';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export type AutocompleteValueT =
  | NonNullable<string | IdLabel>
  | (string | IdLabel)[]
  | { label: string; id: keyof typeof FilterOperator }
  | null;

type SelectProps = {
  variant?: TextFieldVariants;
  className?: string;
  label?: string;
  isChip?: boolean;
  removeItem?: (id: string, label: string) => void;
  disabled?: boolean;
  error?: boolean;
  readOnlyLabel?: string;
} & Omit<
  AutocompleteProps<
    IdLabel | string,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >,
  'renderInput'
>;
const IAMultiSelect = (props: SelectProps) => {
  const {
    onChange,
    placeholder = '',
    id,
    multiple = true,
    disableClearable = true,
    variant = 'outlined',
    className,
    label,
    isChip,
    removeItem,
    disabled,
    error,
    disablePortal = true,
    readOnlyLabel,
    ...rest
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  const handleInput = (display: string) => {
    if (inputRef?.current) {
      inputRef.current.style.display = display;
    }
    if (display === 'none') {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const getDeleteIcon = (item: SelectValue) => {
    if (item.label !== readOnlyLabel) {
      return (
        <span
          onMouseDown={() => {
            if (removeItem && !disabled) {
              removeItem(item.id, item.label);
            }
          }}
        >
          <Cross fill='#0B41CD' />
        </span>
      );
    }
    return <></>;
  };

  const getSelectedChipData = (selectedData: SelectValue[]) => {
    if (!selectedData?.length) {
      return [];
    }
    const readOnlyItem = selectedData.find((item) => item.label === readOnlyLabel);
    if (!readOnlyItem) {
      return selectedData;
    }
    return [readOnlyItem, ...selectedData.filter((item) => item.label !== readOnlyLabel)];
  };

  return (
    <div className='ia-autocomplete'>
      {label && <label htmlFor={label}>{label}</label>}

      <Autocomplete
        {...rest}
        open={open}
        className={className}
        disableClearable={!(rest.value as (string | IdLabel)[])?.length || disableClearable}
        multiple={multiple}
        disablePortal={disablePortal}
        id={id}
        disabled={disabled}
        onClose={() => handleInput('none')}
        renderOption={(renderOptionProps, option, { selected }) => (
          <li {...renderOptionProps}>
            {multiple && <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />}
            {typeof option === 'string' ? option : option.label}
          </li>
        )}
        renderInput={(params) => (
          <div
            className={disabled ? 'disabled' : ''}
            onClick={() => {
              handleInput(!open ? 'unset' : 'none');
              if (inputRef?.current) {
                inputRef.current.focus();
              }
            }}
          >
            <TextField
              {...params}
              placeholder={
                !props.value || !(props.value as SelectValue[]).length ? placeholder : ''
              }
              variant={variant}
              inputRef={inputRef}
              error={error}
              disabled={disabled}
              helperText={error ? 'This field is required' : ''}
            />
          </div>
        )}
        isOptionEqualToValue={(option, value) =>
          option === value ||
          (!!(option as IdLabel).id &&
            !!(option as IdLabel).label &&
            ((option as IdLabel).id === value ||
              (option as IdLabel).label === value ||
              (option as IdLabel).id === (value as IdLabel).id))
        }
        renderTags={(selected) =>
          isChip ? (
            <Stack gap={1} direction='row' flexWrap='wrap'>
              {getSelectedChipData(selected as SelectValue[]).map((value) => (
                <Chip
                  key={value.id}
                  label={value.label}
                  onDelete={() => {}}
                  deleteIcon={getDeleteIcon(value)}
                  avatar={<Avatar alt={value.label}>{getInitials(value.label)}</Avatar>}
                />
              ))}
            </Stack>
          ) : (
            <>
              {selected
                .map((option) => (typeof option === 'string' ? option : option.label))
                .join()}
            </>
          )
        }
        popupIcon={<Arrow />}
        onChange={onChange}
        style={props.disabled ? { backgroundColor: '#FBFBFA' } : {}}
        disableCloseOnSelect
      />
    </div>
  );
};

export default IAMultiSelect;
