import { useState, useEffect } from 'react';
import { Outlet, useLocation, useLoaderData, Navigate, useParams } from 'react-router-dom';
// import LogoTitle from '../../assets/svg/ia-logo-title.svg';
import SideMenuList from '../../components/SideMenu/SideMenu';
import { Header } from '../../components/Header/Header';
import './Layout.scss';
import UserGuide from '../../components/UserGuide/UserGuide';
import { useDispatch, useSelector } from 'react-redux';
import { getSystemDefinitions } from '../../state/actions/system';
import { handleProjectHeaderCells } from '../../state/actions/project';
import {
  handleWorkingDocumentHeaderCells,
  getWorkingDocumentById,
} from '../../state/actions/workingDocument';
import { handleTemplateHeaderCells, getTemplateById } from '../../state/actions/template';
import { UserInteface } from '../../core/models/user/user';
import ConfidentialityDisclaimer from '../../components/ConfidentialityDisclaimer/ConfidentialityDisclaimer';
import { SystemDefinitionInterface } from '../../core/models/system/systemDefinition';
import {
  getFavoriteDocuments,
  getFavoriteProjects,
  getFavoriteTemplates,
} from '../../state/actions/favorite';
import { GlobalState } from '../../core/models/state/globalState';

export const Layout = () => {
  const dispatch = useDispatch();
  const { id, type } = useParams();
  const location = useLocation();
  const user = useLoaderData() as UserInteface;
  if (!user) {
    return <Navigate to={`/?pathname=${location.pathname}${location.hash}`} />;
  }
  const [isdeMenuCollapsed, setIsdeMenuCollapsed] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const isEditPage =
    location.pathname.includes('/edit') || location.pathname.includes('/data-dashboard');
  const { documents, templates, projects } = useSelector((state: GlobalState) => state.favorite);

  useEffect(() => {
    dispatch(
      getSystemDefinitions((err: unknown, systemDefinitions: SystemDefinitionInterface) => {
        dispatch(handleProjectHeaderCells(systemDefinitions.project.fields));
        dispatch(handleWorkingDocumentHeaderCells(systemDefinitions.document.fields));
        dispatch(handleTemplateHeaderCells(systemDefinitions.template.fields));
        if (type && id) {
          if (type === 'template') {
            dispatch(getTemplateById(() => {}, id));
          } else if (type === 'document') {
            dispatch(getWorkingDocumentById(() => {}, id));
          }
        }
      }),
    );
    dispatch(getFavoriteProjects(() => {}, projects));
    dispatch(getFavoriteDocuments(() => {}, documents));
    dispatch(getFavoriteTemplates(() => {}, templates));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setIsdeMenuCollapsed(windowSize < 992);
  }, [windowSize]);

  const toggleDrawer = (open: boolean) => {
    if (open && !isdeMenuCollapsed && !isSidebarOpen) {
      setIsdeMenuCollapsed(true);
    }

    setIsSidebarOpen(open);
  };

  function onSideMenuClickedHandler() {
    setIsdeMenuCollapsed(!isdeMenuCollapsed);
  }

  const getAppSidebarClassName = () =>
    `app__sidebar ${
      isdeMenuCollapsed || location.pathname.includes('/edit') ? 'minimized' : 'maximized'
    } ${location.pathname.includes('/edit') ? 'justify-content-center' : ''}`;

  return (
    <>
      <div>
        {/* <Navbar />
          <SubNavBar /> */}
        <Header user={user} />
        <div>
          <div
            className={`app ${isEditPage ? 'app--withoutSubNav app--minimized' : ''} ${
              isdeMenuCollapsed ? 'app--minimized' : ''
            } ${isSidebarOpen ? 'app--guideOpen' : ''}`}
          >
            <UserGuide isSidebarOpen={isSidebarOpen} toggleDrawer={toggleDrawer} />

            <div
              className={getAppSidebarClassName()}
              style={location.pathname.includes('/edit') ? {} : { height: 'calc(100vh - 76px)' }}
            >
              <SideMenuList
                collapseState={isdeMenuCollapsed || isEditPage}
                hideDoubleArrows={isEditPage}
                onSideMenuClicked={onSideMenuClickedHandler}
                toggleDrawer={toggleDrawer}
              />
            </div>
            <Outlet context={{ isdeMenuCollapsed }} />
            <ConfidentialityDisclaimer />
          </div>
        </div>
      </div>
      <div id='background-cover'></div>
    </>
  );
};
