import { AnyAction } from 'redux';
import { FilterActionTypes } from '../actions/filter';
import { FilterTypes, FilterStateInterface } from '../../core/models/filter/filterState';
import { LovValue } from '../../core/models/lovValue';
import { DefinitioniInterface } from '../../core/models/system/systemDefinition';
import { applyNewFilters } from '../../core/models/filter/filter';

const initalFiltersApplied = {
  projectFilters: [],
  documentFilters: [],
  templateFilters: [],
  outputFilters: [],
};

const initialState: FilterStateInterface = {
  filtersApplied: JSON.parse(
    localStorage.getItem('appliedFilters') ?? JSON.stringify(initalFiltersApplied),
  ),
  projectCategories: [],
  documentCategories: [],
  templateCategories: [],
  outputCategories: [],
  values: [],
};

const handleSetValues = (state: FilterStateInterface, action: AnyAction) => {
  const values = action.payload.map((lov: LovValue) => lov.label);
  return {
    ...state,
    values,
  };
};

const handleSetFilters = (state: FilterStateInterface, action: AnyAction) => {
  const { filtersApplied } = state;
  const from: FilterTypes = action.from;
  filtersApplied[from] = action.payload;
  localStorage.setItem('appliedFilters', JSON.stringify(filtersApplied));
  action.callback();
  return {
    ...state,
    documentCategories: [...state.documentCategories],
    filtersApplied: {
      ...filtersApplied,
    },
  };
};

const handleApplyNewFilters = (state: FilterStateInterface, action: AnyAction) => {
  const { filtersApplied } = state;
  const from: FilterTypes = action.from;
  const newFiltersApplies = applyNewFilters(filtersApplied, from, action.payload);
  localStorage.setItem('appliedFilters', JSON.stringify(newFiltersApplies));
  action.callback();
  return {
    ...state,
    documentCategories: [...state.documentCategories],
    filtersApplied: {
      ...newFiltersApplies,
    },
  };
};

const handleRemoveFilterById = (state: FilterStateInterface, action: AnyAction) => {
  const { filtersApplied } = state;
  const from: FilterTypes = action.from;
  const index = filtersApplied[from].findIndex((el) => el.id === action.payload);
  filtersApplied[from].splice(index, 1);
  localStorage.setItem('appliedFilters', JSON.stringify(filtersApplied));

  return {
    ...state,
    filtersApplied: {
      ...filtersApplied,
    },
  };
};
export default function filter(state = initialState, action: AnyAction) {
  switch (action.type) {
    case FilterActionTypes.SET_PROJECT_CATEGORIES: {
      return {
        ...state,
        projectCategories: action.payload.filter(
          (el: DefinitioniInterface) => el.id !== 'FIAPOBJECTTYPE',
        ),
      };
    }
    case FilterActionTypes.SET_TEMPLATE_CATEGORIES: {
      return {
        ...state,
        templateCategories: action.payload.filter(
          (el: DefinitioniInterface) => el.id !== 'FIAPOBJECTTYPE',
        ),
      };
    }
    case FilterActionTypes.SET_DOCUMENT_CATEGORIES: {
      return {
        ...state,
        documentCategories: action.payload.filter(
          (el: DefinitioniInterface) => el.id !== 'FIAPOBJECTTYPE',
        ),
      };
    }
    case FilterActionTypes.SET_OUTPUT_CATEGORIES: {
      return {
        ...state,
        outputCategories: action.payload,
      };
    }

    case FilterActionTypes.SET_VALUES:
      return handleSetValues(state, action);
    case FilterActionTypes.SET_FILTERS:
      return handleSetFilters(state, action);
    case FilterActionTypes.APPLY_NEW_FILTERS:
      return handleApplyNewFilters(state, action);
    case FilterActionTypes.REMOVE_APPLIED_FILTER_BY_ID:
      return handleRemoveFilterById(state, action);
    default:
      return state;
  }
}
