import { AnyAction } from 'redux';
import { systemActionTypes } from '../actions/system';

const initialState = {
  document: {
    fields: [],
    lovs: [],
  },
  project: {
    fields: [],
    lovs: [],
  },
  template: {
    fields: [],
    lovs: [],
  },
  output: {
    fields: [],
    lovs: [],
  },
};

export default function system(state = initialState, action: AnyAction) {
  switch (action.type) {
    case systemActionTypes.GET_SYSTEM_DEFINITIONS_SUCCESS:
      return {
        ...state,
        document: action.payload.document,
        output: action.payload.output,
        project: action.payload.project,
        template: action.payload.template,
      };
    case systemActionTypes.GET_PROJECT_LOVS_SUCCESS: {
      const { fields } = state.project;
      return {
        ...state,
        project: {
          fields,
          lovs: action.payload,
        },
      };
    }
    case systemActionTypes.GET_DOCUMENT_LOVS_SUCCESS: {
      const { fields } = state.document;
      return {
        ...state,
        document: {
          fields,
          lovs: action.payload,
        },
      };
    }
    case systemActionTypes.GET_TEMPLATE_LOVS_SUCCESS: {
      const { fields } = state.template;
      return {
        ...state,
        template: {
          fields,
          lovs: action.payload,
        },
      };
    }
    case systemActionTypes.GET_OUTPUT_LOVS_SUCCESS: {
      const { fields } = state.output;
      return {
        ...state,
        output: {
          fields,
          lovs: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
