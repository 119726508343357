import { outputActionTypes } from '../actions/output';
import { Output } from '../../core/models/output/output';
import { FieldResponseInterface } from '../../core/models/system/systemDefinition';
import { OutputState } from '../../core/models/output/outputState';
import { AnyAction } from 'redux';
import { OutputFormats, OutputInterface } from '../../core/models/output/outputInterface';
import { defaultTableHeadCells } from '../../constants';
import { transformFieldsToObject } from '../../core/services/apiTranformations';
import { getOutputFormat } from '../../core/services/helpers';

export const initialState: OutputState = {
  output: new Output({}),
  outputs: [],
  tableHeadCells: defaultTableHeadCells.output,
  tableHeadCellsTransffered: defaultTableHeadCells.transferredOutput,
  tableRows: [],
  totalItems: 0,
};

const handleGetOutputsSuccess = (state: OutputState, action: AnyAction) => {
  const data = action.payload.items;
  const totalItems = action.payload.total;
  const tableRows: OutputInterface[] = [];
  data.forEach((item: { id: string; isFavorite: boolean; fields: FieldResponseInterface[] }) => {
    let itemObject = Output.createOutput({
      id: item.id,
    }) as OutputInterface;
    itemObject = transformFieldsToObject(item.fields, [], false, itemObject);
    itemObject.FISHOUTPUTFORMATREF = getOutputFormat(
      itemObject.FISHOUTPUTFORMATREF as OutputFormats,
    );

    tableRows.push(itemObject);
  });
  return {
    ...state,
    outputs: action.payload,
    tableRows,
    totalItems,
  };
};

const outputReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case outputActionTypes.GET_OUTPUTS_SUCCESS:
      return handleGetOutputsSuccess(state, action);
    default:
      return state;
  }
};

export default outputReducer;
