import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import {
  favoriteActionTypes,
  getFavoriteDocumentsSuccess,
  getFavoriteProjectsSuccess,
  getFavoriteTemplatesSuccess,
} from '../actions/favorite';
import { SystemStateInterface } from '../../core/models/system/systemState';
import { getWorkingDocumentByIdWithErrorHandling } from '../../core/api/workingDocument.api';
import { getTemplateByIdWithErrorHandling } from '../../core/api/template.api';
import { getProjectByIdWithErrorHandling } from '../../core/api/project.api';
import { GlobalState } from '../../core/models/state/globalState';
import {
  setProjectCategories,
  setDocumentCategories,
  setTemplateCategories,
} from '../actions/filter';
import { ItemsInterface } from '../../core/models/system/systemDefinition';
import { TYPES_NO_FILTERING } from '../../constants';

const getProjectFields = (state: GlobalState) => state.system;
const getTemplateFields = (state: GlobalState) => state.system;
const getWorkingDocumentFields = (state: GlobalState) => state.system;

export function* getFavoriteWorkingDocuments(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: string[];
}) {
  try {
    let workingDocuments: ItemsInterface[] = yield all(
      action.payload.map((id) => call(() => getWorkingDocumentByIdWithErrorHandling(id))),
    );
    workingDocuments = workingDocuments.filter((wd) => wd);

    const favorites = localStorage.getItem('favorites') as string;
    const parsedFavorites = favorites ? JSON.parse(favorites) : {};
    localStorage.setItem(
      'favorites',
      JSON.stringify({
        projects: parsedFavorites.projects,
        documents: workingDocuments.map((wd) => wd.id),
        templates: parsedFavorites.templates,
      }),
    );

    const { document }: SystemStateInterface = yield select(getWorkingDocumentFields) || [];
    yield put(
      setDocumentCategories(
        () => {},
        document.fields
          .filter((field) => field.type === 'lov' && !field.id.startsWith('FCUS'))
          .map((field) => ({ id: field.id, label: field.label, type: field.type })),
      ),
    );

    yield put(getFavoriteDocumentsSuccess(workingDocuments));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}
export function* getFavoriteTemplates(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: string[];
}) {
  try {
    let templates: ItemsInterface[] = yield all(
      action.payload.map((id) => call(() => getTemplateByIdWithErrorHandling(id))),
    );
    templates = templates.filter((el) => el);

    const favorites = localStorage.getItem('favorites') as string;
    const parsedFavorites = favorites ? JSON.parse(favorites) : {};
    localStorage.setItem(
      'favorites',
      JSON.stringify({
        projects: parsedFavorites.projects,
        documents: parsedFavorites.documents,
        templates: templates.map((el) => el.id),
      }),
    );

    const { template }: SystemStateInterface = yield select(getTemplateFields) || [];
    yield put(
      setTemplateCategories(
        () => {},
        template.fields
          .filter(
            (field) =>
              field.flags?.isFilterable === true && !TYPES_NO_FILTERING.includes(field.type),
          )
          .map((field) => ({ id: field.id, label: field.label, type: field.type })),
      ),
    );

    yield put(getFavoriteTemplatesSuccess(templates));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}
export function* getFavoriteProjects(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: string[];
}) {
  try {
    let projects: ItemsInterface[] = yield all(
      action.payload.map((id) => call(() => getProjectByIdWithErrorHandling(id))),
    );
    projects = projects.filter((el) => el);

    const favorites = localStorage.getItem('favorites') as string;
    const parsedFavorites = favorites ? JSON.parse(favorites) : {};
    localStorage.setItem(
      'favorites',
      JSON.stringify({
        projects: projects.map((el) => el.id),
        documents: parsedFavorites.documents,
        templates: parsedFavorites.templates,
      }),
    );

    const { project }: SystemStateInterface = yield select(getProjectFields) || [];
    yield put(
      setProjectCategories(
        () => {},
        project.fields
          .filter(
            (field) =>
              field.flags?.isFilterable === true && !TYPES_NO_FILTERING.includes(field.type),
          )
          .map((field) => ({ id: field.id, label: field.label, type: field.type })),
      ),
    );

    yield put(getFavoriteProjectsSuccess(projects));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export default function* favoritesWatcher() {
  yield takeLatest(favoriteActionTypes.GET_FAVORITE_DOCUMENTS, getFavoriteWorkingDocuments);
  yield takeLatest(favoriteActionTypes.GET_FAVORITE_PROJECTS, getFavoriteProjects);
  yield takeLatest(favoriteActionTypes.GET_FAVORITE_TEMPLATES, getFavoriteTemplates);
}
