import { useState, useEffect } from 'react';
import IADialog from '../Dialog/Dialog';
import { Grid, CircularProgress } from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import { SelectValue, Member } from '../../core/models/global';
import { getInitials } from '../../core/services/userService';
import IASelect, { AutocompleteValueT } from '../Select/Select';
import { UserInteface } from '../../core/models/user/user';
import Cross from '../icons/Cross';
import IASearch from '../Search/Search';

interface TemplateMembersDialogProps {
  open: boolean;
  handleClose: () => void;
  save?: (data: Member[]) => void;
  addMembers?: (members: Member[]) => void;
  addedMembers: Member[];
  saveInProcess?: boolean;
  templateCreator?: UserInteface;
  allUsers?: SelectValue[];
  isView?: boolean;
}

const TemplateMembersDialog = (props: TemplateMembersDialogProps) => {
  const {
    open,
    handleClose,
    addMembers,
    addedMembers = [],
    save,
    saveInProcess,
    templateCreator,
    allUsers = [],
    isView,
  } = props;
  const allMembers = allUsers.filter((item) => item.label !== templateCreator?.displayName);
  const [members, setMembers] = useState<Member[]>(
    addedMembers.filter((el) => el.label !== templateCreator?.displayName),
  );

  const roles = [
    { id: 'FIAPAUTHORLIST', label: 'Author' },
    { id: 'FIAPREVIEWER', label: 'Reviewer' },
  ];

  useEffect(() => {
    if (!saveInProcess) {
      setMembers(addedMembers.filter((el) => el.label !== templateCreator?.displayName));
    }
  }, [addedMembers]);

  const handleMembers = (userId: string, value?: AutocompleteValueT) => {
    if (value) {
      const { label } = value as { id: string; label: string };
      const member = members.find((item) => item.value === userId);
      if (member) {
        member.role = label;
        setMembers([...members]);
      }
    }
  };

  const handleMemberSelection = (value: SelectValue) => {
    const member = members.find((memberItem) => memberItem.value === value.id);
    if (!member) {
      members.unshift({
        role: '',
        label: value.label,
        value: value.id,
      });
      setMembers([...members]);
    }
  };

  const searchMembers = (text: string) => {
    const regex = new RegExp(text.toLowerCase(), 'g');
    const result = addedMembers.filter((member) =>
      (member.label as string).toLowerCase().match(regex),
    );
    setMembers(result);
  };

  const getSearchOrSelect = () => {
    if (isView) {
      return (
        <IASearch
          style={{ width: '100%' }}
          handleSearch={(value: string) => searchMembers(value)}
        />
      );
    }
    return (
      <IASelect
        data-testid='template-members'
        label=''
        placeholder='Select member'
        options={allMembers.filter((el) => !members.find((member) => member.value === el.id))}
        onChange={(_, value) => handleMemberSelection(value as SelectValue)}
        value=''
        disablePortal={false}
        disabled={!!members.length && !members[0]?.role}
      />
    );
  };

  const getRole = (element: Member) => {
    if (isView) {
      return (
        <div>
          <div className='role-name-dialog'>{element.role}</div>
        </div>
      );
    }
    return (
      <>
        <Grid item xs={3} style={{ marginLeft: '100px' }}>
          <IASelect
            label=''
            placeholder='Assign role'
            options={roles}
            onChange={(_, value) => handleMembers(element.value as string, value)}
            value={element.role}
            error={false}
          />
        </Grid>
        {!isView && (
          <Cross
            style={{ cursor: 'pointer' }}
            onClick={() => setMembers(members.filter((member) => member.value !== element.value))}
          />
        )}
      </>
    );
  };

  const getMainButtonText = () => {
    if (isView) {
      return;
    }
    if (saveInProcess) {
      return 'Saving';
    }
    return 'Save';
  };

  const getTitle = () => {
    if (isView) {
      return `${members.length} member${members.length > 1 ? 's' : ''}`;
    }
    if (save) {
      return 'Update members';
    }
    return 'Add members';
  };

  return (
    <IADialog
      title={`${getTitle()}`}
      open={open}
      handleClose={handleClose}
      handleSubmit={() => {
        if (save) {
          save(members.filter((el) => el.role));
        } else if (addMembers) {
          addMembers([...members.filter((el) => el.role)]);
        }
      }}
      xButton
      mainBtnText={getMainButtonText()}
      maxWidth='sm'
      disabled={saveInProcess}
      cancelButton={!isView}
    >
      {!isView && (
        <div className='add-member-description' data-testid='add-member-description'>
          Search a member and assign the role to continue.
        </div>
      )}
      <div className='members' data-testid='members'>
        {templateCreator && (
          <Grid container className='member-row'>
            <Grid item xs={6} className='creator-avatar'>
              <AvatarGroup className='project-card-avatar'>
                <Avatar alt={templateCreator.displayName}>
                  {getInitials(templateCreator.displayName)}
                </Avatar>
              </AvatarGroup>
              <span className='member-name'>{templateCreator.displayName}</span>
            </Grid>

            <Grid item xs={3}>
              <IASelect
                label=''
                placeholder=''
                options={roles}
                onChange={() => {}}
                value='Author'
                disabled={true}
                error={false}
              />
            </Grid>
          </Grid>
        )}
        {getSearchOrSelect()}
        {members?.map((el) => (
          <Grid
            container
            className='member-row'
            key={el.value as string}
            style={{ marginTop: '16px' }}
          >
            <Grid item xs={6} className='creator-avatar'>
              <AvatarGroup className='project-card-avatar'>
                <Avatar alt={el.label}>{getInitials(el.label as string)}</Avatar>
              </AvatarGroup>
              <span className='member-name'>{el.label}</span>
            </Grid>
            {getRole(el)}
          </Grid>
        ))}
      </div>
      {saveInProcess && (
        <>
          <div className='loader-container with-bg'>
            <CircularProgress value={30} size='80px' />
          </div>
          <div className='loader-background'></div>
        </>
      )}
    </IADialog>
  );
};

export default TemplateMembersDialog;
