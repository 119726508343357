import { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import IADialog from '../Dialog/Dialog';
import { Member, SelectValue } from '../../core/models/global';
import IAMultiSelect from '../../components/MultiSelect/MultiSelect';
import { AutocompleteValueT } from '../../components/Select/Select';
import IAButton from '../Button/Button';

interface ProjectMembersDialogProps {
  open: boolean;
  handleClose: () => void;
  save: (data: SelectValue[]) => void;
  existingMembers: SelectValue[];
  memberList: SelectValue[];
  saveInProcess?: boolean;
  creator?: string;
  allDocMembers?: { [key in 'docTitle' | keyof Member]: string }[];
}

const ProjectMembersDialog = (props: ProjectMembersDialogProps) => {
  const {
    open,
    handleClose,
    memberList,
    existingMembers,
    save,
    saveInProcess,
    creator,
    allDocMembers,
  } = props;
  const [members, setMembers] = useState<SelectValue[]>(existingMembers || []);
  const [existingDocMembers, setExistingDocMembers] = useState<
    { [key in 'docTitle' | keyof Member]: string }[]
  >([]);

  useEffect(() => {
    setMembers(existingMembers || []);
  }, [existingMembers]);

  const onChange = (value: AutocompleteValueT) => {
    setMembers(value as SelectValue[]);
  };

  const removeItem = (id: string, label: string) => {
    const existingMemberItems = allDocMembers?.filter((item) => item.label === label);
    if (existingMemberItems?.length) {
      setExistingDocMembers(existingMemberItems);
      return;
    }
    setMembers(members.filter((member) => member.id !== id));
  };

  return existingDocMembers.length ? (
    <IADialog
      title='Restricted member'
      open={open}
      handleClose={() => setExistingDocMembers([])}
      handleSubmit={() => save(members)}
      xButton
      maxWidth='md'
      customButton={
        <IAButton className='back-btn' handleClick={() => setExistingDocMembers([])}>
          Back
        </IAButton>
      }
    >
      <div className='publish-text' data-testid='publish-text'>
        <p>
          Sorry, you cannot remove{' '}
          <span style={{ color: '#0066CC', fontWeight: 'bold' }}>
            {existingDocMembers[0].label}
          </span>{' '}
          from the workspace because they are part of the{' '}
          <span style={{ color: '#0066CC', fontWeight: 'bold' }}>
            {existingDocMembers.map((el) => el.docTitle).join(', ')}
          </span>{' '}
          document{`${existingDocMembers.length > 1 ? 's' : ''}`}. If you wish to remove this user
          from the workspace, please ensure that you first delete them from the document
          {`${existingDocMembers.length > 1 ? 's' : ''}`}.
        </p>
      </div>
    </IADialog>
  ) : (
    <IADialog
      title={`${members.length || 'Add'} workspace member${members.length === 1 ? '' : 's'}`}
      open={open}
      handleClose={handleClose}
      handleSubmit={() => save(members)}
      xButton
      mainBtnText={saveInProcess ? 'Saving...' : 'Save'}
      maxWidth='md'
      disabled={saveInProcess}
    >
      <IAMultiSelect
        data-testid='Workspace members (optional)'
        label='Workspace members (optional)'
        placeholder='Add workspace members'
        options={memberList}
        onChange={(_, value) => onChange(value)}
        value={members}
        isChip
        getOptionDisabled={(option) =>
          !!members.find((el) => el.label === creator) && (option as SelectValue).label === creator
        }
        disablePortal={false}
        componentsProps={{
          popper: {
            modifiers: [
              {
                name: 'flip',
                enabled: false,
              },
            ],
          },
        }}
        removeItem={(id: string, label: string) => label !== creator && removeItem(id, label)}
        readOnlyLabel={creator}
      />
      {saveInProcess && (
        <>
          <div className='loader-container with-bg'>
            <CircularProgress value={30} size='80px' />
          </div>
          <div className='loader-background'></div>
        </>
      )}
    </IADialog>
  );
};

export default ProjectMembersDialog;
