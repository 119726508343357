import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../core/models/state/globalState';
import CircularProgress from '@mui/material/CircularProgress';
import IATabs from '../../components/Tabs/Tabs';
import TabPanel from '../../components/DashboardTable/TabPanel';
import IAButton from '../../components/Button/Button';
import {
  getProjectById,
  getProjectMetadataValues,
  updateProject,
} from '../../state/actions/project';
import { getWorkingDocuments } from '../../state/actions/workingDocument';
import Columns from '../../components/Columns/Columns';
import IATableHeader from '../../components/TableHeader/TableHeader';
import IATable from '../../components/Table/Table';
import { WorkingDocumentInterface } from '../../core/models/workingDocument/workingDocumentInterface';
import NoSearchResults from '../../components/NoSearchResults/NoSearchResults';
import { Order, OrderByInterface } from '../../core/models/order';
import { setFavorite } from '../../state/actions/favorite';
import { Alert, Snackbar, Tooltip } from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import './ProjectView.scss';
import { FilterIterface } from '../../core/models/filter/filter';
import { setFilters } from '../../state/actions/filter';
import { Links } from '../../components/WorkspaceLinks/WorkspaceLinks';
import DataPreviewList from '../../components/DataPreview/DataPreviewList';
import Star from '../../components/icons/Star';
import AddMember from '../../components/icons/AddMember';
import ProjectMembersDialog from '../../components/ProjectSteps/ProjectMembersDialog';
import { DEFAULT_ORDER, memberHeadCell } from '../../constants';
import { disableEventsClass } from '../../core/services/helpers';
import { getInitials } from '../../core/services/userService';
import { SelectValue, Member } from '../../core/models/global';
import { RequiredMetadata } from '../../core/models/workingDocument/requiredMetadata';
import DocumentMembersDialog from '../../components/DocumentSteps/DocumentMembersDialog';

export const ProjectView = () => {
  const { id: projectId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: GlobalState) => state.user);
  const {
    document: { fields: wdFields },
    project: { lovs },
  } = useSelector((state: GlobalState) => state.system);
  const { project } = useSelector((state: GlobalState) => state.project);
  const { tableRows, tableHeadCells, totalItems } = useSelector(
    (state: GlobalState) => state.workingDocument,
  );
  const { documentCategories, values, filtersApplied } = useSelector(
    (state: GlobalState) => state.filter,
  );

  const [isDataLoaded, setIsDataLoaded] = useState(true);
  const [isWDDataLoaded, setIsWDDataLoaded] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [alertText, setAlertText] = useState<string>('');
  const [orderBy, setOrderBy] = useState<OrderByInterface | undefined>(DEFAULT_ORDER);
  const [order, setOrder] = useState<Order>('desc');

  const [searchQuery, setSearchQuery] = useState('');
  const [pageAndSize, setPageAndSize] = useState({ page: 1, size: 10 });
  const [iconColor, setIconColor] = useState('');
  const [productFamilies, setProductFamilies] = useState('');
  const [membersDialog, setMembersDialog] = useState(false);
  const [members, setMembers] = useState<SelectValue[]>([]);
  const [memberList, setMemberList] = useState<SelectValue[]>([]);
  const [saveInProcess, setSaveInProcess] = useState(false);
  const [docMembers, setDocMembers] = useState<Member[]>([]);
  const [canChange, setCanChange] = useState(false);
  const fieldsToHide = ['FIAPOBJECTTYPE', 'FIAPAUTHORLIST', 'FIAPREVIEWER'];

  const handleUsers = (fields: RequiredMetadata[]) => {
    const field = fields.find((el) => el.id === 'FIAPPROJECTASSIGNEE');
    if (field) {
      setMemberList(
        field.values.map((item) => ({
          id: item.value as string,
          label: item.label as string,
        })),
      );
    }
  };

  useEffect(() => {
    setCanChange(
      !!user?.isAdmin || !!(user?.isAuthor && !!members?.find((el) => el.id === user.userId)),
    );
  }, [user, members?.length]);

  useEffect(() => {
    dispatch(
      getProjectMetadataValues(
        (err, fields: RequiredMetadata[]) => {
          if (!err && fields) {
            handleUsers(fields);
          }
        },
        {
          fields: [],
        },
      ),
    );
  }, [lovs]);

  const getData = (load = true, alert = '', filterFields = filtersApplied.documentFilters) => {
    if (load) {
      setIsWDDataLoaded(false);
    }
    dispatch(
      getWorkingDocuments(
        (err: unknown) => {
          setIsWDDataLoaded(true);
          if (!err && alert) {
            setAlertText(alert);
          }
        },
        {
          page: pageAndSize.page,
          size: pageAndSize.size,
          filterFields: [
            ...filterFields,
            {
              id: 'FIAPPROJECTREF',
              operator: 'Equal',
              value: [projectId ?? ''],
              type: 'projectReference',
            },
          ],
          orderBy,
        },
      ),
    );
  };

  const getSelectedMembers = () => {
    const arr = (project?.FIAPPROJECTASSIGNEE as string)?.split(',') || [];
    return memberList.filter((el) => arr.includes(el.label));
  };

  const getProjectData = () => {
    setIsDataLoaded(false);
    dispatch(
      getProjectById(() => {
        setIsDataLoaded(true);
      }, projectId as string),
    );
  };

  useEffect(() => {
    if (projectId) {
      getProjectData();
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      getData();
    }
  }, [pageAndSize, wdFields, filtersApplied, orderBy]);

  useEffect(() => {
    if (memberList?.length) {
      setMembers(getSelectedMembers());
    }
  }, [memberList]);

  useEffect(() => {
    const filterFields: FilterIterface[] = [];
    if (searchQuery.length > 0) {
      filterFields.push({
        id: 'FTITLE',
        type: 'string',
        operator: 'Like',
        value: [`%${searchQuery}%`],
      });
    }
    dispatch(setFilters(() => {}, 'documentFilters', filterFields));
    getData(true, '', filterFields);
  }, [searchQuery]);

  const headCells = tableHeadCells.map((cell) => {
    const field = wdFields.find((item) => item.id === cell.id);
    const label = cell.id === memberHeadCell.id ? memberHeadCell.label : field?.label;
    return {
      id: cell.id,
      label: label ?? '',
      isSortable: field?.flags?.isSortable,
    };
  });
  const isFiltersApplied = () => {
    return !!filtersApplied?.documentFilters?.length;
  };
  const isEmptyState = () => isWDDataLoaded && !tableRows?.length && !isFiltersApplied();

  const handleSort = (id: string) => {
    const isAsc = orderBy?.id === id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy({ id, order: isAsc ? 'Descending' : 'Ascending' });
  };
  const handleFavoriteClick = (id: string) => {
    const document = tableRows.find((item) => item.id === id);

    if (document) {
      dispatch(
        setFavorite(
          () => {
            setAlertText(
              `Document ${document.FTITLE ?? ''} is ${
                !document.isFavorite ? 'removed from' : 'added to'
              } favorites`,
            );
          },
          'documents',
          document,
        ),
      );
    }
  };

  const handleProductFamiliy = () => {
    if (project.FCUSTPRODUCTFAMILY) {
      const productFamilyIds = (project.FCUSTPRODUCTFAMILY as string).split(',');
      setProductFamilies(
        (lovs?.DCUSTPRODUCTFAMILY ?? [])
          .filter((item) => productFamilyIds.includes(item.id))
          .map((el) => el.label)
          .join(','),
      );
    } else {
      setProductFamilies('No product family');
    }
  };

  useEffect(() => {
    if (project.isFavorite) {
      setIconColor('#FF7D29');
    } else {
      setIconColor('');
    }
    handleProductFamiliy();
    setMembers(getSelectedMembers());
  }, [project]);

  useEffect(() => {
    handleProductFamiliy();
  }, [lovs]);

  const handleProjectFavoriteClick = () => {
    if (project) {
      const isFavorite = project.isFavorite;
      dispatch(
        setFavorite(
          () => {
            setAlertText(
              `Projecт ${project.FTITLE ?? ''} is ${
                isFavorite ? 'removed from' : 'added to'
              } favorites`,
            );
          },
          'projects',
          project,
        ),
      );
    }
  };

  const saveMembers = (data: SelectValue[]) => {
    setSaveInProcess(true);
    dispatch(
      updateProject(
        (err) => {
          setSaveInProcess(false);
          if (err) {
            console.log(err);
          } else {
            getProjectData();
            setMembersDialog(false);
          }
        },
        {
          id: projectId as string,
          body: {
            FIAPPROJECTASSIGNEE: data.map((el) => el.id),
          },
        },
      ),
    );
  };

  const getAvatarInfo = () => {
    if (project.FIAPPROJECTASSIGNEE) {
      return (
        <Tooltip title='Update members' placement='top'>
          <AvatarGroup
            className='project-card-avatar'
            max={3}
            onClick={() => {
              setMembers(getSelectedMembers());
              setMembersDialog(true);
            }}
          >
            <Avatar alt='add member'>
              <AddMember />
            </Avatar>
            {(project.FIAPPROJECTASSIGNEE as string)?.split(',').map((el) => (
              <Avatar key={el} alt={el}>
                {getInitials(el)}
              </Avatar>
            ))}
          </AvatarGroup>
        </Tooltip>
      );
    }
    return (
      <AvatarGroup className='project-card-avatar' max={4} onClick={() => setMembersDialog(true)}>
        <Tooltip title='Add member' placement='top'>
          <Avatar alt='add member'>
            <AddMember />
          </Avatar>
        </Tooltip>
      </AvatarGroup>
    );
  };

  const renderAvatar = () => {
    if (!!memberList?.length && isDataLoaded && isWDDataLoaded && canChange) {
      return <div style={{ cursor: 'pointer' }}>{getAvatarInfo()}</div>;
    }
    return <></>;
  };

  const getDocMember = (tableRow: WorkingDocumentInterface) => {
    const authors = tableRow.FIAPAUTHORLIST
      ? (tableRow.FIAPAUTHORLIST as string).split(',').map((item) => ({
          role: 'Author',
          label: item,
          value: item,
          docTitle: tableRow.FTITLE as string,
        }))
      : [];
    const reviewers = tableRow.FIAPREVIEWER
      ? (tableRow.FIAPREVIEWER as string).split(',').map((item) => ({
          role: 'Reviewer',
          label: item,
          value: item,
          docTitle: tableRow.FTITLE as string,
        }))
      : [];

    return [...authors, ...reviewers];
  };

  const getDocMembers = () => {
    let result: { [key in 'docTitle' | keyof Member]: string }[] = [];
    tableRows.forEach((tableRow) => {
      const memberData = getDocMember(tableRow);
      result = result.concat(memberData);
    });

    return result;
  };

  return (
    <div className='project-details'>
      <div className='page-header'>
        <PageTitle
          name={project?.id === projectId ? (project.FTITLE as string) : ''}
          icon={
            <div className='title-back-icon'>
              <ArrowBackIcon />
            </div>
          }
          onIconClick={() => navigate('/layout/workspaces')}
        />
        {project?.id === projectId && (
          <div className='title-buttons' data-testid='project-title-buttons'>
            <Star
              className='favorite-icon'
              fill={iconColor}
              stroke={iconColor}
              onClick={() => {
                handleProjectFavoriteClick();
              }}
            />
            <div className='status status-released' data-testid='project-status'>
              {project.FIAPPROJECTSTATUS ?? 'Open'}
            </div>
            <IAButton
              className='back-btn'
              handleClick={() => navigate('./details')}
              data-testid='project-title-main-action-btn'
            >
              View Details
            </IAButton>
          </div>
        )}
      </div>
      <div className='page-content'>
        {project?.id === projectId && (
          <div className='details-members-container' style={{ width: '100%' }}>
            <div className='details'>
              <div>
                <div className='detail-text'>Product Family</div>
                <div className='detail-text'>{productFamilies}</div>
              </div>
              <div>
                <div className='detail-text'>Workspace type</div>
                <div className='detail-text'>{project.FCUSTPROJECTTYPE}</div>
              </div>
              <div>
                <div className='detail-text'>Created By</div>
                <div className='detail-text'>{project.FIAPCREATEDBY}</div>
              </div>
              <div>
                <div className='detail-text'>Last Modified By</div>
                <div className='detail-text'>{project.FIAPMODIFIEDBY ?? '-'}</div>
              </div>
            </div>
            {renderAvatar()}
          </div>
        )}
        <div className='tabs'>
          <IATabs
            tabs={[{ label: 'Documents' }, { label: 'Links' }, { label: 'Data Preview' }]}
            handleTabChange={(index) => {
              setActiveTab(index);
            }}
          />
          <TabPanel className='tab-panel' index={0} value={activeTab}>
            {isDataLoaded ? (
              <>
                <div className='actions'>
                  <Columns
                    currentPage={'document'}
                    columns={[...wdFields, memberHeadCell].filter(
                      (field) => !fieldsToHide.includes(field.id) && !field.id.startsWith('FCUS'),
                    )}
                    headCells={headCells}
                    addSpace={canChange}
                  />
                  {canChange && (
                    <IAButton
                      className='create-new-doc main-action-btn'
                      handleClick={() =>
                        navigate(`/layout/working-documents/new?project=${projectId}`)
                      }
                      data-testid='new-wd-main-action-btn'
                    >
                      Create new document
                    </IAButton>
                  )}
                </div>

                <IATableHeader
                  className={disableEventsClass(!isWDDataLoaded)}
                  categories={documentCategories}
                  categoryValues={values}
                  handleSearch={(text: string) => {
                    setSearchQuery(text);
                  }}
                  type='documentFilters'
                />
                {isWDDataLoaded ? (
                  <>
                    {tableRows?.length ? (
                      <IATable
                        headCells={headCells}
                        visibleRows={tableRows}
                        handleClick={(rowItem) => {
                          navigate(`/layout/working-documents/${rowItem.id}`);
                        }}
                        orderBy={orderBy?.id}
                        order={order}
                        handleSort={handleSort}
                        favorite
                        handleFavoriteClick={handleFavoriteClick}
                        editIcon
                        // threeDots
                        goToEditor={(rowItem: WorkingDocumentInterface) => {
                          navigate(`/layout/edit/document/${rowItem.id}/editor`);
                        }}
                        goToReview={(rowItem: WorkingDocumentInterface) => {
                          navigate(`/layout/edit/document/${rowItem.id}/review`);
                        }}
                        goToHistory={(rowItem: WorkingDocumentInterface) => {
                          navigate(`/layout/edit/document/${rowItem.id}/document-history`);
                        }}
                        count={pageAndSize.size}
                        page={pageAndSize.page}
                        pageChangeHandler={(page: number, size: number) => {
                          setPageAndSize({ page, size });
                        }}
                        totalItems={totalItems}
                        tutorial='document'
                        handleMembersDialog={(rowItem: WorkingDocumentInterface) => {
                          const memberData = getDocMember(rowItem);
                          setDocMembers(memberData);
                        }}
                        currentUser={user}
                      />
                    ) : (
                      <div className='loader-container'>
                        <NoSearchResults tutorial='document' isEmptyState={isEmptyState()} />
                      </div>
                    )}
                  </>
                ) : (
                  <div className='loader-container'>
                    <CircularProgress value={30} size='80px' />
                  </div>
                )}
              </>
            ) : (
              <div className='loader-container'>
                <CircularProgress value={30} size='80px' />
              </div>
            )}
          </TabPanel>
          <TabPanel className='tab-panel' index={1} value={activeTab}>
            <Links
              projectId={projectId as string}
              rows={project.FCUSTLINKS ? JSON.parse(project.FCUSTLINKS as string) : []}
              projectLoaded={isDataLoaded}
            />
          </TabPanel>
          <TabPanel className='tab-panel' index={2} value={activeTab}>
            <DataPreviewList
              dashboards={[
                {
                  title: 'PTDE DP Batch Analysis Dashboard',
                  url: `https://tabemeas.roche.com/views/PTDE_DP_Batch_Analysis_Dashboard/Dashboard1?:origin=card_share_link&:embed=y&product=&roche_number=${
                    project.FCUSTPRODFAMROCHENUM as string[]
                  }`,
                  description: 'Possible description value',
                },
                {
                  title: 'PTDC sLIMS Dashboard',
                  url: `https://tabemeas.roche.com/views/PTDC_sLIMS_Dashboard/Dashboard1?:origin=card_share_link&:embed=y&ptdc_group=&product_code=${
                    project.FCUSTPRODFAMFAMILYCODE as string[]
                  }`,
                  description: 'Possible description value',
                },
              ]}
              projectId={projectId as string}
            />
          </TabPanel>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!alertText}
        autoHideDuration={5000}
        onClose={() => setAlertText('')}
      >
        <Alert
          onClose={() => setAlertText('')}
          severity='success'
          variant='filled'
          sx={{ width: '100%' }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <ProjectMembersDialog
        open={membersDialog}
        handleClose={() => setMembersDialog(false)}
        save={(data) => saveMembers(data)}
        existingMembers={members}
        memberList={memberList}
        saveInProcess={saveInProcess}
        creator={project.FIAPCREATEDBY as string}
        allDocMembers={getDocMembers()}
      />
      <DocumentMembersDialog
        open={!!docMembers.length}
        handleClose={() => setDocMembers([])}
        addedMembers={docMembers}
        isView
      />
    </div>
  );
};
