import { useEffect, useState } from 'react';
import { ArrowForwardIos } from '@mui/icons-material';
import './Pagination.scss';
import { MenuItem, Menu } from '@mui/material';
import { IAInput } from '../Input/Input';

export interface PaginationProps {
  count: number;
  totalItems: number;
  page: number;
  pageChangeHandler: (page: number, size?: number) => void;
  isFromDashboard?: boolean;
  isDocCreation?: boolean;
}

const pageSizes = [10, 15, 20];

const Pagination = (props: PaginationProps) => {
  const { pageChangeHandler, totalItems, count, isFromDashboard, isDocCreation } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [page, setPage] = useState<number>(props.page || 1);
  const [startingFrom, setStartingFrom] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(isFromDashboard || isDocCreation ? count : 10);
  // const [upTo, setUpTo] = useState<number>(count ? count : 10);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setStartingFrom(0);
  }, [totalItems]);

  useEffect(() => {
    setPage(props.page);
  }, [props.page]);

  // useEffect(() => {
  //   if (count) {
  //     setUpTo(count);
  //   }
  // }, [count]);

  useEffect(() => {
    setPageSize(isFromDashboard || isDocCreation ? count : 10);
  }, [isFromDashboard, isDocCreation, count]);

  const getPagesCount = () => {
    return Math.ceil(totalItems / pageSize);
  };
  const handlePageSizeClick = (newPageSize: number) => {
    if (pageSize === newPageSize) {
      return;
    }
    if (newPageSize * page > totalItems && totalItems - newPageSize > 0) {
      // setUpTo(totalItems);
      setStartingFrom(totalItems - newPageSize);
      setPage(Math.ceil(totalItems / newPageSize));
    } else {
      setStartingFrom((page - 1) * newPageSize);
      // setUpTo(newPageSize * page);
    }
    setPageSize(newPageSize);
  };

  const paginationChangePage = (direction: string) => {
    if (page === 1 && direction === 'prev') {
      return;
    }
    if (page === getPagesCount() && direction === 'next') {
      return;
    }

    const valueShiftBy = direction === 'next' ? 1 : -1;

    let startFrom = startingFrom;
    setStartingFrom((prevValue: number) => {
      const newValue = direction === 'next' ? (prevValue += pageSize) : (prevValue -= pageSize);
      startFrom = newValue > 0 ? newValue : 0;
      return startFrom;
    });
    // setUpTo((prevValue: number) => {
    //   const newValue = direction === 'next' ? (prevValue += pageSize) : startFrom + pageSize;
    //   return newValue < totalItems ? newValue : totalItems;
    // });
    let newPage = 0;
    setPage((prevState: number) => {
      newPage = prevState += valueShiftBy;
      return newPage;
    });
    setTimeout(() => {
      pageChangeHandler(newPage, pageSize);
    }, 500);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPage = Number(event.target.value);
    if (isNaN(newPage) || newPage > getPagesCount() || newPage <= 0) {
      return;
    }
    setPage(newPage);
    setTimeout(() => {
      pageChangeHandler(newPage, pageSize);
    }, 500);
  };

  return (
    <div className='ia-pagination' data-testid='ia-pagination'>
      <div
        className='ia-pagination-size'
        data-testid='ia-pagination-size'
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {/* <span className='ia-pagination-name' data-testid='ia-pagination-name'>
          List
        </span>
        <div className='ia-pagination-count'>
          <span>
            {startingFrom + 1} to &nbsp;
            <span className='next'>{upTo}</span> / {totalItems}
          </span>
          {!isFromDashboard && (
            <div className='arrow-container'>
              <ArrowForwardIos
                style={{
                  transform: 'rotate(90deg)',
                }}
              />
            </div>
          )}
        </div> */}
      </div>
      {!isFromDashboard && (
        <Menu
          className='ia-page-menu'
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disablePortal
        >
          {pageSizes.map((ps, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                handlePageSizeClick(ps);
              }}
            >
              {ps}
            </MenuItem>
          ))}
        </Menu>
      )}

      <div className='ia-pagination-pages' data-testid='ia-pagination-pages'>
        {/* <span className='ia-pagination-name'>Page</span> */}
        <div className='ia-pagination-arrows'>
          <div
            className='arrow-container'
            onClick={() => {
              paginationChangePage('prev');
            }}
          >
            <ArrowForwardIos
              className='previous-page'
              style={{
                transform: 'rotate(180deg)',
              }}
            />
          </div>

          <div className='pagination-info'>
            <span className='shown-count' data-testid='shown-count'>
              <IAInput type='number' value={page} onChange={handleInputChange} />
            </span>
            <span className='total-count' data-testid='total-count'>
              of {getPagesCount()}
            </span>
          </div>
          <div
            className='arrow-container'
            onClick={() => {
              paginationChangePage('next');
            }}
          >
            <ArrowForwardIos className='next-page' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
