import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import {
  downloadOutputApi,
  getOutputs,
  getOutputsFromTransferService,
} from '../../core/api/output.api';
import { ListResponse } from '../../core/models/system/systemDefinition';
import { getOutputsSuccess, outputActionTypes } from '../actions/output';
import { GlobalState } from '../../core/models/state/globalState';
import { FilterIterface } from '../../core/models/filter/filter';
import { OrderByInterface } from '../../core/models/order';
import { SystemStateInterface } from '../../core/models/system/systemState';
import { OutputState } from '../../core/models/output/outputState';
import { setOutputCategories } from '../actions/filter';
import { systemActionTypes } from '../actions/system';

const getOutputFields = (state: GlobalState) => state.system;
const getOutputTableValues = (state: GlobalState) => state.output;

export function* getOutputsByPagination(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: {
    page: number;
    size: number;
    filterFields: FilterIterface[];
    orderBy: OrderByInterface;
    allFavorites?: boolean;
  };
}) {
  try {
    let outputFields: string[] = [];
    const { output }: SystemStateInterface = yield select(getOutputFields) || [];
    const { tableHeadCells }: OutputState = yield select(getOutputTableValues) || [];

    outputFields = tableHeadCells.map((field) => field.id);
    const { page, size, filterFields, orderBy, allFavorites } = action.payload;

    const outputs: ListResponse = yield call(() =>
      getOutputs(
        filterFields,
        outputFields,
        allFavorites ? 1 : page,
        allFavorites ? 1000 : size,
        orderBy,
      ),
    );
    action.callback(null);
    yield put(
      setOutputCategories(
        () => {},
        output.fields
          .filter((field) => field.type === 'lov')
          .map((field) => ({ id: field.id, label: field.label, type: field.type })),
      ),
    );
    yield put(getOutputsSuccess(outputs));
  } catch (error) {
    action.callback(error);
  }
}

export function* getOutputsWithTransferInfo(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: {
    page: number;
    size: number;
    filterFields: FilterIterface[];
    orderBy: OrderByInterface;
  };
}) {
  try {
    let outputFields: string[] = [];
    const { output }: SystemStateInterface = yield select(getOutputFields) || [];

    const { tableHeadCells }: OutputState = yield select(getOutputTableValues) || [];

    outputFields = tableHeadCells.map((field) => field.id);
    const { page, size, filterFields, orderBy } = action.payload;

    const outputs: ListResponse = yield call(() =>
      getOutputsFromTransferService(filterFields, outputFields, page, size, orderBy),
    );
    action.callback(null);
    yield put(
      setOutputCategories(
        () => {},
        output.fields
          .filter((field) => field.type === 'lov')
          .map((field) => ({ id: field.id, label: field.label, type: field.type })),
      ),
    );
    yield put(getOutputsSuccess(outputs));
  } catch (error) {
    action.callback(error);
  }
}

export function* downloadOutput(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: { id: string; title: string };
}) {
  try {
    yield call(() => downloadOutputApi(action.payload.id));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}
export default function* outputDocumentWatcher() {
  yield take(systemActionTypes.GET_SYSTEM_DEFINITIONS_SUCCESS);
  yield takeLatest(outputActionTypes.GET_OUTPUTS, getOutputsWithTransferInfo);
  yield takeLatest(outputActionTypes.DOWNLOAD_OUTPUT, downloadOutput);
}
