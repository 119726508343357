import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ReviewIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M5 23V18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H11.7L5 23ZM20 4H4V16H7V18.9L11 16H20V4Z'
        fill='#0066CC'
      />
    </SvgIcon>
  );
}
