import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IASelect, { AutocompleteValueT } from '../Select/Select';
import { getProjectSuccess, getProjects } from '../../state/actions/project';
import { GlobalState } from '../../core/models/state/globalState';
import { StepFieldsInterface, Member } from '../../core/models/global';
import {
  DefinitioniInterface,
  ItemsInterface,
  FieldResponseInterface,
  FieldResponseInterfaceValues,
} from '../../core/models/system/systemDefinition';
import { LovValue } from '../../core/models/lovValue';
import {
  SelectedProductMetadata,
  ProjectInterface,
} from '../../core/models/project/projectInterface';
import { getTemplates } from '../../state/actions/template';
import { CircularProgress, Grid, Stack, Chip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IATableHeader from '../TableHeader/TableHeader';
import IATable, { HeadCell } from '../Table/Table';
import { Order, OrderByInterface } from '../../core/models/order';
import { TemplateInterface } from '../../core/models/template/templateInterface';
import { IAInput } from '../Input/Input';
import { IATextArea } from '../IATextArea/IATextArea';
import { WorkingDocumentInterface } from '../../core/models/workingDocument/workingDocumentInterface';
import ReviewActionTitle from '../ReviewActionTitle/ReviewActionTitle';
import NoSearchResults from '../NoSearchResults/NoSearchResults';
import { getLovId } from '../../core/services/apiTranformations';
import { DEFAULT_ORDER, FIRST_STEP, SECOND_STEP, templateHeadCells } from '../../constants';
import { getSelectedMetadata } from '../../core/services/productMetadata';
import { disableEventsClass, getTemplateTypeByProjectType } from '../../core/services/helpers';
import { getInitials } from '../../core/services/userService';
import AddMore from '../icons/AddMore';
import DocumentMembersDialog from './DocumentMembersDialog';
import Cross from '../icons/Cross';
import { TEMPLATE_TYPES } from '../../core/models/template/template';
import { FilterIterface } from '../../core/models/filter/filter';

interface BasicDetailsProps {
  title?: string;
  projectFields?: DefinitioniInterface[];
  projectLovs?: { [key: string]: LovValue[] };
  templateFields?: DefinitioniInterface[];
  fillStepFields: (
    step: number,
    value: StepFieldsInterface | SelectedProductMetadata,
    template?: TemplateInterface,
  ) => void;
  fieldsFilled: StepFieldsInterface;
  fieldsFilledMetadata?: SelectedProductMetadata;
  template?: TemplateInterface;
  isEdit?: boolean;
  isReview?: boolean;
  setActiveStep?: (activeStep: number) => void;
  page?: number;
  setPage?: (page: number) => void;
  projectId?: string;
  handleProjectMetadata?: (productMetadata: SelectedProductMetadata) => void;
  emptyFields?: string[];
  canChange?: boolean;
}

const BasicDetails = (props: BasicDetailsProps) => {
  const {
    title,
    projectFields,
    projectLovs,
    templateFields,
    fillStepFields,
    fieldsFilled,
    template = {},
    isEdit,
    isReview,
    setActiveStep,
    page,
    setPage,
    projectId,
    handleProjectMetadata,
    emptyFields = [],
    fieldsFilledMetadata,
    canChange = true,
  } = props;
  const dispatch = useDispatch();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [orderBy, setOrderBy] = useState<OrderByInterface | undefined>(DEFAULT_ORDER);
  const [order, setOrder] = useState<Order>('desc');
  const [searchQuery, setSearchQuery] = useState('');
  const [pageAndSize, setPageAndSize] = useState({ page, size: 5 });
  const { templateCategories, values, filtersApplied } = useSelector(
    (state: GlobalState) => state.filter,
  );
  const { tableRows: tableRowsProject, projects: projectsData } = useSelector(
    (state: GlobalState) => state.project,
  );
  const { tableRows: tableRowsTemplate, totalItems } = useSelector(
    (state: GlobalState) => state.template,
  );
  const { user } = useSelector((state: GlobalState) => state.user);
  const { project } = useSelector((state: GlobalState) => state.project);
  const [selectedTemplate, setSelectedTemplate] = useState<string>(
    (fieldsFilled?.selectedTemplate?.value as string) ?? '',
  );
  const [selectedProject, setSelectedProject] = useState<string>(
    projectId ?? (fieldsFilled.selectedProject?.value as string) ?? '',
  );
  const [newFields, setNewFields] = useState<WorkingDocumentInterface>({
    FTITLE: (fieldsFilled?.FTITLE?.value as string) ?? '',
    FDESCRIPTION: (fieldsFilled?.FDESCRIPTION?.value as string) ?? '',
  });
  const [members, setMembers] = useState<Member[]>(
    (fieldsFilled?.members?.value as Member[]) ?? [],
  );
  const [membersDialog, setMembersDialog] = useState(false);
  const [projectMemberList, setProjectMemberList] = useState<FieldResponseInterfaceValues[]>(
    (fieldsFilled?.memberList?.value as FieldResponseInterfaceValues[]) ?? [],
  );

  const setListOfMembers = (projectItem: ItemsInterface) => {
    const result =
      (projectItem.fields as FieldResponseInterface[]).find(
        (field) => field.id === 'FIAPPROJECTASSIGNEE',
      )?.values || [];
    setProjectMemberList(result);
  };
  const [headCellIds, setHeadCellIds] = useState(templateHeadCells);

  const [headCells, setHeadCells] = useState<HeadCell[]>([]);

  useEffect(() => {
    const filterFields: FilterIterface[] = [];
    if (!user.isAdmin) {
      filterFields.push({
        id: 'FIAPPROJECTASSIGNEE',
        type: 'userReference',
        operator: 'In',
        value: [user.userId],
        lovValueId: [user.userId],
      });
    }
    dispatch(
      getProjects(() => {}, {
        page: 1,
        size: 1000,
        filterFields,
        orderBy: {
          id: 'MODIFIED-ON',
          order: 'Descending',
        },
        allFavorites: false,
      }),
    );
  }, [projectFields]);

  useEffect(() => {
    const filterFields = [
      ...filtersApplied.templateFilters.filter(
        (filter) => !['FCUSTTEMPLATETYPE', 'FIAPTEMPLATESTATUS'].includes(filter.id),
      ),
    ];
    if (searchQuery.length > 0) {
      filterFields.push({
        id: 'FTITLE',
        type: 'string',
        operator: 'Like',
        value: [`%${searchQuery}%`],
      });
    }
    const projectType = project.FCUSTPROJECTTYPE as string;
    const templateType = getTemplateTypeByProjectType(projectType);
    filterFields.push({
      id: 'FCUSTTEMPLATETYPE',
      type: 'lov',
      operator: 'Equal',
      value: templateType,
      lovValueId: templateType,
    });
    filterFields.push({
      id: 'FIAPTEMPLATESTATUS',
      type: 'lov',
      operator: 'Equal',
      value: ['VIAPTEMPLATESTATUSPUBLISHED'],
      lovValueId: ['VIAPTEMPLATESTATUSPUBLISHED'],
    });
    if (templateType[0] === TEMPLATE_TYPES.submission) {
      setHeadCellIds(['FCUSTCTDTITLE', 'FCUSTCTDCODE', ...templateHeadCells]);
    } else {
      setHeadCellIds(['FCUSTREPORTCLASSIFICATION', 'FCUSTREPORTTITLE', ...templateHeadCells]);
    }
    if (project.FCUSTPRODUCTAREA) {
      const productAreaIds = (project.FCUSTPRODUCTAREA as string)
        .split(', ')
        .map((productAreaLabel) =>
          getLovId(
            'FCUSTPRODUCTAREA',
            productAreaLabel,
            projectFields as DefinitioniInterface[],
            projectLovs as { [key: string]: LovValue[] },
          ),
        ) as string[];
      filterFields.push({
        id: 'FCUSTPRODUCTAREA',
        type: 'lov',
        operator: 'In',
        value: productAreaIds,
        lovValueId: productAreaIds,
      });
    }
    if (selectedProject && project?.id) {
      setIsDataLoaded(false);
      dispatch(
        getTemplates(
          () => {
            setIsDataLoaded(true);
          },
          {
            page: pageAndSize.page as number,
            size: pageAndSize.size,
            filterFields,
            orderBy,
            allFavorites: false,
          },
        ),
      );
    }
  }, [project, selectedProject, pageAndSize, searchQuery, filtersApplied, orderBy]);

  useEffect(() => {
    if (handleProjectMetadata) {
      const {
        selectedProductFamilies,
        selectedDrugProducts,
        selectedDrugSubstances,
        selectedPackagingCodes,
        selectedDPManufacturers,
        selectedDSManufacturers,
        selectedEventTypes,
        selectedEvents,
        selectedChangeCategory,
        studyId,
      } = getSelectedMetadata(project);
      handleProjectMetadata({
        selectedProductFamilies,
        selectedDrugProducts,
        selectedDrugSubstances,
        selectedPackagingCodes,
        selectedDPManufacturers,
        selectedDSManufacturers,
        selectedEventTypes,
        selectedEvents,
        selectedChangeCategory,
        studyId,
      });
      if (
        selectedProductFamilies?.length === 1 &&
        fieldsFilledMetadata &&
        !Object.keys(fieldsFilledMetadata).length
      ) {
        fillStepFields(SECOND_STEP, {
          selectedProductFamilies: [...selectedProductFamilies],
          selectedDrugProducts: selectedDrugProducts.map((el) => el.slice()),
          selectedDrugSubstances: selectedDrugSubstances.map((el) => el.slice()),
          selectedPackagingCodes: selectedPackagingCodes.map((el) => el.slice()),
          selectedDPManufacturers: selectedDPManufacturers.map((el) => el.slice()),
          selectedDSManufacturers: selectedDSManufacturers.map((el) => el.slice()),
          selectedEventTypes: [...selectedEventTypes],
          selectedEvents: [...selectedEvents],
          selectedChangeCategory: selectedChangeCategory,
          studyId,
        } as SelectedProductMetadata);
      }
    }
  }, [project?.id, fieldsFilledMetadata]);

  useEffect(() => {
    fillStepFields(
      FIRST_STEP,
      {
        selectedProject: { value: selectedProject, required: true },
        selectedTemplate: {
          value: selectedTemplate,
          required: true,
        },
        FTITLE: { value: newFields.FTITLE as string, required: true },
        FDESCRIPTION: { value: newFields.FDESCRIPTION as string, required: false },
        members: { value: members, required: false },
        memberList: { value: projectMemberList, required: false },
      },
      tableRowsTemplate.find((item) => item.id === selectedTemplate),
    );
  }, [newFields, selectedTemplate, selectedProject, members, projectMemberList]);

  const onProjectChange = (_event?: React.SyntheticEvent, value?: AutocompleteValueT) => {
    if (value) {
      const { id } = value as { id: string; label: string };
      const projectItem = projectsData.find((proj) => proj.id === id);
      if (projectItem) {
        dispatch(getProjectSuccess(projectItem));
        setListOfMembers(projectItem);
        setMembers([]);
      }
      setSelectedTemplate('');
      setSelectedProject(id ?? '');
      fillStepFields(SECOND_STEP, {});
    }
  };

  const handleSort = (id: string) => {
    const isAsc = orderBy?.id === id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy({ id, order: isAsc ? 'Descending' : 'Ascending' });
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: keyof TemplateInterface,
  ) => {
    setNewFields({ ...newFields, [field]: event.target.value });
  };

  const projects = tableRowsProject.map((item) => ({
    id: item.id as string,
    label: item.FTITLE as string,
  }));

  useEffect(() => {
    setHeadCells(
      headCellIds.map((cell) => {
        const field = templateFields?.find((item) => item.id === cell);
        return {
          id: cell,
          label: field?.label ?? '',
          isSortable: field?.flags?.isSortable,
        };
      }),
    );
  }, [headCellIds]);

  useEffect(() => {
    if (fieldsFilled.selectedProject) {
      setSelectedProject(fieldsFilled.selectedProject?.value as string);
    }
  }, [fieldsFilled.selectedProject?.value]);

  useEffect(() => {
    if (fieldsFilled.members) {
      setMembers(fieldsFilled.members.value as Member[]);
    }
  }, [fieldsFilled.members?.value]);

  useEffect(() => {
    if (fieldsFilled.selectedTemplate) {
      setSelectedTemplate(fieldsFilled.selectedTemplate?.value as string);
    }
  }, [fieldsFilled.selectedTemplate?.value]);

  useEffect(() => {
    if (fieldsFilled.FTITLE) {
      setNewFields({ ...newFields, FTITLE: fieldsFilled.FTITLE?.value as string });
    }
  }, [fieldsFilled.FTITLE?.value]);

  useEffect(() => {
    if (fieldsFilled.FDESCRIPTION) {
      setNewFields({ ...newFields, FDESCRIPTION: fieldsFilled.FDESCRIPTION?.value as string });
    }
  }, [fieldsFilled.FDESCRIPTION?.value]);

  useEffect(() => {
    if (projectId && projectsData?.length) {
      const projectItem = projectsData.find((proj) => proj.id === projectId);
      if (projectItem) {
        setListOfMembers(projectItem);
      }
    }
  }, [projectId, projectsData]);

  const tableContent = () => {
    if (!isDataLoaded) {
      return (
        <div className='loader-container'>
          <CircularProgress value={30} size='80px' />
        </div>
      );
    }
    if (isDataLoaded && !tableRowsTemplate?.length) {
      return (
        <div className='loader-container'>
          <NoSearchResults />
        </div>
      );
    }
    if (isDataLoaded && tableRowsTemplate?.length) {
      return (
        <>
          {emptyFields.includes('selectedTemplate') && (
            <div style={{ marginBottom: '8px' }} className='err-message'>
              Template selection is required
            </div>
          )}
          <IATable
            headCells={headCells}
            visibleRows={tableRowsTemplate}
            orderBy={orderBy?.id}
            order={order}
            handleSort={handleSort}
            handleFavoriteClick={() => {}}
            checkIcon
            count={pageAndSize.size}
            page={pageAndSize.page as number}
            pageChangeHandler={(newPage: number, size: number) => {
              setPageAndSize({ page: newPage, size });
              if (setPage) {
                setPage(newPage);
              }
            }}
            totalItems={totalItems}
            isDocCreation
            checked={(rowItem: TemplateInterface) => rowItem.id === selectedTemplate}
            checkItem={(id: string) => setSelectedTemplate(id)}
            // rowLink={{ text: 'Preview template', link: '/layout/edit/template/' }}
          />
        </>
      );
    }
  };

  const renderTemplates = () => {
    if (!selectedProject) {
      return '';
    }
    if (isReview || isEdit) {
      return (
        <>
          <div className='review-table-title'>Template selected</div>
          <div style={{ pointerEvents: 'none' }}>
            <IATable
              headCells={headCells}
              visibleRows={[template]}
              count={1}
              page={1}
              pageChangeHandler={() => {}}
              totalItems={1}
              hidePagination={true}
              isDocCreation
            />
          </div>
        </>
      );
    }

    return (
      <div className='template-container'>
        <div className='action-title'>Select template</div>
        <div className='page-content' data-testid='table-container'>
          <IATableHeader
            className={disableEventsClass(!isDataLoaded)}
            categories={templateCategories}
            categoryValues={values}
            handleSearch={(text: string) => {
              setSearchQuery(text);
            }}
            type='templateFilters'
            isFromDocCreation
          />
          {tableContent()}
        </div>
      </div>
    );
  };

  const renderNameAndDescription = () => {
    if (!selectedTemplate) {
      return '';
    }
    let nameElement, descriptionElement;
    if (!isReview || isEdit) {
      nameElement = (
        <IAInput
          inputLabel='Document name'
          value={newFields.FTITLE as string}
          onChange={(event) => {
            handleInputChange(event, 'FTITLE' as keyof WorkingDocumentInterface);
          }}
          disabled={isReview && !!title}
          error={emptyFields.includes('FTITLE')}
        />
      );
      descriptionElement = (
        <IATextArea
          label='Description (optional)'
          value={newFields.FDESCRIPTION as string}
          onChange={(event) => {
            handleInputChange(event, 'FDESCRIPTION' as keyof WorkingDocumentInterface);
          }}
          disabled={isReview && isEdit !== true}
        />
      );
    } else {
      nameElement = (
        <div>
          <div className='label'>Document name</div>
          <div className='review-value'>{newFields.FTITLE || '-'}</div>
        </div>
      );
      descriptionElement = (
        <div>
          <div className='label'>Description (optional)</div>
          <div className='review-value'>{(newFields.FDESCRIPTION as string)?.trim() || '-'}</div>
        </div>
      );
    }
    return (
      <>
        <Grid container>
          <Grid item xs={4}>
            <div className='form-field' data-testid='title-container'>
              {nameElement}
            </div>
          </Grid>
        </Grid>

        <div className='description-container' data-testid='description-container'>
          {descriptionElement}
        </div>
      </>
    );
  };

  const getDeleteIcon = (name: string) => {
    return (
      <span
        onMouseDown={() => {
          setMembers(members.filter((el) => el.label !== name));
        }}
      >
        <Cross fill='#0B41CD' />
      </span>
    );
  };

  const renderMembers = () => {
    if (selectedProject && selectedTemplate && !isEdit) {
      if (isReview) {
        return (
          <div className='form-field' data-testid='member-container'>
            <div className='label'>Document members</div>
            <div className='review-value'>
              {members?.length ? members.map(({ label }) => label).join(', ') : '-'}
            </div>
          </div>
        );
      }
      const addMembersIcon = (
        <div className='add-more-container'>
          <div
            data-testid='add-members'
            className='add-more'
            onClick={() => setMembersDialog(true)}
          >
            <AddMore />
            <span>Add members</span>
          </div>
        </div>
      );
      if (!Object.keys(members).length) {
        return addMembersIcon;
      }
      return (
        <div className='form-field' data-testid='member-container'>
          <div className='label'>Document members</div>
          <Stack gap={1} direction='row' flexWrap='wrap' className='members-container'>
            {members.map(({ label, role }) => (
              <Chip
                key={label}
                label={
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className='member-name'>{label}</span>
                    <span className='role-name'>{role}</span>
                  </div>
                }
                onDelete={() => {}}
                deleteIcon={getDeleteIcon(label)}
                avatar={<Avatar alt={label}>{getInitials(label)}</Avatar>}
              />
            ))}
          </Stack>
          {addMembersIcon}
        </div>
      );
    }
  };

  const onEdit = () => {
    if (title && setActiveStep && canChange) {
      return () => {
        setActiveStep(FIRST_STEP);
      };
    }
  };

  return (
    <div data-testid='basic-details'>
      <ReviewActionTitle onEdit={onEdit()} title={title} isReview={isReview} />

      <Grid container>
        <Grid item xs={4}>
          <div className='form-field' data-testid='form-field'>
            {!isReview && !isEdit ? (
              <IASelect
                label='Assign to workspace'
                placeholder='Select workspace'
                options={projects}
                onChange={onProjectChange}
                value={projects.find((el) => el.id === selectedProject) ?? null}
                disabled={isReview ?? !!projectId}
                error={emptyFields.includes('selectedProject')}
              />
            ) : (
              <div>
                <div className='label'>Assign to workspace</div>
                <div className='review-value'>
                  {projects.find((el) => el.id === selectedProject)?.label ?? '-'}
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      {renderTemplates()}
      {renderNameAndDescription()}
      {renderMembers()}
      <DocumentMembersDialog
        open={membersDialog}
        handleClose={() => setMembersDialog(false)}
        project={tableRowsProject.find((el) => el.id === selectedProject) as ProjectInterface}
        documentCreator={user}
        projectMemberList={projectMemberList}
        addMembers={(membersData) => {
          setMembers(membersData);
          setMembersDialog(false);
        }}
        addedMembers={[...members]}
      />
    </div>
  );
};

export default BasicDetails;
