import { WorkingDocumentInterface } from '../../core/models/workingDocument/workingDocumentInterface';
import { TemplateInterface } from '../../core/models/template/templateInterface';
import { Order, OrderByInterface } from '../../core/models/order';

export const sortingHandler = (
  current: WorkingDocumentInterface | TemplateInterface,
  next: WorkingDocumentInterface | TemplateInterface,
  order: Order,
  orderBy: OrderByInterface,
) => {
  // Swap current and next if order is descending

  if (order === 'desc') {
    [current, next] = [next, current];
  }

  // Get the values to compare
  const currentValue = current[orderBy.id];
  const nextValue = next[orderBy.id];

  if (!isNaN(Number(currentValue)) && !isNaN(Number(nextValue))) {
    return Number(currentValue) - Number(nextValue);
  }

  return (
    (String(currentValue) ?? '').localeCompare(String(nextValue) ?? '') ??
    (order === 'desc' ? 1 : -1)
  );
};
