import { Grid } from '@mui/material';
import { RequiredMetadata } from '../../core/models/workingDocument/requiredMetadata';
import { IAInput } from '../Input/Input';
import IAMultiSelect, { AutocompleteValueT } from '../MultiSelect/MultiSelect';
import { LovValue } from '../../core/models/lovValue';
import IASelect from '../Select/Select';
import { FormValues, SelectValue } from '../../core/models/global';

interface PropsInterface {
  additionalMetadata: RequiredMetadata[];
  handleValueChange: (
    id: string,
    value: string | SelectValue | SelectValue[],
    type: string,
  ) => void;
  additionalMetadataValues?: FormValues;
  isReview?: boolean;
}

const AdditionalMetadata = (props: PropsInterface) => {
  const {
    additionalMetadata,
    handleValueChange,
    additionalMetadataValues,
    isReview = false,
  } = props;

  const getInputAndReviewElement = (metadataField: RequiredMetadata) => {
    let reviewValue;
    let inputElement;
    if (metadataField.definition.type === 'string') {
      reviewValue = (additionalMetadataValues?.[metadataField.definition.id] as string) ?? '-';
      inputElement = (
        <Grid key={metadataField.definition.id} item xs={4}>
          <div className='form-field' data-testid={`field-for-${metadataField.definition.id}`}>
            <IAInput
              inputLabel={metadataField.definition.label}
              placeholder={metadataField.definition.label}
              onChange={(event) => {
                handleValueChange(
                  metadataField.definition.id,
                  event.target.value,
                  metadataField.definition.type,
                );
              }}
              value={additionalMetadataValues?.[metadataField.definition.id]}
              disabled={!metadataField.definition.flags?.isRoot}
            />
          </div>
        </Grid>
      );
      return {
        reviewElement: (
          <Grid key={metadataField.definition.id} item xs={4}>
            <div className='form-field' data-testid={`field-for-${metadataField.definition.id}`}>
              <div>
                <div className='label'>{metadataField.definition.label}</div>
                <div className='review-value'>{reviewValue}</div>
              </div>
            </div>
          </Grid>
        ),
        inputElement,
      };
    }
    if (metadataField.definition.flags?.isMultiValue) {
      reviewValue =
        (additionalMetadataValues?.[metadataField.definition.id] as SelectValue[])
          ?.map((el) => el.label)
          .join(', ') || '-';

      inputElement = (
        <Grid key={metadataField.definition.id} item xs={4}>
          <div className='form-field' data-testid={`field-for-${metadataField.definition.id}`}>
            <IAMultiSelect
              data-testid={metadataField.definition.label}
              label={metadataField.definition.label}
              placeholder={`Select ${metadataField.definition?.label}`}
              options={
                metadataField.values?.map((el) => ({
                  label: (el.value as LovValue).label || (el.value as string),
                  id: (el.value as LovValue).id || (el.value as string),
                })) ?? []
              }
              onChange={(_event, value) => {
                const selectedValue = value as LovValue[];
                handleValueChange(
                  metadataField.id,
                  selectedValue.map((el) => ({ id: el.id, label: el.label })),
                  metadataField.type,
                );
              }}
              value={(
                additionalMetadataValues?.[metadataField.definition.id] as SelectValue[]
              )?.map((el) => ({ id: el.id, label: el.label }))}
              // disabled={!metadataField.values?.length || !metadataField.definition.flags?.isRoot}
              disableClearable={false}
            />
          </div>
        </Grid>
      );
    } else {
      reviewValue = (additionalMetadataValues?.[metadataField.id] as SelectValue)?.label ?? '-';
      inputElement = (
        <Grid key={metadataField.definition.id} item xs={4}>
          <div className='form-field' data-testid={`field-for-${metadataField.definition.id}`}>
            <IASelect
              data-testid={metadataField.definition.label}
              label={metadataField.definition.label}
              placeholder={`Select ${metadataField.definition.label}`}
              options={
                metadataField.values?.map((el) => ({
                  label: (el.value as LovValue).label ?? (el.value as string),
                  id: (el.value as LovValue).id ?? (el.value as string),
                })) ?? []
              }
              onChange={(_event?: React.SyntheticEvent, value?: AutocompleteValueT) => {
                const selectedValue = value as LovValue;
                handleValueChange(
                  metadataField.id,
                  selectedValue && { id: selectedValue.id, label: selectedValue.label },
                  metadataField.type,
                );
              }}
              value={(additionalMetadataValues?.[metadataField.id] as SelectValue)?.label}
              disabled={!metadataField.values?.length || !metadataField.definition.flags?.isRoot}
              disableClearable={false}
            />
          </div>
        </Grid>
      );
    }

    return {
      reviewElement: (
        <Grid key={metadataField.definition.id} item xs={4}>
          <div className='form-field' data-testid={`field-for-${metadataField.definition.id}`}>
            <div>
              <div className='label'>{metadataField.definition.label}</div>
              <div className='review-value'>{reviewValue}</div>
            </div>
          </div>
        </Grid>
      ),
      inputElement,
    };
  };

  const mapFieldsToElements = () => {
    if (isReview) {
      return Object.values(
        additionalMetadata
          .map((metadataField) => getInputAndReviewElement(metadataField))
          .map((result) => result.reviewElement),
      );
    } else {
      return Object.values(
        additionalMetadata
          .map((metadataField) => getInputAndReviewElement(metadataField))
          .map((result) => result.inputElement),
      );
    }
  };
  return (
    <div>
      <hr className='divider-dotted' />
      <div className='product-group-title'>Additional Variables</div>
      <Grid container spacing={2}>
        {mapFieldsToElements()}
      </Grid>
    </div>
  );
};
export default AdditionalMetadata;
