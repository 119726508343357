export type Order = 'asc' | 'desc';

export interface OrderByInterface {
  id: string;
  order: string;
}

export const getOrderAndOrderBy = (
  id: string,
  order: Order,
  orderBy: OrderByInterface,
): { order: Order; orderBy: OrderByInterface } => {
  const isAsc = orderBy.id === id && order === 'asc';
  if (isAsc) {
    return {
      order: 'desc',
      orderBy: {
        id,
        order: 'Descending',
      },
    };
  }
  return {
    order: 'asc',
    orderBy: {
      id,
      order: 'Ascending',
    },
  };
};
