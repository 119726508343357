import api from './index';
import { NewWorkingDocumentInterface } from '../models/workingDocument/workingDocumentInterface';
import { OrderByInterface } from '../models/order';
import { FilterFieldsInterface } from '../models/filter/filter';
import { FieldRequestInterface } from '../models/system/systemDefinition';

export async function getWorkingDocumentById(id: string, requestedFields: string[]) {
  let requestedFieldIdsQuery = '';
  requestedFields.forEach((field) => {
    requestedFieldIdsQuery += `requestedFieldIds=${field}&`;
  });
  const result = await api.get(`/dashboard/document/${id}?${requestedFieldIdsQuery.slice(0, -1)}`);
  return result.data;
}

export async function getWorkingDocumentByIdWithErrorHandling(id: string) {
  try {
    const result = await api.get(`/dashboard/document/${id}`);
    return result.data;
  } catch (error) {
    return null;
  }
}

export async function getWorkingDocuments(
  filterFields: FilterFieldsInterface[],
  requestedFields: string[],
  page: number | string,
  size: number | string,
  orderByField?: OrderByInterface,
) {
  let requestedFieldIdsQuery = '';
  requestedFields.forEach((field) => {
    requestedFieldIdsQuery += `requestedFieldIds=${field}&`;
  });
  const result = await api.post(`/dashboard/document/list?${requestedFieldIdsQuery.slice(0, -1)}`, {
    filterFields,
    requestedFields,
    page,
    size,
    orderByField,
  });
  return result.data;
}

export async function createWorkingDocument(
  body: NewWorkingDocumentInterface,
  projectId: string,
  templateId: string,
) {
  const result = await api.post(`/dashboard/document/${projectId}/${templateId}`, body);
  return result.data;
}

export async function getDocumentRequiredMetadata(
  projectId: string,
  templateId: string,
  body: FieldRequestInterface[] = [],
) {
  const result = await api.post(`/dashboard/document/${projectId}/${templateId}/values`, {
    fields: body,
  });
  return result.data;
}

export async function updateWorkingDocument(id: string, body: NewWorkingDocumentInterface) {
  const result = await api.patch(`/dashboard/document/${id}`, body);
  return result?.data;
}

export async function deleteWorkingDocumentById(id: string) {
  const result = await api.delete(`/workingDocument/${id}`);
  return result.data;
}
