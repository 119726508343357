import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './DatePicker.scss';
import dayjs, { Dayjs } from 'dayjs';
interface DatePickerComponentProps {
  onChange?: (val: Dayjs | null) => void;
  label?: string;
  value?: string;
  disabled?: boolean;
}

const DatePickerComponent = (props: DatePickerComponentProps) => {
  const { onChange, label, value, disabled } = props;

  return (
    <div className='date-picker'>
      {label && <label htmlFor={label}>{label}</label>}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value ? dayjs(value) : null}
          onChange={onChange}
          format='DD MMM YYYY'
          disabled={disabled}
          disablePast
        />
      </LocalizationProvider>
    </div>
  );
};

export default DatePickerComponent;
