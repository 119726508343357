import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function HistoryIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='24' height='24' viewBox='0 0 24 24'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.42085 3.11322C8.83675 2.38266 10.4067 2.00099 12 2C13.9356 1.99809 15.8302 2.55796 17.4538 3.61169C19.0774 4.66541 20.3604 6.1677 21.1469 7.93626C21.9335 9.70483 22.1899 11.6637 21.885 13.5751C21.5802 15.4865 20.7271 17.2684 19.4294 18.7045C18.1317 20.1407 16.445 21.1693 14.5742 21.6656C12.7033 22.162 10.7286 22.1047 8.8896 21.5007C7.05064 20.8968 5.42648 19.7721 4.21418 18.2632C3.00189 16.7542 2.25356 14.9259 2.06 13H4.06C4.29184 14.8651 5.17296 16.5891 6.54882 17.8695C7.92468 19.1499 9.70743 19.9051 11.5844 20.0024C13.4613 20.0998 15.3127 19.5333 16.8137 18.4021C18.3146 17.2709 19.3694 15.6474 19.7931 13.8163C20.2167 11.9852 19.9821 10.0634 19.1304 8.38794C18.2787 6.71251 16.8643 5.39044 15.1352 4.6536C13.4062 3.91677 11.473 3.81222 9.67455 4.35829C7.87614 4.90437 6.32741 6.06619 5.3 7.64L7.66 10H2V4.34L3.86 6.2C4.7841 4.9021 6.00494 3.84379 7.42085 3.11322ZM11 12.41V6H13V11.59L15.71 14.29L14.29 15.71L11 12.41Z'
        fill='#0066CC'
      />
    </SvgIcon>
  );
}
