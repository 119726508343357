import { Grid } from '@mui/material';
import { DocumentTransferMetadata } from '../../core/models/transfer/phoenixData';
import IASelect from '../MultiSelect/MultiSelect';
import IARadio from '../Radio/Radio';
import { IAInput } from '../Input/Input';
import { IdLabel } from '../../core/models/global';

interface PropsInterface {
  documentMetadata: DocumentTransferMetadata;
  handlePartialDetailsChange: (
    key: keyof DocumentTransferMetadata,
    value: string | boolean | string[],
  ) => void;
  languages?: IdLabel[];
  emptyFields?: string[];
}
export const BasicDetails = (props: PropsInterface) => {
  const { documentMetadata, handlePartialDetailsChange, languages = [], emptyFields = [] } = props;

  return (
    <div className='basic-details'>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='is-english'>
            <IARadio
              inputLabel='Is this document written only in English?'
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
              value={documentMetadata.isDocumentInEnglish ? 'Yes' : 'No'}
              handleChange={(event) => {
                handlePartialDetailsChange('isDocumentInEnglish', event.target.value);
              }}
            />
          </div>
        </Grid>
      </Grid>
      {!documentMetadata.isDocumentInEnglish && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field' data-testid='title-in-english'>
              <IAInput
                inputLabel='Title In English'
                placeholder='Title'
                required
                onChange={(event) => {
                  handlePartialDetailsChange('titleInEnglish', event.target.value);
                }}
                value={documentMetadata.titleInEnglish}
                error={emptyFields.includes('titleInEnglish')}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field' data-testid='languages'>
              <IASelect
                label='Languages'
                placeholder='Languages'
                options={languages}
                onChange={(_event, value) => {
                  const selectedValues = value as IdLabel[];

                  handlePartialDetailsChange(
                    'languages',
                    selectedValues.map((el) => el.label),
                  );
                }}
                value={documentMetadata.languages}
                error={emptyFields.includes('languages')}
              />
            </div>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='title'>
            <IAInput
              inputLabel='Title'
              placeholder='Title'
              required
              onChange={(event) => {
                handlePartialDetailsChange('documentTitle', event.target.value);
              }}
              value={documentMetadata.documentTitle}
              error={emptyFields.includes('documentTitle')}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='scopes'>
            <IASelect
              label='Scope (s)'
              placeholder='Scope (s)'
              options={['GxP']}
              onChange={(_event, value) => {
                handlePartialDetailsChange('generalScope', value as string);
              }}
              value={['GxP']}
            />
          </div>
        </Grid>
      </Grid>
      <div className='security-details'>
        <div className='action-title' data-testid='action-title'>
          Enter security details
        </div>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <div className='form-field' data-testid='security-question-1'>
              <IARadio
                inputLabel='Does this document contain restricted information that should not be widely available within Roche?'
                options={[
                  {
                    label: 'Yes',
                    value: 'Yes',
                    title: 'In that case it should not be transferred from IAP',
                  },
                  { label: 'No', value: 'No' },
                ]}
                value={'No'}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <div className='form-field question' data-testid='security-question-2'>
              <IARadio
                inputLabel='Would it cause/create significant financial (or other) harm to Roche if this document is made public or if a third party (i.e competitor) has access to this document?'
                options={[
                  {
                    label: 'Yes',
                    value: 'Yes',
                    title: 'In that case it should not be transferred from IAP',
                  },
                  { label: 'No', value: 'No' },
                ]}
                value={'No'}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field' data-testid='publish'>
              <IASelect
                label='Publish to portal'
                placeholder='Publish to portal'
                multiple={false}
                options={['Not Published']}
                value={'Not Published'}
                disabled
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field' data-testid='security-scope'>
              <IASelect
                label='Security scope'
                placeholder='Security scope'
                multiple={false}
                options={['Open']}
                onChange={(_event, value) => {
                  handlePartialDetailsChange('securityScope', value as string);
                }}
                value={'Open'}
                disabled
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
