import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Star(props: SvgIconProps) {
  const fill = props.fill || '#FFFFFF';
  const stroke = props.stroke || '#C4C4C4';
  return (
    <SvgIcon {...props} width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M9.54746 1.65049L9.54751 1.6505L9.55 1.64394C9.63018 1.43225 9.80849 1.3335 9.99198 1.3335C10.1755 1.3335 10.3538 1.43225 10.434 1.64394L10.4339 1.64396L10.4365 1.65049L12.3683 6.54215L12.4834 6.83358L12.7959 6.85709L17.8907 7.24039C17.8907 7.2404 17.8908 7.2404 17.8908 7.2404C18.3004 7.27167 18.5248 7.84031 18.1628 8.15717C18.1628 8.15719 18.1627 8.1572 18.1627 8.15722L14.2593 11.5652L14.0316 11.764L14.1018 12.0579L15.3246 17.1802C15.3247 17.1806 15.3248 17.181 15.3249 17.1814C15.4105 17.5524 15.1338 17.8335 14.8537 17.8335C14.7854 17.8335 14.7012 17.8137 14.6084 17.7542L14.6082 17.7541L10.2616 14.9708L9.99198 14.7981L9.72235 14.9708L5.37576 17.7541L5.37559 17.7542C5.28272 17.8137 5.19858 17.8335 5.13024 17.8335C4.85013 17.8335 4.57347 17.5524 4.65908 17.1814C4.65917 17.181 4.65926 17.1806 4.65936 17.1802L5.88219 12.0579L5.95238 11.764L5.7247 11.5652L1.82124 8.15722C1.82121 8.15719 1.82118 8.15716 1.82115 8.15714C1.45263 7.83453 1.6841 7.27094 2.08518 7.2404C2.08521 7.2404 2.08523 7.24039 2.08526 7.24039L7.18805 6.85709L7.50054 6.83362L7.61564 6.54215L9.54746 1.65049Z'
        fill={fill}
        stroke={stroke}
      />
    </SvgIcon>
  );
}
