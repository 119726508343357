import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Iframe from 'react-iframe';
import './Editor.scss';
import { useParams, useNavigate } from 'react-router-dom';
import { getWorkingDocumentById } from '../../state/actions/workingDocument';
import { getTemplateById } from '../../state/actions/template';
import { getProjectById } from '../../state/actions/project';
import IADialog from '../../components/Dialog/Dialog';
import Warning from '@mui/icons-material/Warning';
import { GlobalState } from '../../core/models/state/globalState';

export const Editor = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: GlobalState) => state.user);
  const { workingDocument } = useSelector((state: GlobalState) => state.workingDocument);
  const { template } = useSelector((state: GlobalState) => state.template);
  const { id, type, mode = 'editor' } = useParams();
  const [warningOpen, setWarningOpen] = useState(type === 'document');
  const [url, setUrl] = useState(
    `${process.env.REACT_APP_URL}/IAP/api/dashboard/${type}/launch/${id}/${mode}`,
  );
  const dispatch = useDispatch();

  const handleRedirect = (authors: string[]) => {
    if (
      (!authors.includes(user?.displayName) || !user?.isAuthor) &&
      !user?.isAdmin &&
      mode === 'editor'
    ) {
      navigate(`/layout/edit/${type}/${id}/review`);
      setUrl(`${process.env.REACT_APP_URL}/IAP/api/dashboard/${type}/launch/${id}/review`);
    }
  };

  useEffect(() => {
    if (workingDocument?.id === id && type === 'document') {
      dispatch(getProjectById(() => {}, workingDocument.FIAPPROJECTREFID as string));
      const authors = (workingDocument?.FIAPAUTHORLIST as string)?.split(',') || [];
      handleRedirect(authors);
    } else if (template?.id === id && type === 'template') {
      const authors = (template?.FIAPAUTHORLIST as string)?.split(',') || [];
      handleRedirect(authors);
    }
  }, [workingDocument, template]);

  useEffect(() => {
    if (type && id) {
      if (type === 'template') {
        dispatch(getTemplateById(() => {}, id));
      } else if (type === 'document') {
        dispatch(getWorkingDocumentById(() => {}, id));
      }
    }
  }, []);

  return (
    <>
      <Iframe
        url={url}
        width='100%'
        height='100%'
        id='myId'
        className='iframe_class'
        display='block'
        position='relative'
        onLoad={() => console.log('iframe loaded')}
      />
      <IADialog
        title='Warning'
        open={warningOpen}
        handleClose={() => {}}
        xButton={false}
        mainBtnText='OK'
        handleSubmit={() => setWarningOpen(false)}
        maxWidth='xs'
        titleIcon={<Warning style={{ color: '#F4A261' }} />}
      >
        <div className='warning-message'>
          Current Data Source is not yet validated. Please perform a compliance check before using
          this data for Regulatory Submissions, GxP Purposes or any other Quality or Business
          Decisions
        </div>
      </IADialog>
    </>
  );
};
