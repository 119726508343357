import { IdLabel } from '../global';
import { TransferDocumentMetadata, PhoenixFilterType } from './phoenixData';
import { RIMDocumentMetadata } from './rim';

export interface PhoenixFilterTypes {
  types: IdLabel[];
  entities: IdLabel[];
  departments?: IdLabel[];
}
export type PhoenixFilterDataInstances = {
  [key in PhoenixFilterType]: PhoenixFilterTypes;
};

export type CTDReportInterface = {
  ctdCodes: [];
  ctdTitles: [];
  bbCodes: [];
  countries: [];
  reports: { report: string; reportTitles: string[] }[];
};

export type ProductMetadataInterface = {
  productFamily: string;
  productFamilyCode: string;
  productInn: string;
  roNumber: string;
  substances: { name: string; manufacturers: [] }[];
  drugProducts: {
    name: string;
    dosageStrength: string;
    dosageForm: string;
    intNameNo: string;
    packageCodes: { roformis_no: string; package_code: string }[];
    drugProductManufacturers: string[];
  }[];
};

export interface RIMMetadataInterface {
  excipients: string[];
  excipientTypes: string[];
  typeOfImp: string[];
  clinicalStudies: string[];
  mfSiteRoles: { label: string; id: string }[];
}

export interface TransferStateInterface
  extends PhoenixFilterDataInstances,
    CTDReportInterface,
    RIMMetadataInterface {
  documentMetadata: TransferDocumentMetadata;
  documentMetadataRim: RIMDocumentMetadata;
  languages: IdLabel[];
  productMetadata: ProductMetadataInterface[];
  rimOptionalFields: string[];
}

export interface TransferBodyInterface {
  documentId: string;
  outputId: string;
  metadata: TransferDocumentMetadata | RIMDocumentMetadata;
  user?: string;
}

export interface CTDCodesInterface {
  ctdCodeDerived: string;
}

export interface CTDTitlesInterface {
  ctdTitleDerived: string;
}

export interface BBCodesInterface {
  bbCode: string;
}

export interface CountryInterface {
  country: string;
}

export interface ReportInterface {
  reportClassification: string;
  reportMetadata: {
    title: string[];
  };
}

export interface DrugProduct {
  id: string;
  label: string;
  dosageForm?: string;
  dosageStrength?: string;
  packagingCodes?: IdLabel[];
  dpManufacturers?: IdLabel[];
}

export interface Event {
  title: string;
  description: string;
}

export interface EventType {
  id: string;
  label: string;
  events?: Event[];
  eventChangeCategory?: string;
}

export interface DrugSubstance {
  id: string;
  label: string;
  dsManufacturers?: IdLabel[];
}

export const DOCUMENT_MANAGEMENT_SYSTEMS = {
  VQD: 'Veeva QualityDocs',
  RIM: 'Veeva RIM',
  EVAL: 'eVAL Roche',
};
