import { UserInteface } from '../../core/models/user/user';

export const userActionTypes = {
  GET_USER_PROFILE: 'GET_USER_PROFILE',
  GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
  UPDATE_USER_LANG: 'UPDATE_USER_LANG',
};
export const getUserProfile = (
  callback: (err: unknown, user?: UserInteface) => void = () => {},
) => ({
  type: userActionTypes.GET_USER_PROFILE,
  callback,
});

export const getUserProfileSuccess = (payload: UserInteface) => ({
  type: userActionTypes.GET_USER_PROFILE_SUCCESS,
  payload,
});

export const updateUserLang = (payload: string) => ({
  type: userActionTypes.UPDATE_USER_LANG,
  payload,
});
