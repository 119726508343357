import { useDispatch } from 'react-redux';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { updateUserLang } from '../../state/actions/user';
import { LanguageType } from '../../core/models/user/user';

interface LanguageDialogInterface {
  onClose: () => void;
  open: boolean;
  lang: LanguageType;
}

export default function LanguageDialog(props: LanguageDialogInterface) {
  const dispatch = useDispatch();
  const languages = [
    { label: 'English', code: 'en' },
    { label: 'Deutsch', code: 'de' },
  ];

  const { onClose, open, lang } = props;

  const handleListItemClick = (value: string) => {
    dispatch(updateUserLang(value));
    onClose();
  };

  return (
    <Dialog onClose={onClose} aria-labelledby='simple-dialog-title' open={open}>
      <DialogTitle id='simple-dialog-title'>Set tutorials language</DialogTitle>
      <List>
        {languages.map((language) => (
          <ListItemButton
            onClick={() => handleListItemClick(language.code)}
            key={language.code}
            selected={language.code === lang}
          >
            <ListItemText primary={language.label} />
          </ListItemButton>
        ))}
      </List>
    </Dialog>
  );
}
