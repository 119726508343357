import { useState, useEffect } from 'react';
import { TemplateInterface, Component } from '../../core/models/template/templateInterface';
import { StepFieldsInterface } from '../../core/models/global';
import ComponentPreview from '../../assets/images/component-preview.png';
import Tick from '../../components/icons/Tick';
// eslint-disable-next-line import/no-extraneous-dependencies
import XMLToReact from 'xml-to-react';
import Radio from '@mui/material/Radio';
import ReviewActionTitle from '../../components/ReviewActionTitle/ReviewActionTitle';
import { THIRD_STEP } from '../../constants';

interface SelectComponentsProps {
  title?: string;
  subtitle?: string;
  template?: TemplateInterface;
  components: Component[];
  fillStepFields: (
    step: number,
    value?: StepFieldsInterface,
    template?: TemplateInterface,
    components?: Component[],
  ) => void;
  isReview?: boolean;
  setActiveStep?: (activeStep: number) => void;
}

const SelectComponents = (props: SelectComponentsProps) => {
  const { title, subtitle, fillStepFields, isReview, setActiveStep } = props;
  const xmlToReact = new XMLToReact({
    Example: (attrs: object) => ({ type: 'ul', props: attrs }),
    Item: (attrs: object) => ({ type: 'li', props: attrs }),
    Text: (attrs: object) => ({ type: 'p', props: attrs }),
  });
  const [selectedComponent, setSelectedComponent] = useState<Component>();
  const [components, setComponents] = useState<Component[]>(props.components);

  const checkAllComponentValuesForLatestDoc = (
    currentComponents: Component[],
    data = { useAllLatestDocumentComponents: true, useAllTemplateComponents: true },
  ) => {
    currentComponents.forEach((component) => {
      if (!component.useLatestDocComponent) {
        data.useAllLatestDocumentComponents = false;
      } else {
        data.useAllTemplateComponents = false;
      }
      if (component.components) {
        return checkAllComponentValuesForLatestDoc(component.components, data);
      }
    });
    return data;
  };
  const [useAllTemplateComponents, setUseAllTemplateComponents] = useState(
    checkAllComponentValuesForLatestDoc(components).useAllTemplateComponents,
  );
  const [useAllLatestDocumentComponents, setUseAllLatestDocumentComponents] = useState(
    checkAllComponentValuesForLatestDoc(components).useAllLatestDocumentComponents,
  );
  const [selectedComponentPath, setSelectedComponentPath] = useState<number[]>();

  const reactTree = xmlToReact.convert(`
    <Example name="simple">      
      <Text>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
      </Text>
      <Item i="1">one</Item>
      <Item>two</Item>
      <Item>three</Item>
    </Example>
  `);

  const handleComponent = (component: Component, indexArr?: number[]) => {
    setSelectedComponent(component);
    setSelectedComponentPath(indexArr);
  };

  const findComponent = () => {
    let componentsData = [...components];
    let i = 0;
    if (selectedComponentPath) {
      while (i < selectedComponentPath.length && componentsData.length) {
        componentsData = componentsData[selectedComponentPath[i]].components || [];
        i++;
      }
    }
    return componentsData.find((item) => item.id === selectedComponent?.id);
  };

  const handleComponentType = (useLatestDocumentComponent: boolean) => {
    const component = findComponent();
    if (component) {
      component.useLatestDocComponent = useLatestDocumentComponent;
      setComponents([...components]);
      setSelectedComponent({ ...component });
      const checkedResults = checkAllComponentValuesForLatestDoc(components);
      setUseAllLatestDocumentComponents(checkedResults.useAllLatestDocumentComponents);
      setUseAllTemplateComponents(checkedResults.useAllTemplateComponents);
    }
  };

  const updateAllComponents = (componentsData: Component[], useLatestDocComponent: boolean) => {
    componentsData.forEach((component: Component) => {
      component.useLatestDocComponent = useLatestDocComponent;
      updateAllComponents(component.components || [], useLatestDocComponent);
    });
    return componentsData;
  };

  const updateComponents = (useLatestDocComponent: boolean) => {
    const updatedComponents = updateAllComponents(components, useLatestDocComponent);
    setComponents([...updatedComponents]);
    if (selectedComponent) {
      selectedComponent.useLatestDocComponent = useLatestDocComponent;
      setSelectedComponent({ ...selectedComponent });
    }
  };

  const handleUseAll = (option: string) => {
    if (option === 'latest-document') {
      let newVal = false;
      setUseAllLatestDocumentComponents((prev) => {
        newVal = !prev;
        return newVal;
      });
      updateComponents(newVal);
      setUseAllTemplateComponents(!newVal);
    } else {
      let newVal = false;
      setUseAllTemplateComponents((prev) => {
        newVal = !prev;
        return newVal;
      });
      updateComponents(!newVal);
      setUseAllLatestDocumentComponents(!newVal);
    }
  };

  const getComponents = (component: Component, indexArr: number[]) => {
    return component.components?.map((innerComponent, innerParentIndex) => {
      return (
        <div key={innerComponent.id} className='section-title'>
          <>
            <div
              onClick={() => handleComponent(innerComponent, indexArr)}
              className='section-title-tick-container'
              style={{
                cursor: 'pointer',
                ...(innerComponent.id === selectedComponent?.id && {
                  fontWeight: 700,
                  background: '#F5F5F2',
                }),
              }}
            >
              <div className='title-text'>{innerComponent.title}</div>
              {!!innerComponent.useLatestDocComponent && (
                <span>
                  <Tick className='tick-icon' />
                </span>
              )}
            </div>
            {getComponents(innerComponent, [...indexArr, innerParentIndex])}
          </>
        </div>
      );
    });
  };

  useEffect(() => {
    fillStepFields(THIRD_STEP, undefined, undefined, components);
  }, [components]);

  if (!selectedComponent && isReview) {
    setSelectedComponent(props.components[0]);
  }

  return (
    <div>
      <div className='components-title'>
        <ReviewActionTitle
          onEdit={
            setActiveStep
              ? () => {
                  setActiveStep(THIRD_STEP);
                }
              : undefined
          }
          title={title}
        />
        {subtitle && (
          <div className='action-subtitle' data-testid='action-subtitle'>
            {subtitle}
          </div>
        )}
        {!isReview && (
          <div className='select-options-container'>
            <div className='select-options-text'>
              By default, the template components will be used. If you want to make changes, select
              the most suitable options from below.
            </div>
            <div className='select-options'>
              <Radio checked={useAllTemplateComponents} onChange={() => handleUseAll('template')} />
              <span className='select-text'>Use all template components</span>
              <Radio
                checked={useAllLatestDocumentComponents}
                onChange={() => handleUseAll('latest-document')}
              />
              <span className='select-text'>Use all latest document components</span>
            </div>
          </div>
        )}
      </div>
      <div className='components-container' data-testid='components-container'>
        <div className='components-list'>
          <div className='components-block' data-testid='components-block'>
            {components.map((component, index) => (
              <div key={component.id} style={{ marginBottom: '11px' }}>
                <div
                  onClick={() => handleComponent(component)}
                  key={index}
                  className='component-title'
                  style={{
                    cursor: 'pointer',
                    ...(component.id === selectedComponent?.id && {
                      fontWeight: 700,
                      background: '#F5F5F2',
                    }),
                  }}
                >
                  <div className='title-text'>
                    {index + 1}. {component.title}{' '}
                  </div>
                  {!!component.useLatestDocComponent && (
                    <span>
                      <Tick className='tick-icon' />
                    </span>
                  )}
                </div>
                <span>{getComponents(component, [index])}</span>
              </div>
            ))}
          </div>
        </div>
        <div
          className='component-preview'
          data-testid='component-preview'
          style={isReview ? { pointerEvents: 'none' } : {}}
        >
          {selectedComponent && (
            <>
              <span className='component-title' style={{ padding: 'unset', height: '28px' }}>
                {selectedComponent.title}
              </span>
              <span className='component-preview-description'>
                By default, the template component will be used. If you want to make changes, simply
                click on the component that is most suitable for your needs.
              </span>
            </>
          )}
          <div className='component-preview-blocks'>
            {!selectedComponent ? (
              <img src={ComponentPreview} />
            ) : (
              <>
                <div className='component-xml-container'>
                  <div className='component-xml'>{reactTree} </div>
                  <div className='component-option'>
                    <Radio
                      checked={!selectedComponent.useLatestDocComponent}
                      onChange={() => handleComponentType(false)}
                      disabled={isReview}
                    />
                    Template component
                  </div>
                </div>
                <div className='component-xml-container'>
                  <div className='component-xml'>{reactTree} </div>
                  <div className='component-option'>
                    <Radio
                      checked={!!selectedComponent.useLatestDocComponent}
                      onChange={() => handleComponentType(true)}
                      disabled={isReview}
                    />
                    <span>Latest document component</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectComponents;
