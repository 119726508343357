import { Grid } from '@mui/material';
import ReviewActionTitle from '../../ReviewActionTitle/ReviewActionTitle';
import { RIMDocumentMetadata } from '../../../core/models/transfer/rim';

interface PropsInterface {
  selectedSourceSystem: string;
  documentNumber: string;
  documentMetadata: RIMDocumentMetadata;
  openDialog: (content: 'basic' | 'dms') => void;
}
export const TransferOutputReviewRim = (props: PropsInterface) => {
  const { selectedSourceSystem, documentNumber, documentMetadata, openDialog } = props;
  return (
    <div className='review-state'>
      <ReviewActionTitle
        onEdit={() => {
          openDialog('basic');
        }}
        title='Review basic details'
      />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='dm-system'>
            <div className='label'>Select document management system</div>
            <div className='review-value'>{selectedSourceSystem || '-'}</div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='document-number'>
            <div className='label'>Document Number</div>
            <div className='review-value'>{documentNumber || '-'}</div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='title'>
            <div className='label'>Title</div>
            <div className='review-value'>{documentMetadata.documentTitle || '-'}</div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='pf'>
            <div className='label'>Product Family</div>
            <div className='review-value'>{documentMetadata.productFamily || '-'}</div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='ro'>
            <div className='label'>RO Number</div>
            <div className='review-value'>{documentMetadata.roNumber || '-'}</div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='substance'>
            <div className='label'>Active Substance</div>
            <div className='review-value'>{documentMetadata.drugSubstance || '-'}</div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='formulation-number'>
            <div className='label'>Formulation Number</div>
            <div className='review-value'>{documentMetadata.formulationNumber || '-'}</div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='formulation-number'>
            <div className='label'>Manufacturer </div>
            <div className='review-value'>{documentMetadata.manufacturers || '-'}</div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='clinical-study'>
            <div className='label'>Manufacturing Site Role</div>
            <div className='review-value'>
              {documentMetadata.manufacturingSiteRoles?.map((el) => el.label).join('') || '-'}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='stage'>
            <div className='label'>Stage</div>
            <div className='review-value'>{documentMetadata.stage}</div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='countries'>
            <div className='label'>Countries</div>
            <div className='review-value'>{documentMetadata.countries.join(',') || '-'}</div>
          </div>
        </Grid>
      </Grid>
      <div className='document-management-system-details'>
        <ReviewActionTitle
          onEdit={() => {
            openDialog('dms');
          }}
          title='Review document management system details'
        />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='excipient-type'>
            <div className='label'>Excipient type</div>
            <div className='review-value'>{documentMetadata.excipientType || '-'}</div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='excipient-name'>
            <div className='label'>Excipient Name</div>
            <div className='review-value'>{documentMetadata.excipientName || '-'}</div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='type-of-imp'>
            <div className='label'>Type of IMP</div>
            <div className='review-value'>{documentMetadata.typeOfImp || '-'}</div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='clinical-study'>
            <div className='label'>Clinical Studies</div>
            <div className='review-value'>{documentMetadata.clinicalStudy || '-'}</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
