import { getLovLabel } from '../../services/apiTranformations';
import { LovValue } from '../lovValue';
import { DefinitioniInterface } from '../system/systemDefinition';
import { NewWorkingDocumentInterface, WorkingDocumentInterface } from './workingDocumentInterface';

export class WorkingDocument {
  constructor(data: WorkingDocumentInterface) {
    Object.assign(this, data);
  }

  public static readonly createWorkingDocument = (data: WorkingDocumentInterface) => {
    return new WorkingDocument(data);
  };

  public static getValue = (
    data: string | boolean | undefined | string[],
    definition: DefinitioniInterface,
    key: string,
    workingDocumentDefinitions: DefinitioniInterface[],
    lovs: { [key: string]: LovValue[] },
  ) => {
    if (definition.type === 'string' || definition.type === 'dateTime') {
      return !definition.flags?.isMultiValue ? data : (data as string).split(',');
    } else if (definition.type === 'lov' && !definition.flags?.isMultiValue) {
      return {
        id: data,
        label: getLovLabel(key, data as string, workingDocumentDefinitions, lovs),
      };
    } else if (definition.type === 'multiLov' || definition.flags?.isMultiValue) {
      return (data as string[])?.map((item) => ({
        [definition.type === 'userReference' ? 'value' : 'id']: item,
        label: getLovLabel(key, item, workingDocumentDefinitions, lovs),
      }));
    }
  };

  public static createWorkingDocumentObject = (
    data: WorkingDocumentInterface,
    workingDocumentDefinitions: DefinitioniInterface[],
    lovs: { [key: string]: LovValue[] },
  ) => {
    const getMultiValueType = (type: string) => {
      if (type === 'userReference') {
        return 'multiUserReference';
      }
      if (type === 'string') {
        return 'multiString';
      }
      return 'multiLov';
    };
    const result = Object.keys(data)
      .map((key) => {
        const definition = workingDocumentDefinitions.find(
          (item: DefinitioniInterface) => item.id === key,
        );
        if (definition) {
          return {
            id: definition.id,
            value: this.getValue(
              data[key as keyof WorkingDocumentInterface],
              definition,
              key,
              workingDocumentDefinitions,
              lovs,
            ),
            type: definition.flags?.isMultiValue
              ? getMultiValueType(definition.type)
              : definition.type,
          };
        }
        return null;
      })
      .filter((item) => item);
    return { fields: [...result] } as NewWorkingDocumentInterface;
  };
}
