import api from './index';
import { NewProjectInterface } from '../models/project/projectInterface';
import { FilterFieldsInterface } from '../models/filter/filter';
import { OrderByInterface } from '../models/order';
import { FieldRequestInterface } from '../models/system/systemDefinition';

export async function getProjectById(id: string, requestedFields: string[]) {
  let requestedFieldIdsQuery = '';
  requestedFields.forEach((field) => {
    requestedFieldIdsQuery += `requestedFieldIds=${field}&`;
  });
  const result = await api.get(`/dashboard/project/${id}?${requestedFieldIdsQuery.slice(0, -1)}`);
  return result.data;
}

export async function getProjectByIdWithErrorHandling(id: string) {
  try {
    const result = await api.get(`/dashboard/project/${id}`);
    return result.data;
  } catch (error) {
    return null;
  }
}

export async function getProjects(
  filterFields: FilterFieldsInterface[],
  requestedFields: string[],
  page: number,
  size: number,
  orderByField?: OrderByInterface,
) {
  let requestedFieldIdsQuery = '';
  requestedFields.forEach((field) => {
    requestedFieldIdsQuery += `requestedFieldIds=${field}&`;
  });
  const result = await api.post(`/dashboard/project/list?${requestedFieldIdsQuery.slice(0, -1)}`, {
    filterFields,
    requestedFields,
    page,
    size,
    orderByField,
  });
  return result.data;
}

export async function createProject(body: NewProjectInterface) {
  const result = await api.post('/dashboard/project', body);
  return result.data;
}

export async function getProjectMetadataValuesApi(body: FieldRequestInterface[] = []) {
  const result = await api.post('/dashboard/project/values', {
    fields: body,
  });
  return result.data;
}

export async function updateProject(id: string, body: NewProjectInterface) {
  const result = await api.patch(`/dashboard/project/${id}`, body);
  return result.data;
}

export async function deleteProjectById(id: string) {
  const result = await api.delete(`/project/${id}`);
  return result.data;
}
