import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function TemplateMenuIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='24' height='24' viewBox='0 0 24 24'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 22H6C4.9 22 4 21.1 4 20V5C4 3.3 5.3 2 7 2H20V4H7C6.4 4 6 4.4 6 5C6 5.6 6.4 6 7 6H20V20C20 21.1 19.1 22 18 22ZM6 7.8V20H18V8H7C6.6 8 6.3 7.9 6 7.8ZM8 11V14H9.5V14.2C9.5 14.9 8.9 15.4 8.3 15.4H8V17H8.2C9.7 17 11 15.8 11 14.2V14V12.2V11H8ZM13 14V11H16V12.2V14V14.2C16 15.8 14.7 17 13.2 17H13V15.4H13.3C13.9 15.4 14.5 14.9 14.5 14.2V14H13Z'
        fill='#544F4F'
      />
    </SvgIcon>
  );
}
