import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function EditIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 21H19C20.1 21 21 20.1 21 19V11H19V19H5V5H13V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21ZM19.2 3.59998L20.4 4.79999C21.2 5.49999 21.2 6.79998 20.4 7.59998L11 17H7V13L16.4 3.59998C16.8 3.19998 17.3 3 17.8 3C18.3 3 18.8 3.19998 19.2 3.59998ZM9 15H10.2L19 6.20001L17.8 5L9 13.8V15Z'
        fill='#0066CC'
      />
    </SvgIcon>
  );
}
