import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './Projects.scss';
import PageTitle from '../../components/PageTitle/PageTitle';
import { GlobalState } from '../../core/models/state/globalState';
import { useEffect, useState } from 'react';
import { getMYProjects, getProjects } from '../../state/actions/project';
import IATableHeader from '../../components/TableHeader/TableHeader';
import IAButton from '../../components/Button/Button';
import { CircularProgress, Grid, Menu } from '@mui/material';
import { filterFavoriteProjects, setFavorite } from '../../state/actions/favorite';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import IATabs from '../../components/Tabs/Tabs';
import NoSearchResults from '../../components/NoSearchResults/NoSearchResults';
import EmptyState from '../../components/EmptyState/EmptyState';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import VideoTutorialPlaceholder from '../../components/VideoTutorial/VideoTutorialPlaceholder';
import Pagination from '../../components/Pagination/Pagination';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { ProjectInterface } from '../../core/models/project/projectInterface';
import { DEFAULT_ORDER } from '../../constants';
import { disableEventsClass } from '../../core/services/helpers';

export const Projects = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: GlobalState) => state.user);

  const { tableRows, totalItems, myTotalItems, myProjectsTableRows } = useSelector(
    (state: GlobalState) => state.project,
  );
  const { projects, favoriteProjects } = useSelector((state: GlobalState) => state.favorite);

  const {
    project: { fields },
  } = useSelector((state: GlobalState) => state.system);
  const { projectCategories, values, filtersApplied } = useSelector(
    (state: GlobalState) => state.filter,
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [pageAndSize, setPageAndSize] = useState({ page: 1, size: 9 });
  const [pageAndSizeMy, setPageAndSizeMy] = useState({ page: 1, size: 9 });

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isMyDataLoaded, setIsMyDataLoaded] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentTableRows, setCurrentTableRows] = useState<ProjectInterface[]>([]);
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const isMyDataTab = (activeTab = currentTab) => activeTab === 0;
  const isFavoritesDataTab = (activeTab = currentTab) => activeTab === 1;
  const isFiltersApplied = () => {
    return !!filtersApplied?.projectFilters?.length || !!searchQuery;
  };
  const [alertText, setAlertText] = useState<string>('');
  const [tabs, setTabs] = useState<{ label: string; count: number }[]>([]);

  useEffect(() => {
    setTabs([
      { label: 'My Workspaces', count: myTotalItems },
      {
        label: 'Favourites',
        count: favoriteProjects.length,
      },
      { label: 'All Workspaces', count: totalItems },
    ]);
  }, [totalItems, myTotalItems, projects]);

  useEffect(() => {
    if (isMyDataTab()) {
      setCurrentTableRows(myProjectsTableRows);
    } else if (isFavoritesDataTab()) {
      setCurrentTableRows(favoriteProjects);
    } else {
      setCurrentTableRows(tableRows);
    }
  }, [currentTab, tableRows, myProjectsTableRows, favoriteProjects]);

  const getData = () => {
    setIsDataLoaded(false);
    const filterFieldValue = [...filtersApplied.projectFilters];
    if (searchQuery.length > 0 && !filterFieldValue.find((item) => item.id === 'FTITLE')) {
      filterFieldValue.push({
        id: 'FTITLE',
        type: 'string',
        operator: 'Like',
        value: [`%${searchQuery}%`],
      });
    }
    dispatch(filterFavoriteProjects(filterFieldValue));

    dispatch(
      getProjects(
        () => {
          setIsDataLoaded(true);
        },
        {
          page: pageAndSize.page,
          size: pageAndSize.size,
          filterFields: filterFieldValue,
          orderBy: DEFAULT_ORDER,
        },
      ),
    );
    dispatch(
      getMYProjects(
        () => {
          setIsMyDataLoaded(true);
        },
        {
          page: pageAndSizeMy.page,
          size: pageAndSize.size,
          filterFields: [
            ...filterFieldValue,
            {
              id: 'FIAPPROJECTASSIGNEE',
              type: 'userReference',
              operator: 'Equal',
              value: [user.userId],
            },
          ],
          orderBy: DEFAULT_ORDER,
        },
      ),
    );
  };

  useEffect(() => {
    getData();
  }, [pageAndSize, pageAndSizeMy, fields, filtersApplied, searchQuery]);

  const handleFavoriteClick = (id: string) => {
    const project = currentTableRows.find((item) => item.id === id);
    if (project) {
      const isFavorite = project.isFavorite;
      dispatch(
        setFavorite(
          () => {
            setAlertText(
              `Workspace ${project.FTITLE ?? ''} is ${
                isFavorite ? 'removed from' : 'added to'
              } favorites`,
            );
          },
          'projects',
          project,
          isMyDataTab(),
        ),
      );
    }
  };

  const handleTabChange = (tabIndex: number) => {
    setCurrentTab(tabIndex);
  };

  const isEmptyState = () => isDataLoaded && !tableRows?.length && !isFiltersApplied();
  const getPageAndSize = () => (isMyDataTab() ? pageAndSizeMy : pageAndSize);

  return (
    <Box>
      <div className='projects' data-testid='projects'>
        <div className='page-header'>
          <PageTitle name='Workspaces' />
          {!isEmptyState() && (user.isAdmin || user.isAuthor) && (
            <IAButton
              className='main-action-btn'
              handleClick={() => {
                navigate('/layout/workspaces/new');
              }}
            >
              Create new workspace
            </IAButton>
          )}
        </div>
        {isEmptyState() ? (
          <EmptyState type='workspace' isAdminOrAuthor={user.isAdmin || user.isAuthor} />
        ) : (
          <div className='page-content'>
            <IATabs tabs={tabs} handleTabChange={handleTabChange} defaultValue={currentTab} />
            <IATableHeader
              className={disableEventsClass(!isDataLoaded)}
              categories={projectCategories}
              categoryValues={values}
              handleSearch={(text: string) => {
                setSearchQuery(text);
              }}
              type='projectFilters'
            />
            {isDataLoaded && isMyDataLoaded ? (
              <>
                {currentTableRows?.length ? (
                  <div className='project-list-container'>
                    <Grid className='projects-card-list' container spacing={3}>
                      {currentTableRows.map((rowItem) => {
                        return (
                          <Grid key={rowItem.id as string} item xs={4}>
                            <ProjectCard
                              visibleRow={rowItem}
                              handleClick={() => {
                                navigate(`/layout/workspaces/${rowItem.id}`);
                              }}
                              handleThreeDotsClick={(
                                event: React.MouseEvent<SVGSVGElement, MouseEvent>,
                              ) => {
                                event.stopPropagation();
                                setAnchorEl(event.currentTarget);
                              }}
                              handleFavoriteClick={handleFavoriteClick}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                    <div className='projects-card-list-footer'>
                      <VideoTutorialPlaceholder name='Workspace' />
                      {!isFavoritesDataTab() && (
                        <Pagination
                          count={getPageAndSize().size}
                          page={getPageAndSize().page}
                          pageChangeHandler={(page: number) => {
                            if (isMyDataTab()) {
                              setPageAndSizeMy({ page, size: pageAndSize.size });
                            } else {
                              setPageAndSize({ page, size: pageAndSize.size });
                            }
                          }}
                          totalItems={isMyDataTab() ? myTotalItems : totalItems}
                          isFromDashboard={true}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className='loader-container'>
                    <NoSearchResults
                      tutorial='project'
                      isEmptyState={isEmptyState() && isMyDataTab()}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className='loader-container'>
                <CircularProgress value={30} size='80px' />
              </div>
            )}

            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={!!alertText}
              autoHideDuration={5000}
              onClose={() => setAlertText('')}
            >
              <Alert
                onClose={() => setAlertText('')}
                severity='success'
                variant='filled'
                sx={{ width: '100%' }}
              >
                {alertText}
              </Alert>
            </Snackbar>
          </div>
        )}
      </div>
      <div className='options-menu'>
        <Menu
          disablePortal
          open={!!anchorEl}
          className='options-list'
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          disableScrollLock
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          <MenuList autoFocusItem={!!anchorEl}>
            <MenuItem className='menu-item' onClick={() => {}}>
              Archive
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
    </Box>
  );
};
