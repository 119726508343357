import { MenuItem, ListItemIcon, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import './SideMenu.scss';
import List from '@mui/material/List';
import { useLocation, Link } from 'react-router-dom';
import HelperCenterIcon from '../../components//icons/HelpCenterIcon';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { menuItems } from '../../core/services/menuService';
import { MenuItemInterface } from '../../core/models/menuItem';
import RoseIcon from '../../components/icons/RoseIcon';
/**
 * SideMenuList = left sidebar/menu
 * @param props
 * @constructor
 * @internal the vertical position is calculated and set in /src/index.tsx
 */

interface SideMenuPropsInterface {
  toggleDrawer: (value: boolean) => void;
  onSideMenuClicked: () => void;
  collapseState: boolean;
  hideDoubleArrows?: boolean;
}

function SideMenuList(props: Readonly<SideMenuPropsInterface>) {
  const location = useLocation();
  const userGuideMenuItem = {
    id: 8,
    name: 'Tutorials',
    component: Link,
    icon: HelperCenterIcon,
    className: 'user-guide-icon',
    link: '',
    clickHandler: () => {
      props.toggleDrawer(true);
    },
    area: 'userGuide',
  };
  const serviceMenuItem = {
    id: 9,
    name: 'RoSE Portal',
    component: Link,
    icon: RoseIcon,
    link: 'https://go.roche.com/iap-rose',
    area: '',
  };
  const isMenuItemActive = (menuItem: MenuItemInterface) => {
    let isActive = false;
    if (menuItem.activeLinks) {
      const activeLink = menuItem.activeLinks.findIndex((link: string) =>
        location.pathname.startsWith(link),
      );
      isActive = activeLink !== -1;
    } else {
      isActive = location.pathname === menuItem.link;
    }

    return isActive;
  };

  const renderMenuitem = (item: MenuItemInterface, openInNewTab?: boolean) => {
    const isLinkActive = isMenuItemActive(item);
    const IconComponent = item.icon;
    const linkProps: {
      component?: React.ElementType;
      to?: string | null;
      href?: string;
      target?: string;
    } = {};
    if (item.link) {
      linkProps.component = item.component;
      linkProps.to = item.link;
    }
    if (openInNewTab) {
      linkProps.href = item.link as string;
      linkProps.target = '_blank';
    }
    return (
      <Tooltip
        title={item.name}
        placement='right'
        arrow
        disableHoverListener={!props.collapseState}
        key={item.name}
      >
        <div key={item.name}>
          <MenuItem
            {...linkProps}
            style={{
              display: 'flex',
              alignItems: 'center',
              ...(props.collapseState ? { width: '65px' } : {}),
            }}
            className={`menu__item ${isLinkActive && 'menu__item--active'} ${item.className || ''}`}
            data-tip={item.name}
            data-tooltip-id={item.name}
            data-tooltip-content={item.name}
            onClick={() => {
              if (item.clickHandler) {
                item.clickHandler();
              }
            }}
          >
            <ListItemIcon>
              <IconComponent fill={isLinkActive ? '#0066CC' : '#6d6d6d'} fontSize='small' />
            </ListItemIcon>
            {!props.collapseState && (
              <Typography variant='caption' data-testid={item.name}>
                {item.name}
              </Typography>
            )}
          </MenuItem>
        </div>
      </Tooltip>
    );
  };

  return (
    <div className='left-menu'>
      <List component='nav'>
        {menuItems.map((item: MenuItemInterface) => renderMenuitem(item))}
        {renderMenuitem(userGuideMenuItem)}
        <hr className='divider' />
        {renderMenuitem(serviceMenuItem, true)}
        {!props.hideDoubleArrows && (
          <div onClick={() => props.onSideMenuClicked()} aria-hidden='true' className='arrow'>
            <List>
              <MenuItem
                className='menu__item'
                style={{
                  ...(props.collapseState ? { width: 'unset' } : {}),
                }}
              >
                <ListItemIcon>
                  {props.collapseState ? (
                    <div className='arrows'>
                      <KeyboardDoubleArrowRightIcon />
                    </div>
                  ) : (
                    <div className='arrows'>
                      <KeyboardDoubleArrowLeftIcon />
                    </div>
                  )}
                </ListItemIcon>
              </MenuItem>
            </List>
          </div>
        )}
      </List>
    </div>
  );
}

export default SideMenuList;
