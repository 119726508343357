import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { DefinitioniInterface } from '../../core/models/system/systemDefinition';
import IASearch from '../../components/Search/Search';
import './List.scss';

interface CheckboxListProps {
  columns: DefinitioniInterface[];
  selectedColumns: string[];
  setSelectedColumns: (data: string[]) => void;
}

export default function CheckboxList(props: CheckboxListProps) {
  const data = [];
  const { columns, selectedColumns, setSelectedColumns } = props;
  const [searchedColumns, setSearchedColumns] = useState<DefinitioniInterface[]>(columns);
  let blockSize =
    columns.length >= 20
      ? columns.length / 4
      : columns.length >= 10
      ? columns.length / 3
      : columns.length >= 7
      ? columns.length / 2
      : columns.length;
  blockSize = Math.ceil(blockSize);
  const blockSizeFirst = blockSize - 1;

  const handleToggle = (id: string) => {
    let newSelectedColumns: string[] = [];
    if (id === 'all') {
      if (
        selectedColumns.filter((selectedCol: string) =>
          searchedColumns.find((col) => col.id === selectedCol),
        ).length === searchedColumns.length
      ) {
        newSelectedColumns = [];
      } else {
        newSelectedColumns = searchedColumns.map((column: DefinitioniInterface) => column.id);
      }
    } else {
      newSelectedColumns = [...selectedColumns];
      if (!newSelectedColumns.includes(id)) {
        newSelectedColumns.push(id);
      } else {
        newSelectedColumns = newSelectedColumns.filter((item: string) => item !== id);
      }
    }
    setSelectedColumns(newSelectedColumns);
  };

  const handleSearch = (value: string) => {
    if (value) {
      setSearchedColumns(
        columns.filter((col: DefinitioniInterface) =>
          col.label.toLowerCase().match(value.toLowerCase()),
        ),
      );
    } else {
      setSearchedColumns(columns);
    }
  };

  for (
    let blockIndex = 0;
    blockIndex < searchedColumns.length;
    blockIndex += blockIndex === 0 ? blockSizeFirst : blockSize
  ) {
    data.push(
      searchedColumns.slice(
        blockIndex,
        blockIndex + (blockIndex === 0 ? blockSizeFirst : blockSize),
      ),
    );
  }

  return (
    <div className='manage-columns'>
      <p className='description' data-testid='description'>
        Select the columns you would like to see in the table. They will appear in the order of
        selection.
      </p>
      <div className='column-search'>
        <IASearch handleSearch={(value: string) => handleSearch(value)} />
      </div>
      <div style={{ display: 'flex' }}>
        {data.map((item: DefinitioniInterface[], index: number) => (
          <List key={index}>
            {index === 0 && (
              <ListItem sx={{ minWidth: '250px' }} key={'check-uncheck-all'} disablePadding>
                <ListItemButton role={undefined} onClick={() => handleToggle('all')} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge='start'
                      checked={
                        selectedColumns.filter((selectedCol: string) =>
                          searchedColumns.find((col) => col.id === selectedCol),
                        ).length === searchedColumns.length
                      }
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': 'checkbox-list-label-all' }}
                    />
                  </ListItemIcon>
                  <ListItemText id='check-uncheck-all' primary='Select all' />
                </ListItemButton>
              </ListItem>
            )}
            {item.map((column, i) => {
              const labelId = `checkbox-list-label-${column.id}`;
              return (
                <ListItem
                  sx={{ minWidth: '205px' }}
                  key={`${column.label}_${index}_${i}`}
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={() => handleToggle(column.id)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge='start'
                        checked={selectedColumns.includes(column.id)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${column.label}`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        ))}
      </div>
    </div>
  );
}
