import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import { FilterStateInterface, FilterTypes } from '../../../core/models/filter/filterState';
import { GlobalState } from '../../../core/models/state/globalState';
import { useEffect, useState } from 'react';
import './AppliedFilters.scss';
import { FilterIterface, operatorOptions } from '../../../core/models/filter/filter';
import { removeAppliedFilterById } from '../../../state/actions/filter';

const AppliedFilters = (props: { type: FilterTypes; isFromDocCreation?: boolean }) => {
  const { type, isFromDocCreation = false } = props;
  const dispatch = useDispatch();

  const { filtersApplied }: FilterStateInterface = useSelector(
    (state: GlobalState) => state.filter,
  );
  const [filtersOfType, setFiltersOfType] = useState<FilterIterface[]>([]);

  useEffect(() => {
    setFiltersOfType(filtersApplied[type]);
  }, [filtersApplied]);

  const handleRemove = (id: string) => {
    dispatch(removeAppliedFilterById(() => {}, type, id));
  };

  let filters = filtersOfType?.filter((filter) => {
    return filter.id !== 'FIAPCREATEDBY';
  });

  if (isFromDocCreation) {
    filters = filters?.filter((filter) => {
      return !['FCUSTTEMPLATETYPE', 'FIAPTEMPLATESTATUS'].includes(filter.id);
    });
  }

  return filters?.length ? (
    <div className='applied-filters'>
      {filters?.map((filter, index) => (
        <div key={index} className='applied-filter-item'>
          <span className='filter-text'>
            {filter.label ? filter.label : filter.id}{' '}
            {operatorOptions.find((op) => op.id === filter.operator)?.label}{' '}
            {filter.value?.map((v) => v.replaceAll('%', '')).join(',')}
          </span>
          <div className='cross'>
            <Close
              onClick={() => {
                handleRemove(filter.id);
              }}
            />
          </div>
        </div>
      ))}
    </div>
  ) : (
    <></>
  );
};

export default AppliedFilters;
