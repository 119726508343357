import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ProjectIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='24' height='24' viewBox='0 0 24 24'>
      <path
        d='M19 7H15.6L14.4 4.7C13.9 3.7 12.9 3 11.7 3H4C2.9 3 2 3.9 2 5V7V9V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V10C22 8.3 20.7 7 19 7ZM4 7V5H11.8C12.2 5 12.5 5.2 12.7 5.6L13.4 7H4ZM20 9V20H4V9H20Z'
        fill='#544F4F'
      />
    </SvgIcon>
  );
}
