import api from './index';

export async function getUserInfo() {
  try {
    const result = await api.get('/profile/me');
    return result.data;
  } catch (error) {
    return null;
  }
}
