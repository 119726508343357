import axios, { InternalAxiosRequestConfig } from 'axios';

const apiAxios = axios.create({
  baseURL: `${process.env.REACT_APP_URL}/IAP/api`,
});

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  // const token = localStorage.getItem('access_token');
  // config.headers.Authorization = token;
  return config;
};

apiAxios.interceptors.request.use(onRequest);

apiAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && window.location.pathname !== '/') {
      window.location.href = `/?pathname=${window.location.pathname}${window.location.hash}`;
    } else {
      if (error.response.status === 403 && ['post', 'patch', 'put'].includes(error.config.method)) {
        error.response.data = 'Action forbidden, please check your inputted data';
      }
      throw error;
    }
  },
);
export default apiAxios;
