import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import {
  getTemplateById,
  getTemplates,
  createTemplate,
  updateTemplate,
  deleteTemplateById,
  getTemplateMetadataValuesApi,
} from '../../core/api/template.api';
import { TemplateInterface } from '../../core/models/template/templateInterface';
import { FieldRequestInterface, ListResponse } from '../../core/models/system/systemDefinition';
import {
  getTemplateSuccess,
  getTemplatesSuccess,
  createTemplateSuccess,
  updateTemplateSuccess,
  deleteTemplateSuccess,
  templateActionTypes,
  getMyTemplatesSuccess,
} from '../actions/template';
import { getFavorites } from '../actions/favorite';
import { GlobalState } from '../../core/models/state/globalState';
import { SystemStateInterface } from '../../core/models/system/systemState';
import { setTemplateCategories } from '../actions/filter';
import { systemActionTypes } from '../actions/system';
import { createFilterFields } from '../../core/models/filter/filter';
import { Template } from '../../core/models/template/template';
import { FavoriteState } from '../../core/models/favorites/favoriteState';
import { GetListActionInterface } from '../../core/models/global';
import { TYPES_NO_FILTERING } from '../../constants';
import {
  RequiredMetadata,
  RequiredMetadataResponse,
} from '../../core/models/workingDocument/requiredMetadata';

const getTemplateFields = (state: GlobalState) => state.system;
const getFavoriteValues = (state: GlobalState) => state.favorite;

export function* getTemplate(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: string;
}) {
  try {
    const templateItem: TemplateInterface = yield call(() => getTemplateById(action.payload, []));
    action.callback(null);
    const favorites: FavoriteState = yield select(getFavoriteValues) || {};
    const favoriteTemplates = favorites.templates || [];
    if (templateItem.id) {
      templateItem.isFavorite = favoriteTemplates.includes(templateItem.id as string);
    }
    yield put(getTemplateSuccess(templateItem));
  } catch (error) {
    action.callback(error);
  }
}

export function* getTemplatesByPagination(
  action: GetListActionInterface,
  successAction: (payload: ListResponse) => { type: string; payload: ListResponse },
) {
  try {
    const { template }: SystemStateInterface = yield select(getTemplateFields) || [];

    const { page, size, filterFields, orderBy, allFavorites } = action.payload;
    const templates: ListResponse = yield call(() =>
      getTemplates(
        createFilterFields(filterFields),
        Array.from(new Set([...template.fields.map((field) => field.id)])),
        allFavorites ? 1 : page,
        allFavorites ? 1000 : size,
        orderBy,
      ),
    );
    action.callback(null);
    yield put(
      setTemplateCategories(
        () => {},
        template.fields
          .filter(
            (field) =>
              field.flags?.isFilterable === true && !TYPES_NO_FILTERING.includes(field.type),
          )
          .map((field) => ({ id: field.id, label: field.label, type: field.type })),
      ),
    );

    yield put(getFavorites());
    const favorites: FavoriteState = yield select(getFavoriteValues) || {};
    const favoriteTemplates = favorites.templates || [];
    templates.items.forEach((item) => {
      if (favoriteTemplates.includes(item.id)) {
        item.isFavorite = true;
      }
    });
    if (allFavorites) {
      templates.items = templates.items.filter((item) => item.isFavorite);
      // .splice((page - 1) * size, (page - 1) * size + size);
      // templates.total = favoriteTemplates.length;
      // templates.size = size;
    }
    yield put(successAction(templates));
  } catch (error) {
    action.callback(error);
  }
}

export function* addTemplate(action: {
  callback: (err: unknown, data?: TemplateInterface) => void;
  type: string;
  payload: TemplateInterface;
}) {
  try {
    const {
      template: { fields: templateDefinitions },
    }: SystemStateInterface = yield select(getTemplateFields) || [];
    const newTemplate = Template.createTemplateObject(action.payload, templateDefinitions);
    const template: TemplateInterface = yield call(() => createTemplate(newTemplate));
    yield put(createTemplateSuccess(template));
    action.callback(null, template);
  } catch (error) {
    action.callback(error);
  }
}

export function* editTemplate(action: {
  callback: (err: unknown) => void;
  type: string;
  id: string;
  payload: { body: TemplateInterface; id: string };
}) {
  try {
    const {
      template: { fields: templateDefinitions },
    }: SystemStateInterface = yield select(getTemplateFields) || [];
    const updateTemplateData = Template.createTemplateObject(
      action.payload.body,
      templateDefinitions,
    );
    const template: TemplateInterface = yield call(() =>
      updateTemplate(action.payload.id, { ...updateTemplateData, id: action.payload.id }),
    );
    yield put(updateTemplateSuccess(template));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* deleteTemplate(action: {
  callback: (err: unknown) => void;
  type: string;
  id: string;
  payload: string;
}) {
  try {
    const template: TemplateInterface = yield call(() => deleteTemplateById(action.payload));
    yield put(deleteTemplateSuccess(template));
  } catch (error) {
    action.callback(error);
  }
}

export function* getTemplateMetadataValues(action: {
  callback: (err: { response: { data: string } } | null, data?: RequiredMetadata[]) => void;
  type: string;
  payload: {
    fields: FieldRequestInterface[];
  };
}) {
  try {
    const { fields } = action.payload;
    const templateMetadata: RequiredMetadataResponse = yield call(() =>
      getTemplateMetadataValuesApi(fields),
    );
    const { template }: SystemStateInterface = yield select(getTemplateFields) || [];
    templateMetadata.fields.forEach((field) => {
      const definition = template.fields.find((el) => el.id === field.id);
      if (definition) {
        field.definition = definition;
      }
    });
    action.callback(null, templateMetadata.fields);
  } catch (error) {
    action.callback(error as { response: { data: string } });
  }
}
export default function* templateWatcher() {
  yield take(systemActionTypes.GET_SYSTEM_DEFINITIONS_SUCCESS);
  yield takeLatest(templateActionTypes.GET_TEMPLATE, getTemplate);
  yield takeLatest(templateActionTypes.GET_TEMPLATES, (action: GetListActionInterface) =>
    getTemplatesByPagination(action, getTemplatesSuccess),
  );
  yield takeLatest(templateActionTypes.GET_MY_TEMPLATES, (action: GetListActionInterface) =>
    getTemplatesByPagination(action, getMyTemplatesSuccess),
  );
  yield takeLatest(templateActionTypes.CREATE_TEMPLATE, addTemplate);
  yield takeLatest(templateActionTypes.UPDATE_TEMPLATE, editTemplate);
  yield takeLatest(templateActionTypes.DELETE_TEMPLATE, deleteTemplate);
  yield takeLatest(templateActionTypes.GET_TEMPLATE_METADATA_VALUES, getTemplateMetadataValues);
}
