import { useState } from 'react';
import VideoTutorial from '../VideoTutorial/VideoTutorial';

interface VideoTutorialPlaceholderProps {
  name: string;
  text?: string;
}

const VideoTutorialPlaceholder = (props: VideoTutorialPlaceholderProps) => {
  const { name, text = 'Watch tutorial' } = props;
  const [openTutorial, setTutorial] = useState<boolean>(false);

  return (
    <>
      <div
        className='action-link'
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={() => setTutorial((prevValue: boolean) => !prevValue)}
      >
        {text}
      </div>
      <VideoTutorial title={name} open={openTutorial} close={() => setTutorial(false)} />
    </>
  );
};

export default VideoTutorialPlaceholder;
