import SvgIcon from '@mui/material/SvgIcon';

export default function AddMember() {
  return (
    <SvgIcon
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 8.893h.2a2.92 2.92 0 0 1 2.8 2.893V12a2.933 2.933 0 0 1-3 2.814h-.16a2.907 2.907 0 0 1-2.8-2.894v-.12A2.922 2.922 0 0 1 16 8.893zm0-2.373a5.333 5.333 0 0 0-5.333 5.333v.133A5.333 5.333 0 0 0 15.8 17.32h.2a5.333 5.333 0 0 0 5.334-5.08v-.454A5.334 5.334 0 0 0 16.2 6.52H16zm2.667 13.333v2.666H9.974A2.667 2.667 0 0 0 7.587 24l-1.933 3.854H2.667l2.52-5.054a5.334 5.334 0 0 1 4.773-2.946h8.707zm5.333 0h2.667v2.666h2.667v2.667h-2.667v2.667H24v-2.667h-2.666V22.52H24v-2.667z'
        fill='#0B41CD'
      />
    </SvgIcon>
  );
}
