import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import IASelect, { AutocompleteValueT } from '../../components/Select/Select';
import IAMultiSelect from '../../components/MultiSelect/MultiSelect';
import { IAInput } from '../../components/Input/Input';
import ReviewActionTitle from '../../components/ReviewActionTitle/ReviewActionTitle';
import { GlobalState } from '../../core/models/state/globalState';
import { ProjectFormValues } from '../../core/models/project/projectInterface';
import { SelectValue } from '../../core/models/global';
import { getProjectMetadataValues } from '../../state/actions/project';
import { RequiredMetadata } from '../../core/models/workingDocument/requiredMetadata';

interface ProjectDetailsProps {
  fillStepFields: (step: string, value: ProjectFormValues) => void;
  fieldsFilled: ProjectFormValues;
  isReview?: boolean;
  onEdit?: () => void;
  title?: string;
  emptyFields?: string[];
}

const ProjectDetails = (props: ProjectDetailsProps) => {
  const dispatch = useDispatch();
  const { fillStepFields, fieldsFilled, isReview, title, onEdit, emptyFields = [] } = props;
  const {
    project: { lovs },
  } = useSelector((state: GlobalState) => state.system);

  const [formValues, setFormValues] = useState<ProjectFormValues>({
    type: fieldsFilled.type,
    name: fieldsFilled.name ?? '',
    productAreas: fieldsFilled.productAreas ?? [],
    members: fieldsFilled.members ?? [],
  });
  const productAreas = (lovs?.DCUSTPRODUCTAREA || []).map((item) => ({
    id: item.id as string,
    label: item.label as string,
  }));
  const [memberList, setMemberList] = useState<SelectValue[]>([]);

  const onChange = (input: string, value?: AutocompleteValueT) => {
    setFormValues({ ...formValues, [input]: value as string | string[] });
  };

  const handleUsers = (fields: RequiredMetadata[]) => {
    const field = fields.find((el) => el.id === 'FIAPPROJECTASSIGNEE');
    if (field) {
      setMemberList(
        field.values.map((item) => ({
          id: item.value as string,
          label: item.label as string,
        })),
      );
    }
  };

  useEffect(() => {
    dispatch(
      getProjectMetadataValues(
        (err, fields: RequiredMetadata[]) => {
          if (!err && fields) {
            handleUsers(fields);
          }
        },
        {
          fields: [],
        },
      ),
    );
  }, [lovs]);

  useEffect(() => {
    fillStepFields('1', formValues);
  }, [formValues]);

  const removeItem = (id: string) => {
    setFormValues({
      ...formValues,
      members: (formValues.members as SelectValue[]).filter((member) => member.id !== id),
    });
  };

  const renderMembers = () => {
    if (isReview) {
      return (
        <div>
          <div className='label'>Workspace members</div>
          <div className='review-value'>
            {(formValues.members as SelectValue[]).map((el) => el.label).join(', ') || '-'}
          </div>
        </div>
      );
    }
    return (
      <IAMultiSelect
        data-testid='Workspace members (optional)'
        label='Workspace members (optional)'
        placeholder='Add workspace members'
        options={memberList}
        onChange={(_, value) => onChange('members', value)}
        value={formValues.members}
        isChip
        disableClearable={false}
        removeItem={(id: string) => removeItem(id)}
        disabled={isReview}
      />
    );
  };

  return (
    <>
      {title && <ReviewActionTitle onEdit={onEdit} title={title} isReview={isReview} />}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field'>
            {!isReview ? (
              <IASelect
                data-testid='Workspace type'
                label='Workspace type'
                placeholder='Select workspace type'
                options={lovs?.DCUSTPROJECTTYPE || []}
                onChange={(_event, value) => {
                  const selectedValue = value as { label: string; id: string };
                  onChange('type', selectedValue.id);
                }}
                value={lovs?.DCUSTPROJECTTYPE?.find(
                  (el) => el.id === formValues.type || el.label === formValues.type,
                )}
                disabled={isReview}
                error={emptyFields.includes('type')}
              />
            ) : (
              <div>
                <div className='label'>Workspace type</div>
                <div className='review-value'>
                  {
                    lovs?.DCUSTPROJECTTYPE?.find(
                      (el) => el.id === formValues.type || el.label === formValues.type,
                    )?.label
                  }
                </div>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field'>
            {!isReview ? (
              <IAInput
                inputLabel='Workspace name'
                placeholder='Enter workspace name'
                value={formValues.name}
                onChange={(event) => {
                  onChange('name', event?.target?.value);
                }}
                disabled={isReview}
                error={emptyFields.includes('name')}
              />
            ) : (
              <div>
                <div className='label'>Workspace name</div>
                <div className='review-value'>{formValues.name as string}</div>
              </div>
            )}
          </div>
        </Grid>
        {isReview && (
          <Grid item xs={4}>
            <div className='form-field'>
              <div className='label'>Product area</div>
              <div className='review-value'>
                {productAreas
                  .filter((el) => {
                    const productAreaValues = formValues.productAreas as string[];
                    return productAreaValues.indexOf(el.id) > -1;
                  })
                  .map((el) => el.label)
                  .join(', ')}
              </div>
            </div>
          </Grid>
        )}
      </Grid>
      {!isReview && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field'>
              <IAMultiSelect
                data-testid='Product area'
                label='Product area'
                placeholder='Select product area'
                options={productAreas}
                onChange={(_event, value) => {
                  const selectedValues = value as { label: string; id: string }[];
                  onChange(
                    'productAreas',
                    selectedValues.map((el) => el.id),
                  );
                }}
                value={productAreas.filter((el) => {
                  const productAreaValues = formValues.productAreas as string[];
                  return productAreaValues.indexOf(el.id) > -1;
                })}
                disabled={isReview}
                error={emptyFields.includes('productAreas')}
                disableClearable={formValues.type !== 'VCUSTPROJECTTYPEGENERICPROJECT'}
              />
            </div>
          </Grid>
        </Grid>
      )}
      {!isReview && <hr className='divider' />}
      <div className='members'>
        <div className='action-title' data-testid='action-title'>
          {!isReview && 'Add workspace members'}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div className='form-field'>{renderMembers()}</div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ProjectDetails;
