import { FilterIterface } from '../../core/models/filter/filter';
import { OrderByInterface } from '../../core/models/order';
import { ProjectInterface, ProjectFormValues } from '../../core/models/project/projectInterface';
import {
  DefinitioniInterface,
  ListResponse,
  FieldResponseInterface,
  FieldRequestInterface,
  ItemsInterface,
} from '../../core/models/system/systemDefinition';
import {
  RequiredMetadataResponse,
  RequiredMetadata,
} from '../../core/models/workingDocument/requiredMetadata';

export const projectActionTypes = {
  GET_PROJECT: 'GET_PROJECT',
  GET_PROJECT_SUCCESS: 'GET_PROJECT_SUCCESS',
  GET_PROJECTS: 'GET_PROJECTS',
  GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',
  GET_MY_PROJECTS: 'GET_MY_PROJECTS',
  GET_MY_PROJECTS_SUCCESS: 'GET_MY_PROJECTS_SUCCESS',
  CREATE_PROJECT: 'CREATE_PROJECT',
  CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
  UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
  DELETE_PROJECT: 'DELETE_PROJECT',
  DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
  SET_PROJECT_VALUES: 'SET_PROJECT_VALUES',
  SET_NEW_PROJECT: 'SET_NEW_PROJECT',
  SET_PROJECT_TABLE_HEADERS: 'SET_PROJECT_TABLE_HEADERS',
  GET_PROJECT_METADATA_VALUES: 'GET_PROJECT_METADATA_VALUES',
  GET_PROJECT_METADATA_VALUES_SUCCESS: 'GET_PROJECT_METADATA_VALUES_SUCCESS',
};

export const getProjectById = (
  callback: (err: unknown, data: FieldResponseInterface[]) => void = () => {},
  id: string,
) => ({
  payload: id,
  type: projectActionTypes.GET_PROJECT,
  callback,
});

export const getProjectSuccess = (payload: ItemsInterface) => ({
  type: projectActionTypes.GET_PROJECT_SUCCESS,
  payload,
});

export const getProjects = (
  callback: (err: unknown) => void = () => {},
  payload: {
    page: number | string;
    size: number | string;
    filterFields: FilterIterface[];
    orderBy?: OrderByInterface;
    allFavorites?: boolean;
  },
) => ({
  payload,
  type: projectActionTypes.GET_PROJECTS,
  callback,
});

export const getProjectsSuccess = (payload: ListResponse) => ({
  type: projectActionTypes.GET_PROJECTS_SUCCESS,
  payload,
});
export const getMYProjects = (
  callback: (err: unknown) => void = () => {},
  payload: {
    page: number | string;
    size: number | string;
    filterFields: FilterIterface[];
    orderBy?: OrderByInterface;
  },
) => ({
  payload,
  type: projectActionTypes.GET_MY_PROJECTS,
  callback,
});

export const getMyProjectsSuccess = (payload: ListResponse) => ({
  type: projectActionTypes.GET_MY_PROJECTS_SUCCESS,
  payload,
});

export const createProject = (
  callback: (err: { response: { data: string } } | null, data: ProjectInterface) => void = () => {},
  payload: ProjectFormValues,
) => ({
  payload,
  type: projectActionTypes.CREATE_PROJECT,
  callback,
});

export const createProjectSuccess = (payload: ProjectInterface) => ({
  type: projectActionTypes.CREATE_PROJECT_SUCCESS,
  payload,
});

export const updateProject = (
  callback: (err: unknown) => void = () => {},
  payload: { body: ProjectFormValues; id: string },
) => ({
  payload,
  type: projectActionTypes.UPDATE_PROJECT,
  callback,
});

export const updateProjectSuccess = (payload: ProjectInterface) => ({
  type: projectActionTypes.UPDATE_PROJECT_SUCCESS,
  payload,
});

export const deleteProjectById = (id: string, callback: () => void = () => {}) => ({
  payload: id,
  type: projectActionTypes.DELETE_PROJECT,
  callback,
});

export const deleteProjectSuccess = (payload: ProjectInterface) => ({
  type: projectActionTypes.DELETE_PROJECT_SUCCESS,
  payload,
});

export const handleProjectHeaderCells = (payload: DefinitioniInterface[]) => ({
  type: projectActionTypes.SET_PROJECT_TABLE_HEADERS,
  payload,
});

export const getProjectMetadataValues = (
  callback: (
    err: { response: { data: string } } | null,
    data: RequiredMetadata[],
  ) => void = () => {},
  payload: {
    fields: FieldRequestInterface[];
  },
) => ({
  payload,
  type: projectActionTypes.GET_PROJECT_METADATA_VALUES,
  callback,
});

export const getProjectMetadataValuesSuccess = (payload: RequiredMetadataResponse) => ({
  type: projectActionTypes.GET_PROJECT_METADATA_VALUES_SUCCESS,
  payload,
});
