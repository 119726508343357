import { Grid } from '@mui/material';
import { DocumentTransferMetadata } from '../../core/models/transfer/phoenixData';
import ReviewActionTitle from '../ReviewActionTitle/ReviewActionTitle';

interface PropsInterface {
  selectedSourceSystem: string;
  documentNumber: string;
  documentMetadata: DocumentTransferMetadata;
  openDialog: (content: 'basic' | 'dms') => void;
}
export const TransferOutputReview = (props: PropsInterface) => {
  const { selectedSourceSystem, documentNumber, documentMetadata, openDialog } = props;
  return (
    <div className='review-state'>
      <ReviewActionTitle
        onEdit={() => {
          openDialog('basic');
        }}
        title='Review basic details'
      />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='dm-system'>
            <div className='label'>Select document management system</div>
            <div className='review-value'>{selectedSourceSystem || '-'}</div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='document-number'>
            <div className='label'>Document Number</div>
            <div className='review-value'>{documentNumber || '-'}</div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='type'>
            <div className='label'>Type</div>
            <div className='review-value'>Records</div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='subtype'>
            <div className='label'>Sub Type</div>
            <div className='review-value'>Validation</div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <div className='form-field' data-testid='is-english'>
            <div className='label'>Is this document written only in English?</div>
            <div className='review-value'>
              {documentMetadata.isDocumentInEnglish ? 'Yes' : 'No'}
            </div>
          </div>
        </Grid>
      </Grid>
      {!documentMetadata.isDocumentInEnglish && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field' data-testid='title-in-english'>
              <div className='label'>Title In English</div>
              <div className='review-value'>{documentMetadata.titleInEnglish || '-'}</div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field' data-testid='languages'>
              <div className='label'>Languages</div>
              <div className='review-value'>{documentMetadata.languages?.join(', ') || '-'}</div>
            </div>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='title'>
            <div className='label'>Title</div>
            <div className='review-value'>{documentMetadata.documentTitle || '-'}</div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='scopes'>
            <div className='label'>Scope (s)</div>
            <div className='review-value'>{documentMetadata.generalScope.join('') || '-'}</div>
          </div>
        </Grid>
      </Grid>
      <div className='security-details'>
        <div className='action-title' data-testid='action-title'>
          Review security details
        </div>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <div className='form-field' data-testid='security-question-1'>
              <div className='label'>
                Does this document contain restricted information that should not be widely
                available within Roche?
              </div>
              <div className='review-value'>No</div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <div className='form-field' data-testid='security-question-2'>
              <div className='label'>
                Would it cause/create significant financial (or other) harm to Roche if this
                document is made public or if a third party (i.e competitor) has access to this
                document?
              </div>
              <div className='review-value'>No</div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field' data-testid='publish'>
              <div className='label'>Publish to portal</div>
              <div className='review-value'>Not Published</div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field' data-testid='security-scope'>
              <div className='label'>Security scope</div>
              <div className='review-value'>Open</div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className='document-management-system-details'>
        <ReviewActionTitle
          onEdit={() => {
            openDialog('dms');
          }}
          title='Review document management system details'
        />
      </div>
      <div className='product-details'>
        <div className='action-title' data-testid='action-title'>
          Product details
        </div>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div className='form-field' data-testid='is-english'>
              <div className='label'>Is this Product related?</div>
              <div className='review-value'>Yes</div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field' data-testid='product-family'>
              <div className='label'>Product Family</div>
              <div className='review-value'>{documentMetadata.productFamilyName || '-'}</div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field' data-testid='product-name'>
              <div className='label'>Product Name</div>
              <div className='review-value'>{documentMetadata.product || '-'}</div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className='ownership-details'>
        <div className='action-title' data-testid='action-title'>
          Ownership details
        </div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field' data-testid='owning-entity-type'>
              <div className='label'>Owning entity type</div>
              <div className='review-value'>{documentMetadata.owningEntityType || '-'}</div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field' data-testid='owning-entity'>
              <div className='label'>Owning entity</div>
              <div className='review-value'>{documentMetadata.owningEntity || '-'}</div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field' data-testid='owning-department'>
              <div className='label'>Owning department</div>
              <div className='review-value'>{documentMetadata.owningDepartment || '-'}</div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className='applicability-details'>
        <div className='action-title' data-testid='action-title'>
          Applicability details
        </div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field' data-testid='applicable-entity-type'>
              <div className='label'>Applicable entity type</div>
              <div className='review-value'>
                {documentMetadata.applicableEntityTypes.join() || '-'}
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field' data-testid='applicable-entity'>
              <div className='label'>Applicable entities</div>
              <div className='review-value'>
                {documentMetadata.applicableEntities.join() || '-'}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div className='form-field' data-testid='addition-info'>
              <div className='label'>Additional information</div>
              <div className='review-value'>{documentMetadata.additionalInformation || '-'}</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
