import { Grid } from '@mui/material';
import './CountryGroups.scss';
import IAMultiSelect from '../MultiSelect/MultiSelect';
import { getLovValues } from '../../core/services/helpers';
import { LovValue } from '../../core/models/lovValue';
import { useEffect, useState } from 'react';
import AddMore from '../icons/AddMore';
import RemoveItem from '../icons/RemoveItem';
import { CountryGoup } from '../../core/models/template/template';
import { IdLabel } from '../../core/models/global';
import IASelect from '../Select/Select';

interface CountryGroupsPropsInterface {
  lovs: { [key: string]: LovValue[] };
  countryGroupValues: CountryGoup[];
  setCountryGroupValues: (countryGroups: CountryGoup[]) => void;
  getCountriesOfCountryGroup: (countryGroup: IdLabel) => void;
}

export const CountryGroups = (props: CountryGroupsPropsInterface) => {
  const { lovs, setCountryGroupValues, getCountriesOfCountryGroup, countryGroupValues } = props;
  const [countryGroups, setCountryGroups] = useState<CountryGoup[]>(countryGroupValues);

  const [countryGroupOptions, setCountryGroupOptions] = useState<IdLabel[]>(
    getLovValues(lovs, 'DCUSTCOUNTRYGROUP'),
  );

  const onChange = (index: number, key: keyof CountryGoup, value: string | IdLabel[] | IdLabel) => {
    if (key === 'countryGroupName') {
      if (value === null) {
        countryGroups.splice(index, 1);
        setCountryGroups([...countryGroups]);
        setCountryGroupValues([...countryGroups]);
        return;
      }
      if (
        countryGroups[index][key] === value ||
        (countryGroups[index][key] as IdLabel)?.label === value
      ) {
        return;
      }
      countryGroups[index][key] = value as string | IdLabel;
      countryGroups[index].countries = [];
      countryGroups[index].disabled = false;
      if (value && (value as IdLabel).id) {
        getCountriesOfCountryGroup(value as IdLabel);
      } else {
        setCountryGroupValues([...countryGroups]);
      }
    } else if (key === 'countries') {
      countryGroups[index][key] = (value as IdLabel[]).map((el) => el.label);
      setCountryGroupValues([...countryGroups]);
    }
  };

  useEffect(() => {
    if (!countryGroups.length) {
      setCountryGroups([{ countryGroupName: '', countries: [] }]);
    }
    const selectedCountryGroups = countryGroups
      .map(({ countryGroupName }) => (countryGroupName as IdLabel)?.id ?? countryGroupName)
      .filter((el) => el);

    const newCountryGroupOptions = [
      ...getLovValues(lovs, 'DCUSTCOUNTRYGROUP').filter(
        (el) => !selectedCountryGroups.includes(el.id) && !selectedCountryGroups.includes(el.label),
      ),
    ];
    setCountryGroupOptions(newCountryGroupOptions);
  }, [countryGroups]);

  useEffect(() => {
    if (countryGroupValues.length) {
      setCountryGroups(countryGroupValues);
    }
  }, [countryGroupValues]);

  const addCountryGroup = () => {
    const lastIndex = countryGroups.length - 1;
    if (countryGroups[lastIndex].countryGroupName) {
      setCountryGroups([...countryGroups, { countryGroupName: '', countries: [] }]);
    }
  };

  const removeCountryGroup = (index: number) => {
    countryGroups.splice(index, 1);
    setCountryGroups([...countryGroups]);
    setCountryGroupValues([...countryGroups]);
  };

  const renderAddOrRemove = (index: number) => {
    if (index === 0) {
      return (
        <div className='add-more-container'>
          <div
            data-testid={`add-country-group-${index}`}
            className='add-more'
            onClick={() => addCountryGroup()}
            aria-hidden='true'
          >
            <AddMore />
            <span> Add More</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className='add-more-container'>
          <div
            data-testid={`remove-country-group-${index}`}
            className='add-more'
            onClick={() => removeCountryGroup(index)}
            aria-hidden='true'
          >
            <RemoveItem />
            <span> Remove</span>
          </div>
        </div>
      );
    }
  };

  return (
    <div className='country-group-container' data-testid='country-groups'>
      {countryGroups.map((countryGroup, index) => (
        <div className='country-group-row' key={index}>
          <hr className='divider-dotted' />
          <div className='country-group-title' data-testid='country-group-title'>
            Country group {index + 1}
          </div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className='form-field'>
                <IASelect
                  data-testid='Country groups'
                  label={`Country group name ${index + 1} (optional)`}
                  placeholder={`Country group name ${index + 1} (optional)`}
                  freeSolo
                  options={countryGroupOptions}
                  onChange={(_event, value) => {
                    onChange(index, 'countryGroupName', value as IdLabel[]);
                  }}
                  onInputChange={(_event, value) => onChange(index, 'countryGroupName', value)}
                  value={countryGroup.countryGroupName}
                  disableClearable={false}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field'>
                <IAMultiSelect
                  data-testid='Countries (optional)'
                  label='Countries (optional)'
                  placeholder='Enter countries'
                  options={getLovValues(lovs, 'DCUSTCOUNTRY')}
                  onChange={(_event, value) => onChange(index, 'countries', value as IdLabel[])}
                  value={getLovValues(lovs, 'DCUSTCOUNTRY').filter(
                    (el) => countryGroup.countries.indexOf(el.label) > -1,
                  )}
                  disableClearable={false}
                  disabled={countryGroup.disabled}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              {renderAddOrRemove(index)}
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  );
};
