import { Grid } from '@mui/material';
import { RIMDocumentMetadata } from '../../../core/models/transfer/rim';
import { IAInput } from '../../Input/Input';
import IAMultiSelect from '../../MultiSelect/MultiSelect';
import { IdLabel } from '../../../core/models/global';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../core/models/state/globalState';
import IASelect from '../../Select/Select';

interface PropsInterface {
  documentMetadata: RIMDocumentMetadata;
  emptyFields?: string[];
  countries?: string[];
  manufacturers?: string[];
  stage?: string[];
  handlePartialDetailsChange: (
    key: keyof RIMDocumentMetadata,
    value: string | string[] | IdLabel[],
  ) => void;
}

export const BasicDetailsRIM = (props: PropsInterface) => {
  const {
    documentMetadata,
    emptyFields = [],
    handlePartialDetailsChange,
    countries = [],
    manufacturers = [],
    stage = [],
  } = props;
  const { mfSiteRoles } = useSelector((state: GlobalState) => state.transfer);
  return (
    <div className='rim-details'>
      <div className='action-title' data-testid='action-title'>
        Enter Basic details
      </div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='title'>
            <IAInput
              inputLabel='Title'
              placeholder='Title'
              required
              onChange={(event) => {
                handlePartialDetailsChange('documentTitle', event.target.value);
              }}
              value={documentMetadata.documentTitle}
              error={emptyFields.includes('documentTitle')}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='product-family'>
            <IAInput
              inputLabel='Product Family '
              placeholder='Product Family '
              required
              onChange={(event) => {
                handlePartialDetailsChange('productFamily', event.target.value);
              }}
              value={documentMetadata.productFamily}
              error={emptyFields.includes('productFamily')}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='ronumber'>
            <IAInput
              inputLabel='RO Number'
              placeholder='RO Number'
              required
              onChange={(event) => {
                handlePartialDetailsChange('roNumber', event.target.value);
              }}
              value={documentMetadata.roNumber}
              error={emptyFields.includes('roNumber')}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='product-family'>
            <IAInput
              inputLabel='Active Substance'
              placeholder='Active Substance'
              required
              onChange={(event) => {
                handlePartialDetailsChange('drugSubstance', event.target.value);
              }}
              value={documentMetadata.drugSubstance}
              error={emptyFields.includes('drugSubstance')}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='product-family'>
            <IAInput
              inputLabel='Formulation Number'
              placeholder='Formulation Number'
              required
              onChange={(event) => {
                handlePartialDetailsChange('formulationNumber', event.target.value);
              }}
              value={documentMetadata.formulationNumber}
              error={emptyFields.includes('formulationNumber')}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='ronumber'>
            <IAMultiSelect
              data-testid='Manufacturers'
              label='Manufacturers'
              placeholder='Manufacturer'
              options={manufacturers}
              onChange={(_event, value) => {
                handlePartialDetailsChange('manufacturers', value as string[]);
              }}
              value={documentMetadata.manufacturers}
              error={emptyFields.includes('manufacturers')}
              disableClearable={false}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='clinical-studies'>
            <IAMultiSelect
              data-testid='Manufacturing Site Role'
              label='Manufacturing Site Role'
              placeholder='Manufacturing Site Role'
              options={mfSiteRoles}
              onChange={(_event, value) => {
                const values = value as IdLabel[];
                console.log(values);
                handlePartialDetailsChange('manufacturingSiteRoles', values);
              }}
              value={documentMetadata.manufacturingSiteRoles}
              error={emptyFields.includes('manufacturingSiteRoles')}
              disableClearable={false}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='stage'>
            <IASelect
              label='Stage'
              placeholder='Stage'
              options={stage}
              onChange={(_event, value) => {
                handlePartialDetailsChange('stage', value as string);
              }}
              value={documentMetadata.stage}
              error={emptyFields.includes('stage')}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='countries'>
            <IAMultiSelect
              data-testid='Countries'
              label='Countries'
              placeholder='Enter countries'
              options={countries}
              onChange={(_event, value) => {
                handlePartialDetailsChange('countries', value as string);
              }}
              value={documentMetadata.countries}
              error={emptyFields.includes('countries')}
              disableClearable={false}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
