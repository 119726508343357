import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Tick(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984C18.3337 5.39746 14.6027 1.6665 10.0003 1.6665ZM5.83366 10.3498L7.00866 9.17484L9.16699 11.3248L13.8253 6.6665L15.0003 7.84984L9.16699 13.6832L5.83366 10.3498Z'
        fill='#0066CC'
      />
    </SvgIcon>
  );
}
