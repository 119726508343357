import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { createProject } from '../../state/actions/project';
import {
  SelectedProductMetadata,
  ProjectFormValues,
  ProductFamilies,
} from '../../core/models/project/projectInterface';
import IAButton from '../../components/Button/Button';
import './Project.scss';
import Stepper from '../../components/Stepper/Stepper';
import Review from '../../components/ProjectSteps/Review';
import ProjectDetails from '../../components/ProjectSteps/ProjectDetails';
import ProductDetails from '../../components/ProjectSteps/ProductDetails';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { FIRST_STEP, SECOND_STEP, THIRD_STEP } from '../../constants';
import { validateProductMetadata } from '../../core/services/productMetadata';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { actionButtonContentAndAction } from '../../core/services/helpers';
import { SelectValue } from '../../core/models/global';
import { GlobalState } from '../../core/models/state/globalState';

export const Project = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: GlobalState) => state.user);
  const workspaceRoute = '/layout/workspaces';

  const [activeStep, setActiveStep] = useState<number>(FIRST_STEP);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [fieldsFilled, setFieldsFilled] = useState<{
    [step: string]: ProjectFormValues | SelectedProductMetadata;
  }>({});
  const [saving, setSaving] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [emptyFields, setEmptyFields] = useState<string[]>([]);
  const [nextStepClicked, setNextStepClicked] = useState(false);
  const [productMetadata, setProductMetadata] = useState<ProductFamilies[]>();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const steps = ['Enter basic details', 'Enter workspace data', 'Review & Save'];

  const isStepsCompleted = (): boolean => {
    let newEmptyFields: string[] = [];
    if (activeStep === THIRD_STEP) {
      return true;
    }
    if (activeStep === FIRST_STEP && fieldsFilled[activeStep]) {
      const data = { ...(fieldsFilled[activeStep] as ProjectFormValues) };
      delete data.members;
      Object.entries(data).forEach(([key, value]) => {
        if (!value?.length || (value && typeof value === 'string' && !value.trim())) {
          if (data.type === 'VCUSTPROJECTTYPEGENERICPROJECT' && key === 'productAreas') {
            return;
          }
          newEmptyFields.push(key);
        }
      });
    }
    if (activeStep === SECOND_STEP && fieldsFilled[activeStep]) {
      const data = fieldsFilled[activeStep] as SelectedProductMetadata;
      const firstStepData = fieldsFilled[FIRST_STEP] as ProjectFormValues;
      const workspaceType = firstStepData?.type as string;
      newEmptyFields = validateProductMetadata(data, newEmptyFields, workspaceType);
    }
    setEmptyFields(newEmptyFields);
    return !newEmptyFields.length;
  };

  useEffect(() => {
    if (!user?.isAdmin && !user?.isAuthor) {
      navigate(workspaceRoute);
    }
  }, [user]);

  useEffect(() => {
    if (nextStepClicked) {
      isStepsCompleted();
    }
  }, [fieldsFilled]);

  const nextStep = () => {
    if (!isStepsCompleted()) {
      setNextStepClicked(true);
      return;
    }
    if (activeStep < steps.length) {
      document.getElementsByClassName('page-header')?.[0]?.scrollIntoView();
      setNextStepClicked(false);
      setActiveStep((prev) => prev + 1);
      if (!completedSteps.includes(activeStep)) {
        setCompletedSteps([...completedSteps, activeStep]);
      }
    }
  };

  const prevStep = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const fillStepFields = (step: string, value?: ProjectFormValues | SelectedProductMetadata) => {
    if (value) {
      fieldsFilled[step] = value;
      setFieldsFilled({ ...fieldsFilled });
    }
  };

  const getStep = () => {
    switch (activeStep) {
      case FIRST_STEP: {
        return (
          <ProjectDetails
            fillStepFields={fillStepFields}
            fieldsFilled={(fieldsFilled[activeStep] as ProjectFormValues) || {}}
            title='Enter basic details'
            emptyFields={emptyFields}
          />
        );
      }
      case SECOND_STEP: {
        return (
          <ProductDetails
            title='Enter workspace data'
            fieldsFilled={fieldsFilled[activeStep] as SelectedProductMetadata}
            fillStepFields={fillStepFields}
            giveProductMetadata={(data: ProductFamilies[]) => setProductMetadata(data)}
            productMetadata={productMetadata}
            emptyFields={emptyFields}
            workspaceType={(fieldsFilled[1] as ProjectFormValues).type as string}
          />
        );
      }
      case THIRD_STEP: {
        return (
          <Review
            fieldsFilled={fieldsFilled}
            setActiveStep={setActiveStep}
            productMetadata={productMetadata}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const getBodyValue = (key: keyof SelectedProductMetadata) => {
    return Array.from(
      new Set(
        ((fieldsFilled[SECOND_STEP] as SelectedProductMetadata)[key] as string[])
          ?.flat(3)
          .filter((el) => !el.includes('TEMP')),
      ),
    );
  };

  const saveProject = () => {
    setSaving(true);
    const members = ((fieldsFilled[FIRST_STEP] as ProjectFormValues).members as SelectValue[]).map(
      (el) => el.id,
    );
    if (!members.includes(user.userId)) {
      members.unshift(user.userId);
    }
    dispatch(
      createProject(
        (err, data) => {
          if (data) {
            navigate(workspaceRoute);
          } else if (err) {
            console.log(err);
            setSaving(false);
            if (err.response?.data) {
              setErrMessage(err.response.data);
            }
          }
        },
        {
          FTITLE: (fieldsFilled[FIRST_STEP] as ProjectFormValues).name as string,
          FCUSTPROJECTTYPE: (fieldsFilled[FIRST_STEP] as ProjectFormValues).type as string,
          // FCUSTMETADATA: productMetadata
          //   ? JSON.stringify(
          //       productMetadata.filter((el) =>
          //         getBodyValue('selectedProductFamilies').includes(el.id),
          //       ),
          //     )
          //   : '',
          FCUSTPRODUCTFAMILY: getBodyValue('selectedProductFamilies'),
          FCUSTPRODUCT: getBodyValue('selectedDrugProducts'),
          FCUSTSUBSTANCE: getBodyValue('selectedDrugSubstances'),
          FCUSTPACKAGINGCODE: getBodyValue('selectedPackagingCodes'),
          FCUSTDPMANUFACTURER: getBodyValue('selectedDPManufacturers'),
          FCUSTDSMANUFACTURER: getBodyValue('selectedDSManufacturers'),
          FCUSTEVENTTYPE: getBodyValue('selectedEventTypes'),
          FCUSTEVENT: getBodyValue('selectedEvents'),
          FCUSTSTUDYID: (fieldsFilled[SECOND_STEP] as SelectedProductMetadata).studyId as string,
          FIAPPROJECTSTATUS: 'VIAPPROJECTSTATUSOPEN',
          FCUSTPRODUCTAREA: (fieldsFilled[FIRST_STEP] as ProjectFormValues)
            .productAreas as string[],
          FIAPPROJECTASSIGNEE: members,
        },
      ),
    );
  };

  return (
    <div className='project'>
      <div className='page-header'>
        <PageTitle
          name={'Create new workspace'}
          icon={<ArrowBackIcon />}
          onIconClick={() => {
            if (activeStep > 1) {
              setShowConfirmationPopup(true);
            } else {
              navigate(workspaceRoute);
            }
          }}
        />
      </div>
      <div className='page-content'>
        <div className='stepper-container'>
          <div className='project-stepper'>
            <Stepper activeStep={activeStep} steps={steps} completedSteps={completedSteps} />
          </div>
        </div>
        <div className='form'>{getStep()}</div>

        <div className='form-button-container'>
          {activeStep !== FIRST_STEP && (
            <IAButton disabled={saving} className='back-btn' handleClick={prevStep}>
              Back
            </IAButton>
          )}
          <IAButton
            disabled={saving}
            className='main-action-btn'
            handleClick={
              actionButtonContentAndAction(activeStep, saving, nextStep, saveProject).handler
            }
          >
            {actionButtonContentAndAction(activeStep, saving, nextStep, saveProject).text}
          </IAButton>
        </div>
        {saving && (
          <>
            <div className='loader-container with-bg'>
              <CircularProgress value={30} size='80px' />
            </div>
            <div className='loader-background'></div>
          </>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errMessage}
        autoHideDuration={5000}
        onClose={() => setErrMessage('')}
      >
        <Alert
          onClose={() => setErrMessage('')}
          severity='error'
          variant='filled'
          sx={{ width: '100%' }}
        >
          {errMessage}
        </Alert>
      </Snackbar>
      <ConfirmationDialog
        open={showConfirmationPopup}
        handleClose={() => setShowConfirmationPopup(false)}
        mainBtnText='Confirm'
        confirmationText='You are about to go back to main screen'
        descriptionData={[
          'Are you sure you want to go back to main screen?',
          'You will lose all the progress and this action cannot be undone.',
        ]}
        handleSubmit={() => navigate(workspaceRoute)}
      />
    </div>
  );
};
