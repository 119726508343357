import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Menu,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import './Table.scss';
import { ProjectInterface } from '../../core/models/project/projectInterface';
import { TemplateInterface } from '../../core/models/template/templateInterface';
import { WorkingDocumentInterface } from '../../core/models/workingDocument/workingDocumentInterface';
import Radio from '@mui/material/Radio';
import { Order } from '../../core/models/order';
import EditIcon from '../../components/icons/EditIcon';
import ReviewIcon from '../../components/icons/ReviewIcon';
import HistoryIcon from '../../components/icons/HistoryIcon';
import ThreeDots from '../../components/icons/ThreeDots';
import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Pagination from '../Pagination/Pagination';
import VideoTutorialPlaceholder from '../VideoTutorial/VideoTutorialPlaceholder';
import IAButton from '../Button/Button';
import Refresh from '../icons/Refresh';
import ProcessIcon from '../icons/ProcessIcon';
import Star from '../icons/Star';
import ErrorIcon from '../icons/ErrorIcon';
import { SingleObject } from '../../core/models/global';
import { getStatusClassName } from '../../core/services/helpers';
import { UserInteface } from '../../core/models/user/user';
import DownloadIcon from '../icons/Download';
import { getDocumentUrlInSystem } from '../../core/services/transfer';

export interface HeadCell {
  id: string;
  label: string;
  isSortable?: boolean;
}

type ProjectKeys = keyof ProjectInterface;
type TemplateKeys = keyof TemplateInterface;
type WorkingDocumentKeys = keyof WorkingDocumentInterface;

interface TableProps {
  headCells: HeadCell[];
  orderBy?: string | number;
  order?: Order;
  visibleRows?: TemplateInterface[] | ProjectInterface[] | WorkingDocumentInterface[];
  favorite?: boolean;
  isAllFavorite?: boolean;
  handleRefresh?: () => void;
  handleTransferButton?: (row: { [key: string]: string | boolean | undefined | string[] }) => void;
  handleDownloadButton?: (row: { [key: string]: string | boolean | undefined | string[] }) => void;
  handleClick?: (rowItem: TemplateInterface | ProjectInterface | WorkingDocumentInterface) => void;
  handleSort?: (id: string, order: Order) => void;
  optionsVisible?: boolean;
  isViewAsGroupsActive?: boolean;
  handleFavoriteClick?: (id: string, type?: string) => void;
  editIcon?: boolean;
  reviewIcon?: boolean;
  historyIcon?: boolean;
  threeDots?: boolean;
  showStatusAsLabel?: boolean;
  checkIcon?: boolean;
  rowLink?: { text: string; link: string };
  goToEditor?: (rowItem: TemplateInterface | ProjectInterface | WorkingDocumentInterface) => void;
  goToReview?: (rowItem: TemplateInterface | ProjectInterface | WorkingDocumentInterface) => void;
  goToHistory?: (rowItem: TemplateInterface | ProjectInterface | WorkingDocumentInterface) => void;
  count: number;
  totalItems: number;
  page: number;
  pageChangeHandler: (page: number, size: number) => void;
  hidePagination?: boolean;
  isFromDashboard?: boolean;
  checkItem?: (id: string) => void;
  checked?: (rowItem: TemplateInterface | ProjectInterface | WorkingDocumentInterface) => boolean;
  tutorial?: string;
  errorText?: boolean;
  isDocCreation?: boolean;
  menuItems?: {
    label: string;
    onClick: (row: { [key: string]: string | boolean | undefined | string[] } | undefined) => void;
  }[];
  isRowClickable?: boolean;
  handleMembersDialog?: (rowItem: TemplateInterface | WorkingDocumentInterface) => void;
  currentUser?: UserInteface;
}

const IATable = (props: TableProps) => {
  const {
    headCells,
    orderBy,
    order = 'asc',
    visibleRows = [],
    favorite = false,
    isAllFavorite = false,
    handleRefresh,
    handleTransferButton,
    handleDownloadButton = () => {},
    handleClick = () => {},
    handleSort = () => {},
    handleFavoriteClick = () => {},
    editIcon,
    threeDots,
    // showStatusAsLabel,
    checkIcon,
    goToEditor = () => {},
    goToReview = () => {},
    goToHistory = () => {},
    count,
    totalItems,
    pageChangeHandler = () => {},
    hidePagination = false,
    isFromDashboard,
    page,
    checked,
    checkItem,
    tutorial,
    rowLink,
    errorText,
    isDocCreation,
    menuItems = [
      {
        label: 'Archive',
        onClick: () => {},
      },
    ],
    isRowClickable = true,
    handleMembersDialog = () => {},
    currentUser,
  } = props;
  const [anchorEl, setAnchorEl] = useState<{
    target?: SVGSVGElement | null;
    row?: { [key: string]: string | boolean | undefined | string[] };
  }>({});

  const handleSorting = (id: string) => {
    handleSort(id, order);
  };

  const rowLeftCells = (row: SingleObject) => {
    let editorIconsCell = <></>,
      editorIconsHeadCell = <></>,
      radioCell = <></>,
      radioHeadCell = <></>,
      errorCell = <></>,
      errorHeadCell = <></>;

    const authors = (row.FIAPAUTHORLIST as string)?.split(',');
    const isUserAuthor = authors?.includes(currentUser?.displayName as string);
    const isUserAdmin = currentUser?.isAdmin;
    if (editIcon) {
      if ((isUserAuthor && currentUser?.isAuthor) || isUserAdmin) {
        editorIconsCell = (
          <TableCell key='edit-icon' style={{ width: '100px' }}>
            <div className='icon-group'>
              <Tooltip title='Go to editor' key='Go to editor' placement='top' arrow>
                <div style={{ width: '30px' }}>
                  <EditIcon
                    onClick={(event) => {
                      event.stopPropagation();
                      goToEditor(row);
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip title='Go to review' key='Go to review' placement='top' arrow>
                <div style={{ width: '30px' }}>
                  <ReviewIcon
                    onClick={(event) => {
                      event.stopPropagation();
                      goToReview(row);
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip title='Go to history' key='Go to history' placement='top' arrow>
                <div style={{ width: '30px' }}>
                  <HistoryIcon
                    onClick={(event) => {
                      event.stopPropagation();
                      goToHistory(row);
                    }}
                  />
                </div>
              </Tooltip>
            </div>
          </TableCell>
        );
      } else {
        editorIconsCell = (
          <TableCell key='edit-icon' style={{ width: '100px' }}>
            <div className='icon-group'>
              <Tooltip title='Go to review' key='Go to review' placement='top' arrow>
                <div style={{ width: '30px' }}>
                  <ReviewIcon
                    onClick={(event) => {
                      event.stopPropagation();
                      goToReview(row);
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip title='Go to history' key='Go to history' placement='top' arrow>
                <div style={{ width: '30px' }}>
                  <HistoryIcon
                    onClick={(event) => {
                      event.stopPropagation();
                      goToHistory(row);
                    }}
                  />
                </div>
              </Tooltip>
            </div>
          </TableCell>
        );
      }
      editorIconsHeadCell = <TableCell key='edit-icon'></TableCell>;
    }
    if (errorText) {
      errorCell = (
        <TableCell key='error-icon' size='small'>
          {row.TRANSFERRESULTTEXT && (
            <Tooltip
              title={row.TRANSFERRESULTTEXT}
              key={row.TRANSFERRESULTTEXT as string}
              placement='bottom-start'
              arrow
            >
              <div className='error-icon'>
                <ErrorIcon />
              </div>
            </Tooltip>
          )}
        </TableCell>
      );
      errorHeadCell = <TableCell key='error-icon' size='small'></TableCell>;
    }

    if (checkIcon) {
      radioCell = (
        <TableCell key='check-icon'>
          <Radio
            checked={checked && checked(row)}
            onChange={() => {
              if (checkItem) {
                checkItem(row.id as string);
              }
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        </TableCell>
      );
      radioHeadCell = <TableCell key='edit-icon'></TableCell>;
    }
    return {
      head: { editorIconsHeadCell, errorHeadCell, radioHeadCell },
      body: { editorIconsCell, radioCell, errorCell },
    };
  };

  const cellBody = (row: SingleObject, headCell: string) => {
    if (headCell === 'linkName') {
      return (
        <a target='_blank' rel='noreferrer' href={row.link as string} className='row-link'>
          {row[headCell] || '-'}
        </a>
      );
    }
    if (headCell === 'TRANSFERSTATUS') {
      return <div className='status-label'>{row[headCell] || '-'}</div>;
    }
    if (headCell === 'FCUSTEFFECTIVEDATE') {
      if (row[headCell]) {
        return new Date(row[headCell] as string).toLocaleDateString('en-GB');
      } else {
        return 'No Date';
      }
    }

    if (headCell === 'CREATED-ON' || headCell === 'MODIFIED-ON' || headCell === 'dateAdded') {
      return `${new Date(row[headCell] as string).toLocaleDateString('en-GB')} ${new Date(
        row[headCell] as string,
      ).toLocaleTimeString('en-GB')}`;
    }
    if (headCell === 'MEMBERS') {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleMembersDialog(row);
          }}
          className='members-count'
        >
          {row[headCell] || '-'}
        </div>
      );
    }
    if (['FIAPDOCUMENTSTATUS', 'FIAPTEMPLATESTATUS'].includes(headCell)) {
      return (
        <div className='title-buttons'>
          <div
            className={`status ${getStatusClassName(row[headCell] as string)}`}
            data-testid='table-status'
            style={{ padding: 'unset', fontSize: '14px' }}
          >
            {row[headCell] || '-'}
          </div>
        </div>
      );
    }
    if (headCell === 'DOCUMENTNUMBER') {
      const hrefUrl = getDocumentUrlInSystem(row.SYSTEM as string, row.IDINSYSTEM as string);
      return (
        <a href={hrefUrl} rel='noreferrer' target='_blank'>
          {row[headCell]}
        </a>
      );
    }
    return row[headCell] || '-';
  };

  const rowRightCell = (row: SingleObject) => {
    let favoriteIcon = <></>,
      transferButton = <></>,
      transferStatus = <></>,
      threeDotsIcon = <></>;
    if (favorite || isAllFavorite) {
      favoriteIcon = (
        <Star
          className='favorite-icon'
          fill={row.isFavorite ? '#FF7D29' : ''}
          stroke={row.isFavorite ? '#FF7D29' : ''}
          onClick={(event) => {
            event.stopPropagation();
            handleFavoriteClick((row.id as string) || '');
          }}
        />
      );
    }
    if (handleTransferButton && row.TRANSFERSTATUS !== 'Transfer in process') {
      transferButton = (
        <>
          <IAButton
            className='table-button'
            handleClick={(e) => {
              e.stopPropagation();
              handleTransferButton(row);
            }}
          >
            {row.TRANSFERSTATUS === 'Transfer Failed' ? 'Retransfer' : 'Transfer'}
          </IAButton>
          <DownloadIcon
            className='download-button'
            onClick={(e) => {
              e.stopPropagation();
              handleDownloadButton(row);
            }}
          />
        </>
      );
    }
    if (row.TRANSFERSTATUS === 'Transfer in process') {
      transferStatus = (
        <>
          <span className='transfer-text'>
            <ProcessIcon />
            <span>Transfer in process</span>
          </span>
          <DownloadIcon
            className='download-button'
            onClick={(e) => {
              e.stopPropagation();
              handleDownloadButton(row);
            }}
          />
        </>
      );
    }

    if (threeDots) {
      threeDotsIcon = (
        <ThreeDots
          className='table-three-dots'
          aria-label='three-dots'
          onClick={(event) => {
            event.stopPropagation();
            setAnchorEl({ target: event.currentTarget, row: row });
          }}
        />
      );
    }
    return { favoriteIcon, transferButton, transferStatus, threeDotsIcon };
  };

  const cellOnRightSide = () =>
    favorite || isAllFavorite || handleTransferButton || threeDots || rowLink;

  const getOrdering = (
    headCell: HeadCell,
  ): { direction: Order; sortDirection: Order | undefined } => {
    if (orderBy === headCell.id) {
      return {
        direction: order,
        sortDirection: order,
      };
    } else {
      return {
        direction: 'asc',
        sortDirection: undefined,
      };
    }
  };
  return (
    <>
      <div className={!isDocCreation ? 'table-container' : ''}>
        <div className='scrollable-table'>
          <Table
            data-testid='ia-table'
            className='ia-table'
            aria-labelledby='tableTitle'
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            <TableHead data-testid='thead' key={'thead'}>
              <TableRow key='thead' className={editIcon || checkIcon ? 'with-start-icon' : ''}>
                {Object.values(rowLeftCells({}).head).map((el) => el)}
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} sortDirection={getOrdering(headCell).sortDirection}>
                    {headCell.isSortable ? (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={getOrdering(headCell).direction}
                        onClick={() => {
                          handleSorting(headCell.id);
                        }}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
                {cellOnRightSide() && (
                  <TableCell className='options-cell' component='th' key='options'>
                    {handleRefresh && (
                      <Refresh className='table-refresh-icon' onClick={handleRefresh} />
                    )}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody data-testid='tbody'>
              {visibleRows.map((row, rowIndex) => {
                return (
                  <TableRow
                    hover
                    onClick={() => handleClick(row)}
                    role='checkbox'
                    tabIndex={-1}
                    key={(row.id as string) || rowIndex}
                    sx={isRowClickable ? { cursor: 'pointer' } : {}}
                  >
                    {Object.values(rowLeftCells(row).body).map((el) => el)}
                    {(
                      headCells.map((headCell) => headCell.id) as Array<
                        ProjectKeys | WorkingDocumentKeys | TemplateKeys
                      >
                    ).map((headCell, i) => (
                      <TableCell component='td' scope='row' padding='none' key={i}>
                        {cellBody(row, headCell as string)}
                      </TableCell>
                    ))}

                    {cellOnRightSide() && (
                      <TableCell
                        style={{ width: rowLink ? '15%' : threeDots ? '10%' : '5%' }}
                        component='td'
                        key='options'
                        className='favorite'
                        align={rowLink ? 'left' : 'right'}
                      >
                        {Object.values(rowRightCell(row)).map((el) => el)}
                        {rowLink && (
                          <a
                            target='_blank'
                            rel='noreferrer'
                            className='row-link'
                            href={`${rowLink.link}${row.id}`}
                          >
                            {rowLink.text}
                          </a>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>

        <div className={`bottom-container ${tutorial ? 'with-tutorial' : ''}`}>
          {!!tutorial && <VideoTutorialPlaceholder name={tutorial} />}
          {!hidePagination && (
            <Pagination
              count={count}
              page={page}
              pageChangeHandler={(newPage: number) => {
                pageChangeHandler(newPage, count);
              }}
              totalItems={totalItems}
              isFromDashboard={isFromDashboard}
              isDocCreation={isDocCreation}
            />
          )}
        </div>
      </div>
      <div className='options-menu'>
        <Menu
          disablePortal
          open={!!anchorEl.target}
          className='options-list'
          anchorEl={anchorEl.target}
          onClose={() => setAnchorEl({})}
          disableScrollLock
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          <MenuList autoFocusItem={!!anchorEl}>
            {menuItems.map((menuItem) => {
              return (
                <MenuItem
                  key={menuItem.label}
                  className='menu-item'
                  onClick={() => {
                    menuItem.onClick(anchorEl.row);
                    setAnchorEl({});
                  }}
                >
                  {menuItem.label}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </div>
    </>
  );
};

export default IATable;
