import {
  DocumentTransferMetadata,
  PhoenixFilterData,
  PhoenixFilterSubType,
  PhoenixFilterType,
  createDocumentMetadataObject,
} from '../../core/models/transfer/phoenixData';
import {
  TransferStateInterface,
  CTDCodesInterface,
  CTDTitlesInterface,
  BBCodesInterface,
  ReportInterface,
} from '../../core/models/transfer/transfer';
import { AnyAction } from 'redux';
import { transferActionTypes } from '../actions/transfer';
import { RimDocumentMetadata } from '../../core/models/transfer/rim';

export const initialState: TransferStateInterface = {
  applicabilities: {
    types: [],
    entities: [],
  },
  ownerships: {
    types: [],
    departments: [],
    entities: [],
  },
  documentMetadata: new DocumentTransferMetadata(),
  documentMetadataRim: new RimDocumentMetadata(),
  rimOptionalFields: [],
  languages: [],
  ctdCodes: [],
  ctdTitles: [],
  bbCodes: [],
  countries: [],
  reports: [],
  productMetadata: [],
  excipients: [],
  excipientTypes: ['Compendial', 'Non Compendial'],
  typeOfImp: ['Active', 'Placebo'],
  clinicalStudies: [],
  mfSiteRoles: [],
};

const handleGetFiltersSuccess = (state: TransferStateInterface, action: AnyAction) => {
  const subType: PhoenixFilterSubType = action.payload.subType;
  const type: PhoenixFilterType = action.payload.type;
  const typeData = state[type];
  let subTypeData = typeData[subType];
  subTypeData = action.payload.data.map((el: PhoenixFilterData) => ({
    label: el.name,
    id: el.dataId,
  }));

  return {
    ...state,
    [type]: {
      ...state[type],
      departments: [],
      [subType]: subTypeData,
    },
  };
};

const handleGetDocumentMetadataSuccess = (state: TransferStateInterface, action: AnyAction) => {
  const documentMetadata = createDocumentMetadataObject({
    ...action.payload,
    applicableEntities: action.payload.applicableEntities,
    applicableEntityTypes: action.payload.applicableEntityTypes,
    generalScope: action.payload.generalScope,
  });
  return {
    ...state,
    documentMetadata,
  };
};
const handleGetRimDocumentMetadataSuccess = (state: TransferStateInterface, action: AnyAction) => {
  const { documentMetadataRim } = state;
  return {
    ...state,
    documentMetadataRim: {
      ...documentMetadataRim,
      id: action.payload[0].id,
    },
  };
};

const handleSetDocumentMetadataValues = (state: TransferStateInterface, action: AnyAction) => {
  const documentMetadata: DocumentTransferMetadata = { ...state.documentMetadata };
  const key = action.payload.field as keyof DocumentTransferMetadata;
  const value = action.payload.value as never;
  documentMetadata[key] = value;
  return {
    ...state,
    documentMetadata,
  };
};

const handleGetPhoenixLanguagesSuccess = (state: TransferStateInterface, action: AnyAction) => {
  const languages = action.payload.map((el: PhoenixFilterData) => ({
    label: el.name,
    id: el.dataId,
  }));
  return {
    ...state,
    languages,
  };
};

const handleGetCtdCodesSuccess = (state: TransferStateInterface, action: AnyAction) => {
  return {
    ...state,
    ctdCodes: [...action.payload.map((el: CTDCodesInterface) => el.ctdCodeDerived), 'Non-CTD'],
  };
};

const handleGetCtdTItlesSucess = (state: TransferStateInterface, action: AnyAction) => {
  return {
    ...state,
    ctdTitles: action.payload.map((el: CTDTitlesInterface) => el.ctdTitleDerived),
  };
};

const handleGetBBCodesSuccess = (state: TransferStateInterface, action: AnyAction) => {
  return {
    ...state,
    bbCodes: action.payload.map((el: BBCodesInterface) => el.bbCode),
  };
};

const handleGetReportsSuccess = (state: TransferStateInterface, action: AnyAction) => {
  return {
    ...state,
    reports: action.payload.map((el: ReportInterface) => ({
      report: el.reportClassification,
      reportTitles: el.reportMetadata.title,
    })),
  };
};

const handleGetExcipients = (state: TransferStateInterface, action: AnyAction) => {
  return {
    ...state,
    excipients: action.payload.map((el: { inactiveIngredient: string }) => el.inactiveIngredient),
  };
};

const handleGetMFSiteRoles = (state: TransferStateInterface, action: AnyAction) => {
  return {
    ...state,
    mfSiteRoles: action.payload.map((el: { name: string; globalId: string }) => ({
      label: el.name,
      id: el.globalId,
    })),
  };
};

const handleGetClinicalStudies = (state: TransferStateInterface, action: AnyAction) => {
  return {
    ...state,
    clinicalStudies: action.payload.map((el: { study_cd: string }) => el.study_cd),
  };
};
const handleGetRimOptionalFields = (state: TransferStateInterface, action: AnyAction) => {
  return {
    ...state,
    rimOptionalFields: action.payload,
  };
};

const transferReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case transferActionTypes.GET_FILTERS_BY_TYPE_AND_PARENT_SUCCESS:
      return handleGetFiltersSuccess(state, action);
    case transferActionTypes.SET_DOCUMENT_METADATA_VALUES:
      return handleSetDocumentMetadataValues(state, action);
    case transferActionTypes.GET_DOCUMENT_METADATA_SUCCESS:
      return handleGetDocumentMetadataSuccess(state, action);
    case transferActionTypes.GET_PHOENIX_LANGUAGES_SUCCESS:
      return handleGetPhoenixLanguagesSuccess(state, action);
    case transferActionTypes.GET_CTD_CODES_SUCCESS:
      return handleGetCtdCodesSuccess(state, action);
    case transferActionTypes.GET_CTD_TITLES_SUCCESS:
      return handleGetCtdTItlesSucess(state, action);
    case transferActionTypes.GET_BB_CODES_SUCCESS:
      return handleGetBBCodesSuccess(state, action);
    case transferActionTypes.GET_REPORTS_SUCCESS:
      return handleGetReportsSuccess(state, action);
    case transferActionTypes.GET_EXCIPIENTS_SUCCESS:
      return handleGetExcipients(state, action);
    case transferActionTypes.GET_MF_SITE_ROLES_SUCCESS:
      return handleGetMFSiteRoles(state, action);
    case transferActionTypes.GET_CLINICAL_STUDIES_SUCCESS:
      return handleGetClinicalStudies(state, action);
    case transferActionTypes.GET_RIM_DOCUMENT_METADATA_SUCCESS:
      return handleGetRimDocumentMetadataSuccess(state, action);
    case transferActionTypes.GET_RIM_OPTIONAL_FIELDS_SUCCESS:
      return handleGetRimOptionalFields(state, action);
    default:
      return state;
  }
};

export default transferReducer;
