import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../core/models/state/globalState';
import CircularProgress from '@mui/material/CircularProgress';
import NoSearchResults from '../../components/NoSearchResults/NoSearchResults';
import IATable from '../../components/Table/Table';
import IATableHeader from '../../components/TableHeader/TableHeader';
import { Order, OrderByInterface } from '../../core/models/order';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
import { downloadOutput, getOutputs } from '../../state/actions/output';
import { OutputInterface } from '../../core/models/output/outputInterface';
import { DEFAULT_ORDER, formatsToTransfer } from '../../constants';
import { disableEventsClass } from '../../core/services/helpers';

interface OutputTableProps {
  documentId: string;
}

export const OutputTable = (props: OutputTableProps) => {
  const { documentId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDataLoaded, setIsDataLoaded] = useState(true);
  const [alertText, setAlertText] = useState<string>('');
  const [alertType, setAlertType] = useState<AlertColor>('success');
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<OrderByInterface | undefined>(DEFAULT_ORDER);
  const [pageAndSize, setPageAndSize] = useState({ page: 1, size: 1000 });
  const [transferredDocs, setTransferredDocs] = useState<OutputInterface[]>([]);
  const [notTransfferedDocs, setNotTransfferedDocs] = useState<OutputInterface[]>([]);

  const { tableRows, tableHeadCells, tableHeadCellsTransffered, totalItems } = useSelector(
    (state: GlobalState) => state.output,
  );
  const {
    output: { fields },
  } = useSelector((state: GlobalState) => state.system);
  const { outputCategories, values, filtersApplied } = useSelector(
    (state: GlobalState) => state.filter,
  );
  const headCells = tableHeadCells.map((cell) => {
    const field = fields.find((item) => item.id === cell.id);
    return {
      id: cell.id,
      label: cell.label || field?.label || '',
    };
  });
  const transferredHeadCells = tableHeadCellsTransffered.map((cell) => {
    const field = fields.find((item) => item.id === cell.id);
    return {
      id: cell.id,
      label: cell.label || field?.label || '',
    };
  });
  const getData = (load = true, alert = '', filterFields = filtersApplied.outputFilters || []) => {
    if (load) {
      setIsDataLoaded(false);
    }
    dispatch(
      getOutputs(
        (err: unknown) => {
          setIsDataLoaded(true);
          if (!err && alert) {
            setAlertText(alert);
          }
        },
        {
          page: pageAndSize.page,
          size: pageAndSize.size,
          filterFields: [
            {
              id: 'FIAPDOCUMENTREF',
              operator: 'Equal',
              type: 'documentReference',
              value: [documentId],
            },
            ...filterFields,
          ],
          orderBy,
        },
      ),
    );
  };

  useEffect(() => {
    getData();
  }, [pageAndSize, fields, filtersApplied.outputFilters, orderBy]);

  useEffect(() => {
    const transferredDocsTemp: OutputInterface[] = [];
    const notTransfferedDocsTemp: OutputInterface[] = [];
    tableRows.forEach((el) => {
      if (
        el.TRANSFERSTATUS === 'Transfer in process' ||
        el.TRANSFERSTATUS === null ||
        el.TRANSFERSTATUS === 'Transfer Failed'
      ) {
        notTransfferedDocsTemp.push(el);
      } else {
        transferredDocsTemp.push(el);
      }
    });
    setTransferredDocs(transferredDocsTemp);
    setNotTransfferedDocs(notTransfferedDocsTemp);
  }, [tableRows]);
  const handleSort = (id: string) => {
    const isAsc = orderBy?.id === id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy({ id, order: isAsc ? 'Descending' : 'Ascending' });
  };

  const handleDownload = (output: OutputInterface) => {
    if (output.FISHPUBSTATUS === 'Pending') {
      setAlertType('warning');
      return setAlertText('The output is not yet available for download.');
    }
    dispatch(
      downloadOutput(
        (err) => {
          if (err) {
            setAlertType('error');
            return setAlertText(
              'Download failed, the output generation is still in progress or failed. Please, contact support for additional information.',
            );
          }
          setAlertType('success');
          return setAlertText('Download started');
        },
        { id: output.id as string },
      ),
    );
  };

  return (
    <div>
      <div className='output-table'>
        {isDataLoaded ? (
          <>
            {notTransfferedDocs?.length ? (
              <div className='transfer-section'>
                <IATableHeader
                  title='Outputs ready to Transfer'
                  className={disableEventsClass(!isDataLoaded)}
                  categories={outputCategories}
                  categoryValues={values}
                  type='outputFilters'
                  hideFilter
                />
                <IATable
                  headCells={headCells}
                  visibleRows={notTransfferedDocs}
                  handleRefresh={() => getData()}
                  handleTransferButton={(rowItem) => {
                    if (!formatsToTransfer.includes(rowItem.FISHOUTPUTFORMATREF as string)) {
                      setAlertType('warning');
                      return setAlertText('Only docx or PDF output can be transfered');
                    }
                    navigate(`./transfer/${rowItem.id}/${rowItem.VERSION}`);
                  }}
                  handleDownloadButton={handleDownload}
                  handleClick={() => {}}
                  orderBy={orderBy?.id}
                  order={order}
                  handleSort={handleSort}
                  // threeDots
                  count={pageAndSize.size}
                  page={pageAndSize.page}
                  pageChangeHandler={(page: number, size: number) => {
                    setPageAndSize({ page, size });
                  }}
                  totalItems={totalItems}
                  hidePagination={true}
                  errorText={true}
                />
              </div>
            ) : (
              <div className='loader-container'>
                {transferredDocs?.length ? (
                  <div className='title'>No outputs ready to Transfer</div>
                ) : (
                  <NoSearchResults title='No transfer outputs' tutorial='output' />
                )}
              </div>
            )}
            {transferredDocs?.length ? (
              <>
                <IATableHeader
                  title='Outputs in document management system'
                  className={disableEventsClass(!isDataLoaded)}
                  categories={outputCategories}
                  categoryValues={values}
                  type='outputFilters'
                  hideFilter
                />
                <IATable
                  headCells={transferredHeadCells}
                  visibleRows={transferredDocs}
                  handleRefresh={() => getData()}
                  handleClick={() => {}}
                  orderBy={orderBy?.id}
                  order={order}
                  handleSort={handleSort}
                  // threeDots
                  handleDownloadButton={handleDownload}
                  count={pageAndSize.size}
                  page={pageAndSize.page}
                  pageChangeHandler={(page: number, size: number) => {
                    setPageAndSize({ page, size });
                  }}
                  totalItems={totalItems}
                  hidePagination={true}
                  showStatusAsLabel
                />
              </>
            ) : (
              <div className='loader-container'>
                <NoSearchResults
                  title='No outputs in document management system'
                  className='no-results'
                  tutorial='output'
                />
              </div>
            )}
          </>
        ) : (
          <div className='loader-container'>
            <CircularProgress value={30} size='80px' />
          </div>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!alertText}
        autoHideDuration={5000}
        onClose={() => setAlertText('')}
      >
        <Alert
          onClose={() => setAlertText('')}
          severity={alertType}
          variant='filled'
          sx={{ width: '100%' }}
        >
          {alertText}
        </Alert>
      </Snackbar>
    </div>
  );
};
