import { SingleObject } from '../models/global';
import { LovValue } from '../models/lovValue';
import { OutputInterface } from '../models/output/outputInterface';
import { ProjectInterface, ProductFamilies } from '../models/project/projectInterface';
import { DefinitioniInterface, FieldResponseInterface } from '../models/system/systemDefinition';
import { TemplateInterface } from '../models/template/templateInterface';
import { DrugProduct } from '../models/transfer/transfer';
import { RequiredMetadata } from '../models/workingDocument/requiredMetadata';
import { WorkingDocumentInterface } from '../models/workingDocument/workingDocumentInterface';

const productLovFields = [
  'FCUSTPRODUCTFAMILY',
  'FCUSTPRODUCT',
  'FCUSTSUBSTANCE',
  'FCUSTPACKAGINGCODE',
  'FCUSTDPMANUFACTURER',
  'FCUSTDSMANUFACTURER',
];

export const handleMultivalueDerivedFields = (field: FieldResponseInterface) => {
  const result: SingleObject = {};
  field.values.forEach((val) => {
    if (val.derivedFrom) {
      result[(val.derivedFrom.value as LovValue).label] = val.value as string[];
    }
  });
  return result;
};

export function getValue(
  field: FieldResponseInterface | RequiredMetadata,
  labelOrId: 'id' | 'label',
) {
  try {
    if (field.type === 'lov') {
      return (field.values?.[0]?.value as DefinitioniInterface)?.[labelOrId];
    } else if (field.type === 'multiLov') {
      return field.values
        ?.map((el) => (el.value as DefinitioniInterface)[labelOrId])
        .join(`,${labelOrId === 'id' ? '' : ' '}`);
    } else if (field.type === 'multiUserReference') {
      return (field.values as { label: string }[])?.map((el) => el.label).join(',');
    } else if (field.type.includes('Reference')) {
      return field.values?.[0]?.label;
    } else if (field.type === 'multiString') {
      return field.values?.map((val) => (val.value as string[])?.[0]).join(',');
    } else {
      return field.values?.[0]?.value as string;
    }
  } catch (error) {
    console.log(error);
  }
}

export function transformFieldsToObject(
  fields: FieldResponseInterface[],
  headCells: string[],
  checkHeadCells: boolean,
  row: WorkingDocumentInterface | ProjectInterface | TemplateInterface | OutputInterface,
) {
  fields.forEach((field: FieldResponseInterface) => {
    if (checkHeadCells && !headCells.includes(field.id)) {
      return;
    }
    if (productLovFields.includes(field.id)) {
      row[field.id] = getValue(field, 'id');
    } else {
      row[field.id] = getValue(field, 'label');
    }
  });
  return row;
}

export const productMetadataFields = [
  'FCUSTMOLECULENAME',
  'FCUSTDOSAGEFORM',
  'FCUSTSTRENGTH',
  'FCUSTDSROCHENUM',
  'FCUSTPRODFAMROCHENUM',
];
export const populateFieldsFromProductMetadata = (
  productmetadata: ProductFamilies[],
  selectedProductFamilies: string[],
  selectedDrugProducts: string[],
) => {
  const productMetadata = productmetadata.find((el) => el.id === selectedProductFamilies[0]);
  return {
    FCUSTMOLECULENAME:
      productMetadata?.productInn?.toString() ?? productMetadata?.roNumber?.toString() ?? '',
    FCUSTDOSAGEFORM:
      productMetadata?.drugProducts
        .filter((dp) => selectedDrugProducts.includes(dp.id))
        .map((el: DrugProduct) => el.dosageForm)
        .join(',') ?? '',
    FCUSTSTRENGTH:
      productMetadata?.drugProducts
        .filter((dp) => selectedDrugProducts.includes(dp.id))
        .map((el: DrugProduct) => el.dosageStrength)
        .join(',') ?? '',
    FCUSTDOSAGESTRENGTH:
      productMetadata?.drugProducts
        .filter((dp) => selectedDrugProducts.includes(dp.id))
        .map((el: DrugProduct) => el.dosageStrength)
        .join(',') ?? '',
    FCUSTPRODFAMROCHENUM: productMetadata?.roNumber?.toString() ?? '',
  };
};

export const filterAdditionalMetadataFields = (
  documentAdditionalMetadataAndReports: { [key: string]: string[] },
  metadataList: RequiredMetadata[],
  template: TemplateInterface,
) => {
  const documentMetadataFields = Object.keys(documentAdditionalMetadataAndReports);
  const tempAdditionalMetadata: RequiredMetadata[] = [];
  documentMetadataFields.forEach((key) => {
    const metadataEl = metadataList.find((el) => el.id === key);
    if (metadataEl && (
      ((documentAdditionalMetadataAndReports[metadataEl.id]?.includes(template?.FCUSTCTDCODE as string) ||
        documentAdditionalMetadataAndReports[metadataEl.id]?.includes(
          template?.FCUSTREPORTCLASSIFICATION as string,
        ) ||
        documentAdditionalMetadataAndReports[metadataEl.id]?.includes('*'))) ||
      (template?.FCUSTTEMPLATETYPE === 'Regulatory Submission' &&
        documentAdditionalMetadataAndReports[metadataEl.id]?.includes('S*')) ||
      (template?.FCUSTTEMPLATETYPE === 'PT Quality Agreements' &&
        documentAdditionalMetadataAndReports[metadataEl.id]?.includes('PTQ'))
    )) {
      tempAdditionalMetadata.push(metadataEl);
    }

  })
  return tempAdditionalMetadata;
};

export const getLovLabel = (
  fieldId: string,
  fieldValue: string,
  fields: DefinitioniInterface[],
  lovValues: { [key: string]: LovValue[] },
) => {
  const lovId = fields.find((field) => field.id === fieldId)?.lovId;
  if (lovId) {
    return lovValues[lovId].find((lov) => lov.id === fieldValue)?.label;
  }
};

export const getLovId = (
  fieldId: string,
  lovLabel: string,
  fields: DefinitioniInterface[],
  lovValues: { [key: string]: LovValue[] },
) => {
  const lovId = fields.find((field) => field.id === fieldId)?.lovId;
  if (lovId) {
    return lovValues?.[lovId]?.find((lov) => lov.label === lovLabel)?.id;
  }
};
