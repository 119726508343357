import SvgIcon from '@mui/material/SvgIcon';

export default function XIcon() {
  return (
    <SvgIcon
      style={{ transform: 'rotate(45deg)' }}
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='30' cy='30' r='29' fill='#0066CC' stroke='#0066CC' strokeWidth='2' />
      <circle cx='30' cy='30' r='27.5' fill='#0066CC' stroke='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.8448 20.6665V28.8448H20.6665V31.1548H28.8448V39.3332H31.1548V31.1548H39.3332V28.8448H31.1548V20.6665H28.8448Z'
        fill='white'
      />
    </SvgIcon>
  );
}
