import { FavoriteObjectTypes, FavoriteState } from '../../core/models/favorites/favoriteState';
import { FilterIterface } from '../../core/models/filter/filter';
import { Order, OrderByInterface } from '../../core/models/order';
import { ProjectInterface } from '../../core/models/project/projectInterface';
import { ItemsInterface } from '../../core/models/system/systemDefinition';
import { TemplateInterface } from '../../core/models/template/templateInterface';
import { WorkingDocumentInterface } from '../../core/models/workingDocument/workingDocumentInterface';

export const favoriteActionTypes = {
  SET_FAVORITE: 'SET_FAVORITE',
  GET_FAVORITES: 'GET_FAVORITES',
  GET_FAVORITE_TEMPLATES: 'GET_FAVORITE_TEMPLATES',
  GET_FAVORITE_TEMPLATES_SUCCESS: 'GET_FAVORITE_TEMPLATES_SUCCESS',
  GET_FAVORITE_DOCUMENTS: 'GET_FAVORITE_DOCUMENTS',
  GET_FAVORITE_DOCUMENTS_SUCCESS: 'GET_FAVORITE_DOCUMENTS_SUCCESS',
  GET_FAVORITE_PROJECTS: 'GET_FAVORITE_PROJECTS',
  GET_FAVORITE_PROJECTS_SUCCESS: 'GET_FAVORITE_PROJECTS_SUCCESS',
  FILTER_FAVORITE_TEMPLATE: 'FILTER_FAVORITE_TEMPLATE',
  FILTER_FAVORITE_PROJECT: 'FILTER_FAVORITE_PROJECT',
  FILTER_FAVORITE_DOCUMENT: 'FILTER_FAVORITE_DOCUMENT',
};

export const setFavorite = (
  callback: () => void,
  objectType: FavoriteObjectTypes,
  payload: TemplateInterface | ProjectInterface | WorkingDocumentInterface,
  isMyData?: boolean,
) => ({
  type: favoriteActionTypes.SET_FAVORITE,
  objectType,
  payload,
  isMyData,
  callback,
});

export const getFavorites = (callback: (data: FavoriteState) => void = () => {}) => ({
  callback,
  type: favoriteActionTypes.GET_FAVORITES,
});

export const getFavoriteDocuments = (callback: () => void, payload: string[]) => ({
  callback,
  type: favoriteActionTypes.GET_FAVORITE_DOCUMENTS,
  payload,
});

export const getFavoriteDocumentsSuccess = (payload: ItemsInterface[]) => ({
  type: favoriteActionTypes.GET_FAVORITE_DOCUMENTS_SUCCESS,
  payload,
});

export const getFavoriteTemplates = (callback: () => void, payload: string[]) => ({
  callback,
  type: favoriteActionTypes.GET_FAVORITE_TEMPLATES,
  payload,
});

export const getFavoriteTemplatesSuccess = (payload: ItemsInterface[]) => ({
  type: favoriteActionTypes.GET_FAVORITE_TEMPLATES_SUCCESS,
  payload,
});

export const getFavoriteProjects = (callback: () => void, payload: string[]) => ({
  callback,
  type: favoriteActionTypes.GET_FAVORITE_PROJECTS,
  payload,
});

export const getFavoriteProjectsSuccess = (payload: ItemsInterface[]) => ({
  type: favoriteActionTypes.GET_FAVORITE_PROJECTS_SUCCESS,
  payload,
});

export const filterFavoriteProjects = (payload: FilterIterface[]) => ({
  type: favoriteActionTypes.FILTER_FAVORITE_PROJECT,
  payload,
});

export const filterFavoriteDocuments = (payload: {
  filterFields: FilterIterface[];
  order: Order;
  orderBy: OrderByInterface;
}) => ({
  type: favoriteActionTypes.FILTER_FAVORITE_DOCUMENT,
  payload,
});

export const filterFavoriteTemplates = (payload: {
  filterFields: FilterIterface[];
  order: Order;
  orderBy: OrderByInterface;
}) => ({
  type: favoriteActionTypes.FILTER_FAVORITE_TEMPLATE,
  payload,
});
