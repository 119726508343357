import { ReactNode } from 'react';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { InputAdornment } from '@mui/material';
import './Input.scss';

interface IAInputInterface extends OutlinedInputProps {
  inputLabel?: string;
  placeholder?: string;
  icon?: ReactNode;
  error?: boolean;
  errMessage?: string;
  maxLength?: number;
}
export const IAInput = (props: IAInputInterface) => {
  const {
    inputLabel,
    placeholder,
    icon,
    required = false,
    id,
    error,
    errMessage,
    maxLength,
    ...rest
  } = props;
  return (
    <div className='input-with-label'>
      {inputLabel && (
        <label htmlFor={inputLabel}>
          {inputLabel} {required && <span className='mandatory'>*</span>}
        </label>
      )}

      <OutlinedInput
        id={id ? id : inputLabel}
        className='ia-input'
        data-testid='ia-input'
        required
        placeholder={placeholder || ''}
        {...rest}
        startAdornment={icon ? <InputAdornment position='start'>{icon}</InputAdornment> : undefined}
        error={error}
        inputProps={maxLength ? { maxLength } : {}}
      />
      {error && <div className='err-message'>{errMessage || 'This field is required'}</div>}
    </div>
  );
};
