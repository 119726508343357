import {
  DocumentTransferMetadata,
  PhoenixFilterData,
} from '../../core/models/transfer/phoenixData';
import {
  TransferBodyInterface,
  CTDCodesInterface,
  CTDTitlesInterface,
} from '../../core/models/transfer/transfer';

export const transferActionTypes = {
  GET_FILTERS_BY_TYPE_AND_PARENT: 'GET_FILTERS_BY_TYPE_AND_PARENT',
  GET_FILTERS_BY_TYPE_AND_PARENT_SUCCESS: 'GET_FILTERS_BY_TYPE_AND_PARENT_SUCCESS',
  SET_DOCUMENT_METADATA_VALUES: 'SET_DOCUMENT_METADATA_VALUES',
  GET_DOCUMENT_METADATA: 'GET_DOCUMENT_METADATA',
  GET_DOCUMENT_METADATA_SUCCESS: 'GET_DOCUMENT_METADATA_SUCCESS',
  TRANSFER_DOCUMENT: 'TRANSFER_DOCUMENT',
  TRANSFER_DOCUMENT_SUCCESS: 'TRANSFER_DOCUMENT_SUCCESS',
  GET_PHOENIX_LANGUAGES: 'GET_PHOENIX_LANGUAGES',
  GET_PHOENIX_LANGUAGES_SUCCESS: 'GET_PHOENIX_LANGUAGES_SUCCESS',
  GET_CTD_CODES: 'GET_CTD_CODES',
  GET_CTD_CODES_SUCCESS: 'GET_CTD_CODES_SUCCESS',
  GET_CTD_TITLES: 'GET_CTD_TITLES',
  GET_CTD_TITLES_SUCCESS: 'GET_CTD_TITLES_SUCCESS',
  GET_BB_CODES: 'GET_BB_CODES',
  GET_BB_CODES_SUCCESS: 'GET_BB_CODES_SUCCESS',
  GET_COUNTRIES: 'GET_COUNTRIES',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_REPORTS: 'GET_REPORTS',
  GET_REPORTS_SUCCESS: 'GET_REPORTS_SUCCESS',
  GET_EXCIPIENTS: 'GET_EXCIPIENTS',
  GET_EXCIPIENTS_SUCCESS: 'GET_EXCIPIENTS_SUCCESS',
  GET_MF_SITE_ROLES: 'GET_MF_SITE_ROLES',
  GET_MF_SITE_ROLES_SUCCESS: 'GET_MF_SITE_ROLES_SUCCESS',
  GET_CLINICAL_STUDIES: 'GET_CLINICAL_STUDIES',
  GET_CLINICAL_STUDIES_SUCCESS: 'GET_CLINICAL_STUDIES_SUCCESS',
  GET_RIM_DOCUMENT_METADATA: 'GET_RIM_DOCUMENT_METADATA',
  GET_RIM_DOCUMENT_METADATA_SUCCESS: 'GET_RIM_DOCUMENT_METADATA_SUCCESS',
  GET_RIM_OPTIONAL_FIELDS: 'GET_RIM_OPTIONAL_FIELDS',
  GET_RIM_OPTIONAL_FIELDS_SUCCESS: 'GET_RIM_OPTIONAL_FIELDS_SUCCESS',
};

export const getFiltersByTypeAndParent = (
  callback: () => void = () => {},
  payload: { type: string; subType: string; parentId: string | null | string[] },
) => ({
  payload,
  type: transferActionTypes.GET_FILTERS_BY_TYPE_AND_PARENT,
  callback,
});

export const getFiltersByTypeAndParentSuccess = (payload: {
  data: PhoenixFilterData[];
  type: string;
  subType: string;
}) => ({
  type: transferActionTypes.GET_FILTERS_BY_TYPE_AND_PARENT_SUCCESS,
  payload,
});

export const setDocumentMetadataValues = (payload: {
  field: keyof DocumentTransferMetadata;
  value: string | boolean | string[];
}) => ({
  type: transferActionTypes.SET_DOCUMENT_METADATA_VALUES,
  payload,
});

export const getDocumentMetadata = (
  callback: (error: { incorrectField: string; message: string }) => void = () => {},
  payload: string,
) => ({
  type: transferActionTypes.GET_DOCUMENT_METADATA,
  payload,
  callback,
});

export const getDocumentMetadataSuccess = (payload: DocumentTransferMetadata) => ({
  type: transferActionTypes.GET_DOCUMENT_METADATA_SUCCESS,
  payload,
});

export const transferDocument = (
  callback: (err: unknown) => void,
  payload: TransferBodyInterface,
) => ({
  type: transferActionTypes.TRANSFER_DOCUMENT,
  payload,
  callback,
});

export const transferDocumentSuccess = (payload: string) => ({
  type: transferActionTypes.TRANSFER_DOCUMENT_SUCCESS,
  payload,
});

export const getPhoenixLanguages = (callback: (err: unknown) => void) => ({
  type: transferActionTypes.GET_PHOENIX_LANGUAGES,
  callback,
});

export const getPhoenixLanguagesSuccess = (payload: PhoenixFilterData[]) => ({
  type: transferActionTypes.GET_PHOENIX_LANGUAGES_SUCCESS,
  payload,
});

export const getCtdCodes = (callback: (err: unknown) => void) => ({
  type: transferActionTypes.GET_CTD_CODES,
  callback,
});

export const getCtdCodesSuccess = (payload: CTDCodesInterface[]) => ({
  type: transferActionTypes.GET_CTD_CODES_SUCCESS,
  payload,
});

export const getCtdTitles = (callback: (err: unknown) => void, payload: string) => ({
  type: transferActionTypes.GET_CTD_TITLES,
  callback,
  payload,
});

export const getCtdTitlesSuccess = (payload: CTDTitlesInterface[]) => ({
  type: transferActionTypes.GET_CTD_TITLES_SUCCESS,
  payload,
});

export const getBBCodes = (callback: (err: unknown) => void, payload: string) => ({
  type: transferActionTypes.GET_BB_CODES,
  callback,
  payload,
});

export const getBBCodesSuccess = (payload: []) => ({
  type: transferActionTypes.GET_BB_CODES_SUCCESS,
  payload,
});

export const getReports = (callback: (err: unknown) => void) => ({
  type: transferActionTypes.GET_REPORTS,
  callback,
});

export const getReportsSuccess = (payload: []) => ({
  type: transferActionTypes.GET_REPORTS_SUCCESS,
  payload,
});

export const getExcipients = (callback: (err: unknown) => void) => ({
  type: transferActionTypes.GET_EXCIPIENTS,
  callback,
});

export const getExcipientsSuccess = (payload: []) => ({
  type: transferActionTypes.GET_EXCIPIENTS_SUCCESS,
  payload,
});

export const getMFSiteRoles = (callback: (err: unknown) => void) => ({
  type: transferActionTypes.GET_MF_SITE_ROLES,
  callback,
});

export const getMFSiteRolesSuccess = (payload: []) => ({
  type: transferActionTypes.GET_MF_SITE_ROLES_SUCCESS,
  payload,
});

export const getClinicalStudies = (callback: (err: unknown) => void) => ({
  type: transferActionTypes.GET_CLINICAL_STUDIES,
  callback,
});

export const getClinicalStudiesSuccess = (payload: []) => ({
  type: transferActionTypes.GET_CLINICAL_STUDIES_SUCCESS,
  payload,
});

export const getRimDocumentMetadata = (
  callback: (err: unknown) => void = () => {},
  payload: string,
) => ({
  type: transferActionTypes.GET_RIM_DOCUMENT_METADATA,
  payload,
  callback,
});

export const getRimDocumentMetadataSuccess = (payload: DocumentTransferMetadata) => ({
  type: transferActionTypes.GET_RIM_DOCUMENT_METADATA_SUCCESS,
  payload,
});

export const getRimOptionalFields = (
  callback: (err: unknown) => void = () => {},
  payload: string,
) => ({
  type: transferActionTypes.GET_RIM_OPTIONAL_FIELDS,
  payload,
  callback,
});

export const getRimOptionalFieldsSuccess = (payload: string[]) => ({
  type: transferActionTypes.GET_RIM_OPTIONAL_FIELDS_SUCCESS,
  payload,
});
