import VideoTutorialPlaceholder from '../VideoTutorial/VideoTutorialPlaceholder';
import DocumentIcon from '../icons/DocumentIcon';

import NoSearchResultsIcon from '../icons/NoSearchResultsIcon';
import ProjectIcon from '../icons/ProjectIcon';
import TemplateIcon from '../icons/TemplateIcon';
import './NoSearchResults.scss';

export interface NoSearchResultsProps {
  className?: string;
  title?: string;
  subTitle?: string;
  tutorial?: 'project' | 'template' | 'document' | 'output';
  isEmptyState?: boolean;
}

interface TypeDetailsType {
  [key: string]: {
    type: 'project' | 'template' | 'document' | 'output';
    icon: JSX.Element;
    readonly title: string;
    subTitleOne: string;
  };
}

const typeDetails: TypeDetailsType = {
  project: {
    type: 'project',
    icon: <ProjectIcon className='icon' />,
    get title(): string {
      return `Create your first ${this.type}`;
    },
    get subTitleOne(): string {
      return `Get started by creating your ${this.type} in 3 easy steps`;
    },
  },
  template: {
    type: 'template',
    icon: <TemplateIcon className='icon' />,
    get title(): string {
      return `Create your first ${this.type}`;
    },
    get subTitleOne(): string {
      return `Get started by creating your ${this.type} in 1 easy step`;
    },
  },
  document: {
    type: 'document',
    icon: <DocumentIcon className='icon' />,
    get title(): string {
      return `Create your first ${this.type}`;
    },
    get subTitleOne(): string {
      return `Get started by creating your ${this.type} in 4 easy steps`;
    },
  },
};

const NoSearchResults = (props: NoSearchResultsProps) => {
  const { className, tutorial, isEmptyState = false } = props;
  let { title = 'No search results', subTitle = 'We couldn’t find what you were looking for' } =
    props;

  let icon = <NoSearchResultsIcon className='icon' />;
  if (tutorial && isEmptyState) {
    icon = typeDetails[tutorial].icon;
    title = typeDetails[tutorial].title;
    subTitle = typeDetails[tutorial].subTitleOne;
  }
  return (
    <div data-testid='no-search-results' className={`no-search-results ${className}`}>
      {icon}
      <div data-testid='title' className='title'>
        {title}
      </div>
      <div data-testid='sub-title' className='sub-title'>
        {subTitle}
      </div>
      <div className='video-tutorial-placeholder'>
        {!!tutorial && <VideoTutorialPlaceholder name={tutorial} />}
      </div>
    </div>
  );
};

export default NoSearchResults;
