import Box from '@mui/material/Box';
import StepperMUI from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

interface StepperProps {
  activeStep: number;
  steps: string[];
  completedSteps: number[];
}

export default function Stepper(props: StepperProps) {
  const { activeStep, steps, completedSteps } = props;

  return (
    <Box sx={{ width: '100%' }} className='stepper' data-testid='stepper'>
      <StepperMUI activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step
            key={label}
            className={
              index + 1 === activeStep
                ? 'active'
                : completedSteps.includes(index + 1)
                ? 'completed'
                : 'non-active'
            }
            completed={false}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </StepperMUI>
    </Box>
  );
}
