import AvatarGroup from '@mui/material/AvatarGroup';
import { useSelector } from 'react-redux';
import Star from '../icons/Star';
// import ThreeDots from '../icons/ThreeDots';
import './ProjectCard.scss';
import Avatar from '@mui/material/Avatar';
import { Tooltip } from '@mui/material';
import { ProjectInterface } from '../../core/models/project/projectInterface';
import { GlobalState } from '../../core/models/state/globalState';
import { getInitials } from '../../core/services/userService';

interface ProjectCardProps {
  visibleRow: ProjectInterface;
  handleClick?: () => void;
  handleThreeDotsClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  handleFavoriteClick?: (id: string) => void;
}

function ProjectCard(props: ProjectCardProps) {
  const {
    visibleRow,
    handleClick = () => {},
    // handleThreeDotsClick = () => {},
    handleFavoriteClick = () => {},
  } = props;
  const {
    project: { lovs },
  } = useSelector((state: GlobalState) => state.system);
  const productFamilyId = visibleRow.FCUSTPRODUCTFAMILY
    ? (visibleRow.FCUSTPRODUCTFAMILY as string).split(',')[0]
    : '';
  const productFamily = (lovs?.DCUSTPRODUCTFAMILY || []).find(
    (item) => item.id === productFamilyId,
  );

  const renderAvatars = () => {
    if (visibleRow.FIAPPROJECTASSIGNEE) {
      let members = (visibleRow.FIAPPROJECTASSIGNEE as string).split(',');
      members = members.filter((member) => member !== visibleRow.FIAPCREATEDBY);
      if (members?.length) {
        return (
          <AvatarGroup className='project-card-avatar' max={4}>
            {members.map((el) => (
              <Avatar key={el} alt={el}>
                {getInitials(el)}
              </Avatar>
            ))}
          </AvatarGroup>
        );
      }
      return <></>;
    }
    return <></>;
  };

  return (
    <div className='project-card' onClick={handleClick}>
      <div className='project-card-header'>
        <div className='project-card-title' data-testid='project-title'>
          {visibleRow.FTITLE}
        </div>
        <Star
          className='project-card-star'
          fill={visibleRow.isFavorite ? '#FF7D29' : ''}
          stroke={visibleRow.isFavorite ? '#FF7D29' : ''}
          onClick={(event) => {
            event.stopPropagation();
            handleFavoriteClick((visibleRow.id as string) || '');
          }}
        />
        {/* <ThreeDots className='project-card-three-dots' onClick={handleThreeDotsClick} /> */}
      </div>
      <div className='project-card-content'>
        <div className='project-card-row'>
          <div className='project-card-text' data-testid='project-type'>
            {visibleRow.FCUSTPROJECTTYPE || 'Regulatory Submission'}
          </div>
          <div className='project-card-status'>{visibleRow.FIAPPROJECTSTATUS || 'Open'}</div>
        </div>
        <div className='project-card-label' data-testid='project-ro-number-label'>
          {productFamily?.label || 'No product family'}
        </div>
        <div className='project-card-row' style={{ marginTop: '24px' }}>
          <div className='project-card-footer'>
            Created by:{' '}
            {
              <AvatarGroup className='project-card-avatar' max={4} style={{ marginLeft: '5px' }}>
                <Tooltip title={visibleRow.FIAPCREATEDBY} placement='top'>
                  <Avatar key='creator' alt='Creator'>
                    {getInitials(visibleRow.FIAPCREATEDBY as string)}
                  </Avatar>
                </Tooltip>
              </AvatarGroup>
            }
          </div>
          {renderAvatars()}
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
