import { call, put, takeLatest } from 'redux-saga/effects';
import {
  systemActionTypes,
  getSystemDefinitionsSuccess,
  getProjectLovs as getProjectLovsAction,
  getTemplateLovs as getTemplateLovsAction,
  getDocumentLovs as getDocumentLovsAction,
  getOutputLovs as getOutputLovsAction,
  getProjectLovsSuccess,
  getTemplateLovsSuccess,
  getDocumentLovsSuccess,
  getOutputLovsSuccess,
} from '../actions/system';
import { SystemDefinitionInterface } from '../../core/models/system/systemDefinition';
import {
  getLOV,
  getSystemDefinitions as getSystemDefinitionsApi,
  updateCountryGroupLov,
} from '../../core/api/system.api';
import { LovValue } from '../../core/models/lovValue';
import { CountryGoup } from '../../core/models/template/template';

export function* getSystemDefinitions(action: {
  callback: (err: unknown, systemDefinitions?: SystemDefinitionInterface) => void;
  type: string;
}) {
  try {
    const systemDefinitions: SystemDefinitionInterface = yield call(getSystemDefinitionsApi);
    yield put(getSystemDefinitionsSuccess(systemDefinitions));
    const projectLoves = systemDefinitions.project.fields
      .filter((field) => field.type === 'lov')
      .map((field) => field.lovId || '');
    if (projectLoves) {
      yield put(getProjectLovsAction(projectLoves));
    }
    const documentLoves = systemDefinitions.document.fields
      .filter((field) => field.type === 'lov')
      .map((field) => field.lovId || '');
    if (documentLoves) {
      yield put(getDocumentLovsAction(documentLoves));
    }
    const templateLoves = systemDefinitions.template.fields
      .filter((field) => field.type === 'lov')
      .map((field) => field.lovId || '');
    if (templateLoves) {
      yield put(getTemplateLovsAction(templateLoves));
    }
    const outputLoves = systemDefinitions.output.fields
      .filter((field) => field.type === 'lov')
      .map((field) => field.lovId || '');
    if (outputLoves) {
      yield put(getOutputLovsAction(outputLoves));
    }
    action.callback(null, systemDefinitions);
  } catch (error) {
    action.callback(error);
  }
}

export function* getProjectLovs(action: {
  callback: (err: unknown, systemDefinitions?: SystemDefinitionInterface) => void;
  type: string;
  payload: string[];
}) {
  const lov: { [key: string]: LovValue[] } = yield call(() => getLOV(action.payload));
  yield put(getProjectLovsSuccess(lov));
}

export function* getTemplateLovs(action: {
  callback: (err: unknown, systemDefinitions?: SystemDefinitionInterface) => void;
  type: string;
  payload: string[];
}) {
  const lov: { [key: string]: LovValue[] } = yield call(() => getLOV(action.payload));
  yield put(getTemplateLovsSuccess(lov));
}

export function* getDocumentLovs(action: {
  callback: (err: unknown, systemDefinitions?: SystemDefinitionInterface) => void;
  type: string;
  payload: string[];
}) {
  const lov: { [key: string]: LovValue[] } = yield call(() => getLOV(action.payload));
  yield put(getDocumentLovsSuccess(lov));
}

export function* getOutputLovs(action: {
  callback: (err: unknown, systemDefinitions?: SystemDefinitionInterface) => void;
  type: string;
  payload: string[];
}) {
  const lov: { [key: string]: LovValue[] } = yield call(() => getLOV(action.payload));
  yield put(getOutputLovsSuccess(lov));
}

export function* updateCountryGroupValues(action: {
  callback: (err: unknown, ids?: string[]) => void;
  type: string;
  payload: CountryGoup[];
}) {
  try {
    if (action.payload.length > 0) {
      const data: string[] = yield call(() => updateCountryGroupLov(action.payload));
      action.callback(null, data);
    } else {
      action.callback(null, []);
    }
  } catch (error) {
    action.callback(error);
  }
}

export default function* systemWatcher() {
  yield takeLatest(systemActionTypes.GET_SYSTEM_DEFINITIONS, getSystemDefinitions);
  yield takeLatest(systemActionTypes.GET_PROJECT_LOVS, getProjectLovs);
  yield takeLatest(systemActionTypes.GET_TEMPLATE_LOVS, getTemplateLovs);
  yield takeLatest(systemActionTypes.GET_DOCUMENT_LOVS, getDocumentLovs);
  yield takeLatest(systemActionTypes.GET_OUTPUT_LOVS, getOutputLovs);
  yield takeLatest(systemActionTypes.UPDATE_COUNTRY_GROUP_VALUES, updateCountryGroupValues);
}
