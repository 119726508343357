import { useNavigate } from 'react-router-dom';
import './EmptyState.scss';
import IAButton from '../Button/Button';
import ProjectIcon from '../icons/ProjectIcon';
import TemplateIcon from '../icons/TemplateIcon';
import DocumentIcon from '../icons/DocumentIcon';
import VideoTutorialPlaceholder from '../VideoTutorial/VideoTutorialPlaceholder';

interface TypeDetailsType {
  [key: string]: {
    type: 'workspace' | 'template' | 'document';
    icon: JSX.Element;
    readonly title: string;
    subTitleOne: string;
    readonly bottonText: string;
    onClickLink: string;
  };
}

const typeDetails: TypeDetailsType = {
  workspace: {
    type: 'workspace',
    icon: <ProjectIcon className='icon' />,
    get title(): string {
      return `Create your first ${this.type}`;
    },
    get subTitleOne(): string {
      return `Get started by creating your ${this.type} in 3 easy steps`;
    },
    get bottonText(): string {
      return `Create new ${this.type}`;
    },
    onClickLink: '/layout/workspaces/new',
  },
  template: {
    type: 'template',
    icon: <TemplateIcon className='icon' />,
    get title(): string {
      return `Create your first ${this.type}`;
    },
    get subTitleOne(): string {
      return `Get started by creating your ${this.type} efficiently`;
    },
    get bottonText(): string {
      return `Create new ${this.type}`;
    },
    onClickLink: '/layout/templates/new',
  },
  document: {
    type: 'document',
    icon: <DocumentIcon className='icon' />,
    get title(): string {
      return `Create your first ${this.type}`;
    },
    get subTitleOne(): string {
      return `Get started by creating your ${this.type} in 4 easy steps`;
    },
    get bottonText(): string {
      return `Create new ${this.type}`;
    },
    onClickLink: '/layout/working-documents/new',
  },
};

const typeDetailsNoncreator: TypeDetailsType = {
  workspace: {
    type: 'workspace',
    icon: <ProjectIcon className='icon' />,
    get title(): string {
      return `No ${this.type}`;
    },
    get subTitleOne(): string {
      return `No ${this.type} available now. Once created, they'll be here.`;
    },
    get bottonText(): string {
      return '';
    },
    onClickLink: '/layout/workspaces/new',
  },
  template: {
    type: 'template',
    icon: <TemplateIcon className='icon' />,
    get title(): string {
      return `No ${this.type}`;
    },
    get subTitleOne(): string {
      return `No ${this.type} available now. Once created, they'll appear here.`;
    },
    get bottonText(): string {
      return '';
    },
    onClickLink: '/layout/templates/new',
  },
  document: {
    type: 'document',
    icon: <DocumentIcon className='icon' />,
    get title(): string {
      return `No ${this.type}`;
    },
    get subTitleOne(): string {
      return `No ${this.type} available now. Once created, they'll be here.`;
    },
    get bottonText(): string {
      return '';
    },
    onClickLink: '/layout/working-documents/new',
  },
};

interface EmptyStateProps {
  className?: string;
  type: 'workspace' | 'template' | 'document';
  isAdminOrAuthor?: boolean;
}

const EmptyState = (props: EmptyStateProps) => {
  const { type, icon, title, subTitleOne, bottonText, onClickLink } = !props.isAdminOrAuthor
    ? typeDetailsNoncreator[props.type]
    : typeDetails[props.type];
  const navigate = useNavigate();

  return (
    <div data-testid='empty-state' className={`empty-state ${props.className}`}>
      {icon}
      <div data-testid='title' className='title'>
        {title}
      </div>
      <div data-testid='sub-title-one' className='sub-title'>
        {subTitleOne}
      </div>
      {props.isAdminOrAuthor && (
        <div data-testid='sub-title-two' className='sub-title'>
          Watch our {<VideoTutorialPlaceholder name={type} text='tutorial video' />} for a quick
          assistance.
        </div>
      )}
      {props.isAdminOrAuthor && (
        <IAButton
          data-testid='button'
          className='main-action-btn'
          handleClick={() => {
            navigate(onClickLink);
          }}
        >
          {bottonText}
        </IAButton>
      )}
    </div>
  );
};

export default EmptyState;
