import { projectActionTypes } from '../actions/project';
import { Project } from '../../core/models/project/project';
import { ProjectInterface } from '../../core/models/project/projectInterface';
import { ProjectState } from '../../core/models/project/projectState';
import { AnyAction } from 'redux';
import { FieldResponseInterface } from '../../core/models/system/systemDefinition';
import { defaultTableHeadCells } from '../../constants';
import { favoriteActionTypes } from '../actions/favorite';
import { transformFieldsToObject } from '../../core/services/apiTranformations';
import { setFavorite, setTableHeaders } from '../../core/services/helpers';

export const initialState: ProjectState = {
  project: {},
  projectData: {
    id: '',
    fields: [],
  },
  projects: [],
  tableHeadCells: defaultTableHeadCells.project,
  tableRows: [],
  totalItems: 0,
  myProjectsTableRows: [],
  myProjects: [],
  myTotalItems: 0,
};

const handleGetProjectSuccess = (state: ProjectState, action: AnyAction) => {
  const data = action.payload;
  let project: ProjectInterface = { id: data.id, isFavorite: data.isFavorite };
  project = transformFieldsToObject(data.fields, [], false, project);

  return {
    ...state,
    projectData: action.payload,
    project,
  };
};

const handleGetProjectsSuccess = (state: ProjectState, action: AnyAction) => {
  const data = action.payload.items;
  const totalItems = action.payload.total;
  const tableRows: ProjectInterface[] = [];
  data.forEach((item: { id: string; isFavorite: boolean; fields: FieldResponseInterface[] }) => {
    let itemObject = Project.createProject({
      id: item.id,
      isFavorite: item.isFavorite,
    }) as ProjectInterface;
    itemObject = transformFieldsToObject(item.fields, [], false, itemObject);
    tableRows.push(itemObject);
  });
  return {
    ...state,
    projects: action.payload.items,
    tableRows,
    totalItems,
  };
};
const handleGetMyProjectsSuccess = (state: ProjectState, action: AnyAction) => {
  const data = action.payload.items;
  const myTotalItems = action.payload.total;
  const myProjectsTableRows: ProjectInterface[] = [];
  data.forEach((item: { id: string; isFavorite: boolean; fields: FieldResponseInterface[] }) => {
    let itemObject = Project.createProject({
      id: item.id,
      isFavorite: item.isFavorite,
    }) as ProjectInterface;
    itemObject = transformFieldsToObject(item.fields, [], false, itemObject);
    myProjectsTableRows.push(itemObject);
  });
  return {
    ...state,
    myProjects: action.payload,
    myProjectsTableRows,
    myTotalItems,
  };
};

const handleCreateProjectSuccess = (state: ProjectState, action: AnyAction) => {
  const { tableRows, myProjectsTableRows, myTotalItems } = state;
  let newProject = Project.createProject({ id: action.payload.id }) as ProjectInterface;
  newProject = transformFieldsToObject(action.payload.fields, [], false, newProject);
  tableRows.unshift(newProject);
  myProjectsTableRows.unshift(newProject);
  return {
    ...state,
    tableRows,
    myProjectsTableRows,
    myTotalItems: myTotalItems + 1,
  };
};

const handleUpdateProjectSuccess = (state: ProjectState, action: AnyAction) => {
  const { tableRows } = state;
  let updatedProject = Project.createProject({ id: action.payload.id }) as ProjectInterface;
  updatedProject = transformFieldsToObject(action.payload.fields, [], false, updatedProject);

  const projectIndex = tableRows.findIndex(
    (project: ProjectInterface) => project.id === updatedProject.id,
  );
  if (projectIndex !== -1) {
    tableRows[projectIndex] = updatedProject;
  }
  return {
    ...state,
    tableRows,
  };
};

const handleSetProjectHeaders = (state: ProjectState, action: AnyAction) => {
  const tableHeadCellsNew = setTableHeaders('project', action.payload);
  return {
    ...state,
    tableHeadCells: tableHeadCellsNew,
  };
};

const handleSetFavorite = (state: ProjectState, action: AnyAction) => {
  const { tableRows, myProjectsTableRows, project } = state;
  const { isMyData, payload, callback } = action;
  const { myTableRows, singleObject, allTableRows } = setFavorite(
    isMyData,
    myProjectsTableRows,
    payload.id,
    tableRows,
    project,
  );
  callback();
  return {
    ...state,
    tableRows: [...allTableRows],
    myProjectsTableRows: [...myTableRows],
    project: {
      ...singleObject,
    },
  };
};

const projectReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case projectActionTypes.GET_PROJECT_SUCCESS:
      return handleGetProjectSuccess(state, action);
    case projectActionTypes.GET_PROJECTS_SUCCESS:
      return handleGetProjectsSuccess(state, action);
    case projectActionTypes.GET_MY_PROJECTS_SUCCESS:
      return handleGetMyProjectsSuccess(state, action);
    case projectActionTypes.CREATE_PROJECT_SUCCESS:
      return handleCreateProjectSuccess(state, action);
    case projectActionTypes.UPDATE_PROJECT_SUCCESS:
      return handleUpdateProjectSuccess(state, action);
    case projectActionTypes.SET_PROJECT_TABLE_HEADERS:
      return handleSetProjectHeaders(state, action);
    case favoriteActionTypes.SET_FAVORITE:
      return handleSetFavorite(state, action);
    default:
      return state;
  }
};

export default projectReducer;
