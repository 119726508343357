import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import './Dashboard.scss';
import { GlobalState } from '../../core/models/state/globalState';
import { Order, OrderByInterface, getOrderAndOrderBy } from '../../core/models/order';

import {
  filterFavoriteDocuments,
  filterFavoriteProjects,
  filterFavoriteTemplates,
  setFavorite,
} from '../../state/actions/favorite';
import { FavoriteObjectTypes } from '../../core/models/favorites/favoriteState';
import DashboardTable from '../../components/DashboardTable/DashboardTable';
import TabPanel from '../../components/DashboardTable/TabPanel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IATabs from '../../components/Tabs/Tabs';
import { DEFAULT_ORDER, memberHeadCell } from '../../constants';

export const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [projectOrderBy, setProjectOrderBy] = useState<OrderByInterface>(DEFAULT_ORDER);
  const [projectOrder, setProjectOrder] = useState<Order>('desc');
  const [wdOrderBy, setWdOrderBy] = useState<OrderByInterface>(DEFAULT_ORDER);
  const [wdOrder, setWdOrder] = useState<Order>('desc');
  const [templateOrderBy, setTemplateOrderBy] = useState<OrderByInterface>(DEFAULT_ORDER);
  const [templateOrder, setTemplateOrder] = useState<Order>('desc');

  const [projectPage, setProjectPage] = useState<number>(1);
  const [wdPage, setWdPage] = useState<number>(1);
  const [templatePage, setTemplatePage] = useState<number>(1);
  const [value, setValue] = useState(0);

  const [alertText, setAlertText] = useState<string>('');

  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const { tableHeadCells: tableHeadCellsWorkingDocument } = useSelector(
    (state: GlobalState) => state.workingDocument,
  );
  const { tableHeadCells: tableHeadCellsTemplate } = useSelector(
    (state: GlobalState) => state.template,
  );

  const {
    projects,
    favoriteProjects,
    documents,
    favoriteDocuments,
    templates,
    favoriteTemplates,
    favoriteProjectsLoading,
    favoriteDocumentsLoading,
    favoriteTemplatesLoading,
  } = useSelector((state: GlobalState) => state.favorite);
  const {
    document: { fields: fieldsWorkingDocument },
    template: { fields: fieldsTemplate },
  } = useSelector((state: GlobalState) => state.system);

  const { projectCategories, documentCategories, templateCategories, values, filtersApplied } =
    useSelector((state: GlobalState) => state.filter);
  const { projectFilters, documentFilters, templateFilters } = filtersApplied;
  const { user } = useSelector((state: GlobalState) => state.user);
  const isFiltersApplied = () => {
    return (
      !!projectFilters.length ||
      !!documentFilters.length ||
      !!templateFilters.length ||
      !!searchQuery
    );
  };

  const getProjectData = () => {
    const filterFieldValue = [...projectFilters];
    if (searchQuery.length > 0 && !filterFieldValue.find((item) => item.id === 'FTITLE')) {
      filterFieldValue.push({
        id: 'FTITLE',
        type: 'string',
        operator: 'Like',
        value: [`%${searchQuery}%`],
      });
    }
    dispatch(filterFavoriteProjects(filterFieldValue));
  };

  useEffect(() => {
    if (value === 0) {
      getProjectData();
    }
  }, [value, filtersApplied, searchQuery, favoriteProjectsLoading]);

  const getWDData = () => {
    const filterFieldValue = [...documentFilters];
    if (searchQuery.length > 0 && !filterFieldValue.find((item) => item.id === 'FTITLE')) {
      filterFieldValue.push({
        id: 'FTITLE',
        type: 'string',
        operator: 'Like',
        value: [`%${searchQuery}%`],
      });
    }
    dispatch(
      filterFavoriteDocuments({
        filterFields: filterFieldValue,
        order: wdOrder,
        orderBy: wdOrderBy,
      }),
    );
  };

  useEffect(() => {
    if (value === 1) {
      getWDData();
    }
  }, [value, filtersApplied, searchQuery, wdOrderBy, favoriteDocumentsLoading]);

  const getTemplateData = () => {
    const filterFieldValue = [...templateFilters];
    if (searchQuery.length > 0 && !filterFieldValue.find((item) => item.id === 'FTITLE')) {
      filterFieldValue.push({
        id: 'FTITLE',
        type: 'string',
        operator: 'Like',
        value: [`%${searchQuery}%`],
      });
    }
    dispatch(
      filterFavoriteTemplates({
        filterFields: filterFieldValue,
        order: templateOrder,
        orderBy: templateOrderBy,
      }),
    );
  };

  useEffect(() => {
    if (value === 2) {
      getTemplateData();
    }
  }, [value, filtersApplied, searchQuery, templateOrderBy, favoriteTemplatesLoading]);

  const handleSort = (id: string, tableName: string) => {
    switch (tableName) {
      case 'workspace': {
        setProjectOrder(getOrderAndOrderBy(id, projectOrder, projectOrderBy).order);
        setProjectOrderBy(getOrderAndOrderBy(id, projectOrder, projectOrderBy).orderBy);
        break;
      }
      case 'document': {
        setWdOrder(getOrderAndOrderBy(id, wdOrder, wdOrderBy).order);
        setWdOrderBy(getOrderAndOrderBy(id, wdOrder, wdOrderBy).orderBy);
        break;
      }
      case 'template': {
        setTemplateOrder(getOrderAndOrderBy(id, templateOrder, templateOrderBy).order);
        setTemplateOrderBy(getOrderAndOrderBy(id, templateOrder, templateOrderBy).orderBy);
        break;
      }
    }
  };

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const handleFavoriteClick = (id: string, table: FavoriteObjectTypes) => {
    if (table === 'projects') {
      const project = favoriteProjects.find((item) => item.id === id);
      if (project) {
        dispatch(
          setFavorite(
            () => {
              setAlertText(`Project ${project.FTITLE ?? ''} is removed from favorites`);
            },
            'projects',
            project,
          ),
        );
      }
    } else if (table === 'templates') {
      const template = favoriteTemplates.find((item) => item.id === id);
      if (template) {
        dispatch(
          setFavorite(
            () => {
              setAlertText(`Template ${template.FTITLE ?? ''} is removed from favorites`);
            },
            'templates',
            template,
          ),
        );
      }
    } else if (table === 'documents') {
      const document = favoriteDocuments.find((item) => item.id === id);
      if (document) {
        dispatch(
          setFavorite(
            () => {
              setAlertText(`Document ${document.FTITLE ?? ''} is removed from favorites`);
            },
            'documents',
            document,
          ),
        );
      }
    }
  };

  const headCellsWd = tableHeadCellsWorkingDocument.map((cell) => {
    const field = fieldsWorkingDocument.find((item) => item.id === cell.id);
    const label = cell.id === memberHeadCell.id ? memberHeadCell.label : field?.label;
    return {
      id: cell.id,
      label: label ?? '',
      isSortable: field?.flags?.isSortable,
    };
  });

  const headCellsTemplate = tableHeadCellsTemplate.map((cell) => {
    const field = fieldsTemplate.find((item) => item.id === cell.id);
    const label = cell.id === memberHeadCell.id ? memberHeadCell.label : field?.label;
    return {
      id: cell.id,
      label: label ?? '',
      isSortable: field?.flags?.isSortable,
    };
  });

  const pageSize = value === 0 ? 9 : 10;

  return (
    <div className='dashboard' data-testid='dashboard'>
      <div className='dashboard-title' data-testid='dashboard-title'>
        <div>Hello IAP User!</div>
        <div>Here is the personalised IAP overview</div>
      </div>
      <div className='page-content'>
        <div className='table-title' data-testid='table-title'>
          Favourites
        </div>
        <IATabs
          tabs={[
            { label: 'Workspaces', count: favoriteProjects.length ?? 0 },
            { label: 'Documents', count: favoriteDocuments.length ?? 0 },
            { label: 'Templates', count: favoriteTemplates.length ?? 0 },
          ]}
          handleTabChange={handleChange}
        />
        <TabPanel index={0} value={value}>
          <DashboardTable
            paginationCount={pageSize < projects.length ? pageSize : projects.length}
            page={projectPage}
            pageChangeHandler={(page: number) => {
              setProjectPage(page);
            }}
            totalItems={favoriteProjects.length}
            loading={favoriteProjectsLoading}
            tableRows={favoriteProjects}
            headCells={[]}
            navigate={navigate}
            editorPath='/layout/workspaces'
            settingPath='/layout/workspaces'
            name='workspace'
            handleSort={handleSort}
            order={projectOrder}
            orderBy={projectOrderBy}
            handleFavoriteClick={(id: string) => handleFavoriteClick(id, 'projects')}
            categories={[...projectCategories]}
            categoryValues={[...values]}
            isFiltersApplied={isFiltersApplied}
            setSearchQuery={setSearchQuery}
            setAnchorEl={setAnchorEl}
            anchorEl={anchorEl}
            filterType='projectFilters'
            currentUser={user}
          />
        </TabPanel>
        <TabPanel index={1} value={value}>
          <DashboardTable
            paginationCount={pageSize < documents.length ? pageSize : documents.length}
            page={wdPage}
            pageChangeHandler={(page: number) => {
              setWdPage(page);
            }}
            totalItems={favoriteDocuments.length}
            loading={favoriteDocumentsLoading}
            tableRows={favoriteDocuments}
            headCells={headCellsWd}
            navigate={navigate}
            editorPath='/layout/edit/document'
            settingPath='/layout/working-documents'
            name='document'
            handleSort={handleSort}
            order={wdOrder}
            orderBy={wdOrderBy}
            handleFavoriteClick={(id: string) => handleFavoriteClick(id, 'documents')}
            fields={fieldsWorkingDocument.filter((field) => !field.id.startsWith('FCUS'))}
            categories={[...documentCategories]}
            categoryValues={[...values]}
            isFiltersApplied={isFiltersApplied}
            setSearchQuery={setSearchQuery}
            filterType='documentFilters'
            currentUser={user}
          />
        </TabPanel>
        <TabPanel index={2} value={value}>
          <DashboardTable
            paginationCount={pageSize < templates.length ? pageSize : templates.length}
            page={templatePage}
            pageChangeHandler={(page: number) => {
              setTemplatePage(page);
            }}
            totalItems={favoriteTemplates.length}
            loading={favoriteTemplatesLoading}
            tableRows={favoriteTemplates}
            headCells={headCellsTemplate}
            navigate={navigate}
            editorPath='/layout/edit/template'
            settingPath='/layout/templates'
            name='template'
            handleSort={handleSort}
            order={templateOrder}
            orderBy={templateOrderBy}
            handleFavoriteClick={(id: string) => handleFavoriteClick(id, 'templates')}
            fields={fieldsTemplate.filter(
              (field) =>
                field.id !== 'FIAPTEMPLATEOWNER' &&
                field.id !== 'FIAPTEMPLATEMANAGER' &&
                !field.id.includes('FCUSTCOUNTRY'),
            )}
            categories={[...templateCategories]}
            categoryValues={[...values]}
            isFiltersApplied={isFiltersApplied}
            setSearchQuery={setSearchQuery}
            filterType='templateFilters'
            currentUser={user}
          />
        </TabPanel>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!alertText}
        autoHideDuration={5000}
        onClose={() => setAlertText('')}
      >
        <Alert
          onClose={() => setAlertText('')}
          severity='success'
          variant='filled'
          sx={{ width: '100%' }}
        >
          {alertText}
        </Alert>
      </Snackbar>
    </div>
  );
};
