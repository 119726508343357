import { FilterIterface } from '../models/filter/filter';
import { OrderByInterface } from '../models/order';
import api from './index';

export async function getOutputs(
  filterFields: FilterIterface[],
  requestedFields: string[],
  page: number,
  size: number,
  orderByField?: OrderByInterface,
) {
  let requestedFieldIdsQuery = '';
  requestedFields.forEach((field) => {
    requestedFieldIdsQuery += `requestedFieldIds=${field}&`;
  });
  const result = await api.post(`/dashboard/output/list?${requestedFieldIdsQuery.slice(0, -1)}`, {
    filterFields,
    requestedFields,
    page,
    size,
    orderByField,
  });
  return result.data;
}

export async function getOutputsFromTransferService(
  filterFields: FilterIterface[],
  requestedFields: string[],
  page: number,
  size: number,
  orderByField?: OrderByInterface,
) {
  const result = await api.post('/proxy/data-transfer/api/output/getAllWithStatus', {
    filterFields,
    requestedFields,
    page,
    size,
    orderByField,
  });
  return result.data;
}

export async function downloadOutputApi(id: string) {
  await api.get(`/dashboard/output/${id}/download`);
  const href = `${process.env.REACT_APP_URL}/IAP/api/dashboard/output/${id}/download`;

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
  return '';
}
