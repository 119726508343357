import { templateActionTypes } from '../actions/template';
import { Template } from '../../core/models/template/template';
import { TemplateInterface } from '../../core/models/template/templateInterface';
import { TemplateState } from '../../core/models/template/templateState';
import { AnyAction } from 'redux';
import { FieldResponseInterface } from '../../core/models/system/systemDefinition';
import { defaultTableHeadCells } from '../../constants';
import { favoriteActionTypes } from '../actions/favorite';
import { transformFieldsToObject } from '../../core/services/apiTranformations';
import { setFavorite, setTableHeaders } from '../../core/services/helpers';

export const initialState: TemplateState = {
  template: {},
  templateObject: {
    id: '',
    fields: [],
  },
  templateResponse: [],
  templates: [],
  tableHeadCells: defaultTableHeadCells.template,
  tableRows: [],
  totalItems: 0,
  myTotalItems: 0,
  myTemplates: [],
  myTemplatesTableRows: [],
};

const handleGetTemplateSuccess = (state: TemplateState, action: AnyAction) => {
  const data = action.payload;
  let template: TemplateInterface = { id: data.id, isFavorite: data.isFavorite };
  const templateResponse = [...data.fields];
  template = transformFieldsToObject(data.fields, [], false, template);
  return {
    ...state,
    templateResponse,
    template,
    templateObject: data,
  };
};

const handleGetTemplatesSuccess = (state: TemplateState, action: AnyAction) => {
  const data = action.payload.items;
  const totalItems = action.payload.total;
  const tableRows: TemplateInterface[] = [];
  data.forEach((item: { id: string; isFavorite: boolean; fields: FieldResponseInterface[] }) => {
    let itemObject: TemplateInterface = Template.createTemplate({
      id: item.id,
      isFavorite: item.isFavorite,
    }) as TemplateInterface;
    itemObject = transformFieldsToObject(item.fields, [], false, itemObject);
    itemObject.MEMBERS = String(
      (itemObject.FIAPAUTHORLIST ? (itemObject.FIAPAUTHORLIST as string).split(',').length : 0) +
        (itemObject.FIAPREVIEWER ? (itemObject.FIAPREVIEWER as string).split(',').length : 0),
    );
    tableRows.push(itemObject);
  });
  return {
    ...state,
    templates: action.payload,
    tableRows,
    totalItems,
  };
};
const handleGetMyTemplatesSuccess = (state: TemplateState, action: AnyAction) => {
  const data = action.payload.items;
  const myTotalItems = action.payload.total;
  const myTemplatesTableRows: TemplateInterface[] = [];
  data.forEach((item: { id: string; isFavorite: boolean; fields: FieldResponseInterface[] }) => {
    let itemObject: TemplateInterface = Template.createTemplate({
      id: item.id,
      isFavorite: item.isFavorite,
    }) as TemplateInterface;
    itemObject = transformFieldsToObject(item.fields, [], false, itemObject);
    itemObject.MEMBERS = String(
      (itemObject.FIAPAUTHORLIST ? (itemObject.FIAPAUTHORLIST as string).split(',').length : 0) +
        (itemObject.FIAPREVIEWER ? (itemObject.FIAPREVIEWER as string).split(',').length : 0),
    );
    myTemplatesTableRows.push(itemObject);
  });
  return {
    ...state,
    myTemplates: action.payload,
    myTemplatesTableRows,
    myTotalItems,
  };
};

const handleCreateTemplateSuccess = (state: TemplateState, action: AnyAction) => {
  const { tableRows, myTemplatesTableRows, myTotalItems } = state;
  let newTemplate = Template.createTemplate({ id: action.payload.id }) as TemplateInterface;
  newTemplate = transformFieldsToObject(action.payload.fields, [], false, newTemplate);
  tableRows.unshift(newTemplate);
  myTemplatesTableRows.unshift(newTemplate);
  return {
    ...state,
    tableRows,
    myTemplatesTableRows,
    myTotalItems: myTotalItems + 1,
  };
};

const handleUpdateTemplateSuccess = (state: TemplateState, action: AnyAction) => {
  const { tableRows } = state;
  const { id, fields } = action.payload;
  let updatedTemplate = Template.createTemplate({
    id,
  }) as TemplateInterface;
  updatedTemplate = transformFieldsToObject(fields, [], false, updatedTemplate);
  const templateIndex = tableRows.findIndex(
    (template: TemplateInterface) => template.id === updatedTemplate.id,
  );
  if (templateIndex !== -1) {
    tableRows[templateIndex] = updatedTemplate;
  }
  return {
    ...state,
    tableRows,
  };
};

const handleSetTemplateHeaders = (state: TemplateState, action: AnyAction) => {
  const tableHeadCellsNew = setTableHeaders('template', action.payload);
  return {
    ...state,
    tableHeadCells: tableHeadCellsNew,
  };
};

const handleSetFavorite = (state: TemplateState, action: AnyAction) => {
  const { tableRows, myTemplatesTableRows, template } = state;
  const { isMyData, payload, callback } = action;
  const { myTableRows, singleObject, allTableRows } = setFavorite(
    isMyData,
    myTemplatesTableRows,
    payload.id,
    tableRows,
    template,
  );
  callback();
  return {
    ...state,
    tableRows: [...allTableRows],
    myTemplatesTableRows: [...myTableRows],
    template: {
      ...singleObject,
    },
  };
};

const templateReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case templateActionTypes.GET_TEMPLATE_SUCCESS:
      return handleGetTemplateSuccess(state, action);
    case templateActionTypes.GET_TEMPLATES_SUCCESS:
      return handleGetTemplatesSuccess(state, action);
    case templateActionTypes.GET_MY_TEMPLATES_SUCCESS:
      return handleGetMyTemplatesSuccess(state, action);
    case templateActionTypes.CREATE_TEMPLATE_SUCCESS:
      return handleCreateTemplateSuccess(state, action);
    case templateActionTypes.UPDATE_TEMPLATE_SUCCESS:
      return handleUpdateTemplateSuccess(state, action);
    case templateActionTypes.SET_TEMPLATE_TABLE_HEADERS:
      return handleSetTemplateHeaders(state, action);
    case favoriteActionTypes.SET_FAVORITE:
      return handleSetFavorite(state, action);
    default:
      return state;
  }
};

export default templateReducer;
