import { Grid } from '@mui/material';
import IASelect from '../Select/Select';
import { getLovValues } from '../../core/services/helpers';
import { IAInput } from '../Input/Input';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../core/models/state/globalState';
import { IdLabel, SelectValue } from '../../core/models/global';

interface EventsPropsInterface {
  index: number;
  isReview?: boolean;
  handleChange: (key: 'eventType' | 'event', values: SelectValue) => void;
  selectedEventType: IdLabel[];
  selectedEvent: IdLabel[];
  events: IdLabel[];
  changeCategories: string[];
  disableAll?: boolean;
}
const EventDetails = (props: EventsPropsInterface) => {
  const {
    isReview,
    handleChange,
    index,
    selectedEventType,
    selectedEvent,
    events,
    changeCategories,
    disableAll = false,
  } = props;
  const {
    project: { lovs },
  } = useSelector((state: GlobalState) => state.system);
  if (isReview) {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field'>
              <div className='label'>Event Type</div>
              <div className='review-value'>{selectedEventType[index]?.label ?? '-'}</div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field'>
              <div className='label'>Event</div>
              <div className='review-value'>{selectedEvent[index]?.label ?? '-'}</div>
            </div>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <div className='form-field'>
            <div className='label'>Event Change Category</div>
            <div className='review-value'>{changeCategories?.[index] ?? '-'}</div>
          </div>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field'>
            <IASelect
              data-testid='Event Type'
              label='Event Type'
              placeholder='Select event type'
              options={getLovValues(lovs, 'DCUSTEVENTTYPE') || []}
              onChange={(_event, value) => {
                handleChange('eventType', value as SelectValue);
              }}
              value={selectedEventType[index]}
              disabled={disableAll}
              error={false}
            />
          </div>
        </Grid>
        <Grid item xs={8}>
          <div className='form-field'>
            <IASelect
              data-testid='Event'
              label='Event'
              placeholder='Select event'
              options={events || []}
              onChange={(_event, value) => {
                handleChange('event', value as SelectValue);
              }}
              value={selectedEvent[index]}
              disabled={disableAll}
              error={false}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field'>
            <IAInput
              inputLabel='Change Category'
              placeholder='Change Category'
              value={changeCategories[index]}
              disabled
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default EventDetails;
