import { FilterIterface } from '../../core/models/filter/filter';
import { OrderByInterface } from '../../core/models/order';
import {
  TemplateInterface,
  TemplateRequestBody,
} from '../../core/models/template/templateInterface';
import {
  DefinitioniInterface,
  FieldRequestInterface,
  ListResponse,
} from '../../core/models/system/systemDefinition';
import { RequiredMetadata } from '../../core/models/workingDocument/requiredMetadata';

export const templateActionTypes = {
  GET_TEMPLATE: 'GET_TEMPLATE',
  GET_TEMPLATE_SUCCESS: 'GET_TEMPLATE_SUCCESS',
  GET_TEMPLATES: 'GET_TEMPLATES',
  GET_TEMPLATES_SUCCESS: 'GET_TEMPLATES_SUCCESS',
  GET_MY_TEMPLATES: 'GET_MY_TEMPLATES',
  GET_MY_TEMPLATES_SUCCESS: 'GET_MY_TEMPLATES_SUCCESS',
  CREATE_TEMPLATE: 'CREATE_TEMPLATE',
  CREATE_TEMPLATE_SUCCESS: 'CREATE_TEMPLATE_SUCCESS',
  UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
  UPDATE_TEMPLATE_SUCCESS: 'UPDATE_TEMPLATE_SUCCESS',
  DELETE_TEMPLATE: 'DELETE_TEMPLATE',
  DELETE_TEMPLATE_SUCCESS: 'DELETE_TEMPLATE_SUCCESS',
  SET_TEMPLATE_VALUES: 'SET_TEMPLATE_VALUES',
  SET_NEW_TEMPLATE: 'SET_NEW_TEMPLATE',
  SET_TEMPLATE_TABLE_HEADERS: 'SET_TEMPLATE_TABLE_HEADERS',
  SET_FAVORITE_TEMPLATES: 'SET_FAVORITE_TEMPLATES',
  GET_TEMPLATE_METADATA_VALUES: 'GET_TEMPLATE_METADATA_VALUES',
};

export const getTemplateById = (callback: (err: unknown) => void = () => {}, id: string) => ({
  payload: id,
  type: templateActionTypes.GET_TEMPLATE,
  callback,
});

export const getTemplateSuccess = (payload: TemplateInterface) => ({
  type: templateActionTypes.GET_TEMPLATE_SUCCESS,
  payload,
});

export const getTemplates = (
  callback: (err: unknown) => void = () => {},
  payload: {
    page: number | string;
    size: number | string;
    filterFields: FilterIterface[];
    orderBy?: OrderByInterface;
    allFavorites?: boolean;
  },
) => ({
  payload,
  type: templateActionTypes.GET_TEMPLATES,
  callback,
});

export const getTemplatesSuccess = (payload: ListResponse) => ({
  type: templateActionTypes.GET_TEMPLATES_SUCCESS,
  payload,
});
export const getMYTemplates = (
  callback: (err: unknown) => void = () => {},
  payload: {
    page: number | string;
    size: number | string;
    filterFields: FilterIterface[];
    orderBy?: OrderByInterface;
  },
) => ({
  payload,
  type: templateActionTypes.GET_MY_TEMPLATES,
  callback,
});

export const getMyTemplatesSuccess = (payload: ListResponse) => ({
  type: templateActionTypes.GET_MY_TEMPLATES_SUCCESS,
  payload,
});

export const createTemplate = (
  callback: (
    err: { response: { data: string } } | null,
    data: TemplateInterface,
  ) => void = () => {},
  payload: TemplateRequestBody,
) => ({
  payload,
  type: templateActionTypes.CREATE_TEMPLATE,
  callback,
});

export const createTemplateSuccess = (payload: TemplateInterface) => ({
  type: templateActionTypes.CREATE_TEMPLATE_SUCCESS,
  payload,
});

export const updateTemplate = (
  callback: (err: unknown) => void = () => {},
  payload: { body: TemplateInterface; id: string },
) => ({
  payload,
  type: templateActionTypes.UPDATE_TEMPLATE,
  callback,
});

export const updateTemplateSuccess = (payload: TemplateInterface) => ({
  type: templateActionTypes.UPDATE_TEMPLATE_SUCCESS,
  payload,
});

export const deleteTemplateById = (id: string, callback: () => void = () => {}) => ({
  payload: id,
  type: templateActionTypes.DELETE_TEMPLATE,
  callback,
});

export const deleteTemplateSuccess = (payload: TemplateInterface) => ({
  type: templateActionTypes.DELETE_TEMPLATE_SUCCESS,
  payload,
});

export const handleTemplateHeaderCells = (payload: DefinitioniInterface[]) => ({
  type: templateActionTypes.SET_TEMPLATE_TABLE_HEADERS,
  payload,
});

export const setFavoriteTemplates = (payload: ListResponse) => ({
  type: templateActionTypes.SET_FAVORITE_TEMPLATES,
  payload,
});

export const getTemplateMetadataValues = (
  callback: (
    err: { response: { data: string } } | null,
    data: RequiredMetadata[],
  ) => void = () => {},
  payload: {
    fields: FieldRequestInterface[];
  },
) => ({
  payload,
  type: templateActionTypes.GET_TEMPLATE_METADATA_VALUES,
  callback,
});
