import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ThreeDots(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <g id='Outline/more_vertical'>
        <path
          id='Union'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.02 7C11.1916 7 10.52 6.32843 10.52 5.5C10.52 4.67157 11.1916 4 12.02 4C12.8484 4 13.52 4.67157 13.52 5.5C13.52 6.32843 12.8484 7 12.02 7ZM10.56 12C10.56 12.8284 11.2316 13.5 12.06 13.5C12.8885 13.5 13.56 12.8284 13.56 12C13.56 11.1716 12.8885 10.5 12.06 10.5C11.2316 10.5 10.56 11.1716 10.56 12ZM10.52 18.5C10.52 19.3284 11.1916 20 12.02 20C12.8484 20 13.52 19.3284 13.52 18.5C13.52 17.6716 12.8484 17 12.02 17C11.1916 17 10.52 17.6716 10.52 18.5Z'
          fill='black'
        />
      </g>
    </SvgIcon>
  );
}
