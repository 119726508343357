import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function VideoArrow(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='76' height='76' viewBox='0 0 76 76'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M37.9999 6.33301C20.5109 6.33301 6.33325 20.5107 6.33325 37.9997C6.33325 55.4887 20.5109 69.6663 37.9999 69.6663C55.4889 69.6663 69.6666 55.4887 69.6666 37.9997C69.6666 20.5107 55.4889 6.33301 37.9999 6.33301ZM37.9999 12.6663C51.9911 12.6663 63.3333 24.0085 63.3333 37.9997C63.3333 51.9909 51.9911 63.333 37.9999 63.333C24.0087 63.333 12.6666 51.9909 12.6666 37.9997C12.6823 24.015 24.0152 12.682 37.9999 12.6663ZM50.6666 37.9997L31.6666 50.6663V25.333L50.6666 37.9997Z'
        fill='white'
      />
    </SvgIcon>
  );
}
