import { OutlinedInputProps } from '@mui/material';
import { Search } from '@mui/icons-material';
import { IAInput } from '../Input/Input';
import { useEffect, useState } from 'react';
import './Search.scss';

export interface SearchPropsTypes extends OutlinedInputProps {
  handleSearch?: (value: string) => void;
}

const IASearch = (props: SearchPropsTypes) => {
  const { handleSearch = () => {}, id, ...rest } = props;
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleSearch(inputValue);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [inputValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  return (
    <div className='ia-search-input' data-testid='ia-search-input'>
      <IAInput
        icon={<Search />}
        placeholder='Search...'
        id={id}
        onChange={handleInputChange}
        value={inputValue}
        {...rest}
      />
    </div>
  );
};

export default IASearch;
