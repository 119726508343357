import './ReviewActionTitle.scss';
import Pencil from '../icons/Pencil';

interface ReviewActionTitlePropes {
  onEdit?: () => void;
  title?: string;
  isReview?: boolean;
  allowEdit?: boolean;
}

function ReviewActionTitle(props: ReviewActionTitlePropes) {
  const { onEdit, title, isReview, allowEdit = !!onEdit, ...rest } = props;
  return (
    <div className='review-container'>
      <div className='review-action-title' {...rest}>
        {title && (
          <div className='action-title-text' data-testid='action-title'>
            {title}
          </div>
        )}
        {allowEdit && (
          <div style={{ display: 'flex' }}>
            <Pencil className='action-title-edit-icon' onClick={onEdit} />
            <div className='action-title-edit-text' onClick={onEdit}>
              Edit details
            </div>
          </div>
        )}
      </div>
      {isReview && <hr className='divider' />}
    </div>
  );
}

export default ReviewActionTitle;
