export interface PhoenixFilterData {
  dataId: string;
  name: string;
  parentId: string;
}

export type PhoenixFilterType = 'ownerships' | 'applicabilities';
export type PhoenixFilterSubType = 'types' | 'entities' | 'departments';

export type PhoenixFilterSubData = {
  owningEntity: string;
  owningDepartment: string;
  applicableEntities: string[];
};

export interface TransferDocumentMetadata {
  documentNumber: string;
  documentTitle: string;
  isDocumentInEnglish: boolean;
  titleInEnglish?: string;
  languages?: string[];
  type: string;
  subtype: string;
  generalScope: string[];
  publishToPortal: string;
  securityScope: string;
  product: string[];
  productStage: string[];
  roformisNumbers: string[];
  materials: string[];
  owningEntityType: string;
  owningEntity: string;
  owningDepartment: string;
  applicableEntityTypes: string[];
  applicableEntities: string[];
  additionalInformation: string;
  productFamilyCode?: string;
  productFamilyName?: string;
}

export class DocumentTransferMetadata implements TransferDocumentMetadata {
  securityScope = 'Open';

  isDocumentInEnglish = true;

  titleInEnglish? = '';

  languages?: string[] = [];

  documentNumber = '';

  documentTitle = '';

  type = 'Records';

  subtype = 'Validation';

  generalScope: string[] = ['GxP'];

  publishToPortal = 'Not Published';

  product: string[] = [];

  productStage: string[] = [];

  roformisNumbers: string[] = [];

  materials: string[] = [];

  owningEntityType = '';

  owningEntity = '';

  owningDepartment = '';

  applicableEntityTypes: string[] = [];

  applicableEntities: string[] = [];

  additionalInformation = '';

  productFamilyCode? = '';

  productFamilyName? = '';
}

export const createDocumentMetadataObject = (data: DocumentTransferMetadata) => {
  return {
    documentNumber: data.documentNumber,
    documentTitle: data.documentTitle,
    isDocumentInEnglish: data.isDocumentInEnglish,
    type: data.type,
    subtype: data.subtype,
    generalScope: data.generalScope,
    publishToPortal: data.publishToPortal,
    productFamilyName: data.productFamilyName,
    productFamilyCode: data.productFamilyCode,
    product: data.product,
    productStage: data.productStage,
    materials: data.materials,
    roformisNumbers: data.roformisNumbers,
    owningEntityType: data.owningEntityType,
    owningEntity: data.owningEntity,
    owningDepartment: data.owningDepartment,
    applicableEntityTypes: data.applicableEntityTypes,
    applicableEntities: data.applicableEntities,
    additionalInformation: data.additionalInformation,
  };
};
