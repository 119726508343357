import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DashboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 2L1 9V11.4L3 10.1V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V10.1L23 11.4V9L12 2ZM19 20H5V8.8L12 4.3L19 8.8V20Z'
        fill='#544F4F'
      />
    </SvgIcon>
  );
}
