import { Grid } from '@mui/material';
import { RIMDocumentMetadata } from '../../../core/models/transfer/rim';
import IASelect from '../../Select/Select';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../core/models/state/globalState';
import { IdLabel } from '../../../core/models/global';

interface PropsInterface {
  documentMetadata: RIMDocumentMetadata;
  emptyFields?: string[];
  handlePartialDetailsChange: (
    key: keyof RIMDocumentMetadata,
    value: string | string[] | IdLabel[],
  ) => void;
}

export const DMSDetailsRIM = (props: PropsInterface) => {
  const { documentMetadata, emptyFields = [], handlePartialDetailsChange } = props;
  const { excipients, excipientTypes, typeOfImp, clinicalStudies } = useSelector(
    (state: GlobalState) => state.transfer,
  );
  return (
    <div className='rim-details'>
      <div className='action-title' data-testid='action-title'>
        Enter RIM details
      </div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='excipient-type'>
            <IASelect
              label='Excipient type'
              placeholder='Excipient type'
              options={excipientTypes}
              onChange={(_event, value) => {
                handlePartialDetailsChange('excipientType', value as string);
              }}
              value={documentMetadata.excipientType}
              error={emptyFields.includes('excipientType')}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='excipient-name'>
            <IASelect
              label='Excipient Name'
              placeholder='Excipient Name'
              options={excipients}
              onChange={(_event, value) => {
                handlePartialDetailsChange('excipientName', value as string);
              }}
              value={documentMetadata.excipientName}
              error={emptyFields.includes('excipientName')}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div className='form-field' data-testid='type-of-imp'>
            <IASelect
              label='Type of IMP'
              placeholder='Type of IMP'
              options={typeOfImp}
              onChange={(_event, value) => {
                handlePartialDetailsChange('typeOfImp', value as string);
              }}
              value={documentMetadata.typeOfImp}
              error={emptyFields.includes('typeOfImp')}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field' data-testid='clinical-studies'>
            <IASelect
              label='Clinical Studies'
              placeholder='Clinical Studies'
              options={clinicalStudies}
              onChange={(_event, value) => {
                handlePartialDetailsChange('clinicalStudy', value as string);
              }}
              value={documentMetadata.clinicalStudy}
              error={emptyFields.includes('clinicalStudy')}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
