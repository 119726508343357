import { useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './Header.scss';
import RocheIcon from '../icons/RocheIcon';
import { Menu, Tooltip } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GlobalState } from '../../core/models/state/globalState';
import LanguageDialog from './LanguageDialog';
import { UserInteface } from '../../core/models/user/user';
import LogoFinal from '../../assets/svg/logo-blue.svg';
import { getInitials } from '../../core/services/userService';

interface HeaderInterface {
  user: UserInteface;
}

export const Header = (props: HeaderInterface) => {
  const { user } = props;
  const { id } = useParams();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [langDialogOpen, setLangDialogOpen] = useState<boolean>(false);
  const { lang } = useSelector((state: GlobalState) => state.user);
  const { workingDocument } = useSelector((state: GlobalState) => state.workingDocument);
  const { project } = useSelector((state: GlobalState) => state.project);
  const { template } = useSelector((state: GlobalState) => state.template);
  const isDocumentEditorOpen = location.pathname.includes('/layout/edit/document');
  const isTemplateEditorOpen = location.pathname.includes('/layout/edit/template');
  const isDataDashboardOpen = location.pathname.includes('/layout/data-dashboard');
  const isEditorOpen = isDocumentEditorOpen || isTemplateEditorOpen || isDataDashboardOpen;

  const openLangDialog = () => {
    setLangDialogOpen(true);
    setOpen(false);
  };

  const getEnv = () => {
    if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV.toLowerCase() === 'prod') {
      return '';
    }
    return `${process.env.REACT_APP_ENV[0].toUpperCase()}${process.env.REACT_APP_ENV.slice(1)}`;
  };
  const getLink = () => {
    if (
      isDocumentEditorOpen &&
      workingDocument.id === id &&
      workingDocument.FIAPPROJECTREFID === project.id
    ) {
      return (
        <>
          <Link to={`/layout/workspaces/${project.id}`}>{project.FTITLE}</Link>
          {!!project.FTITLE && <ArrowRightIcon />}
          <Link to={`/layout/working-documents/${id}`} style={{ fontSize: '16px' }}>
            {workingDocument.FTITLE} (v.{ workingDocument.VERSION })
          </Link>
        </>
      );
    } else if (isTemplateEditorOpen && template.id === id) {
      return <Link to={`/layout/templates/${id}`}>{template.FTITLE} (v.{template.VERSION})</Link>;
    } else if (isDataDashboardOpen && project.id === id) {
      return <Link to={`/layout/workspaces/${id}`}>{project.FTITLE}</Link>;
    } else {
      return '';
    }
  };
  return (
    <>
      <div className='header' data-testid='header'>
        <RocheIcon fill='#0B41CD' className='roche-icon' />
        <div className='divider'></div>
        {!isEditorOpen && (
          <>
            <div className='appLogo' data-testid='appLogo'>
              <Link to='/layout/dashboard'>
                <img
                  src={LogoFinal}
                  className={'logo-icon'}
                  alt='Logo'
                  style={{ transition: 'all 300ms ease-in-out' }}
                />
              </Link>
            </div>
            <div className='ia-title'>
              <Link to='/layout/dashboard'>Intelligent Authoring Platform (IAP)</Link>
            </div>
          </>
        )}
        <div className='doc-title'>{isEditorOpen && getLink()}</div>
        {user?.displayName && (
          <div className='profile-popup'>
            {!!getEnv() && <div className='environment'>{getEnv()}</div>}
            <Tooltip title={user.displayName} placement='bottom' arrow>
              <div className='user-info'>
                <span className='user-name'>{getInitials(user.displayName)}</span>
              </div>
            </Tooltip>
            <IconButton
              aria-label='user'
              className='logout-modal-btn'
              ref={anchorRef}
              size='medium'
              onClick={() => setOpen(!open)}
            >
              <MoreVertIcon style={{ fill: '#0066CC' }} />
            </IconButton>
            <div>
              <Menu
                disablePortal
                open={open}
                role={undefined}
                className='logout-modal'
                anchorEl={anchorRef.current}
                onClose={() => setOpen(false)}
                disableScrollLock
              >
                <MenuList autoFocusItem={open}>
                  <MenuItem className='menu-item' onClick={() => openLangDialog()}>
                    Change tutorial language ({lang})
                  </MenuItem>
                </MenuList>
                <MenuList autoFocusItem={open}>
                  <a
                    href={`${process.env.REACT_APP_URL}/IAP/authentication/logout?returnUrl=${process.env.REACT_APP_URL}/`}
                    rel='noreferrer'
                  >
                    <MenuItem className='menu-item'>Logout</MenuItem>
                  </a>
                </MenuList>
              </Menu>
            </div>
          </div>
        )}
      </div>
      <LanguageDialog lang={lang} open={langDialogOpen} onClose={() => setLangDialogOpen(false)} />
    </>
  );
};
