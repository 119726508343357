import { getUserProfileSuccess } from '../../state/actions/user';
import { store } from '../../state/store';
import { getUserInfo } from '../../core/api/user.api';

export const authLoader = async () => {
  let result = null;
  result = await getUserInfo();
  if (result) {
    store.dispatch(getUserProfileSuccess(result));
  }
  return result;
};

export const getInitials = (name: string) => {
  if (!name) {
    return '';
  }
  const splittedName = name.split(' ');
  let firstLastName;
  if (splittedName.length > 1) {
    firstLastName = [splittedName[0], splittedName[splittedName.length - 1]];
  } else {
    firstLastName = splittedName;
  }
  return firstLastName.map((value) => value.substring(0, 1).toUpperCase()).join('');
};
