import { all, spawn } from 'redux-saga/effects';
import userWatcher from './user';
import workingDocumentWatcher from './workingDocument';
import templateWatcher from './template';
import projectWatcher from './project';
import systemWatcher from './system';
import outputWatcher from './output';
import transferWatcher from './transfer';
import favoritesWatcher from './favorites';

export default function* rootSaga() {
  yield all([
    spawn(userWatcher),
    spawn(workingDocumentWatcher),
    spawn(templateWatcher),
    spawn(projectWatcher),
    spawn(systemWatcher),
    spawn(outputWatcher),
    spawn(transferWatcher),
    spawn(favoritesWatcher),
  ]);
}
