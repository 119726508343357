import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import {
  getWorkingDocumentById,
  getWorkingDocuments,
  createWorkingDocument,
  updateWorkingDocument,
  deleteWorkingDocumentById,
  getDocumentRequiredMetadata as getDocumentRequiredMetadataApi,
} from '../../core/api/workingDocument.api';
import { WorkingDocumentInterface } from '../../core/models/workingDocument/workingDocumentInterface';
import { FieldRequestInterface, ListResponse } from '../../core/models/system/systemDefinition';
import {
  getWorkingDocumentSuccess,
  getWorkingDocumentsSuccess,
  createWorkingDocumentSuccess,
  updateWorkingDocumentSuccess,
  deleteWorkingDocumentSuccess,
  workingDocumentActionTypes,
  getMyWorkingDocumentsSuccess,
  getDocumentRequiredMetadataSuccess,
} from '../actions/workingDocument';
import { getFavorites } from '../actions/favorite';
import { GlobalState } from '../../core/models/state/globalState';
import { systemActionTypes } from '../actions/system';
import { createFilterFields } from '../../core/models/filter/filter';
import { setDocumentCategories } from '../actions/filter';
import { SystemStateInterface } from '../../core/models/system/systemState';
import { FavoriteState } from '../../core/models/favorites/favoriteState';
import { WorkingDocument } from '../../core/models/workingDocument/workingDocument';
import { GetListActionInterface } from '../../core/models/global';
import {
  RequiredMetadataResponse,
  RequiredMetadata,
} from '../../core/models/workingDocument/requiredMetadata';

const getWorkingDocumentFields = (state: GlobalState) => state.system;
const getFavoriteValues = (state: GlobalState) => state.favorite;

export function* getWorkingDocument(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: string;
}) {
  try {
    const workingDocument: WorkingDocumentInterface = yield call(() =>
      getWorkingDocumentById(action.payload, []),
    );
    const favorites: FavoriteState = yield select(getFavoriteValues) || {};
    const favoriteDocuments = favorites.documents || [];
    if (workingDocument.id) {
      workingDocument.isFavorite = favoriteDocuments.includes(workingDocument.id as string);
    }
    yield put(getWorkingDocumentSuccess(workingDocument));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* getWorkingDocumentsByPagination(
  action: GetListActionInterface,
  successAction: (payload: ListResponse) => { type: string; payload: ListResponse },
) {
  try {
    const { document, project, template }: SystemStateInterface = yield select(
      getWorkingDocumentFields,
    ) || [];

    const { page, size, filterFields, orderBy, allFavorites } = action.payload;
    const workingDocuments: ListResponse = yield call(() =>
      getWorkingDocuments(
        createFilterFields(filterFields),
        Array.from(
          new Set([
            ...document.fields
              .filter((item) => !item.id.startsWith('FCUS'))
              .map((field) => field.id),
          ]),
        ),
        allFavorites ? 1 : page,
        allFavorites ? 1000 : size,
        orderBy,
      ),
    );
    yield put(
      setDocumentCategories(
        () => {},
        document.fields
          .filter(
            (field) =>
              field.type === 'lov' &&
              (!field.id.startsWith('FCUS') ||
                project.fields.find((projectField) => projectField.id === field.id) ||
                template.fields.find((templateField) => templateField.id === field.id)),
          )
          .map((field) => ({
            id: field.id,
            label: field.label,
            type: field.type,
          })),
      ),
    );
    yield put(getFavorites());
    const favorites: FavoriteState = yield select(getFavoriteValues) || {};
    const favoriteDocuments = favorites.documents || [];
    workingDocuments.items.forEach((item) => {
      if (favoriteDocuments.includes(item.id)) {
        item.isFavorite = true;
      }
    });
    if (allFavorites) {
      workingDocuments.items = workingDocuments.items.filter((item) => item.isFavorite);
      //   .splice((page - 1) * size, (page - 1) * size + size);
      // workingDocuments.total = favoriteDocuments.length;
      // workingDocuments.size = size;
    }
    yield put(successAction(workingDocuments));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* addWorkingDocument(action: {
  callback: (err: unknown, data?: WorkingDocumentInterface) => void;
  type: string;
  id: string;
  payload: { data: WorkingDocumentInterface; additionalMetadataValues: FieldRequestInterface[] };
}) {
  try {
    const { projectId, templateId } = action.payload.data;
    const data: WorkingDocumentInterface = {
      ...action.payload.data,
    };
    delete data.projectId;
    delete data.templateId;
    const {
      document: { fields: documentDefinitions, lovs },
    }: SystemStateInterface = yield select(getWorkingDocumentFields) || [];
    const newDocument = WorkingDocument.createWorkingDocumentObject(
      data,
      documentDefinitions,
      lovs,
    );
    const workingDocument: WorkingDocumentInterface = yield call(() =>
      createWorkingDocument(newDocument, projectId as string, templateId as string),
    );
    yield put(createWorkingDocumentSuccess(workingDocument));
    action.callback(null, workingDocument);
  } catch (error) {
    action.callback(error);
  }
}

export function* editWorkingDocument(action: {
  callback: (err: unknown) => void;
  type: string;
  id: string;
  payload: { body: WorkingDocumentInterface; id: string };
}) {
  try {
    const {
      document: { fields: documentDefinitions, lovs },
    }: SystemStateInterface = yield select(getWorkingDocumentFields) || [];
    const updateWorkingDocumentData = WorkingDocument.createWorkingDocumentObject(
      action.payload.body,
      documentDefinitions,
      lovs,
    );
    const document: WorkingDocumentInterface = yield call(() =>
      updateWorkingDocument(action.payload.id, {
        ...updateWorkingDocumentData,
        id: action.payload.id,
      }),
    );
    yield put(updateWorkingDocumentSuccess(document));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* deleteWorkingDocument(action: {
  callback: (err: unknown) => void;
  type: string;
  id: string;
  payload: string;
}) {
  try {
    const workingDocument: WorkingDocumentInterface = yield call(() =>
      deleteWorkingDocumentById(action.payload),
    );
    yield put(deleteWorkingDocumentSuccess(workingDocument));
  } catch (error) {
    action.callback(error);
  }
}

export function* getDocumentRequiredMetadata(action: {
  callback: (err: { response: { data: string } } | null, data?: RequiredMetadata[]) => void;
  type: string;
  payload: {
    projectId: string;
    templateId: string;
    fields: FieldRequestInterface[];
  };
}) {
  try {
    const { projectId, templateId, fields } = action.payload;
    const documentMetadata: RequiredMetadataResponse = yield call(() =>
      getDocumentRequiredMetadataApi(projectId, templateId, fields),
    );
    const { document }: SystemStateInterface = yield select(getWorkingDocumentFields) || [];
    documentMetadata.fields.forEach((field) => {
      const definition = document.fields.find((el) => el.id === field.id);
      if (definition) {
        field.definition = definition;
      }
    });
    yield put(getDocumentRequiredMetadataSuccess(documentMetadata));
    action.callback(null, documentMetadata.fields);
  } catch (error) {
    action.callback(error as { response: { data: string } });
  }
}

export default function* workingDocumentWatcher() {
  yield take(systemActionTypes.GET_SYSTEM_DEFINITIONS_SUCCESS);
  yield takeLatest(workingDocumentActionTypes.GET_WORKING_DOCUMENT, getWorkingDocument);
  yield takeLatest(
    workingDocumentActionTypes.GET_WORKING_DOCUMENTS,
    (action: GetListActionInterface) =>
      getWorkingDocumentsByPagination(action, getWorkingDocumentsSuccess),
  );
  yield takeLatest(
    workingDocumentActionTypes.GET_MY_WORKING_DOCUMENTS,
    (action: GetListActionInterface) =>
      getWorkingDocumentsByPagination(action, getMyWorkingDocumentsSuccess),
  );
  yield takeLatest(workingDocumentActionTypes.CREATE_WORKING_DOCUMENT, addWorkingDocument);
  yield takeLatest(workingDocumentActionTypes.UPDATE_WORKING_DOCUMENT, editWorkingDocument);
  yield takeLatest(
    workingDocumentActionTypes.GET_DOCUMENT_REQUIRED_METADATA,
    getDocumentRequiredMetadata,
  );
  yield takeLatest(workingDocumentActionTypes.DELETE_WORKING_DOCUMENT, deleteWorkingDocument);
}
