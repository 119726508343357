import { combineReducers } from 'redux';
import user from './user';
import filter from './filter';
import project from './project';
import template from './template';
import workingDocument from './workingDocument';
import system from './system';
import output from './output';
import favorite from './favorite';
import transfer from './transfer';

const reducers = combineReducers({
  user,
  filter,
  project,
  template,
  workingDocument,
  system,
  output,
  favorite,
  transfer,
});

export default reducers;
