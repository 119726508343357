import { FilterIterface } from '../../core/models/filter/filter';
import { OrderByInterface } from '../../core/models/order';
import { ListResponse } from '../../core/models/system/systemDefinition';

export const outputActionTypes = {
  GET_OUTPUTS: 'GET_OUTPUTS',
  GET_OUTPUTS_SUCCESS: 'GET_OUTPUTS_SUCCESS',
  DOWNLOAD_OUTPUT: 'DOWNLOAD_OUTPUT',
};

export const getOutputs = (
  callback: (err: unknown) => void = () => {},
  payload: {
    page: number;
    size: number;
    filterFields: FilterIterface[];
    orderBy?: OrderByInterface;
  },
) => ({
  payload,
  type: outputActionTypes.GET_OUTPUTS,
  callback,
});

export const getOutputsSuccess = (payload: ListResponse) => ({
  type: outputActionTypes.GET_OUTPUTS_SUCCESS,
  payload,
});
export const downloadOutput = (
  callback: (err: unknown) => void = () => {},
  payload: { id: string },
) => ({
  type: outputActionTypes.DOWNLOAD_OUTPUT,
  payload,
  callback,
});
