import { Box } from '@mui/material';
import './PageTitle.scss';
import { ReactNode } from 'react';

const PageTitle = (props: {
  icon?: ReactNode;
  name: string;
  isDashboardTable?: boolean;
  onIconClick?: () => void;
}) => {
  const { name, icon, onIconClick } = props;

  return (
    <div className='page-title' data-testid='page-title'>
      <Box display='flex' alignItems='center'>
        {!!icon && <span onClick={() => onIconClick && onIconClick()}>{icon}</span>}
        <span>{name}</span>
      </Box>
    </div>
  );
};

export default PageTitle;
