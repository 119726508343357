import './Home.scss';
import { useLoaderData, Navigate, useLocation } from 'react-router-dom';
import RocheIcon from '../../components/icons/RocheIcon';
import AliceCarousel, { DotsItem } from 'react-alice-carousel';
import { UserInteface } from '../../core/models/user/user';
import OnboardingFirst from '../../assets/svg/onboarding-first.svg';
import OnboardingSecond from '../../assets/svg/onboarding-second.svg';
import OnboardingThird from '../../assets/svg/onboarding-third.svg';
import LogoFinal from '../../assets/svg/logo-blue.svg';
import LearnMore from '../../components/icons/LearnMore';

export const Home = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pathName = queryParams.get('pathname');

  const user = useLoaderData() as UserInteface;
  if (user) {
    return <Navigate to={pathName ? `${pathName}${location.hash}` : '/layout'} />;
  }

  const renderDotsItem = (e: DotsItem) => (
    <div className={e.isActive ? 'carousel-dot-active' : 'carousel-dot'}></div>
  );

  const sliderItems = [
    <div key='first' className='img-container'>
      <div className='img-container-inner'>
        <img src={OnboardingFirst} />
        <div className='onboarding-text'>
          Effortlessly create and author data driven documents & templates
        </div>
      </div>
    </div>,
    <div key='second' className='img-container'>
      <div className='img-container-inner'>
        <img src={OnboardingSecond} />
        <div className='onboarding-text'>
          Access the potential to include interactively data into your reports by connecting your
          data products
        </div>
      </div>
    </div>,
    <div key='third' className='img-container'>
      <div className='img-container-inner'>
        <img src={OnboardingThird} />
        <div className='onboarding-text'>Learn how to make the most of our platform</div>
      </div>
    </div>,
  ];

  return (
    <div className='wrapper'>
      <div className='main' data-testid='main'>
        <div className='login-form-container' data-testid='login-form-container'>
          <RocheIcon fill='#0B41CD' width='86' height='45' className='roche-icon' />
          <div className='login-block'>
            <div className='appLogo-login' data-testid='appLogo-login'>
              <img
                src={LogoFinal}
                className={'logo-icon'}
                alt='Logo'
                style={{ transition: 'all 300ms ease-in-out' }}
              />
            </div>
            <div className='login-title' data-testid='login-title-1' style={{ marginTop: '20px' }}>
              Welcome to Intelligent
            </div>
            <div className='login-title' data-testid='login-title-2'>
              Authoring Platform
            </div>
            <div className='login-description' data-testid='login-description'>
              Unlock the power of content standardization and automation for high-quality reports
              and digital content.{' '}
              <a
                href='https://go.roche.com/iap-learn-more'
                target='_blank'
                rel='noreferrer'
                className='learn-more'
              >
                Learn more <LearnMore />
              </a>
            </div>
            <a
              href={`${process.env.REACT_APP_URL}/IAP/authentication/login?returnUrl=${
                process.env.REACT_APP_URL
              }${pathName || '/'}${location.hash}`}
              rel='noreferrer'
              className='login-btn'
            >
              Login with SSO
            </a>
          </div>
        </div>
        <div className='login-carousel-container' data-testid='login-carousel-container'>
          <AliceCarousel
            mouseTracking={false}
            items={sliderItems}
            disableButtonsControls
            renderDotsItem={renderDotsItem}
            autoPlay
            autoPlayInterval={10000}
            animationDuration={1000}
            infinite
            autoPlayStrategy='default'
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
