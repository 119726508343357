import { IdLabel } from '../global';

export interface RIMDocumentMetadata {
  ctdTitle: string;
  id: string;
  documentTitle: string;
  eventName: string;
  productFamily: string;
  roNumber: string;
  formulationNumber: string;
  manufacturers: string[];
  drugSubstance: string[];
  excipientName: string;
  excipientType: string;
  stage: string;
  typeOfImp: string;
  countries: string[];
  clinicalStudy: string;
  manufacturingSiteRoles: IdLabel[];
}

export class RimDocumentMetadata implements RIMDocumentMetadata {
  ctdTitle = '';

  id = '';

  documentTitle = '';

  eventName = '';

  productFamily = '';

  roNumber = '';

  formulationNumber = '';

  manufacturers = [];

  drugSubstance = [];

  excipientName = '';

  excipientType = '';

  stage = '';

  typeOfImp = '';

  countries = [];

  clinicalStudy = '';

  manufacturingSiteRoles = [];
}
