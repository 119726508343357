import { useState } from 'react';
import IADialog from '../Dialog/Dialog';

const ConfidentialityDisclaimer = () => {
  const IADisclaimerShown = localStorage.getItem('IADisclaimer');
  const [isOpen, setIsOpen] = useState(!IADisclaimerShown);

  const handleClick = () => {
    localStorage.setItem('IADisclaimer', 'true');
    setIsOpen(false);
  };
  return (
    <IADialog
      open={isOpen}
      title='Legal acknowledgement'
      handleSubmit={handleClick}
      mainBtnText='OK'
      maxWidth='sm'
    >
      <p>
        I acknowledge that I am bound by confidentiality obligations imposed through my employment
        or contractual agreement with Roche in connection with my access to confidential
        information, including INTELLIGENT AUTHORING and its contents. By entering INTELLIGENT
        AUTHORING, I confirm that I understand that my activities within INTELLIGENT AUTHORING may
        be monitored consistent with local law, and all contents and passwords are confidential
        information, and that unauthorized disclosure or use of such confidential information may
        result in disciplinary action including termination of my employment or services and/or
        legal action based on local law.
      </p>
    </IADialog>
  );
};

export default ConfidentialityDisclaimer;
