import Grid from '@mui/material/Grid';
import { IAInput } from '../../components/Input/Input';
import {
  SelectedProductMetadata,
  ProductFamilies,
  ProjectInterface,
} from '../models/project/projectInterface';
import { WorkingDocumentInterface } from '../models/workingDocument/workingDocumentInterface';
import {
  RequiredMetadata,
  RequiredMetadataValues,
} from '../models/workingDocument/requiredMetadata';
import {
  DefinitioniInterface,
  FieldResponseInterface,
  ItemsInterface,
} from '../models/system/systemDefinition';

export const validateProductMetadata = (
  selectedProductMetadata: SelectedProductMetadata,
  newEmptyFields: string[],
  workspaceType?: string,
) => {
  const processProductFamilies = (families: string[], type: string | undefined) => {
    families.forEach((family, i) => {
      if (
        family.includes('TEMP') &&
        (i > 0 ||
          ['VCUSTPROJECTTYPEGENERICPROJECT', 'VCUSTPROJECTTYPEPTQUALITYAGREEMENTS'].indexOf(
            type as string,
          ) === -1)
      ) {
        newEmptyFields.push(`name ${i + 1}`);
      }
    });
  };

  const processSubstancesOrProducts = (items: string[][], prefix: string) => {
    items.forEach((item, i) => {
      item.forEach((value, j) => {
        if (value.includes('TEMP') && j > 0) {
          newEmptyFields.push(`${prefix} ${i + 1} ${j + 1}`);
        }
      });
    });
  };
  Object.entries(selectedProductMetadata).forEach(([key, value]) => {
    switch (key) {
      case 'selectedProductFamilies':
        processProductFamilies(value as string[], workspaceType);
        break;
      case 'selectedDrugSubstances':
        processSubstancesOrProducts(value as string[][], 'substance');
        break;
      case 'selectedDrugProducts':
        processSubstancesOrProducts(value as string[][], 'product');
        break;
      default:
        break;
    }
  });
  return newEmptyFields;
};

export const getProductFamilyById = (
  productFamilyId: string,
  productFamilyList: ProductFamilies[],
) => {
  return productFamilyList.find((item) => item.id === productFamilyId);
};

export const getSelectedDrugProducts = (
  upperIndex: number,
  productFamilyId: string,
  selectedDrugProducts: string[][],
  productFamilyList: ProductFamilies[],
) => {
  let result = getProductFamilyById(productFamilyId, productFamilyList)
    ?.drugProducts?.filter((el) => selectedDrugProducts[upperIndex]?.includes(el.id))
    .map((el) => el.id);
  result = selectedDrugProducts[upperIndex]?.filter((el) => result?.includes(el));
  if (!result) {
    return [];
  }
  if (selectedDrugProducts[upperIndex].includes(`TEMP_${productFamilyId}`)) {
    result.push(`TEMP_${productFamilyId}`);
  }
  return result;
};

export const getSelectedDrugSubstances = (
  upperIndex: number,
  productFamilyId: string,
  selectedDrugSubstances: string[][],
  productFamilyList: ProductFamilies[],
) => {
  let result = getProductFamilyById(productFamilyId, productFamilyList)
    ?.drugSubstances?.filter((el) => selectedDrugSubstances[upperIndex]?.includes(el.id))
    .map((el) => el.id);
  result = selectedDrugSubstances[upperIndex]?.filter((el) => result?.includes(el));
  if (!result) {
    return [];
  }
  if (selectedDrugSubstances[upperIndex].includes(`TEMP_${productFamilyId}`)) {
    result.push(`TEMP_${productFamilyId}`);
  }
  return result;
};

export const getFieldValue = (
  fields: RequiredMetadata[],
  fieldId: string,
  definitions: DefinitioniInterface[],
) => {
  const type = definitions.find((definition) => definition.id === fieldId)?.type;
  if (type === 'string') {
    return (fields.find((field) => field.id === fieldId)?.values?.[0].value as string) ?? '';
  }
  return (fields.find((field) => field.id === fieldId)?.values as RequiredMetadataValues[]) ?? [];
};

export const getFieldValues = (
  fields: RequiredMetadata[],
  keys: string[],
  definitions: DefinitioniInterface[],
) => {
  const data: { [key: string]: string | RequiredMetadataValues[] } = {};
  keys.forEach((key) => {
    data[key] = getFieldValue(fields, key, definitions);
  });
  return data;
};

export const getSelectedMetadata = (obj: ProjectInterface | WorkingDocumentInterface) => {
  const studyId = obj?.FCUSTSTUDYID as string;
  const selectedProductFamilies = (obj?.FCUSTPRODUCTFAMILY as string)?.split(',') || [];
  const selectedEventTypes = (obj?.FCUSTEVENTTYPE as string)?.split(',') || [];
  const selectedEvents = (obj?.FCUSTEVENT as string)?.split(',') || [];
  const selectedChangeCategory = (obj?.FCUSTEVENTCHANGECATEGORY as string)?.split(',') || [];
  const selectedDrugProducts: string[][] = [[]];
  const selectedDrugSubstances: string[][] = [[]];
  const selectedPackagingCodes: string[][][] = [[[]]];
  const selectedDPManufacturers: string[][][] = [[[]]];
  const selectedDSManufacturers: string[][][] = [[[]]];
  selectedProductFamilies.forEach((_, upperIndex) => {
    if (obj.FCUSTPRODUCT) {
      selectedDrugProducts[upperIndex] = [];
      selectedDPManufacturers[upperIndex] = [];
      selectedPackagingCodes[upperIndex] = [];
      (obj.FCUSTPRODUCT as string)?.split(',').map((drugProductId: string, index: number) => {
        selectedDrugProducts[upperIndex].push(drugProductId);
        selectedPackagingCodes[upperIndex][index] = [];
        selectedDPManufacturers[upperIndex][index] = [];
        (obj.FCUSTPACKAGINGCODE as string)?.split(',').forEach((packagingCodeId: string) => {
          selectedPackagingCodes[upperIndex][index].push(packagingCodeId);
        });
        (obj.FCUSTDPMANUFACTURER as string)?.split(',').forEach((dpManufacturerId: string) => {
          selectedDPManufacturers[upperIndex][index].push(dpManufacturerId);
        });
      });
    }

    if (obj.FCUSTSUBSTANCE) {
      selectedDrugSubstances[upperIndex] = [];
      selectedDSManufacturers[upperIndex] = [];
      (obj.FCUSTSUBSTANCE as string)
        ?.split(',')
        .forEach((drugSubstanceId: string, index: number) => {
          selectedDrugSubstances[upperIndex].push(drugSubstanceId);
          selectedDSManufacturers[upperIndex][index] = [];
          (obj.FCUSTDSMANUFACTURER as string)?.split(',').forEach((dsManufacturerId: string) => {
            selectedDSManufacturers[upperIndex][index].push(dsManufacturerId);
          });
        });
    }
  });

  return {
    selectedProductFamilies,
    selectedDrugProducts,
    selectedDrugSubstances,
    selectedPackagingCodes,
    selectedDPManufacturers,
    selectedDSManufacturers,
    studyId,
    selectedEventTypes,
    selectedEvents,
    selectedChangeCategory,
  };
};

export const renderDosageForm = (
  productFamilyId: string,
  selectedProductId: string,
  productFamilyList: ProductFamilies[],
  isReview?: boolean,
) => {
  if (isReview) {
    return (
      <div>
        <div className='label'>Dosage form</div>
        <div className='review-value'>
          {getProductFamilyById(productFamilyId, productFamilyList)
            ?.drugProducts.find((el) => el.id === selectedProductId)
            ?.dosageForm?.toString() || '-'}
        </div>
      </div>
    );
  }
  return (
    <IAInput
      inputLabel='Dosage form'
      placeholder='Dosage form'
      value={
        getProductFamilyById(productFamilyId, productFamilyList)
          ?.drugProducts.find((el) => el.id === selectedProductId)
          ?.dosageForm?.toString() || ''
      }
      disabled
    />
  );
};

export const renderDosageStrength = (
  productFamilyId: string,
  selectedProductId: string,
  productFamilyList: ProductFamilies[],
  isReview?: boolean,
) => {
  if (isReview) {
    return (
      <div>
        <div className='label'>Dosage strength</div>
        <div className='review-value'>
          {getProductFamilyById(productFamilyId, productFamilyList)
            ?.drugProducts.find((el) => el.id === selectedProductId)
            ?.dosageStrength?.toString() || '-'}
        </div>
      </div>
    );
  }
  return (
    <IAInput
      inputLabel='Dosage strength'
      placeholder='Dosage strength'
      value={
        getProductFamilyById(productFamilyId, productFamilyList)
          ?.drugProducts.find((el) => el.id === selectedProductId)
          ?.dosageStrength?.toString() ?? ''
      }
      disabled
    />
  );
};

export const renderProductINNAndCode = (
  productFamilyId: string,
  productFamilyList: ProductFamilies[],
  isReview?: boolean,
) => {
  if (isReview) {
    return (
      <>
        <Grid item xs={4}>
          <div className='form-field'>
            <div className='label'>Product INN</div>
            <div className='review-value'>
              {getProductFamilyById(productFamilyId, productFamilyList)?.productInn?.toString() ||
                '-'}
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='form-field'>
            <div className='label'>Product Family Code</div>
            <div className='review-value'>
              {getProductFamilyById(
                productFamilyId,
                productFamilyList,
              )?.productFamilyCode?.toString() || '-'}
            </div>
          </div>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid item xs={4}>
        <div className='form-field'>
          <IAInput
            inputLabel='Product INN'
            placeholder='Product INN'
            value={getProductFamilyById(productFamilyId, productFamilyList)?.productInn ?? ''}
            disabled
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className='form-field'>
          <IAInput
            inputLabel='Product Family Code'
            placeholder='Product family code'
            value={
              getProductFamilyById(productFamilyId, productFamilyList)?.productFamilyCode ?? ''
            }
            disabled
          />
        </div>
      </Grid>
    </>
  );
};

export const getDSInReviewMode = (
  productFamilyId: string,
  selectedDrugSubstanceId: string,
  productFamilyList: ProductFamilies[],
) => (
  <Grid item xs={4}>
    <div className='form-field'>
      <div className='label'>Drug Substance</div>
      <div className='review-value'>
        {getProductFamilyById(productFamilyId, productFamilyList)?.drugSubstances.find(
          (el) => el.id === selectedDrugSubstanceId,
        )?.label || '-'}
      </div>
    </div>
  </Grid>
);

export const getDSManufacturersInReviewMode = (
  productFamilyId: string,
  selectedDrugSubstanceId: string,
  productFamilyList: ProductFamilies[],
  upperIndex: number,
  index: number,
  selectedDSManufacturers: string[][][],
) => (
  <Grid item xs={4}>
    <div className='form-field'>
      <div className='label'>Drug Substance Manufacturers</div>
      <div className='review-value'>
        {getProductFamilyById(productFamilyId, productFamilyList)
          ?.drugSubstances.find((el) => el.id === selectedDrugSubstanceId)
          ?.dsManufacturers?.filter(({ id }) =>
            selectedDSManufacturers[upperIndex][index].includes(id),
          )
          .map((el) => el.label)
          .join(', ') || '-'}
      </div>
    </div>
  </Grid>
);

export const getDPInReviewMode = (
  productFamilyId: string,
  productFamilyList: ProductFamilies[],
  selectedProductId: string,
) => (
  <div>
    <div className='label'>Product name</div>
    <div className='review-value'>
      {getProductFamilyById(productFamilyId, productFamilyList)?.drugProducts.find(
        (el) => el.id === selectedProductId,
      )?.label || '-'}
    </div>
  </div>
);

export const getPackagingCodesInReviewMode = (
  productFamilyId: string,
  selectedProductId: string,
  productFamilyList: ProductFamilies[],
  upperIndex: number,
  index: number,
  selectedPackagingCodes: string[][][],
) => (
  <div>
    <div className='label'>Packaging Code</div>
    <div className='review-value'>
      {getProductFamilyById(productFamilyId, productFamilyList)
        ?.drugProducts.find((el) => el.id === selectedProductId)
        ?.packagingCodes?.filter(({ id }) => selectedPackagingCodes[upperIndex][index].includes(id))
        .map((el) => el.label)
        .join(', ') || '-'}
    </div>
  </div>
);

export const getDPManufacturersInReviewMode = (
  productFamilyId: string,
  selectedProductId: string,
  productFamilyList: ProductFamilies[],
  upperIndex: number,
  index: number,
  selectedDPManufacturers: string[][][],
) => (
  <div>
    <div className='label'>Drug Product Manufacturers</div>
    <div className='review-value'>
      {getProductFamilyById(productFamilyId, productFamilyList)
        ?.drugProducts.find((el) => el.id === selectedProductId)
        ?.dpManufacturers?.filter(({ id }) =>
          selectedDPManufacturers[upperIndex][index].includes(id),
        )
        .map((el) => el.label)
        .join(', ') || '-'}
    </div>
  </div>
);

export const getIdOfValueFromData = (data: ItemsInterface, itemId: string) => {
  return (data?.fields as FieldResponseInterface[])
    ?.find((el) => el.id === itemId)
    ?.values?.map((el) => (el.value as DefinitioniInterface).id || (el.value as string[])[0]);
};
