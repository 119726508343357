import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function NoSearchResultsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='164' height='164' viewBox='0 0 164 164' fill='none'>
      <path
        d='M87.9367 42.3007L49.626 80.6113L62.1063 93.0916L100.417 54.781L87.9367 42.3007Z'
        fill='#999999'
      />
      <path
        d='M111.918 12.3327L63.2627 60.9883L81.718 79.4436L130.374 30.788L111.918 12.3327Z'
        fill='#999999'
      />
      <path
        d='M131.49 42.4705L88.1101 85.8405L56.8701 54.6005L100.25 11.2305L131.49 42.4705Z'
        fill='url(#paint0_linear_692_144617)'
      />
      <path
        d='M82.7402 80.4797L78.6602 84.5597L58.1602 64.0497L62.2402 59.9697L82.7402 80.4797Z'
        fill='url(#paint1_linear_692_144617)'
      />
      <path
        d='M143.42 30.53L130.37 30.8L111.92 12.34L112.89 0L143.42 30.53Z'
        fill='url(#paint2_linear_692_144617)'
      />
      <path d='M67.23 118.38H26.79V124.89H67.23V118.38Z' fill='#999999' />
      <path
        d='M117.17 145.55C120.73 150.52 122.88 156.57 123.01 163.12H43.1601C43.3501 154.72 46.8401 147.12 52.3901 141.58C56.2001 137.78 60.9701 134.95 66.3201 133.5H20.5801V124.89H88.2001C102.78 124.89 114.65 113.25 114.65 98.69C114.65 86.8 106.64 76.33 95.1601 73.22L102.7 45.54C126.67 52.03 143.42 73.9 143.42 98.69C143.42 118.47 132.91 135.85 117.17 145.55Z'
        fill='url(#paint3_linear_692_144617)'
      />
      <path d='M152 152H12V164H152V152Z' fill='#999999' />
      <path
        d='M111.63 86.3804C122.808 86.3804 131.87 77.3186 131.87 66.1404C131.87 54.9621 122.808 45.9004 111.63 45.9004C100.452 45.9004 91.3901 54.9621 91.3901 66.1404C91.3901 77.3186 100.452 86.3804 111.63 86.3804Z'
        fill='#CCCCCC'
      />
      <path
        d='M111.63 75.0505C116.551 75.0505 120.54 71.0613 120.54 66.1405C120.54 61.2196 116.551 57.2305 111.63 57.2305C106.709 57.2305 102.72 61.2196 102.72 66.1405C102.72 71.0613 106.709 75.0505 111.63 75.0505Z'
        fill='#999999'
      />
      <defs>
        <linearGradient
          id='paint0_linear_692_144617'
          x1='56.873'
          y1='48.5364'
          x2='131.485'
          y2='48.5364'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CBCDD1' />
          <stop offset='1' stopColor='#E4E5EA' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_692_144617'
          x1='58.1558'
          y1='72.2646'
          x2='82.7447'
          y2='72.2646'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CBCDD1' />
          <stop offset='1' stopColor='#E4E5EA' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_692_144617'
          x1='111.918'
          y1='15.3983'
          x2='143.423'
          y2='15.3983'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CBCDD1' />
          <stop offset='1' stopColor='#E4E5EA' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_692_144617'
          x1='20.5773'
          y1='104.332'
          x2='143.423'
          y2='104.332'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CBCDD1' />
          <stop offset='1' stopColor='#E4E5EA' />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
