import { FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material';
import { ChangeEvent } from 'react';

export interface RadionPropsInterface {
  handleChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  inputLabel: string;
  options: {
    checked?: boolean;
    label: string;
    value: string | boolean;
    disabled?: boolean;
    title?: string;
  }[];
  value: string | boolean;
}

const IARadio = (props: RadionPropsInterface) => {
  const { handleChange = () => {}, inputLabel, options, value } = props;

  return (
    <div className='ia-radio' data-testid='ia-radio'>
      {inputLabel && (
        <label className='label' htmlFor={inputLabel}>
          {inputLabel}
        </label>
      )}
      <RadioGroup
        aria-labelledby='demo-controlled-radio-buttons-group'
        name='controlled-radio-buttons-group'
        value={value}
        onChange={handleChange}
        row
      >
        {options.map((option, key) => (
          <Tooltip title={option.title} key={key}>
            <FormControlLabel
              className={`option-${key}`}
              value={option.value}
              control={<Radio />}
              disabled={option.disabled}
              label={option.label}
            />
          </Tooltip>
        ))}
      </RadioGroup>
    </div>
  );
};

export default IARadio;
