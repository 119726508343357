import IADialog from '../Dialog/Dialog';
import './TemplateSteps.scss';

interface TemplateExistsDialogProps {
  open: boolean;
  handleClose: () => void;
  existingTemplateName: string;
  link: string;
}

const TemplateExistsDialog = (props: TemplateExistsDialogProps) => {
  const { open, handleClose, existingTemplateName, link } = props;

  return (
    <IADialog
      title='Template already exists'
      open={open}
      handleClose={handleClose}
      handleSubmit={handleClose}
      xButton
      mainBtnText='Continue'
      maxWidth='sm'
    >
      <div className='template-exists-dialog' data-testid='template-exists-dialog'>
        <div>
          It seems that a template with identical details already exists:{' '}
          <a target='_blank' rel='noreferrer' href={link}>
            {existingTemplateName}
          </a>
        </div>
        <div>To create new template update/change details.</div>
      </div>
    </IADialog>
  );
};

export default TemplateExistsDialog;
