import { createBrowserRouter, redirect } from 'react-router-dom';
import { Home } from './pages/Home/Home';
import { Layout } from './pages/Layout/Layout';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Templates } from './pages/Templates/Templates';
import { WorkingDocuments } from './pages/WorkingDocuments/WorkingDocuments';
import { Projects } from './pages/Projects/Projects';
import { authLoader } from './core/services/userService';
import { Template } from './pages/Template/Template';
import { Editor } from './pages/Editor/Editor';
import { WorkingDocument } from './pages/WorkingDocument/WorkingDocument';
import { Project } from './pages/Project/Project';
import { TransferOutput } from './pages/TransferOutput/TransferOutput';
import { WorkingDocumentDetails } from './pages/WorkingDocumentDetails/WorkingDocumentDetails';
import { ProjectView } from './pages/ProjectView/ProjectView';
import { Details } from './pages/ProjectView/Details';
import { TemplateDetails } from './pages/TemplateDetails/TemplateDetails';
import { DataDashboard } from './pages/DataDashboard/DataDashboard';

const router = createBrowserRouter([
  {
    path: '/',
    loader: () => authLoader(),
    element: <Home />,
  },
  {
    path: '/layout',
    element: <Layout />,
    loader: () => authLoader(),
    children: [
      {
        index: true,
        loader: () => redirect('/layout/dashboard'),
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'workspaces',
        element: <Projects />,
      },
      {
        path: 'workspaces/new',
        element: <Project />,
      },
      {
        path: 'workspaces/:id',
        children: [
          {
            index: true,
            element: <ProjectView />,
          },
          {
            path: 'details',
            element: <Details />,
          },
        ],
      },
      {
        path: 'working-documents',
        element: <WorkingDocuments />,
      },
      {
        path: 'working-documents/new',
        element: <WorkingDocument />,
      },
      {
        path: 'working-documents/:id',
        children: [
          {
            index: true,
            element: <WorkingDocumentDetails />,
          },
          {
            path: 'transfer/:outputId/:outputVersion',
            element: <TransferOutput />,
          },
        ],
      },

      {
        path: 'templates',
        element: <Templates />,
      },
      {
        path: 'templates/new',
        element: <Template />,
      },
      {
        path: 'templates/:id',
        element: <TemplateDetails />,
      },
      {
        path: 'edit/:type/:id',
        element: <Editor />,
      },
      {
        path: 'edit/:type/:id/:mode',
        element: <Editor />,
      },
      {
        path: 'data-dashboard/:id',
        element: <DataDashboard />,
      },
    ],
  },
]);

export { router };
