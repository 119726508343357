import { useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import guidance from '../../assets/json/guidance.json';
import { useState, Fragment, useEffect } from 'react';
import {
  Button,
  CardContent,
  CircularProgress,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  MobileStepper,
  Typography,
} from '@mui/material';
import './UserGuide.scss';
import {
  CheckCircle,
  ExpandLess,
  ExpandMore,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  ModifiedTutorialsInterface,
  TutorialInterface,
  UserGuideInterface,
} from '../../core/models/userGuide';
import { LanguageType } from '../../core/models/user/user';
import { GlobalState } from '../../core/models/state/globalState';
import VideoTutorial from '../VideoTutorial/VideoTutorial';
import IASearch from '../Search//Search';

interface UserGuidePropsInterface {
  isSidebarOpen: boolean;
  toggleDrawer: (value: boolean) => void;
}

const UserGuide = (props: UserGuidePropsInterface) => {
  const { lang } = useSelector((state: GlobalState) => state.user);
  const { toggleDrawer = () => {}, isSidebarOpen = false } = props;
  const languages: LanguageType[] = ['en', 'de'];
  const defaultLanguage = 'en';
  const browserLanguage = window.navigator.language;
  const existingBrowserLanguage: LanguageType =
    languages.find((language) => browserLanguage.startsWith(language)) || 'en';
  const [guidanceData] = useState(guidance);
  const [pageTutorials, setPageTutorials] = useState<ModifiedTutorialsInterface[]>([]);
  const [openTutorial, setTutorial] = useState<boolean>(false);

  const [selectedLanguage, setSelectedLanguage] = useState<LanguageType>(
    lang === defaultLanguage ? existingBrowserLanguage : lang || defaultLanguage,
  );
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
  const [tutorialTitle, setTutorialTitle] = useState<string>('');

  const handleStepChange = (type: string, tutorialIndex: number) => {
    const tutorialsCopy: ModifiedTutorialsInterface[] = [...pageTutorials];
    const { activeStep: prevActiveStep } = tutorialsCopy[tutorialIndex];
    tutorialsCopy[tutorialIndex].activeStep =
      type === 'back' ? prevActiveStep - 1 : prevActiveStep + 1;
    setPageTutorials(tutorialsCopy);
  };

  const findSidebarDataByPage = () => {
    const sidebarData = guidanceData.userTutorialResult;
    const pageKeys = sidebarData.map((item: UserGuideInterface) => {
      return item.page;
    });
    const currentPageKey = pageKeys.find((item: string) => {
      const data = sidebarData.filter((s: UserGuideInterface) => {
        if (item === s.page) {
          return s;
        }
      });
      const params = data[0].page.includes(':');
      if (!params) {
        return item.includes(location.pathname);
      }
      const paramsStart = item.indexOf(':');
      const path = item.slice(0, paramsStart);
      return location.pathname.startsWith(path);
    });
    return sidebarData.filter((item: UserGuideInterface) => {
      if (item.page === currentPageKey) {
        return item;
      }
    });
  };

  const getModifiedTutorialsData = (data: UserGuideInterface) => {
    const getSteps = (tutorial: TutorialInterface) => {
      const steps = [];
      for (let i = 0; i <= tutorial.number_of_steps - 1; i++) {
        steps.push({
          title: tutorial.tutorial_title,
          description: tutorial.steps[i].description,
        });
      }
      return steps;
    };

    if (data && data.tutorials) {
      return data.tutorials.map((item: TutorialInterface) => {
        const { tutorial_id: id, tutorial_title: title, number_of_steps: allSteps } = item;
        const steps = getSteps(item);

        return {
          id,
          activeStep: item.current_step - 1,
          allSteps,
          title,
          steps,
        };
      });
    }

    return [];
  };

  useEffect(() => {
    if (guidanceData) {
      const data = findSidebarDataByPage();
      const tutorials = getModifiedTutorialsData(data[0]);
      setPageTutorials(tutorials);
    }
  }, [guidanceData, location.pathname]);

  useEffect(() => {
    setSelectedLanguage(
      lang === defaultLanguage ? existingBrowserLanguage : lang || defaultLanguage,
    );
  }, [lang]);

  const toggleDropdownItem = (index: number) => {
    const newValue: number | null = index === openDropdownIndex ? null : index;
    setOpenDropdownIndex(newValue);
  };
  const getButton = (type: string, tutorial: ModifiedTutorialsInterface, index: number) => {
    const disabled =
      type === 'back' ? tutorial.activeStep === 0 : tutorial.allSteps - 1 === tutorial.activeStep;

    return (
      <Button
        size='small'
        disabled={disabled}
        onClick={() => handleStepChange(type, index)}
        startIcon={type === 'back' ? <KeyboardArrowLeft /> : null}
        endIcon={type !== 'back' ? <KeyboardArrowRight /> : null}
      >
        {type === 'back' ? 'Back' : 'Next'}
      </Button>
    );
  };

  const list = (
    <div role='presentation'>
      {pageTutorials &&
        pageTutorials.map((tutorial: ModifiedTutorialsInterface, index) => (
          <Fragment key={`${tutorial.id} ${index}`}>
            <div className={'listItem user-guidance-item'} data-testid='listItem'>
              <ListItem button onClick={() => toggleDropdownItem(index)} disableRipple>
                <ListItemIcon>
                  <CheckCircle
                    // onClick={(event) => handleThickClick(event, index)}
                    className={`
                    check-icon`}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginRight: '5px' }}
                  primary={tutorial.title[selectedLanguage]}
                />
                {openDropdownIndex === index ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openDropdownIndex === index}
                timeout='auto'
                unmountOnExit
                className={'collapse'}
              >
                <CardContent>
                  <Typography variant='body2' color='textSecondary' component='p'>
                    {tutorial.steps[tutorial.activeStep].description[selectedLanguage]}
                  </Typography>
                  <br />
                  <div
                    style={{ textAlign: 'center', fontSize: '0.875rem' }}
                    className='action-link'
                    onClick={() => {
                      setTutorialTitle(tutorial.title.en);
                      setTutorial((prevValue: boolean) => !prevValue);
                    }}
                  >
                    Watch Tutorial
                  </div>
                </CardContent>
                <MobileStepper
                  steps={tutorial.allSteps}
                  activeStep={tutorial.activeStep}
                  position='static'
                  variant='text'
                  nextButton={getButton('next', tutorial, index)}
                  backButton={getButton('back', tutorial, index)}
                />
              </Collapse>
            </div>
          </Fragment>
        ))}
    </div>
  );

  const handleSearch = (value: string) => {
    const data = findSidebarDataByPage();
    const tutorials = getModifiedTutorialsData(data[0]);
    if (value) {
      setPageTutorials(
        tutorials.filter((item) =>
          item.title[selectedLanguage].toLowerCase().match(value.toLowerCase()),
        ),
      );
    } else {
      setPageTutorials(tutorials);
    }
  };

  return (
    <Drawer
      className='drawer'
      id='user-guidance'
      variant='persistent'
      open={isSidebarOpen}
      onClose={() => {
        toggleDrawer(false);
        const input = document.getElementById('search-tutorial-input') as HTMLInputElement;
        if (input) {
          input.value = '';
        }
      }}
    >
      <div className='toolbar' data-testid='toolbar'>
        {!guidanceData ? (
          <div className='loading'>
            <CircularProgress value={30} size='50px' />
          </div>
        ) : (
          <>
            <div className='help-center-title'>
              <ArrowBackIcon
                onClick={() => {
                  const input = document.getElementById(
                    'search-tutorial-input',
                  ) as HTMLInputElement;
                  if (input) {
                    input.value = '';
                  }
                  toggleDrawer(false);
                }}
              />
              Tutorials
            </div>
            <div className='listWrapper' data-testid='listWrapper'>
              <div className='search-tutorial'>
                <IASearch id='search-tutorial-input' handleSearch={handleSearch} />
              </div>
              {list}
            </div>
            <VideoTutorial
              title={tutorialTitle}
              open={openTutorial}
              close={() => setTutorial(false)}
            />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default UserGuide;
