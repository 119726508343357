import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Cross(props: SvgIconProps) {
  const fill = props.fill || '#544F4F';
  return (
    <SvgIcon {...props} width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.974 6.436L17.565 5.026L12 10.59L6.411 5L5 6.41L10.59 12L5 17.59L6.411 19L12 13.41L17.59 19L19 17.59L13.411 12L18.974 6.436Z'
        fill={fill}
      />
    </SvgIcon>
  );
}
