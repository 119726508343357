import Box from '@mui/material/Box';

interface TabPanelProps {
  className?: string;
  children?: React.ReactNode;
  index: number;
  value: number;
  title?: string;
}

export default function TabPanel(props: TabPanelProps) {
  const { className, children, index, value, title, ...other } = props;

  return (
    <div
      className={className}
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      data-testid={`dashboard-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {title && (
            <Box sx={{ pt: 2 }}>
              <span className='tabpanel-text'>{title}</span>
            </Box>
          )}
          {children}
        </>
      )}
    </div>
  );
}
