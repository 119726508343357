import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Pencil(props: SvgIconProps) {
  const fill = props.fill || '#0066CC';
  return (
    <SvgIcon {...props} width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <g clipPath='url(#clip0_1357_3681)'>
        <path
          d='M17.42 4.58L16.21 5.79L14.8 4.38H14.79L13.63 3.21H13.62L12.21 1.79L13.42 0.58C13.81 0.19 14.32 0 14.83 0C15.34 0 15.85 0.19 16.25 0.58L17.42 1.75C18.19 2.54 18.19 3.8 17.42 4.58Z'
          fill='#0066CC'
        />
        <path
          d='M14.79 7.20996L4 18H0V14L10.79 3.20996L12.21 4.62996L13.38 5.79996L14.79 7.20996Z'
          fill='#0066CC'
        />
      </g>
      <defs>
        <clipPath>
          <rect width='18' height='18' fill={fill} />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
