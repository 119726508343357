import SvgIcon from '@mui/material/SvgIcon';

export default function LearnMore() {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2667 2H14V5.8L12.6 4.33333L7.6 9.33333L6.66667 8.4L11.6667 3.4L10.2667 2ZM3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V9.33333H12.6667V12.6667H3.33333V9.33333H2V12.6667C2 13.4 2.6 14 3.33333 14Z'
        fill='#0066CC'
      />
    </SvgIcon>
  );
}
