import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DocumentMenuIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='24' height='24' viewBox='0 0 24 24'>
      <path
        d='M20.42 4.75L19.25 3.58C18.85 3.19 18.34 3 17.83 3C17.32 3 16.81 3.19 16.42 3.58L3 17V21H7L20.42 7.58C21.19 6.8 21.19 5.54 20.42 4.75ZM6.17 19H5V17.83L15.21 7.62L16.38 8.79L6.17 19ZM17.79 7.38L16.62 6.21L17.84 5L19.01 6.17L17.79 7.38Z'
        fill='#544F4F'
      />
    </SvgIcon>
  );
}
