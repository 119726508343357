import { useEffect, useState, ReactNode } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import './Dialog.scss';
import Cross from '../icons/Cross';
import IAButton from '../Button/Button';

interface IADialogProps extends DialogProps {
  className?: string;
  title?: string;
  handleSubmit: () => void;
  mainBtnText?: string;
  errorBtnText?: string;
  handleClose?: () => void;
  cancelButton?: boolean;
  xButton?: boolean;
  disabled?: boolean;
  customButton?: ReactNode;
  confirmationText?: string;
  titleIcon?: ReactNode;
}

const IADialog = (props: IADialogProps) => {
  const [open, setOpen] = useState(props.open);

  const {
    className,
    handleSubmit,
    scroll,
    title,
    mainBtnText,
    errorBtnText,
    maxWidth = 'xs',
    handleClose = (_event: MouseEvent, reason: string) => {
      if (reason && reason === 'backdropClick') {
        return;
      }
      setOpen(false);
    },
    cancelButton,
    xButton,
    disabled,
    customButton,
    confirmationText,
    titleIcon,
  } = props;

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog
      className={className}
      open={open}
      onClose={(e, reason) => {
        if (disabled) {
          return;
        }
        handleClose(e as MouseEvent, reason);
      }}
      scroll={scroll}
      aria-labelledby='scroll-dialog-header'
      aria-describedby='scroll-dialog-description'
      data-testid='dialog'
      maxWidth={maxWidth}
      disableEscapeKeyDown={!cancelButton}
      fullWidth
    >
      <div className='dialog-header' id='scroll-dialog-header'>
        {!!title && (
          <div className='dialog-title' data-testid='dialog-title'>
            {!!titleIcon && <span className='title-icon'>{titleIcon}</span>} {title}
          </div>
        )}
        {!!confirmationText && (
          <div className='confirmation-text' data-testid='confirmation-text'>
            {confirmationText}
          </div>
        )}
        {xButton && (
          <div
            className='close-icon'
            onClick={() => {
              if (disabled) {
                return;
              }
              handleClose({} as MouseEvent, '');
            }}
          >
            <Cross />
          </div>
        )}
      </div>
      <DialogContent dividers={scroll === 'paper'}>{props.children}</DialogContent>
      <DialogActions>
        {cancelButton && (
          <IAButton
            className='back-btn'
            handleClick={() => handleClose({} as MouseEvent, '')}
            disabled={disabled}
          >
            Cancel
          </IAButton>
        )}
        {!!customButton && customButton}
        {mainBtnText && (
          <IAButton
            className='main-action-btn'
            handleClick={handleSubmit}
            data-testid='dialog-submit'
            disabled={disabled}
          >
            {mainBtnText}
          </IAButton>
        )}
        {errorBtnText && (
          <IAButton className='error-action-btn' handleClick={handleSubmit}>
            {errorBtnText}
          </IAButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default IADialog;
