import { FormControl } from '@mui/material';
import { useEffect, useState } from 'react';
import './Filter.scss';
import IASelect, { AutocompleteValueT } from '../Select/Select';
import { IAInput } from '../Input/Input';
import { FilterOperator, operatorOptions } from '../../core/models/filter/filter';
import { FilterTypes } from '../../core/models/filter/filterState';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../core/models/state/globalState';
import { LovValue } from '../../core/models/lovValue';
import { applyNewFilter, setValues } from '../../state/actions/filter';
import { IdLabel } from '../../core/models/global';

export interface FilterProps {
  categories?: IdLabel[];
  categoryValues?: string[];
  handleCategorySelect?: (category: IdLabel) => void;
  handleValueSelect?: (
    category: IdLabel,
    operator: keyof typeof FilterOperator,
    value: string,
  ) => void;
  type?: FilterTypes;
}

const Filter = (props: FilterProps) => {
  const {
    categories = [],
    categoryValues = [],
    handleCategorySelect = () => {},
    handleValueSelect = () => {},
    type = 'projectFilters',
  } = props;
  const dispatch = useDispatch();
  const {
    project: { lovs: projectLov, fields: projectFields },
    template: { lovs: templateLov, fields: templateFields },
    document: { lovs: documentLov, fields: documentFields },
    output: { lovs: outputLov, fields: outputFields },
  } = useSelector((state: GlobalState) => state.system);

  const [selectedStatement, setSelectedStatement] = useState<keyof typeof FilterOperator>('Equal');
  const [selectedCategory, setSelectedCategory] = useState<{
    id: string;
    label: string;
    type: string;
  }>({
    id: '',
    label: '',
    type: 'string',
  });
  const [lovValueId, setLovValueId] = useState('');

  const [selectedValue, setSelectedValue] = useState<string>('');

  const applyFilters = () => {
    dispatch(
      applyNewFilter(
        () => {
          setSelectedValue('');
        },
        type,
        {
          id: selectedCategory.id,
          operator: selectedStatement,
          value: [selectedStatement === 'Like' ? `%${selectedValue}%` : selectedValue],
          label: selectedCategory.label,
          type: selectedCategory.type,
          lovValueId: [lovValueId],
        },
      ),
    );
  };

  const findAndSetLovValueId = (value: string) => {
    switch (type) {
      case 'projectFilters': {
        const lovid = projectFields.find((field) => field.id === selectedCategory.id)?.lovId;
        if (lovid) {
          const lovValue = projectLov[lovid].find((el) => el.label === value)?.id;
          setLovValueId(lovValue ?? '');
        }
        break;
      }
      case 'templateFilters': {
        const lovid = templateFields.find((field) => field.id === selectedCategory.id)?.lovId;
        if (lovid) {
          const lovValue = templateLov[lovid].find((el) => el.label === value)?.id;
          setLovValueId(lovValue ?? '');
        }
        break;
      }
      case 'documentFilters': {
        const lovid = documentFields.find((field) => field.id === selectedCategory.id)?.lovId;
        if (lovid) {
          const lovValue = documentLov[lovid].find((el) => el.label === value)?.id;
          setLovValueId(lovValue ?? '');
        }
        break;
      }
    }
  };

  const handleStatementChange = (_event?: React.SyntheticEvent, value?: AutocompleteValueT) => {
    const statement = (value as { label: string; id: keyof typeof FilterOperator }).id;
    if (value) {
      setSelectedStatement(statement);
    }
  };
  const handleCategoryChange = (_event?: React.SyntheticEvent, value?: AutocompleteValueT) => {
    const category = value as { id: string; label: string; type: string };
    let data: { [key: string]: LovValue[] } = {};
    let lovid;
    console.log(value);
    if (category?.type === 'string') {
      setSelectedStatement('Like');
    }
    if (category) {
      switch (type) {
        case 'projectFilters': {
          lovid = projectFields.find((field) => field.id === category.id)?.lovId;
          data = projectLov;
          break;
        }
        case 'templateFilters': {
          lovid = templateFields.find((field) => field.id === category.id)?.lovId;
          data = templateLov;
          break;
        }
        case 'documentFilters': {
          lovid = documentFields.find((field) => field.id === category.id)?.lovId;
          data = documentLov;
          break;
        }
        case 'outputFilters': {
          lovid = outputFields.find((field) => field.id === category.id)?.lovId;
          data = outputLov;
          break;
        }
      }
      if (lovid) {
        dispatch(setValues(() => {}, data ? data[lovid] : []));
      }
      setSelectedCategory(category);
      handleCategorySelect(category);
    }
  };

  const handleValueChange = (_event?: React.SyntheticEvent, value?: AutocompleteValueT) => {
    setSelectedValue(value as string);
    handleValueSelect(selectedCategory, selectedStatement, value as string);
    findAndSetLovValueId(value as string);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (selectedValue) {
        applyFilters();
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [selectedValue]);

  // open this when the request does not throw 500
  // useEffect(() => {
  //   if (type === 'templateFilters') {
  //     dispatch(
  //       applyNewFilter(
  //         () => {
  //           setSelectedValue('');
  //         },
  //         type,
  //         {
  //           id: 'FIAPTEMPLATESTATUS',
  //           operator: 'NotEqual',
  //           value: ['Archived'],
  //           label: 'Template status',
  //           type: 'string',
  //         },
  //       ),
  //     );
  //   }
  // }, []);

  const handleInputValueChange = (
    event?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setSelectedValue(event?.target.value ?? '');
    handleValueSelect(selectedCategory, selectedStatement, event?.target.value ?? '');
  };

  return (
    <div className='filter-container'>
      <div className='filters-body'>
        <span className='filter-by'>Filter by:</span>
        <div className='field-item category'>
          <FormControl className='form-group' fullWidth>
            <IASelect
              value={selectedCategory.id ? selectedCategory : null}
              placeholder='Category'
              options={categories}
              onChange={handleCategoryChange}
            />
            <span className='select-line' />
          </FormControl>
        </div>
        <div className='field-item statement'>
          <FormControl className='form-group' fullWidth>
            <IASelect
              className='statement'
              value={operatorOptions.find((op) => op.id === selectedStatement)}
              placeholder=''
              options={
                selectedCategory.type === 'string'
                  ? [{ id: 'Like', label: 'contains' }]
                  : operatorOptions.filter((operator) => !operator.hidden)
              }
              onChange={(event, value) => {
                handleStatementChange(event, value);
              }}
            />
          </FormControl>
        </div>
        <div className='field-item value'>
          <FormControl className='form-group' fullWidth>
            {selectedStatement === 'Like' ? (
              <IAInput
                placeholder='Value'
                value={selectedValue}
                onChange={handleInputValueChange}
                disabled={!selectedCategory.id}
              />
            ) : (
              <>
                <IASelect
                  disabled={!selectedCategory.id}
                  placeholder='Select Values'
                  value={selectedValue || null}
                  options={categoryValues}
                  onChange={handleValueChange}
                />
                <span className='select-line' />
              </>
            )}
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default Filter;
