import { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Iframe from 'react-iframe';
import './DataDashboard.scss';
import { useLocation, useParams } from 'react-router-dom';
import IADialog from '../../components/Dialog/Dialog';
import { getProjectById } from '../../state/actions/project';
import { Warning } from '@mui/icons-material';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const DataDashboard = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const query = useQuery();
  let url = query.get('url') || '';
  const queryEntries = Array.from(query.entries());
  queryEntries.splice(0, 1);
  url += queryEntries.map((el) => el.join('=')).join('&');
  const [warningOpen, setWarningOpen] = useState(true);

  useEffect(() => {
    if (id) {
      dispatch(getProjectById(() => {}, id));
    }
  }, []);

  return (
    <>
      <Iframe
        url={url}
        width='100%'
        height='100%'
        id='myId'
        className='iframe_class'
        display='block'
        position='relative'
      />
      <IADialog
        title='Warning'
        open={warningOpen}
        handleClose={() => {}}
        xButton={false}
        mainBtnText='OK'
        handleSubmit={() => setWarningOpen(false)}
        maxWidth='xs'
        titleIcon={<Warning style={{ color: '#F4A261' }} />}
      >
        <div className='warning-message'>
          I&apos;m aware that the embedded Dashboards are not a part of Intelligent Authoring
          Platform (IAP) and therefore not validated. The Dashboard owner is responsible for its
          data and content accuracy displayed in these Dashboards.
        </div>
      </IADialog>
    </>
  );
};
