import IADialog from '../Dialog/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState, useRef } from 'react';
import VideoArrow from '../icons/VideoArrow';
import './VideoTutorial.scss';
interface VideoTutorialProps {
  title: string;
  open: boolean;
  close: () => void;
}

interface VideoSrc {
  default: string;
}

const VideoTutorial = (props: VideoTutorialProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { title, open, close } = props;
  const [videoSrc, setVideoSrc] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [showOverlay, setOverlay] = useState(true);

  const handleTutorial = async () => {
    const titleLowerCase = title.toLowerCase();
    let videoSrcImported: VideoSrc = { default: '' };
    let newName = '';
    if (titleLowerCase.includes('document')) {
      videoSrcImported = (await import('../../assets/images/document-creation.mp4')) as VideoSrc;
      newName = 'Working Document';
    } else if (titleLowerCase.includes('project') || titleLowerCase.includes('workspace')) {
      videoSrcImported = (await import('../../assets/images/workspace-creation.mp4')) as VideoSrc;
      newName = 'Workspace';
    } else if (titleLowerCase.includes('template')) {
      videoSrcImported = (await import('../../assets/images/template-creation.mp4')) as VideoSrc;
      newName = 'Template';
    }
    setVideoSrc(videoSrcImported.default);
    setName(newName);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const func = async () => {
      await handleTutorial();
    };
    func();
  }, [title]);

  useEffect(() => {
    setOverlay(true);
  }, [open]);

  return (
    <IADialog
      title={`${name} Tutorial`}
      handleSubmit={() => {}}
      open={open}
      maxWidth='md'
      handleClose={close}
      xButton
    >
      {!loading ? (
        <div className='video-container'>
          {showOverlay && <div className='color-overlay'></div>}
          <video ref={videoRef} controls data-testid='video' onPlay={() => setOverlay(false)}>
            <source src={videoSrc} type='video/webm'></source>
            <source src={videoSrc} type='video/mp4'></source>
          </video>
          {showOverlay && (
            <div className='styled-arrow' onClick={() => videoRef?.current?.play()}>
              <VideoArrow />
            </div>
          )}
        </div>
      ) : (
        <div className='loader-container'>
          <CircularProgress value={30} size='80px' />
        </div>
      )}
    </IADialog>
  );
};

export default VideoTutorial;
