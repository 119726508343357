import api from './index';

import { AxiosError } from 'axios';
import { TransferBodyInterface } from '../models/transfer/transfer';

export async function getFiltersByTypeAndParent(type: string, parentId: string[]) {
  try {
    let query = '';
    if (parentId) {
      parentId.forEach((field) => {
        query += `parentId=${field}&`;
      });
    }

    const result = await api.get(
      `/proxy/data-transfer/api/phoenix/filters/${type}?${query.slice(0, -1)}`,
    );
    return result.data;
  } catch (error) {
    return null;
  }
}

export async function getDocumentMetadata(documentNumber: string) {
  try {
    const result = await api.get(`/proxy/data-transfer/api/phoenix/document/${documentNumber}`);
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw error.response?.data;
    } else {
      throw error;
    }
  }
}
export async function getRimDocumentMetadata(documentNumber: string) {
  try {
    const result = await api.get(`/proxy/data-transfer/api/rim/document/${documentNumber}`);
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw error.response?.data;
    } else {
      throw error;
    }
  }
}
export async function getRimOptionalFields(documentNumber: string) {
  try {
    const result = await api.get(`/proxy/data-transfer/api/rim/optionalFields/${documentNumber}`);
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw error.response?.data;
    } else {
      throw error;
    }
  }
}

export async function transferDocument(body: TransferBodyInterface) {
  try {
    const result = await api.post('/proxy/data-transfer/api/transfer', body);
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }
}

export async function getCtdCodes() {
  try {
    const result = await api.get(
      '/proxy/data-transfer/api/submission-experts-ctd/get-all-ctd-code',
    );
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }
}

export async function getCtdTitlesByCode(ctdCode: string) {
  try {
    const result = await api.get(
      `/proxy/data-transfer/api/submission-experts-ctd/get-all-ctd-title-by-ctd-code/${ctdCode}`,
    );
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }
}

export async function getBBCodesByCtdCode(ctdCode: string) {
  try {
    const result = await api.get(
      `/proxy/data-transfer/api/submission-experts-ctd/get-all-bb-code-by-ctd-code/${ctdCode}`,
    );
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }
}

export async function getAllBBCodes() {
  try {
    const result = await api.get('/proxy/data-transfer/api/submission-experts-ctd/get-all-bb-code');
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }
}

export async function getReports() {
  try {
    const result = await api.get('/proxy/data-transfer/api/internal-reports-metadata/find-all');
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }
}

export async function getExcipients() {
  try {
    const result = await api.get('/proxy/data-transfer/api/rim/excipients');
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }
}
export async function getManufacturingSiteRoles() {
  try {
    const result = await api.get('/proxy/data-transfer/api/rim/manufacturingSiteRoles');
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }
}

export async function getClinicalStudies() {
  try {
    const result = await api.get('/proxy/data-transfer/api/rim/clinicalStudies');
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }
}
