import { AnyAction } from 'redux';
import { userActionTypes } from '../actions/user';

const initialState = {
  user: {
    displayName: '',
    img: '',
    isAuthor: false,
    isReviewer: false,
    isAdmin: false,
  },
  lang: 'en',
};

export default function user(state = initialState, action: AnyAction) {
  switch (action.type) {
    case userActionTypes.GET_USER_PROFILE_SUCCESS: {
      const { roleIds } = action.payload;
      const isAuthor = roleIds?.includes('VUSERROLEAUTHOR');
      const isReviewer = roleIds?.includes('VUSERROLEREVIEWER');
      const isAdmin = roleIds?.includes('VUSERROLEADMINISTRATOR');
      return {
        ...state,
        user: { ...action.payload, isAuthor, isReviewer, isAdmin },
      };
    }
    case userActionTypes.UPDATE_USER_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    default:
      return state;
  }
}
