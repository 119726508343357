import { DefinitioniInterface } from '../../core/models/system/systemDefinition';
import { Component, TemplateInterface } from '../../core/models/template/templateInterface';
import {
  ProductFamilies,
  SelectedProductMetadata,
} from '../../core/models/project/projectInterface';
import { FieldsFilledInterface, FormValues, StepFieldsInterface } from '../../core/models/global';
import BasicDetails from './BasicDetails';
import DocumentData from './DocumentData';
import { RequiredMetadata } from '../../core/models/workingDocument/requiredMetadata';
import { LovValue } from '../../core/models/lovValue';
import { FIRST_STEP, SECOND_STEP } from '../../constants';

interface ReviewProps {
  template?: TemplateInterface;
  components: Component[];
  projectFields?: DefinitioniInterface[];
  projectLovs?: { [key: string]: LovValue[] };
  templateFields?: DefinitioniInterface[];
  fillStepFields: (
    step: number,
    value?: StepFieldsInterface | SelectedProductMetadata,
    template?: TemplateInterface,
    components?: Component[],
  ) => void;
  fieldsFilled: FieldsFilledInterface;
  setActiveStep?: (activeStep: number) => void;
  productMetadata: ProductFamilies[];
  additionalMetadata?: RequiredMetadata[];
  additionalMetadataValues?: FormValues;
  projectMetadata?: SelectedProductMetadata;
}

const Review = (props: ReviewProps) => {
  const {
    template,
    projectFields,
    projectLovs,
    templateFields,
    fillStepFields,
    fieldsFilled,
    setActiveStep,
    productMetadata,
    additionalMetadata = [],
    additionalMetadataValues,
    projectMetadata,
  } = props;
  return (
    <>
      <BasicDetails
        title='Review basic details'
        projectFields={projectFields}
        projectLovs={projectLovs}
        templateFields={templateFields}
        fillStepFields={fillStepFields}
        fieldsFilled={(fieldsFilled[FIRST_STEP] as StepFieldsInterface) || {}}
        template={template}
        isReview={true}
        setActiveStep={setActiveStep}
      />
      <DocumentData
        title='Review document data'
        fillStepFields={fillStepFields}
        fieldsFilled={(fieldsFilled[SECOND_STEP] as SelectedProductMetadata) || {}}
        giveProductMetadata={() => {}}
        isReview={true}
        setActiveStep={setActiveStep}
        productMetadata={productMetadata}
        additionalMetadata={additionalMetadata}
        additionalMetadataValues={additionalMetadataValues}
        documentLovs={{}}
        handleAdditionalMetadataChange={() => {}}
        projectMetadata={projectMetadata}
        projectId={
          ((fieldsFilled[FIRST_STEP] as StepFieldsInterface)?.selectedProject?.value as string) ||
          ''
        }
        templateId={
          ((fieldsFilled[FIRST_STEP] as StepFieldsInterface)?.selectedTemplate?.value as string) ||
          ''
        }
      />
    </>
  );
};

export default Review;
