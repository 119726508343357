import { AxiosError } from 'axios';
import { CountryGoup } from '../models/template/template';
import api from './index';

export async function getSystemDefinitions() {
  const result = await api.get('/dashboard/system/entity/definitions');
  return result.data;
}
export async function getLOV(lovIds: string[]) {
  let listOfValuesIds = '';
  lovIds.forEach((field) => {
    listOfValuesIds += `listOfValuesIds=${field}&`;
  });
  const result = await api.get(`/dashboard/system/lov?${listOfValuesIds.slice(0, -1)}`);
  return result.data;
}

export async function updateCountryGroupLov(countryGroups: CountryGoup[]) {
  try {
    const result = await api.post(
      '/proxy/data-transfer/api/lovs/update-country-groups',
      countryGroups,
    );
    return result.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    } else {
      throw error;
    }
  }
}
