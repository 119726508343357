import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DownloadIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='24' height='24' viewBox='0 0 24 24'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13 3V11H16L12 15L8 11H11V3H13ZM3 19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V14H19V19H5V14H3V19Z'
        fill='#21201F'
      />
    </SvgIcon>
  );
}
