import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import IATableHeader from '../TableHeader/TableHeader';
import IATable from '../Table/Table';
import IADialog from '../Dialog/Dialog';
import { IAInput } from '..//Input/Input';
import NoSearchResults from '../NoSearchResults/NoSearchResults';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { updateProject, getProjectById } from '../../state/actions/project';
import { GlobalState } from '../../core/models/state/globalState';
import { Order, OrderByInterface } from '../../core/models/order';
import { SingleObject } from '../../core/models/global';
import { disableEventsClass } from '../../core/services/helpers';

type RowType = { [key: string]: string };
type LinkObj = { id: string; link: string; linkName: string };
interface LinksProps {
  rows: RowType[];
  projectId: string;
  projectLoaded?: boolean;
}

export const Links = (props: LinksProps) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: GlobalState) => state.user);
  const { rows = [], projectId, projectLoaded } = props;
  const pageSize = 10;
  const headCells = [
    { id: 'linkName', label: 'Link name', isSortable: true },
    { id: 'addedBy', label: 'Added By', isSortable: true },
    { id: 'dateAdded', label: 'Date added', isSortable: true },
  ];
  const [page, setPage] = useState(1);
  const [openLinkPopup, setOpenLinkPopup] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedLink, setSelectedLink] = useState<LinkObj | null>(null);
  const [linkName, setLinkName] = useState('');
  const [link, setLink] = useState('');
  const [saveClicked, setSaveClicked] = useState(false);
  const [errMessageLink, setErrMessageLink] = useState('');
  const [errMessageLinkName, setErrMessageLinkName] = useState('');
  const [deletionPopup, setDeletionPopup] = useState(false);
  const [saveInProcess, setSaveInProcess] = useState(false);
  const [isDataLoadedAfterUpdate, setIsDataLoadedAfterUpdate] = useState(true);
  const [orderBy, setOrderBy] = useState<OrderByInterface>({
    id: 'dateAdded',
    order: 'Descending',
  });
  const [order, setOrder] = useState<Order>('desc');

  const getRows = (data: RowType[], from: number) => {
    return data
      .sort((a, b) => {
        let current = a;
        let next = b;
        if (order === 'desc') {
          current = b;
          next = a;
        }
        if (orderBy.id === 'dateAdded') {
          return new Date(current.dateAdded).getTime() - new Date(next.dateAdded).getTime();
        }
        return current[orderBy.id].localeCompare(next[orderBy.id]);
      })
      .slice((from - 1) * pageSize, (from - 1) * pageSize + pageSize);
  };

  const [visibleRows, setVisibleRows] = useState(getRows(rows, page));
  const [searchedRows, setSearchedRows] = useState<RowType[]>([]);

  useEffect(() => {
    setVisibleRows(getRows(rows, page));
  }, [rows, orderBy, order]);

  const handleData = (text: string) => {
    const regex = new RegExp(text.toLowerCase(), 'g');
    const result = rows.filter((row) => row.linkName.toLowerCase().match(regex));
    setPage(1);
    setSearchedRows(result);
    setVisibleRows(getRows(result, 1));
  };

  const checkUrl = (url: string) => {
    const regexp = new RegExp(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
    );
    if (url?.trim() && !url.match(regexp)) {
      setErrMessageLink('The value should be URL');
      return false;
    }
    setErrMessageLink('');
    return true;
  };

  const checkLinkName = (linkNameValue: string) => {
    if (
      rows.find((row) => row.linkName === linkNameValue && selectedLink?.linkName !== linkNameValue)
    ) {
      setErrMessageLinkName('The link name should be unique within workspace');
      return false;
    }
    setErrMessageLinkName('');
    return true;
  };

  const handleLinkChange = (linkValue: string) => {
    setLink(linkValue);
    if (saveClicked) {
      checkUrl(linkValue);
    }
  };

  const handleLinkNameChange = (linkNameValue: string) => {
    setLinkName(linkNameValue);
    if (saveClicked) {
      checkLinkName(linkNameValue);
    }
  };

  const handleClose = () => {
    setOpenLinkPopup(false);
    setDeletionPopup(false);
    setSaveClicked(false);
    setLink('');
    setLinkName('');
    setErrMessageLink('');
    setErrMessageLinkName('');
    setSelectedLink(null);
  };

  const saveData = (result: RowType[]) => {
    setSaveInProcess(true);
    setSearchText('');
    dispatch(
      updateProject(
        () => {
          setSaveInProcess(false);
          handleClose();
          setIsDataLoadedAfterUpdate(false);
          dispatch(
            getProjectById(() => {
              setIsDataLoadedAfterUpdate(true);
            }, projectId),
          );
        },
        { id: projectId, body: { FCUSTLINKS: JSON.stringify(result) } },
      ),
    );
  };

  const handleSaveClick = () => {
    setSaveClicked(true);
    const urlValid = !!link?.trim() && checkUrl(link);
    const linkNameValid = !!linkName?.trim() && checkLinkName(linkName);
    if (!link?.trim() || !linkName?.trim() || !urlValid || !linkNameValid) {
      return;
    }
    if (selectedLink) {
      const updatedLink = rows.find((row) => row.id === selectedLink?.id);
      if (updatedLink) {
        updatedLink.link = link.trim();
        updatedLink.linkName = linkName.trim();
      }
    } else {
      let maxId = 0;
      if (rows.length) {
        const sortedByDate = [...rows].sort(
          (current, next) =>
            new Date(current.dateAdded).getTime() - new Date(next.dateAdded).getTime(),
        );
        const lastItem = sortedByDate[sortedByDate.length - 1];
        maxId = Number(lastItem.id[lastItem.id.length - 1]);
      }
      const currentDate = new Date().toISOString();
      const newLink = {
        id: `${projectId}_link_${maxId + 1}`,
        link: link.trim(),
        linkName: linkName.trim(),
        addedBy: user?.displayName,
        dateAdded: currentDate.slice(0, currentDate.indexOf('.')),
      };
      rows.push(newLink);
    }
    saveData(rows);
  };

  const confirmDeletion = () => {
    const result = rows.filter((row) => row.id !== selectedLink?.id);
    saveData(result);
  };

  const handleEditLink = (linkObj: LinkObj) => {
    setSelectedLink(linkObj);
    setLink(linkObj.link);
    setLinkName(linkObj.linkName);
    setOpenLinkPopup(true);
  };

  const handleRemoveLink = (linkObj: LinkObj) => {
    setDeletionPopup(true);
    setSelectedLink(linkObj);
  };

  const handleSort = (id: string) => {
    const isAsc = orderBy?.id === id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy({ id, order: isAsc ? 'Descending' : 'Ascending' });
  };

  const renderContent = () => {
    if (!isDataLoadedAfterUpdate || !projectLoaded) {
      return (
        <div className='loader-container'>
          <CircularProgress value={30} size='80px' />
        </div>
      );
    }
    if (searchText && !searchedRows.length) {
      return (
        <div className='loader-container'>
          <NoSearchResults />
        </div>
      );
    }
    if (!rows.length) {
      return (
        <div className='loader-container'>
          <NoSearchResults
            title='No Links added'
            subTitle='Add link within the Intelligent Authoring Platform to provide easy access to your supporting documents'
            className='no-results'
          />
        </div>
      );
    }
    return (
      <IATable
        headCells={headCells}
        visibleRows={visibleRows}
        threeDots
        count={pageSize}
        page={page}
        handleSort={handleSort}
        orderBy={orderBy?.id}
        order={order}
        pageChangeHandler={(pageNumber: number) => {
          setPage(pageNumber);
          setVisibleRows(getRows(searchedRows.length ? searchedRows : rows, pageNumber));
        }}
        totalItems={searchText ? searchedRows.length : rows.length}
        menuItems={[
          {
            label: 'Edit link',
            onClick: (e: SingleObject | undefined) => handleEditLink(e as LinkObj),
          },
          {
            label: 'Remove link',
            onClick: (e: SingleObject | undefined) => handleRemoveLink(e as LinkObj),
          },
        ]}
        isRowClickable={false}
      />
    );
  };

  const dialogTitle = () => `${selectedLink ? 'Edit' : 'Add'} Link`;

  return (
    <div className='links' data-testid='links'>
      <div
        className={`links-header ${disableEventsClass(!isDataLoadedAfterUpdate || !projectLoaded)}`}
        data-testid='links-header'
      >
        <IATableHeader
          handleSearch={(text: string) => {
            setSearchText(text);
            handleData(text);
          }}
          hideFilter
        />
        <div className='add-link' onClick={() => setOpenLinkPopup(true)} aria-hidden='true'>
          <span className='plus-icon'>+</span> Add Link
        </div>
      </div>
      {renderContent()}

      <IADialog
        title={dialogTitle()}
        handleSubmit={handleSaveClick}
        handleClose={handleClose}
        open={openLinkPopup}
        maxWidth='md'
        cancelButton
        xButton
        mainBtnText={saveInProcess ? 'Saving...' : 'Save'}
        disabled={saveInProcess}
      >
        <>
          <div className='form-field' data-testid='title-container'>
            <IAInput
              inputLabel='Display link as'
              value={linkName}
              onChange={(event) => handleLinkNameChange(event.target.value)}
              error={(!linkName?.trim() || !!errMessageLinkName) && saveClicked}
              errMessage={errMessageLinkName}
              maxLength={70}
            />
          </div>
          <div className='form-field' data-testid='title-container'>
            <IAInput
              inputLabel='Link'
              value={link}
              onChange={(event) => handleLinkChange(event.target.value)}
              error={(!link?.trim() || !!errMessageLink) && saveClicked}
              errMessage={errMessageLink}
            />
          </div>
          {saveInProcess && (
            <>
              <div className='loader-container with-bg'>
                <CircularProgress value={30} size='80px' />
              </div>
              <div className='loader-background'></div>
            </>
          )}
        </>
      </IADialog>
      <ConfirmationDialog
        open={deletionPopup}
        handleClose={handleClose}
        mainBtnText='Confirm'
        confirmationText={`Are you sure you want to remove the link called '${selectedLink?.linkName}' ?`}
        handleSubmit={confirmDeletion}
        saveInProcess={saveInProcess}
      />
    </div>
  );
};
