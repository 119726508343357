import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  getDocumentMetadataSuccess,
  getFiltersByTypeAndParentSuccess,
  getPhoenixLanguagesSuccess,
  transferActionTypes,
  transferDocumentSuccess,
  getCtdCodesSuccess,
  getCtdTitlesSuccess,
  getBBCodesSuccess,
  getReportsSuccess,
  getExcipientsSuccess,
  getMFSiteRolesSuccess,
  getClinicalStudiesSuccess,
  getRimDocumentMetadataSuccess,
  getRimOptionalFieldsSuccess,
} from '../actions/transfer';
import {
  DocumentTransferMetadata,
  PhoenixFilterData,
} from '../../core/models/transfer/phoenixData';
import {
  getDocumentMetadata,
  getFiltersByTypeAndParent,
  transferDocument as transferDocumentApi,
  getCtdCodes,
  getCtdTitlesByCode,
  getBBCodesByCtdCode,
  getAllBBCodes,
  getReports,
  getExcipients as getExcipientsApi,
  getClinicalStudies as getClinicalStudiesApi,
  getManufacturingSiteRoles,
  getRimDocumentMetadata,
  getRimOptionalFields as getRimOptionalFieldsApi,
} from '../../core/api/transfer.api';
import { TransferBodyInterface, CTDCodesInterface } from '../../core/models/transfer/transfer';

export function* getFiltersBy(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: { type: string; subType: string; parentId: string | string[] };
}) {
  try {
    const parentIdArr =
      typeof action.payload.parentId === 'string'
        ? [action.payload.parentId]
        : action.payload.parentId;
    const filtersData: PhoenixFilterData[] = yield call(() =>
      getFiltersByTypeAndParent(action.payload.type, parentIdArr),
    );
    yield put(
      getFiltersByTypeAndParentSuccess({
        data: filtersData,
        type: action.payload.type,
        subType: action.payload.subType,
      }),
    );
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* getDocumentMetadataByDocNumber(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: string;
}) {
  try {
    const documentMetadata: DocumentTransferMetadata = yield call(() =>
      getDocumentMetadata(action.payload),
    );
    yield put(getDocumentMetadataSuccess(documentMetadata));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* getRimDocumentMetadataByDocNumber(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: string;
}) {
  try {
    const documentMetadata: DocumentTransferMetadata = yield call(() =>
      getRimDocumentMetadata(action.payload),
    );
    yield put(getRimDocumentMetadataSuccess(documentMetadata));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* getRimOptionalFields(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: string;
}) {
  try {
    const fields: string[] = yield call(() => getRimOptionalFieldsApi(action.payload));
    yield put(getRimOptionalFieldsSuccess(fields));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* transferDocument(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: TransferBodyInterface;
}) {
  try {
    const documentMetadata: { documentId: string } = yield call(() =>
      transferDocumentApi(action.payload),
    );
    yield put(transferDocumentSuccess(documentMetadata.documentId));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* getLanguages(action: { callback: (err: unknown) => void; type: string }) {
  try {
    const filtersData: PhoenixFilterData[] = yield call(() =>
      getFiltersByTypeAndParent('languages', []),
    );
    yield put(getPhoenixLanguagesSuccess(filtersData));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* getAllCtdCodes(action: { callback: (err: unknown) => void; type: string }) {
  try {
    const response: CTDCodesInterface[] = yield call(getCtdCodes);
    yield put(getCtdCodesSuccess(response));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* getAllCtdTitles(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: string;
}) {
  try {
    if (action.payload === 'Non-CTD') {
      yield put(getCtdTitlesSuccess([{ ctdTitleDerived: 'N/A' }]));
    } else {
      const response: [] = yield call(() => getCtdTitlesByCode(action.payload));
      yield put(getCtdTitlesSuccess(response));
    }
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* getBBCodes(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: string;
}) {
  try {
    const response: [] = yield call(() =>
      action.payload ? getBBCodesByCtdCode(action.payload) : getAllBBCodes(),
    );
    yield put(getBBCodesSuccess(response));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* getAllReports(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: string;
}) {
  try {
    const response: [] = yield call(() => getReports());
    yield put(getReportsSuccess(response));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* getExcipients(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: string;
}) {
  try {
    const response: [] = yield call(() => getExcipientsApi());
    yield put(getExcipientsSuccess(response));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}
export function* getMFSiteRoles(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: string;
}) {
  try {
    const response: [] = yield call(() => getManufacturingSiteRoles());
    yield put(getMFSiteRolesSuccess(response));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export function* getClinicalStudies(action: {
  callback: (err: unknown) => void;
  type: string;
  payload: string;
}) {
  try {
    const response: [] = yield call(() => getClinicalStudiesApi());
    yield put(getClinicalStudiesSuccess(response));
    action.callback(null);
  } catch (error) {
    action.callback(error);
  }
}

export default function* transferWatcher() {
  yield takeEvery(transferActionTypes.GET_FILTERS_BY_TYPE_AND_PARENT, getFiltersBy);
  yield takeLatest(transferActionTypes.GET_DOCUMENT_METADATA, getDocumentMetadataByDocNumber);
  yield takeLatest(transferActionTypes.TRANSFER_DOCUMENT, transferDocument);
  yield takeLatest(transferActionTypes.GET_PHOENIX_LANGUAGES, getLanguages);
  yield takeLatest(transferActionTypes.GET_CTD_CODES, getAllCtdCodes);
  yield takeLatest(transferActionTypes.GET_CTD_TITLES, getAllCtdTitles);
  yield takeLatest(transferActionTypes.GET_BB_CODES, getBBCodes);
  yield takeLatest(transferActionTypes.GET_REPORTS, getAllReports);
  yield takeLatest(transferActionTypes.GET_EXCIPIENTS, getExcipients);
  yield takeLatest(transferActionTypes.GET_MF_SITE_ROLES, getMFSiteRoles);
  yield takeLatest(transferActionTypes.GET_CLINICAL_STUDIES, getClinicalStudies);
  yield takeLatest(
    transferActionTypes.GET_RIM_DOCUMENT_METADATA,
    getRimDocumentMetadataByDocNumber,
  );
  yield takeLatest(transferActionTypes.GET_RIM_OPTIONAL_FIELDS, getRimOptionalFields);
}
