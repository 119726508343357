import { Grid } from '@mui/material';
import {
  DocumentTransferMetadata,
  PhoenixFilterSubType,
  PhoenixFilterType,
} from '../../core/models/transfer/phoenixData';
import IAMultiSelect from '../MultiSelect/MultiSelect';
import IASelect from '../Select/Select';
import IARadio from '../Radio/Radio';
import { IAInput } from '../Input/Input';
import { PhoenixFilterTypes } from '../../core/models/transfer/transfer';
import { IdLabel } from '../../core/models/global';

interface PropsInterface {
  documentMetadata: DocumentTransferMetadata;
  handlePartialDetailsChange: (
    key: keyof DocumentTransferMetadata,
    value: string | string[],
  ) => void;
  ownerships: PhoenixFilterTypes;
  applicabilities: PhoenixFilterTypes;
  handleOwnershipsAndApplicabilitiesChange: (
    type: PhoenixFilterType,
    subType: PhoenixFilterSubType,
    parentId: string | string[],
  ) => void;
  emptyFields?: string[];
  productOptions: string[];
}
export const DMSDetails = (props: PropsInterface) => {
  const {
    documentMetadata,
    handlePartialDetailsChange,
    ownerships,
    applicabilities,
    handleOwnershipsAndApplicabilitiesChange,
    emptyFields = [],
    productOptions,
  } = props;
  return (
    <div className='basic-details'>
      <div className='product-details'>
        <div className='action-title' data-testid='action-title'>
          Edit product details
        </div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field question' data-testid='is-product-related'>
              <IARadio
                inputLabel=' Is this Product related?'
                options={[
                  { label: 'Yes', value: 'Yes' },
                  {
                    label: 'No',
                    value: 'No',
                    title: 'Only product related documents can be transfered from IAP for now.',
                  },
                ]}
                value={'Yes'}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className='form-field' data-testid='product-family'>
              <IAInput
                inputLabel='Product Family '
                placeholder='Product Family '
                required
                onChange={(event) => {
                  handlePartialDetailsChange('productFamilyName', event.target.value);
                }}
                value={documentMetadata.productFamilyName}
                error={emptyFields.includes('productFamilyName')}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className='form-field' data-testid='product-name'>
              <IAMultiSelect
                label='Product Name'
                placeholder='Product Name'
                options={productOptions}
                multiple
                onChange={(_event, value) => {
                  const selectedValues = value as string[];
                  handlePartialDetailsChange('product', selectedValues);
                }}
                value={documentMetadata.product}
                error={emptyFields.includes('product')}
              />
            </div>
          </Grid>
        </Grid>
        <div className='ownership-details'>
          <div className='action-title' data-testid='action-title'>
            Edit ownership details
          </div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className='form-field' data-testid='owning-entity-type'>
                <IASelect
                  label='Owning entity type'
                  placeholder='Owning entity type'
                  options={ownerships.types}
                  multiple={false}
                  onChange={(_event, value) => {
                    const selectedValue = value as IdLabel;
                    handleOwnershipsAndApplicabilitiesChange(
                      'ownerships',
                      'entities',
                      selectedValue.id,
                    );
                    handlePartialDetailsChange('owningEntityType', selectedValue.label);
                  }}
                  value={ownerships.types.find(
                    (el) =>
                      el.id === documentMetadata.owningEntityType ||
                      el.label === documentMetadata.owningEntityType,
                  )}
                  error={emptyFields.includes('owningEntityType')}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field' data-testid='owning-entity'>
                <IASelect
                  label='Owning entity'
                  placeholder='Owning entity'
                  options={ownerships.entities}
                  multiple={false}
                  onChange={(_event, value) => {
                    const selectedValue = value as IdLabel;
                    handleOwnershipsAndApplicabilitiesChange(
                      'ownerships',
                      'departments',
                      selectedValue.id,
                    );
                    handlePartialDetailsChange('owningEntity', selectedValue.label);
                  }}
                  value={ownerships.entities.find(
                    (el) =>
                      el.id === documentMetadata.owningEntity ||
                      el.label === documentMetadata.owningEntity,
                  )}
                  error={emptyFields.includes('owningEntity')}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field' data-testid='owning-department'>
                <IASelect
                  label='Owning department'
                  placeholder='Owning department'
                  multiple={false}
                  options={ownerships.departments || []}
                  onChange={(_event, value) => {
                    const selectedValue = value as IdLabel;
                    handlePartialDetailsChange('owningDepartment', selectedValue.label);
                  }}
                  value={
                    ownerships.departments?.find(
                      (el) =>
                        el.id === documentMetadata.owningDepartment ||
                        el.label === documentMetadata.owningDepartment,
                    ) || null
                  }
                  error={emptyFields.includes('owningDepartment')}
                />
              </div>
            </Grid>
          </Grid>
          <div className='form-field-row'></div>
        </div>
        <div className='applicability-details'>
          <div className='action-title' data-testid='action-title'>
            Enter applicability details
          </div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className='form-field' data-testid='applicable-entity-type'>
                <IAMultiSelect
                  label='Applicable entity type'
                  placeholder='Applicable entity type'
                  options={applicabilities.types}
                  multiple
                  onChange={(_event, value) => {
                    const selectedValues = value as IdLabel[];
                    handleOwnershipsAndApplicabilitiesChange(
                      'applicabilities',
                      'entities',
                      selectedValues.map((el) => el.id),
                    );
                    handlePartialDetailsChange(
                      'applicableEntityTypes',
                      selectedValues.map((el) => el.label),
                    );
                  }}
                  value={applicabilities.types.filter(
                    (el) => documentMetadata.applicableEntityTypes.indexOf(el.label) > -1,
                  )}
                  error={emptyFields.includes('applicableEntityTypes')}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className='form-field' data-testid='applicable-entity'>
                <IAMultiSelect
                  label='Applicable entities'
                  placeholder='Applicable entities'
                  options={applicabilities.entities}
                  multiple
                  onChange={(_event, value) => {
                    const selectedValues = value as IdLabel[];
                    handlePartialDetailsChange(
                      'applicableEntities',
                      selectedValues.map((el) => el.label),
                    );
                  }}
                  value={applicabilities.entities.filter(
                    (el) => documentMetadata.applicableEntities.indexOf(el.label) > -1,
                  )}
                  error={emptyFields.includes('applicableEntities')}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <div className='form-field ' data-testid='addition-info'>
                <IAInput
                  inputLabel='Additional information'
                  placeholder='Additional information'
                  onChange={(event) => {
                    handlePartialDetailsChange('additionalInformation', event.target.value);
                  }}
                  value={documentMetadata.additionalInformation}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
