import { OutputInterface } from './outputInterface';
export class Output {
  constructor(data: OutputInterface) {
    Object.assign(this, data);
  }

  public static createOutput = (data: OutputInterface) => {
    return new Output(data);
  };
}
