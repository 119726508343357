import { DataPreviewDashboard } from '../../core/models/dataPreview/dashboard';
import './DataPreview.scss';
import DataPreviewImagePlaceholder from '../../assets/images/data-preview-sample.png';
interface DataPreviewProps {
  dashboard: DataPreviewDashboard;
  handleClick: () => void;
}

const DataPreviewCard = (props: DataPreviewProps) => {
  const { dashboard, handleClick } = props;
  return (
    <div className='data-preview-card' onClick={handleClick}>
      <div className='preview-image-placeholder'>
        <img src={DataPreviewImagePlaceholder} alt='' />
      </div>
      <div className='title-container'>
        <p className='title'>{dashboard.title}</p>
      </div>
    </div>
  );
};

export default DataPreviewCard;
