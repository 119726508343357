import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Arrow(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='24' height='24' viewBox='0 0 24 24'>
      <g id='Outline/caret_down'>
        <path
          id='Union'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17 10L12 15L7 10H17Z'
          fill='#544F4F'
        />
      </g>
    </SvgIcon>
  );
}
