import { FilterIterface } from '../../core/models/filter/filter';
import { OrderByInterface } from '../../core/models/order';
import { WorkingDocumentInterface } from '../../core/models/workingDocument/workingDocumentInterface';
import {
  DefinitioniInterface,
  ListResponse,
  FieldRequestInterface,
} from '../../core/models/system/systemDefinition';
import {
  RequiredMetadataResponse,
  RequiredMetadata,
} from '../../core/models/workingDocument/requiredMetadata';
import { FormValues } from '../../core/models/global';

export const workingDocumentActionTypes = {
  GET_WORKING_DOCUMENT: 'GET_WORKING_DOCUMENT',
  GET_WORKING_DOCUMENT_SUCCESS: 'GET_WORKING_DOCUMENT_SUCCESS',
  GET_WORKING_DOCUMENTS: 'GET_WORKING_DOCUMENTS',
  GET_WORKING_DOCUMENTS_SUCCESS: 'GET_WORKING_DOCUMENTS_SUCCESS',
  GET_MY_WORKING_DOCUMENTS: 'GET_MY_WORKING_DOCUMENTS',
  GET_MY_WORKING_DOCUMENTS_SUCCESS: 'GET_MY_WORKING_DOCUMENTS_SUCCESS',
  CREATE_WORKING_DOCUMENT: 'CREATE_WORKING_DOCUMENT',
  CREATE_WORKING_DOCUMENT_SUCCESS: 'CREATE_WORKING_DOCUMENT_SUCCESS',
  UPDATE_WORKING_DOCUMENT: 'UPDATE_WORKING_DOCUMENT',
  UPDATE_WORKING_DOCUMENT_SUCCESS: 'UPDATE_WORKING_DOCUMENT_SUCCESS',
  DELETE_WORKING_DOCUMENT: 'DELETE_WORKING_DOCUMENT',
  DELETE_WORKING_DOCUMENT_SUCCESS: 'DELETE_WORKING_DOCUMENT_SUCCESS',
  SET_WORKING_DOCUMENT_TABLE_HEADERS: 'SET_WORKING_DOCUMENT_TABLE_HEADERS',
  GET_DOCUMENT_REQUIRED_METADATA: 'GET_DOCUMENT_REQUIRED_METADATA',
  GET_DOCUMENT_REQUIRED_METADATA_SUCCESS: 'GET_DOCUMENT_REQUIRED_METADATA_SUCCESS',
};

export const getWorkingDocumentById = (
  callback: (err: unknown) => void = () => {},
  id: string,
) => ({
  payload: id,
  type: workingDocumentActionTypes.GET_WORKING_DOCUMENT,
  callback,
});

export const getWorkingDocumentSuccess = (payload: WorkingDocumentInterface) => ({
  type: workingDocumentActionTypes.GET_WORKING_DOCUMENT_SUCCESS,
  payload,
});

export const getWorkingDocuments = (
  callback: (err: unknown) => void = () => {},
  payload: {
    page: number | string;
    size: number | string;
    filterFields: FilterIterface[];
    orderBy?: OrderByInterface;
    allFavorites?: boolean;
  },
) => ({
  payload,
  type: workingDocumentActionTypes.GET_WORKING_DOCUMENTS,
  callback,
});

export const getWorkingDocumentsSuccess = (payload: ListResponse) => ({
  type: workingDocumentActionTypes.GET_WORKING_DOCUMENTS_SUCCESS,
  payload,
});
export const getMyWorkingDocuments = (
  callback: (err: unknown) => void = () => {},
  payload: {
    page: number | string;
    size: number | string;
    filterFields: FilterIterface[];
    orderBy?: OrderByInterface;
    allFavorites?: boolean;
  },
) => ({
  payload,
  type: workingDocumentActionTypes.GET_MY_WORKING_DOCUMENTS,
  callback,
});

export const getMyWorkingDocumentsSuccess = (payload: ListResponse) => ({
  type: workingDocumentActionTypes.GET_MY_WORKING_DOCUMENTS_SUCCESS,
  payload,
});

export const createWorkingDocument = (
  callback: (
    err: { response: { data: string } } | null,
    createdDoc: WorkingDocumentInterface,
  ) => void = () => {},
  payload: { data: FormValues },
) => ({
  payload,
  type: workingDocumentActionTypes.CREATE_WORKING_DOCUMENT,
  callback,
});

export const getDocumentRequiredMetadata = (
  callback: (
    err: { response: { data: string } } | null,
    data: RequiredMetadata[],
  ) => void = () => {},
  payload: {
    projectId: string;
    templateId: string;
    fields: FieldRequestInterface[];
  },
) => ({
  payload,
  type: workingDocumentActionTypes.GET_DOCUMENT_REQUIRED_METADATA,
  callback,
});

export const getDocumentRequiredMetadataSuccess = (payload: RequiredMetadataResponse) => ({
  type: workingDocumentActionTypes.GET_DOCUMENT_REQUIRED_METADATA_SUCCESS,
  payload,
});

export const createWorkingDocumentSuccess = (payload: WorkingDocumentInterface) => ({
  type: workingDocumentActionTypes.CREATE_WORKING_DOCUMENT_SUCCESS,
  payload,
});

export const updateWorkingDocument = (
  callback: (err: unknown) => void = () => {},
  payload: { body: WorkingDocumentInterface; id: string },
) => ({
  payload,
  type: workingDocumentActionTypes.UPDATE_WORKING_DOCUMENT,
  callback,
});

export const updateWorkingDocumentSuccess = (payload: WorkingDocumentInterface) => ({
  type: workingDocumentActionTypes.UPDATE_WORKING_DOCUMENT_SUCCESS,
  payload,
});

export const deleteWorkingDocumentById = (id: string, callback: () => void = () => {}) => ({
  payload: id,
  type: workingDocumentActionTypes.DELETE_WORKING_DOCUMENT,
  callback,
});

export const deleteWorkingDocumentSuccess = (payload: WorkingDocumentInterface) => ({
  type: workingDocumentActionTypes.DELETE_WORKING_DOCUMENT_SUCCESS,
  payload,
});

export const handleWorkingDocumentHeaderCells = (payload: DefinitioniInterface[]) => ({
  type: workingDocumentActionTypes.SET_WORKING_DOCUMENT_TABLE_HEADERS,
  payload,
});
