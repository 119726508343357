import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DocumentIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='164' height='164' viewBox='0 0 164 164' fill='none'>
      <path
        d='M129.02 133.74H41.2399C38.5899 133.74 36.4299 131.59 36.4299 128.93V16.81C36.4299 14.16 38.5799 12 41.2399 12H129.02C131.67 12 133.83 14.15 133.83 16.81V128.94C133.83 131.59 131.67 133.74 129.02 133.74Z'
        fill='#B2B2B2'
      />
      <path
        d='M112.94 152H21.06C19.37 152 18 150.59 18 148.86V60L50 28H112.94C114.63 28 116 29.41 116 31.14V148.86C116 150.59 114.63 152 112.94 152Z'
        fill='url(#paint0_linear_1864_8764)'
      />
      <path
        d='M104.07 141.15L85.1299 152L90.2399 130.82L96.2099 122.87C97.2699 121.46 98.8199 120.83 99.6799 121.46L110.41 129.47C111.26 130.11 111.1 131.77 110.04 133.19L104.07 141.15Z'
        fill='#B2D1F0'
      />
      <path
        d='M103.1 139.97L91.65 131.42C90.99 130.93 90.86 130 91.35 129.35L131.38 76.0303C132.7 74.2703 135.21 73.9103 136.98 75.2303L144.4 80.7703C146.17 82.0903 146.53 84.5903 145.2 86.3503L105.17 139.67C104.68 140.32 103.75 140.46 103.1 139.97Z'
        fill='url(#paint1_linear_1864_8764)'
      />
      <path
        d='M104.07 141.15L85.1299 152L90.2399 130.82L96.2099 122.87C97.2699 121.46 98.8199 120.83 99.6799 121.46L110.41 129.47C111.26 130.11 111.1 131.77 110.04 133.19L104.07 141.15Z'
        fill='white'
      />
      <path
        d='M104.29 140.851L90.46 130.531L126.62 82.3506L140.45 92.6806L104.29 140.851Z'
        fill='#999999'
      />
      <path d='M93.7899 147.04L85.1299 152L87.4699 142.32L93.7899 147.04Z' fill='#5B6066' />
      <path
        d='M99.4299 137.251L93.9399 133.151L130.87 85.5205L136.36 89.6205L99.4299 137.251Z'
        fill='#B2B2B2'
      />
      <path d='M67.3999 74.8096V106.8' stroke='white' strokeWidth='4' strokeMiterlimit='10' />
      <path d='M83.3799 90.7998H51.4299' stroke='white' strokeWidth='4' strokeMiterlimit='10' />
      <path d='M50 28V60H18L50 28Z' fill='white' />
      <path d='M50 60L18 91.54V60H50Z' fill='#999999' />
      <defs>
        <linearGradient
          id='paint0_linear_1864_8764'
          x1='18'
          y1='90'
          x2='116'
          y2='90'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CBCDD1' />
          <stop offset='1' stopColor='#E4E5EA' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1864_8764'
          x1='91.0518'
          y1='107.344'
          x2='146'
          y2='107.344'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CBCDD1' />
          <stop offset='1' stopColor='#E4E5EA' />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
