import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from '@redux-devtools/extension';
import reducers from './reducers';
import rootSaga from './sagas';

const saga = createSagaMiddleware();

function configureStore() {
  const createdStore = createStore(reducers, composeWithDevTools(applyMiddleware(saga)));
  saga.run(rootSaga);

  return createdStore;
}

export const store = configureStore();
