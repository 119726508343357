import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function HelperCenterIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='24' height='24' viewBox='0 0 24 24'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4ZM12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM11 14.5V13.3C11 12.6 11.4 11.9 12 11.6L13.4 10.9C13.7 10.7 14 10.4 14 10V9.4C14 8.9 13.6 8.5 13 8.5H11C10.5 8.5 10 8.9 10 9.4V10.5H8V9.4C8 7.8 9.3 6.5 10.9 6.5H13C14.6 6.5 16 7.8 16 9.4V10.1C16 11.1 15.5 11.9 14.6 12.4L13.3 13C13.1 13.1 13 13.3 13 13.6V14.5H11ZM13 16V18H11V16H13Z'
        fill='#544F4F'
      />
    </SvgIcon>
  );
}
