import { workingDocumentActionTypes } from '../actions/workingDocument';
import { WorkingDocument } from '../../core/models/workingDocument/workingDocument';
import { WorkingDocumentInterface } from '../../core/models/workingDocument/workingDocumentInterface';
import { FieldResponseInterface } from '../../core/models/system/systemDefinition';
import { WorkingDocumentState } from '../../core/models/workingDocument/workingDocumentState';
import { AnyAction } from 'redux';
import { defaultTableHeadCells } from '../../constants';
import { favoriteActionTypes } from '../actions/favorite';
import { transformFieldsToObject } from '../../core/services/apiTranformations';
import {
  setFavorite,
  setTableHeaders,
  sortFieldsAlphabetically,
} from '../../core/services/helpers';

export const initialState: WorkingDocumentState = {
  workingDocument: {},
  workingDocumentObject: {
    id: '',
    fields: [],
  },
  workingDocuments: [],
  tableHeadCells: defaultTableHeadCells.document,
  tableRows: [],
  totalItems: 0,
  myTotalItems: 0,
  myWorkingDocuments: [],
  myWorkingDocumentsTableRows: [],
  documentRequireMetadataFields: [],
};

const handleGetDocumentSuccess = (state: WorkingDocumentState, action: AnyAction) => {
  const item = action.payload;

  let workingDocumentData: WorkingDocumentInterface = {
    id: item.id,
    isFavorite: item.isFavorite,
  };
  workingDocumentData = transformFieldsToObject(item.fields, [], false, workingDocumentData);
  const projectField = item.fields.find((el: FieldResponseInterface) => el.id === 'FIAPPROJECTREF');
  workingDocumentData.FIAPPROJECTREFID = projectField?.values?.[0]?.value;
  return {
    ...state,
    workingDocument: workingDocumentData,
    workingDocumentObject: item,
  };
};

const handleGetDocumentsSuccess = (state: WorkingDocumentState, action: AnyAction) => {
  const { items, total } = action.payload;
  const tableRows: WorkingDocumentInterface[] = [];
  items.forEach((item: { id: string; isFavorite: boolean; fields: FieldResponseInterface[] }) => {
    let row: WorkingDocumentInterface = WorkingDocument.createWorkingDocument({
      id: item.id,
      isFavorite: item.isFavorite,
    }) as WorkingDocumentInterface;
    row = transformFieldsToObject(item.fields, [], false, row);
    row.MEMBERS = String(
      (row.FIAPAUTHORLIST ? (row.FIAPAUTHORLIST as string).split(',').length : 0) +
        (row.FIAPREVIEWER ? (row.FIAPREVIEWER as string).split(',').length : 0),
    );
    tableRows.push(row);
  });
  return {
    ...state,
    workingDocuments: action.payload,
    tableRows,
    totalItems: total,
  };
};
const handleGetMyDocumentsSuccess = (state: WorkingDocumentState, action: AnyAction) => {
  const { items, total } = action.payload;
  const myWorkingDocumentsTableRows: WorkingDocumentInterface[] = [];
  const headCells = state.tableHeadCells.map((cell: { id: string; label: string }) => cell.id);

  items.forEach((item: { id: string; isFavorite: boolean; fields: FieldResponseInterface[] }) => {
    let row: WorkingDocumentInterface = WorkingDocument.createWorkingDocument({
      id: item.id,
      isFavorite: item.isFavorite,
    }) as WorkingDocumentInterface;
    row = transformFieldsToObject(item.fields, headCells, false, row);
    row.MEMBERS = String(
      (row.FIAPAUTHORLIST ? (row.FIAPAUTHORLIST as string).split(',').length : 0) +
        (row.FIAPREVIEWER ? (row.FIAPREVIEWER as string).split(',').length : 0),
    );
    myWorkingDocumentsTableRows.push(row);
  });
  return {
    ...state,
    workingDocuments: action.payload,
    myWorkingDocumentsTableRows,
    myTotalItems: total,
  };
};

const handleCreateDocumentSuccess = (state: WorkingDocumentState, action: AnyAction) => {
  const { tableRows, myWorkingDocumentsTableRows, myTotalItems } = state;
  let newDoc = WorkingDocument.createWorkingDocument({
    id: action.payload.id,
  }) as WorkingDocumentInterface;
  newDoc = transformFieldsToObject(action.payload.fields, [], false, newDoc);

  tableRows.unshift(newDoc);
  myWorkingDocumentsTableRows.unshift(newDoc);
  return {
    ...state,
    tableRows,
    myTotalItems: myTotalItems + 1,
  };
};

const handleUpdateDocumentSuccess = (state: WorkingDocumentState, action: AnyAction) => {
  const { tableRows } = state;
  const { id, fields } = action.payload;

  let updatedWd = WorkingDocument.createWorkingDocument({
    id,
  }) as WorkingDocumentInterface;
  updatedWd = transformFieldsToObject(fields, [], false, updatedWd);

  const wdIndex = tableRows.findIndex((wd: WorkingDocumentInterface) => wd.id === updatedWd.id);
  if (wdIndex !== -1) {
    tableRows[wdIndex] = updatedWd;
  }
  return {
    ...state,
    tableRows,
  };
};

const handleSetDocumentHeaders = (state: WorkingDocumentState, action: AnyAction) => {
  const tableHeadCellsNew = setTableHeaders('document', action.payload);
  return {
    ...state,
    tableHeadCells: tableHeadCellsNew,
  };
};
const handleGetDocumentMetadata = (state: WorkingDocumentState, action: AnyAction) => {
  const documentRequireMetadataFields = [...action.payload.fields];
  const missingFields = state.documentRequireMetadataFields.filter(
    (field) => !documentRequireMetadataFields.find((el) => el.id === field.id),
  );

  return {
    ...state,
    documentRequireMetadataFields: [...missingFields, ...documentRequireMetadataFields].sort(
      sortFieldsAlphabetically,
    ),
  };
};

const handleSetFavorite = (state: WorkingDocumentState, action: AnyAction) => {
  const { tableRows, myWorkingDocumentsTableRows, workingDocument } = state;
  const { isMyData, payload, callback } = action;
  const { myTableRows, singleObject, allTableRows } = setFavorite(
    isMyData,
    myWorkingDocumentsTableRows,
    payload.id,
    tableRows,
    workingDocument,
  );
  callback();
  return {
    ...state,
    tableRows: [...allTableRows],
    myWorkingDocumentsTableRows: [...myTableRows],
    workingDocument: {
      ...singleObject,
    },
  };
};

const workingDocumentReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case workingDocumentActionTypes.GET_WORKING_DOCUMENT_SUCCESS:
      return handleGetDocumentSuccess(state, action);
    case workingDocumentActionTypes.GET_WORKING_DOCUMENTS_SUCCESS:
      return handleGetDocumentsSuccess(state, action);
    case workingDocumentActionTypes.GET_MY_WORKING_DOCUMENTS_SUCCESS:
      return handleGetMyDocumentsSuccess(state, action);
    case workingDocumentActionTypes.CREATE_WORKING_DOCUMENT_SUCCESS:
      return handleCreateDocumentSuccess(state, action);
    case workingDocumentActionTypes.UPDATE_WORKING_DOCUMENT_SUCCESS:
      return handleUpdateDocumentSuccess(state, action);
    case workingDocumentActionTypes.SET_WORKING_DOCUMENT_TABLE_HEADERS:
      return handleSetDocumentHeaders(state, action);
    case workingDocumentActionTypes.GET_DOCUMENT_REQUIRED_METADATA_SUCCESS:
      return handleGetDocumentMetadata(state, action);
    case favoriteActionTypes.SET_FAVORITE:
      return handleSetFavorite(state, action);
    default:
      return state;
  }
};

export default workingDocumentReducer;
