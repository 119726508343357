import { useState, useEffect } from 'react';
import IADialog from '../Dialog/Dialog';
import { Grid, CircularProgress } from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import { ProjectInterface } from '../../core/models/project/projectInterface';
import { FieldResponseInterfaceValues } from '../../core/models/system/systemDefinition';
import { getInitials } from '../../core/services/userService';
import IASelect, { AutocompleteValueT } from '../Select/Select';
import IASearch from '../Search/Search';
import { UserInteface } from '../../core/models/user/user';
import { Member } from '../../core/models/global';

interface DocumentMembersDialogProps {
  open: boolean;
  handleClose: () => void;
  project?: ProjectInterface;
  save?: (data: Member[]) => void;
  addMembers?: (members: Member[]) => void;
  addedMembers: Member[];
  projectMemberList?: FieldResponseInterfaceValues[];
  saveInProcess?: boolean;
  documentCreator?: UserInteface;
  isView?: boolean;
}

const DocumentMembersDialog = (props: DocumentMembersDialogProps) => {
  const {
    open,
    handleClose,
    project,
    addMembers,
    addedMembers = [],
    projectMemberList = [],
    save,
    saveInProcess,
    documentCreator,
    isView,
  } = props;
  const projectName = project?.FTITLE as string;
  const createdBy = project?.FIAPCREATEDBY as string;
  const allProjectMembers = projectMemberList.filter(
    (item) => item.label !== createdBy && item.value !== documentCreator?.userId,
  );
  const [members, setMembers] = useState<Member[]>(addedMembers);
  const [projectMembers, setProjectMembers] = useState(allProjectMembers);

  const roles = [
    { id: 'FIAPAUTHORLIST', label: 'Author' },
    { id: 'FIAPREVIEWER', label: 'Reviewer' },
  ];

  useEffect(() => {
    setMembers(addedMembers);
  }, [addedMembers]);

  useEffect(() => {
    if (!projectMembers.length && allProjectMembers.length) {
      setProjectMembers(allProjectMembers);
    }
  }, [allProjectMembers]);

  const handleMembers = (userId: string, value?: AutocompleteValueT) => {
    if (value) {
      const { label } = value as { id: string; label: string };
      const member = members.find((item) => item.value === userId);
      if (member) {
        member.role = label;
      } else {
        const memberInList = projectMembers.find((el) => el.value === userId);
        members.push({
          role: label,
          label: memberInList?.label as string,
          value: memberInList?.value as string,
        });
      }
      setMembers([...members]);
    } else {
      setMembers(members.filter((el) => el.value !== userId));
    }
  };

  const searchMembers = (text: string) => {
    const regex = new RegExp(text.toLowerCase(), 'g');
    if (isView) {
      const result = addedMembers.filter((member) =>
        (member.label as string).toLowerCase().match(regex),
      );
      setMembers(result);
    } else {
      const result = allProjectMembers.filter((projectMemberName) =>
        (projectMemberName.label as string).toLowerCase().match(regex),
      );
      setProjectMembers(result);
    }
  };

  const getMembersList = () => {
    if (isView) {
      return members;
    }
    return projectMembers;
  };

  const getRole = (element: Member | FieldResponseInterfaceValues) => {
    if (isView) {
      return (
        <div>
          <div className='role-name-dialog'>{(element as Member).role}</div>
        </div>
      );
    }
    return (
      <Grid item xs={4}>
        <IASelect
          label=''
          placeholder='Assign role'
          options={roles}
          onChange={(_, value) => handleMembers(element.value as string, value)}
          value={members.find((item) => item.label === element.label)?.role}
          error={false}
          disableClearable={false}
        />
      </Grid>
    );
  };

  const getMainButtonText = () => {
    if (isView) {
      return;
    }
    if (saveInProcess) {
      return 'Saving';
    }
    return 'Save';
  };

  const getTitle = () => {
    if (isView) {
      return `${members.length} member${members.length > 1 ? 's' : ''}`;
    }
    if (save) {
      return 'Update members';
    }
    return 'Add members';
  };

  return (
    <IADialog
      title={`${getTitle()}`}
      open={open}
      handleClose={handleClose}
      handleSubmit={() => {
        if (save) {
          save(members);
        } else if (addMembers) {
          addMembers([...members]);
        }
      }}
      xButton
      mainBtnText={getMainButtonText()}
      maxWidth='sm'
      disabled={saveInProcess}
      cancelButton={!isView}
    >
      {!isView && (
        <div className='publish-text'>
          {projectMembers?.length ? (
            <p>
              You can only add members from the{' '}
              <span style={{ fontFamily: 'RocheSans-Medium' }}>{projectName}</span> workspace.
              Please select a member and assign the role to continue.
            </p>
          ) : (
            <p>
              It appears that no members have been added to the{' '}
              <span style={{ fontFamily: 'RocheSans-Medium' }}>{projectName}</span> workspace. To
              enable collaboration and adding members to the document, please ensure that you first
              add members to the workspace.
            </p>
          )}
        </div>
      )}
      <div className='members' data-testid='members'>
        {createdBy && (
          <Grid container className='member-row'>
            <Grid item xs={6} className='creator-avatar'>
              <AvatarGroup className='project-card-avatar'>
                <Avatar alt={createdBy}>{getInitials(createdBy)}</Avatar>
              </AvatarGroup>
              <span className='member-name'>{createdBy}</span>
            </Grid>

            <Grid item xs={4}>
              <IASelect
                label=''
                placeholder=''
                options={roles}
                onChange={() => {}}
                value='Author'
                disabled={true}
                error={false}
              />
            </Grid>
          </Grid>
        )}
        {documentCreator?.displayName && createdBy !== documentCreator?.displayName && (
          <Grid container className='member-row'>
            <Grid item xs={6} className='creator-avatar'>
              <AvatarGroup className='project-card-avatar'>
                <Avatar alt={documentCreator?.displayName}>
                  {getInitials(documentCreator?.displayName as string)}
                </Avatar>
              </AvatarGroup>
              <span className='member-name'>{documentCreator?.displayName}</span>
            </Grid>

            <Grid item xs={4}>
              <IASelect
                label=''
                placeholder=''
                options={roles}
                onChange={() => {}}
                value='Author'
                disabled={true}
                error={false}
              />
            </Grid>
          </Grid>
        )}
        {(!!projectMembers?.length || isView) && (
          <Grid>
            <IASearch
              style={{ width: '100%' }}
              handleSearch={(value: string) => searchMembers(value)}
            />
          </Grid>
        )}
        {getMembersList()?.map((el) => (
          <Grid
            container
            className='member-row'
            key={el.value as string}
            style={{ marginTop: '16px' }}
          >
            <Grid item xs={6} className='creator-avatar'>
              <AvatarGroup className='project-card-avatar'>
                <Avatar alt={el.label}>{getInitials(el.label as string)}</Avatar>
              </AvatarGroup>
              <span className='member-name'>{el.label}</span>
            </Grid>

            {getRole(el)}
          </Grid>
        ))}
      </div>
      {saveInProcess && (
        <>
          <div className='loader-container with-bg'>
            <CircularProgress value={30} size='80px' />
          </div>
          <div className='loader-background'></div>
        </>
      )}
    </IADialog>
  );
};

export default DocumentMembersDialog;
