import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ProcessIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='16' height='16' viewBox='0 0 18 18' fill='none'>
      <path
        id='Union'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.12501 2.35417V0.6875C10.7526 0.846839 12.2973 1.48153 13.5667 2.5125L12.375 3.70417C11.4334 2.96904 10.3103 2.50254 9.12501 2.35417ZM7.45833 2.37085C6.27299 2.51922 5.14993 2.98572 4.20833 3.72085L3.01666 2.52918C4.28608 1.49821 5.83078 0.863522 7.45833 0.704183V2.37085ZM1.68333 8.14583C1.8317 6.96049 2.2982 5.83743 3.03333 4.89583L1.84167 3.70416C0.804694 4.9716 0.164135 6.51648 0 8.14583H1.68333ZM1.68335 9.81251C1.83172 10.9978 2.29822 12.1209 3.03335 13.0625L1.85835 14.2542C0.821377 12.9867 0.180818 11.4419 0.016683 9.81251H1.68335ZM14.9 9.81251C14.7516 10.9978 14.2851 12.1209 13.55 13.0625L14.7417 14.2542C15.7937 12.9917 16.4489 11.4464 16.625 9.81251H14.9ZM14.9 8.14583C14.7516 6.96049 14.2851 5.83743 13.55 4.89583L14.725 3.70416C15.762 4.9716 16.4025 6.51648 16.5667 8.14583H14.9ZM12.375 14.2375C11.4334 14.9726 10.3103 15.4391 9.12501 15.5875V17.2542C10.7544 17.09 12.2992 16.4495 13.5667 15.4125L12.375 14.2375ZM4.20833 14.2375C5.14993 14.9726 6.27299 15.4391 7.45833 15.5875V17.3125C5.82446 17.1364 4.27914 16.4812 3.01666 15.4292L4.20833 14.2375Z'
      />
    </SvgIcon>
  );
}
