import { call, put, takeLatest } from 'redux-saga/effects';
import { getUserInfo } from '../../core/api/user.api';
import { UserInteface } from '../../core/models/user/user';
import { getUserProfileSuccess, userActionTypes } from '../actions/user';

export function* getCurrentUser(action: {
  callback: (err: unknown, user?: UserInteface) => void;
  type: string;
}) {
  try {
    const currentUser: UserInteface = yield call(getUserInfo);
    yield put(getUserProfileSuccess(currentUser));
    action.callback(null, currentUser);
  } catch (error) {
    action.callback(error);
  }
}
export default function* userWatcher() {
  yield takeLatest(userActionTypes.GET_USER_PROFILE, getCurrentUser);
}
