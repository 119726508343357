import ProductDetails from './ProductDetails';
import ProjectDetails from './ProjectDetails';
import {
  SelectedProductMetadata,
  ProjectFormValues,
  ProductFamilies,
} from '../../core/models/project/projectInterface';

interface ReviewProps {
  fieldsFilled: { [step: string]: ProjectFormValues | SelectedProductMetadata };
  setActiveStep?: (activeStep: number) => void;
  productMetadata?: ProductFamilies[];
}

const Review = (props: ReviewProps) => {
  const { fieldsFilled, setActiveStep = () => {}, productMetadata } = props;
  return (
    <>
      <ProjectDetails
        isReview
        fillStepFields={() => {}}
        fieldsFilled={(fieldsFilled[1] as ProjectFormValues) || {}}
        onEdit={() => {
          setActiveStep(1);
        }}
        title='Review basic details'
      />
      <ProductDetails
        isReview
        fillStepFields={() => {}}
        giveProductMetadata={() => {}}
        fieldsFilled={fieldsFilled[2] as SelectedProductMetadata}
        onEdit={() => {
          setActiveStep(2);
        }}
        title='Review workspace data'
        productMetadata={productMetadata}
        workspaceType={(fieldsFilled[1] as ProjectFormValues).type as string}
      />
    </>
  );
};

export default Review;
