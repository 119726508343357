import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Refresh(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 1.5C6.57514 1.50012 4.3003 2.67387 2.895 4.65L1.5 3.255V7.5H5.74275L3.97275 5.73075C5.56093 3.2967 8.66646 2.35272 11.3406 3.49116C14.0147 4.62961 15.4868 7.52241 14.8331 10.3543C14.1794 13.1862 11.5883 15.1411 8.6858 14.9923C5.78325 14.8435 3.40566 12.6339 3.045 9.75H1.5375C1.94197 13.7744 5.46469 16.7532 9.50034 16.4833C13.536 16.2134 16.6307 12.7921 16.4957 8.74969C16.3607 4.70728 13.0447 1.50005 9 1.5Z'
        fill='#21201F'
      />
    </SvgIcon>
  );
}
