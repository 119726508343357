import { useNavigate } from 'react-router-dom';
import { DataPreviewDashboard } from '../../core/models/dataPreview/dashboard';
import './DataPreview.scss';
import DataPreviewCard from './DataPreviewCard';

interface DataPreviewListProps {
  dashboards: DataPreviewDashboard[];
  projectId: string;
}

const DataPreviewList = (props: DataPreviewListProps) => {
  const { dashboards, projectId } = props;
  const navigate = useNavigate();

  return (
    <div className='data-preview-list'>
      {dashboards.map((dashboard) => (
        <DataPreviewCard
          key={dashboard.url}
          dashboard={dashboard}
          handleClick={() => {
            navigate(`/layout/data-dashboard/${projectId}?url=${dashboard.url}`);
          }}
        />
      ))}
    </div>
  );
};

export default DataPreviewList;
