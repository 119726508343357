import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ProjectIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='164' height='164' viewBox='0 0 164 164' fill='none'>
      <g clipPath='url(#clip0_2225_23766)'>
        <path
          d='M129.29 143.99H19.8H18.45C17.48 143.91 16.12 143.51 14.92 142.43C13.92 141.52 13.22 140.28 13.02 138.86L12.86 137.78C12.86 137.78 12.86 137.77 12.86 137.76L12.82 137.46L0.0400404 50.54C-0.24996 48.57 1.18004 46.8 3.05004 46.8H54.57C55.3 46.8 56 47.06 56.55 47.55L71.97 61.07C72.52 61.55 73.22 61.82 73.95 61.82L119.71 54.2C121.21 54.2 122.49 55.36 122.72 56.94L124.48 68.92L134.56 137.47C135.06 140.89 132.56 143.99 129.29 143.99Z'
          fill='#999999'
        />
        <path d='M131.61 136.18H22.7L8.92004 31.62H118.21L131.61 136.18Z' fill='#B2B2B2' />
        <path
          d='M130.06 144H22.53L25.9 20H134.01L130.06 144Z'
          fill='url(#paint0_linear_2225_23766)'
        />
        <path d='M131.61 140.45H22.3201L39.7001 38.89H148.98L131.61 140.45Z' fill='white' />
        <path
          d='M161.75 58.81C163.31 58.81 164.4 60.35 163.87 61.82L161.45 68.92L134.41 141.95C133.99 143.16 132.84 143.96 131.56 143.96C130.96 143.96 130.38 143.97 130.02 144H18.6701C17.4901 144 16.4601 143.63 15.5801 142.95C16.2501 143.15 16.7401 143.17 17.1301 143.17C18.9101 143.17 20.5401 141.99 21.2301 140.48L21.3601 140.17C21.3801 140.13 21.3901 140.09 21.4001 140.07L22.2801 137.92L49.5801 70.8C50.0401 69.67 51.1401 68.93 52.3601 68.93L96.4301 68.92C96.4701 68.92 96.5201 68.92 96.5701 68.91C97.2501 68.89 97.9101 68.62 98.4201 68.17L108.96 59.54C109.51 59.06 110.21 58.8 110.93 58.8H161.75V58.81Z'
          fill='url(#paint1_linear_2225_23766)'
        />
        <path
          d='M137.72 136.5C152.234 136.5 164 124.734 164 110.22C164 95.706 152.234 83.94 137.72 83.94C123.206 83.94 111.44 95.706 111.44 110.22C111.44 124.734 123.206 136.5 137.72 136.5Z'
          fill='#999999'
        />
        <path d='M137.72 98.2V122.23' stroke='white' strokeWidth='4' strokeMiterlimit='10' />
        <path d='M149.74 110.22H125.71' stroke='white' strokeWidth='4' strokeMiterlimit='10' />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_2225_23766'
          x1='22.5327'
          y1='82'
          x2='134.008'
          y2='82'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CBCDD1' />
          <stop offset='1' stopColor='#E4E5EA' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_2225_23766'
          x1='15.5823'
          y1='101.403'
          x2='164'
          y2='101.403'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CBCDD1' />
          <stop offset='1' stopColor='#E4E5EA' />
        </linearGradient>
        <clipPath id='clip0_2225_23766'>
          <rect width='164' height='164' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
