import { SVGProps, forwardRef } from 'react';

export type Ref = SVGSVGElement;

const RocheIcon = forwardRef<Ref, SVGProps<SVGSVGElement>>(
  ({ className, fill, width, height }, ref) => (
    <svg
      width={width || 61}
      height={height || 32}
      viewBox='0 0 197 103'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      ref={ref}
    >
      <path
        d='M133.257 39.249c-1.226-.902-3.174-1.48-5.05-1.443-2.056.036-4.004.685-5.375 1.767V26.66h-6.385v42.134h6.385V46.788c0-1.515 1.299-3.21 3.679-3.319 1.047-.036 2.526.433 3.283 1.551.794 1.155.758 2.742.758 4.438l-.036 19.335h6.385V49.241c0-5.772-.722-7.828-3.644-9.992ZM81.31 53.714c0 4.113-.144 5.267-.18 5.628-.216 2.308-1.19 4.725-3.86 4.725-2.633 0-3.715-2.597-3.823-4.833-.036 0-.18-1.407-.18-5.52 0-4.112.144-5.844.18-5.844 0-2.345 1.226-4.653 3.823-4.653 2.634 0 3.824 2.308 3.86 4.617 0 .036.18 1.768.18 5.88Zm6.17-4.978c-.902-8.982-6.025-10.93-10.21-10.93-5.952 0-9.56 3.787-10.208 10.93-.073.577-.145 1.623-.18 4.978-.037 2.453.143 4.473.18 4.978.396 6.674 4.22 10.75 10.209 10.75 5.988 0 9.812-4.148 10.245-10.75.036-.469.144-2.525.144-4.978 0-1.118-.072-4.112-.18-4.978ZM147.579 49.926c.036-1.407.036-1.695.072-2.092.18-2.525 1.298-4.617 3.896-4.617 3.066-.036 3.788 2.958 3.896 4.617.072.902.108 1.48.108 2.092h-7.972Zm7.9 8.442s0 .469-.072 1.046c-.181 1.479-.758 4.653-3.86 4.653-2.67 0-3.68-2.416-3.86-4.797-.036-.686-.144-1.443-.144-4.04h14.249s0-2.13-.036-3.392c0-.505-.036-1.334-.145-3.102-.216-2.706-1.226-5.664-2.777-7.54-1.768-2.236-4.293-3.39-7.287-3.39-5.772 0-9.379 3.751-10.065 10.93-.072.65-.18 1.551-.18 4.978 0 2.453.144 4.51.18 4.978.361 6.566 4.257 10.75 10.065 10.75 5.772 0 9.632-4.076 10.064-11.074h-6.132Z'
        fill={fill}
      />
      <path
        d='M46.68 5.159H150.5l39.681 46.463-39.681 45.814H46.68L6.818 51.478 46.68 5.158ZM152.99 0H44.154L0 51.297l44.19 50.973h108.8L197 51.478 152.99 0Z'
        fill={fill}
      />
      <path
        d='M47.978 32.394h2.995c3.282 0 5.086 1.732 5.158 4.834 0 .253.036.505.036.974 0 .578 0 .758-.036 1.01-.18 1.84-1.01 5.05-4.834 5.05h-3.319V32.395ZM63.093 66.16l-.649-10.317c-.288-4.618-1.479-7.36-3.896-8.767 1.732-1.046 3.968-3.607 3.932-8.982 0-8.153-5.086-11.039-11.074-11.11h-9.74v41.81h6.276l.036-19.12h3.5c2.02 0 4.292 1.767 4.653 6.746l.65 9.776a18.63 18.63 0 0 0 .324 2.597h6.313s-.288-1.154-.325-2.633ZM106.13 58.08c0 .468-.036.865-.072 1.154-.181 2.344-.974 4.833-3.86 4.833-2.634 0-3.68-2.705-3.896-4.69-.109-1.19-.072-2.128-.109-5.663-.035-4.112.109-5.194.18-5.844.217-2.633 1.227-4.653 3.825-4.653 2.705-.036 3.824 2.308 3.86 4.617 0 .036.072.577.108 1.804h6.313c0-.433-.036-.794-.036-.974-.722-8.73-6.061-10.858-10.245-10.858-5.952 0-9.56 3.787-10.21 10.93-.071.577-.144 1.623-.18 4.978-.036 2.453.145 4.473.18 4.978.398 6.674 4.222 10.75 10.21 10.75 6.205.037 9.632-3.751 10.245-10.75 0-.252.036-.432.036-.613h-6.349Z'
        fill={fill}
      />
    </svg>
  ),
);
RocheIcon.displayName = 'RocheIcon';
export default RocheIcon;
