import IADialog from '../Dialog/Dialog';
import './TemplateSteps.scss';
import IAButton from '../Button/Button';
import CircularProgress from '@mui/material/CircularProgress';
interface TemplatePublishDialogProps {
  open: boolean;
  handleClose: () => void;
  id: string;
  publishTemplate: () => void;
  saving?: boolean;
}

const TemplatePublishDialog = (props: TemplatePublishDialogProps) => {
  const { open, handleClose, id, publishTemplate, saving } = props;

  return (
    <IADialog
      title='Publish template'
      open={open}
      handleClose={() => {
        if (saving) {
          return;
        }
        handleClose();
      }}
      handleSubmit={publishTemplate}
      xButton
      mainBtnText={saving ? 'Publishing...' : 'Publish template'}
      maxWidth='sm'
      disabled={saving}
      customButton={
        <IAButton
          className='back-btn'
          handleClick={() => window.open(`/layout/edit/template/${id}`, 'blank', 'noreferrer')}
          data-testid='open-editor'
          disabled={saving}
        >
          Open Editor
        </IAButton>
      }
    >
      <div className='publish-text' data-testid='publish-text'>
        <p>Please ensure all the components have been released before publishing the template.</p>
        <p>Are you sure you want to proceed?</p>
      </div>
      {saving && (
        <>
          <div className='loader-container with-bg'>
            <CircularProgress value={30} size='80px' />
          </div>
          <div className='loader-background'></div>
        </>
      )}
    </IADialog>
  );
};

export default TemplatePublishDialog;
