import IADialog from '../Dialog/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import './ConfirmationDialog.scss';
interface ConfirmationDialogProps {
  open: boolean;
  handleClose: () => void;
  mainBtnText: string;
  confirmationText: string;
  handleSubmit: () => void;
  saveInProcess?: boolean;
  descriptionData?: string[];
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    open,
    handleClose,
    mainBtnText,
    confirmationText,
    handleSubmit,
    saveInProcess,
    descriptionData,
  } = props;

  return (
    <IADialog
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      xButton
      mainBtnText={mainBtnText}
      maxWidth='xs'
      cancelButton
      confirmationText={confirmationText}
      disabled={!!saveInProcess}
      className='confirmation-dialog'
    >
      {!!descriptionData && (
        <div className='dialog-description' data-testid='dialog-description'>
          {descriptionData.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
      )}
      {!!saveInProcess && (
        <>
          <div className='loader-container with-bg'>
            <CircularProgress value={30} size='80px' />
          </div>
          <div className='loader-background'></div>
        </>
      )}
    </IADialog>
  );
};

export default ConfirmationDialog;
