import { ReactNode } from 'react';
import './Button.scss';
import Button from '@mui/material/Button';

interface ButtonPropes {
  className: string;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  children: ReactNode;
  disabled?: boolean;
}

const IAButton = (props: ButtonPropes) => {
  const { className, handleClick, disabled, ...rest } = props;
  return (
    <Button
      disabled={disabled}
      disableRipple
      className={`ia-btn ${className}`}
      type='button'
      onClick={handleClick}
      {...rest}
    >
      {props.children}
    </Button>
  );
};

export default IAButton;
