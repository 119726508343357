import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ErrorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5C22 18.0228 17.5228 22.5 12 22.5C6.47715 22.5 2 18.0228 2 12.5ZM19.4868 9.39719C18.2315 6.37059 15.2764 4.39837 11.9998 4.40039C7.52823 4.40315 3.90478 8.02884 3.90479 12.5004C3.90479 15.777 5.87883 18.7309 8.90621 19.9843C11.9336 21.2377 15.4179 20.5438 17.7341 18.2262C20.0503 15.9086 20.7421 12.4238 19.4868 9.39719ZM13 7.5V13.5H11V7.5H13ZM11 15.5V17.5H13V15.5H11Z'
        fill='#D0021B'
      />
    </SvgIcon>
  );
}
