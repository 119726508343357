import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function TemplateIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width='164' height='164' viewBox='0 0 164 164' fill='none'>
      <g clipPath='url(#clip0_1863_1853)'>
        <path
          d='M66.7301 34.05C66.6801 34.02 66.6401 33.99 66.5901 33.97C66.9201 34.12 67.2301 34.28 67.5301 34.47C67.2701 34.34 67.0001 34.2 66.7301 34.05Z'
          fill='white'
        />
        <path
          d='M114.06 110.13H108.82V111.88H113.03L115.18 115.81L116.71 114.97C116.71 114.97 115.29 112.38 114.59 111.09C114.58 111.06 114.56 111.04 114.55 111.01C114.54 111 114.54 110.99 114.53 110.97C114.53 110.96 114.52 110.96 114.52 110.95V110.94L114.51 110.92L114.5 110.9L114.49 110.88V110.87V110.86L114.48 110.85L114.22 110.38L114.09 110.45V110.13H114.06ZM103.58 110.13H98.34V111.88H103.58V110.13ZM93.09 110.13H87.85V111.88H93.09V110.13ZM82.6 110.13H77.36V111.88H82.6V110.13ZM72.11 110.13H66.87V111.88H72.11V110.13ZM61.62 110.13H56.38V111.88H61.62V110.13ZM51.13 110.13H45.89V111.88H51.13V110.13ZM41.97 112.36C41.97 112.36 41.42 113.37 40.83 114.44C40.82 114.46 40.81 114.47 40.8 114.49C40.8 114.49 40.8 114.5 40.79 114.5C40.79 114.5 40.79 114.51 40.78 114.51L39.45 116.93L40.98 117.77L43.5 113.17L41.97 112.36ZM36.93 121.57L36.43 122.48L36.42 122.5L36.41 122.52L36.4 122.53V122.54C36.4 122.55 36.39 122.55 36.39 122.56V122.57V122.58L36.38 122.6C36.38 122.6 36.38 122.61 36.37 122.61C36.37 122.62 36.36 122.62 36.36 122.63C35.62 123.99 34.45 126.11 34.45 126.11L35.98 126.95L38.5 122.35L36.93 121.57ZM31.9 130.77L29.38 135.37L30.91 136.21L33.43 131.61L31.9 130.77ZM35.18 136.4H29.94V138.15H35.18V136.4ZM45.67 136.4H40.43V138.15H45.67V136.4ZM56.16 136.4H50.92V138.15H56.16V136.4ZM66.65 136.4H61.4V138.15H66.64V136.4H66.65ZM77.14 136.4H71.9V138.15H77.14V136.4ZM87.63 136.4H82.39V138.15H87.63V136.4ZM98.11 136.4H92.87V138.15H98.11V136.4ZM108.6 136.4H103.36V138.15H108.6V136.4ZM119.09 136.4H113.85V138.15H119.09V136.4ZM128.43 136.4H124.33V138.15C124.33 138.15 127.79 138.15 128.99 138.15H129.39L128.43 136.4ZM124.26 128.78L122.73 129.62L125.25 134.22L126.78 133.38L124.86 129.87V129.86C124.51 129.23 124.26 128.78 124.26 128.78ZM119.23 119.58L117.7 120.42L120.22 125.02L121.75 124.18L119.23 119.58Z'
          fill='#AAAAAA'
        />
        <path
          d='M60.25 60.68L24.98 78.51C24.03 78.99 22.86 78.6 22.39 77.64L0.200046 32.94C-0.279954 31.98 0.110046 30.8 1.06005 30.32L36.34 12.5C37.29 12.02 38.46 12.41 38.93 13.37L61.11 58.07C61.59 59.03 61.2 60.2 60.25 60.68Z'
          fill='#999999'
        />
        <path d='M28.3 48.48L24.76 71.93L49.03 59.67L28.3 48.48Z' fill='#B2B2B2' />
        <path d='M41.8401 49.36L39.5601 64.46L55.1901 56.56L41.8401 49.36Z' fill='#B2B2B2' />
        <path
          d='M115.37 30.14L115.32 46.34L115.17 94.09C115.16 95.27 114.22 96.22 113.05 96.22L84.3401 96.15L49.7201 96.06C48.5501 96.06 47.6101 95.1 47.6201 93.92L47.8101 14.13C47.8101 12.95 48.7601 12 49.9301 12L97.4801 12.16L115.37 30.14Z'
          fill='#CCCCCC'
        />
        <path d='M82.48 32.15H43.41V49.77H82.48V32.15Z' fill='#B2B2B2' />
        <path d='M115.34 30.14H97.48V12.12L115.34 30.14Z' fill='#E5E5E5' />
        <path
          d='M130.5 151.77L73.5901 128.2C72.0501 127.56 71.3101 125.78 71.9401 124.23L101.27 52.1C101.9 50.55 103.66 49.8 105.21 50.44L162.13 74.01C163.67 74.65 164.41 76.43 163.78 77.98L134.45 150.11C133.81 151.67 132.05 152.41 130.5 151.77Z'
          fill='url(#paint0_linear_1863_1853)'
        />
        <path d='M112.63 109.9L95.16 102.67L96.65 99L114.12 106.23L112.63 109.9Z' fill='#B2B2B2' />
        <path
          d='M132.93 118.3L115.46 111.07L116.95 107.4L134.42 114.64L132.93 118.3Z'
          fill='#B2B2B2'
        />
        <path
          d='M110.1 116.12L92.6301 108.89L94.1201 105.22L111.59 112.46L110.1 116.12Z'
          fill='#B2B2B2'
        />
        <path
          d='M130.4 124.53L112.93 117.29L114.42 113.63L131.89 120.86L130.4 124.53Z'
          fill='#B2B2B2'
        />
        <path
          d='M107.57 122.34L90.1001 115.11L91.5901 111.44L109.06 118.68L107.57 122.34Z'
          fill='#B2B2B2'
        />
        <path
          d='M127.87 130.75L110.4 123.51L111.89 119.85L129.36 127.08L127.87 130.75Z'
          fill='#B2B2B2'
        />
        <path
          d='M105.04 128.57L87.5701 121.33L89.0601 117.67L106.53 124.9L105.04 128.57Z'
          fill='#B2B2B2'
        />
        <path
          d='M125.34 136.97L107.87 129.74L109.36 126.07L126.83 133.3L125.34 136.97Z'
          fill='#B2B2B2'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_1863_1853'
          x1='67.3483'
          y1='123.123'
          x2='139.283'
          y2='91.7664'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#CBCDD1' />
          <stop offset='1' stopColor='#E4E5EA' />
        </linearGradient>
        <clipPath id='clip0_1863_1853'>
          <rect width='164' height='164' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
