import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import PageTitle from '../../components/PageTitle/PageTitle';
import IASelect, { AutocompleteValueT } from '../../components/Select/Select';
import IARadio from '../../components/Radio/Radio';
import { IAInput } from '../../components/Input/Input';
import IAButton from '../../components/Button/Button';
import { GlobalState } from '../../core/models/state/globalState';
import './TransferOutput.scss';
import {
  getClinicalStudies,
  getDocumentMetadata,
  getExcipients,
  getFiltersByTypeAndParent,
  getMFSiteRoles,
  getPhoenixLanguages,
  getRimDocumentMetadata,
  getRimOptionalFields,
  setDocumentMetadataValues,
  transferDocument,
} from '../../state/actions/transfer';
import {
  DocumentTransferMetadata,
  PhoenixFilterSubData,
  PhoenixFilterSubType,
  PhoenixFilterType,
} from '../../core/models/transfer/phoenixData';
import { TransferOutputReview } from '../../components/TransferComponents/Review';
import { Alert, AlertColor, CircularProgress, Grid, Snackbar } from '@mui/material';
import IADialog from '../../components/Dialog/Dialog';
import { BasicDetails } from '../../components/TransferComponents/BasicDetails';
import { DMSDetails } from '../../components/TransferComponents/DMSDetails';
import { getWorkingDocumentById } from '../../state/actions/workingDocument';
import { getLovLabel } from '../../core/services/apiTranformations';
import { DOCUMENT_MANAGEMENT_SYSTEMS } from '../../core/models/transfer/transfer';
import { getOutputs } from '../../state/actions/output';
import { DEFAULT_ORDER } from '../../constants';
import { isFieldEmpty } from '../../core/services/helpers';
import { BasicDetailsRIM } from '../../components/TransferComponents/RIM/BasicDetailsRim';
import { RIMDocumentMetadata } from '../../core/models/transfer/rim';
import { DMSDetailsRIM } from '../../components/TransferComponents/RIM/DMSDetailsRim';
import { getTemplateById } from '../../state/actions/template';
import { TransferOutputReviewRim } from '../../components/TransferComponents/RIM/ReviewRim';
import { IdLabel } from '../../core/models/global';

export const TransferOutput = () => {
  const { id: documentId, outputId, outputVersion } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    documentMetadata,
    applicabilities,
    ownerships,
    languages,
    documentMetadataRim,
    rimOptionalFields,
  } = useSelector((state: GlobalState) => state.transfer);
  const { user } = useSelector((state: GlobalState) => state.user);
  const {
    document: { fields, lovs },
  } = useSelector((state: GlobalState) => state.system);
  const { workingDocument, workingDocumentObject } = useSelector(
    (state: GlobalState) => state.workingDocument,
  );
  const { template } = useSelector((state: GlobalState) => state.template);
  const { tableRows: outputs } = useSelector((state: GlobalState) => state.output);

  const [selectedSourceSystem, setSelectedSourceSystem] = useState(DOCUMENT_MANAGEMENT_SYSTEMS.VQD);
  const [documentNumber, setDocumentNumber] = useState('');
  const [isPlaceHolderAvailable, setIsPlaceHolderAvailable] = useState(true);
  const [formState, setFormState] = useState<'basic' | 'details' | 'review'>('basic');
  const [emptyFields, setEmptyFields] = useState<string[]>([]);

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [alertType, setAlertType] = useState<AlertColor>('error');
  const [alertText, setAlertText] = useState<string>('');
  const [incorrectNumberDialogOpen, setIncorrectNumberDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editDialogContent, setEditDialogContent] = useState<'basic' | 'dms'>('basic');
  const [incorrectDataHeader, setIncorrectDataHeader] = useState<string>(
    'Incorrect document number',
  );
  const [incorrectDataText, setIncorrectDataText] = useState<string>(
    'Sorry the document number is invalid. ',
  );
  const [productOptions, setProductOptions] = useState<string[]>([]);

  const [documentPartialMetadata, setDocumentPartialMetadata] =
    useState<DocumentTransferMetadata>(documentMetadata);
  const [documentPartialMetadataRim, setDocumentPartialMetadataRim] =
    useState<RIMDocumentMetadata>(documentMetadataRim);
  const [countries, setCountries] = useState<string[]>([]);
  const [stages, setStages] = useState<string[]>([]);
  const [manufacturers, setManufacturers] = useState<string[]>([]);

  useEffect(() => {
    dispatch(
      getOutputs(
        (err: unknown) => {
          if (err) {
            setAlertText('Something went wrong when getting the output details');
          }
        },
        {
          page: 1,
          size: 1000,
          filterFields: [
            {
              id: 'FIAPDOCUMENTREF',
              operator: 'Equal',
              type: 'documentReference',
              value: [documentId as string],
            },
          ],
          orderBy: DEFAULT_ORDER,
        },
      ),
    );
    dispatch(getExcipients(() => {}));
    dispatch(getMFSiteRoles(() => {}));
    dispatch(getClinicalStudies(() => {}));
  }, []);

  useEffect(() => {
    const outputDetails = outputs.find((el) => el.id === outputId);
    if (outputDetails) {
      setDocumentNumber(outputDetails.DOCUMENTNUMBER as string);
    }
  }, [outputs]);

  useEffect(() => {
    setDocumentPartialMetadata(documentMetadata);
  }, [documentMetadata]);

  useEffect(() => {
    if (
      (!workingDocument?.id && !!documentId) ||
      (documentId && workingDocument.id !== documentId)
    ) {
      dispatch(getWorkingDocumentById(() => {}, documentId));
    }
    if (
      workingDocument.id === documentId &&
      workingDocument.FIAPTEMPLATEREF &&
      (workingDocumentObject.fields.find((el) => el.id === 'FIAPTEMPLATEREF')?.values[0]
        .value as string) !== template.id
    ) {
      dispatch(
        getTemplateById(() => {},
        workingDocumentObject.fields.find((el) => el.id === 'FIAPTEMPLATEREF')?.values[0].value as string),
      );
    }
  }, [workingDocument, fields]);

  useEffect(() => {
    const documentProducts =
      (workingDocument.FCUSTPRODUCT as string)
        ?.split(',')
        .map((product) => {
          return (getLovLabel('FCUSTPRODUCT', product, fields, lovs) as string)?.split(' (Ro')[0];
        })
        .filter((el) => el) ?? [];
    setDocumentPartialMetadata({
      ...documentMetadata,
      productFamilyCode: workingDocument.FCUSTPRODFAMFAMILYCODE as string,
      productFamilyName: getLovLabel(
        'FCUSTPRODUCTFAMILY',
        workingDocument.FCUSTPRODUCTFAMILY as string,
        fields,
        lovs,
      ),
      product: documentProducts,
      roformisNumbers: (workingDocument.FCUSTROFORMISNUM as string)?.split(',').filter((el) => el),
    });
    setProductOptions(documentProducts);
  }, [workingDocument, documentMetadata]);

  useEffect(() => {
    const templateCountries = (template.FCUSTCOUNTRY as string)?.split(',').filter((el) => el);
    const documentCountries = (workingDocument.FCUSTCOUNTRY as string)
      ?.split(',')
      .filter((el) => el);
    setCountries(documentCountries || templateCountries);
    const drugSubstances =
      (workingDocument.FCUSTSUBSTANCE as string)
        ?.split(',')
        .map((product) => {
          return getLovLabel('FCUSTSUBSTANCE', product, fields, lovs) as string;
        })
        .filter((el) => el) ?? [];
    const substanceManufacturers =
      (workingDocument.FCUSTDSMANUFACTURER as string)
        ?.split(',')
        .map((mn) => {
          return getLovLabel('FCUSTDSMANUFACTURER', mn, fields, lovs) as string;
        })
        .filter((el) => el) ?? [];
    const productManufacturers =
      (workingDocument.FCUSTDPMANUFACTURER as string)
        ?.split(',')
        .map((mn) => {
          return getLovLabel('FCUSTDPMANUFACTURER', mn, fields, lovs) as string;
        })
        .filter((el) => el) ?? [];
    const combinedManufacturers = new Set([...substanceManufacturers, ...productManufacturers]);
    setManufacturers(Array.from(combinedManufacturers));
    const ctd = `${template.FCUSTCTDMODULE as string} ${template.FCUSTCTDTITLE as string}`;
    setStages((template.FCUSTTEMPLATESTAGE as string)?.split(',').map((el) => el.trim()));
    setDocumentPartialMetadataRim({
      ...documentMetadataRim,
      documentTitle: workingDocument.FTITLE as string,
      eventName: workingDocument.FCUSTEVENTNAME as string,
      productFamily:
        getLovLabel(
          'FCUSTPRODUCTFAMILY',
          workingDocument.FCUSTPRODUCTFAMILY as string,
          fields,
          lovs,
        ) ?? '',
      roNumber:
        (workingDocument.FCUSTPRODFAMROCHENUM as string) ??
        (workingDocument.FCUSTDSROCHENUM as string) ??
        '',
      drugSubstance: drugSubstances,
      formulationNumber: (workingDocument.FCUSTDRUGPRODFORMULATION as string)?.split(',')[0],
      ctdTitle: ctd,
      stage: (template.FCUSTTEMPLATESTAGE as string)?.split(',')?.[0],
      manufacturers: Array.from(combinedManufacturers),
      countries: documentCountries || templateCountries,
    });
    if (template.FCUSTCTDTITLE) {
      dispatch(getRimOptionalFields(() => {}, ctd));
    }
    if (template.FCUSTTEMPLATETYPE === 'Regulatory Submission') {
      setSelectedSourceSystem(DOCUMENT_MANAGEMENT_SYSTEMS.RIM);
      setIsPlaceHolderAvailable(false);
    }
    console.log(documentMetadataRim);
  }, [workingDocument, documentMetadataRim, template]);

  const handlePartialDetailsChange = (
    key: keyof DocumentTransferMetadata,
    value: string | boolean | string[],
  ) => {
    if (value === 'Yes' || value === 'No') {
      value = value === 'Yes';
    }

    const subs: PhoenixFilterSubData = {} as PhoenixFilterSubData;
    if (key === 'owningEntityType') {
      subs.owningEntity = '';
      subs.owningDepartment = '';
    }
    setDocumentPartialMetadata({ ...documentPartialMetadata, ...subs, [key]: value });
  };
  const handlePartialDetailsChangeRim = (
    key: keyof RIMDocumentMetadata,
    value: string | string[] | IdLabel[],
  ) => {
    setDocumentPartialMetadataRim({ ...documentPartialMetadataRim, [key]: value });
  };

  const onSourceSystemChange = (_event?: React.SyntheticEvent, value?: AutocompleteValueT) => {
    const sourceSystem = value as string;
    setSelectedSourceSystem(sourceSystem);
  };

  const showIncorectDataModal = (incorrectField: string) => {
    if (incorrectField) {
      setIncorrectDataHeader('Incorrect document metadata');
      setIncorrectDataText(
        `The document in Veeva under provided number has incorrect value for field ${incorrectField}. `,
      );
    } else {
      setIncorrectDataHeader('Incorrect document number');
      setIncorrectDataText('Sorry the document number is invalid. ');
    }
  };

  const productFamilyCheck = () => {
    if (
      (workingDocument.FCUSTPRODFAMFAMILYCODE as string[])?.[0] !==
      documentMetadata.productFamilyCode
    ) {
      setAlertType('info');
      setAlertText(
        'The product family information is different in the placeholder. It will be updated after the transfer.',
      );
    }
  };

  const isStepsCompletedVQD = (): boolean => {
    const newEmptyFields: string[] = [];
    if (formState === 'review') {
      return true;
    }

    const optionalFields = [
      'additionalInformation',
      'documentNumber',
      'productStage',
      'roformisNumbers',
      'materials',
      'isDocumentInEnglish',
    ];

    if (documentPartialMetadata.isDocumentInEnglish) {
      optionalFields.push('titleInEnglish', 'languages');
    }
    Object.entries(documentPartialMetadata).forEach(([key, value]) => {
      if (isFieldEmpty(optionalFields, key, value)) {
        newEmptyFields.push(key);
      }
    });
    setEmptyFields(newEmptyFields);
    return !newEmptyFields.length;
  };

  const isStepsCompletedRIM = (): boolean => {
    const newEmptyFields: string[] = [];
    if (formState === 'review') {
      return true;
    }
    const optionalFields = ['id', 'countries', 'eventName', 'manufacturingSiteRoles'];
    if (rimOptionalFields.length) {
      optionalFields.push(...rimOptionalFields);
    } else {
      optionalFields.push('excipientName', 'excipientType', 'typeOfImp', 'clinicalStudy');
    }

    console.log(documentPartialMetadataRim);
    Object.entries(documentPartialMetadataRim).forEach(([key, value]) => {
      if (isFieldEmpty(optionalFields, key, value)) {
        newEmptyFields.push(key);
      }
    });
    setEmptyFields(newEmptyFields);
    console.log(newEmptyFields);
    return !newEmptyFields.length;
  };

  const getPlaceholderMetadata = () => {
    setIsDataLoading(true);
    if (selectedSourceSystem === DOCUMENT_MANAGEMENT_SYSTEMS.VQD) {
      dispatch(
        getDocumentMetadata((error: { incorrectField: string; message: string }) => {
          setIsDataLoading(false);
          if (error) {
            showIncorectDataModal(error.incorrectField);
            setIncorrectNumberDialogOpen(true);
          } else {
            productFamilyCheck();
            setFormState('review');
          }
        }, documentNumber.trim()),
      );
    } else if (selectedSourceSystem === DOCUMENT_MANAGEMENT_SYSTEMS.RIM) {
      dispatch(
        getRimDocumentMetadata((err) => {
          setIsDataLoading(false);
          if (err) {
            showIncorectDataModal('');
            setIncorrectNumberDialogOpen(true);
            return;
          }
          setFormState('details');
        }, documentNumber.trim()),
      );
    }
  };

  const handleNextClick = () => {
    if (selectedSourceSystem === DOCUMENT_MANAGEMENT_SYSTEMS.EVAL) {
      setAlertText('Transfer to selected document management system will be available soon...');
      setAlertType('info');
      return;
    }
    if (formState === 'details') {
      if (selectedSourceSystem === DOCUMENT_MANAGEMENT_SYSTEMS.VQD && !isStepsCompletedVQD()) {
        return;
      }
      if (selectedSourceSystem === DOCUMENT_MANAGEMENT_SYSTEMS.RIM && !isStepsCompletedRIM()) {
        return;
      }
      return setFormState('review');
    }
    if (!isPlaceHolderAvailable) {
      setDocumentPartialMetadataRim({
        ...documentPartialMetadataRim,
        id: '',
      });
      return setFormState('details');
    }
    if (isPlaceHolderAvailable && documentNumber?.trim()) {
      getPlaceholderMetadata();
    }
  };
  const handleTransferClick = () => {
    // transfer the document
    let metadata;
    if (selectedSourceSystem === DOCUMENT_MANAGEMENT_SYSTEMS.RIM) {
      metadata = documentPartialMetadataRim;
    } else {
      metadata = documentPartialMetadata;
    }
    if (documentId && outputId) {
      setIsDataLoading(true);
      const body = {
        documentId,
        outputId,
        outputVersion: outputVersion,
        metadata,
        user: user.displayName,
        documentManagementSystem: selectedSourceSystem,
      };
      dispatch(
        transferDocument((error) => {
          console.log(error);
          setIsDataLoading(false);
          if (error) {
            setAlertType('error');
            setAlertText('Transfer failed, please try again or contact support.');
          } else {
            setAlertType('success');
            setAlertText(
              'Transfer is in progress, the status of the document will be updated automatically upon completion.',
            );
            navigate(`/layout/working-documents/${documentId}#output`);
          }
        }, body),
      );
    }
  };

  const handleOwnershipsAndApplicabilitiesChange = (
    type: PhoenixFilterType,
    subType: PhoenixFilterSubType,
    parentId: string | string[],
  ) => {
    dispatch(getFiltersByTypeAndParent(() => {}, { type, subType, parentId }));
  };

  useEffect(() => {
    dispatch(
      getFiltersByTypeAndParent(() => {}, { type: 'ownerships', subType: 'types', parentId: null }),
    );
    dispatch(
      getFiltersByTypeAndParent(() => {}, {
        type: 'applicabilities',
        subType: 'types',
        parentId: null,
      }),
    );
    dispatch(getPhoenixLanguages(() => {}));
  }, []);

  // Get owning entities by owning entity type from document metadata
  useEffect(() => {
    if (documentMetadata.owningEntityType && ownerships.types.length > 0) {
      const parentId = ownerships.types.find(
        (el) => el.label === documentMetadata.owningEntityType,
      )?.id;
      if (parentId) {
        dispatch(
          getFiltersByTypeAndParent(() => {}, {
            type: 'ownerships',
            subType: 'entities',
            parentId,
          }),
        );
      }
    }
  }, [documentMetadata.owningEntityType, ownerships.types]);

  // Get applicable entities by applicable entity type from document metadata
  useEffect(() => {
    if (documentMetadata.applicableEntityTypes && applicabilities.types.length > 0) {
      const parentId = applicabilities.types
        .filter((el) => documentMetadata.applicableEntityTypes.indexOf(el.label) > -1)
        ?.map((el) => el.id);
      if (parentId) {
        dispatch(
          getFiltersByTypeAndParent(() => {}, {
            type: 'applicabilities',
            subType: 'entities',
            parentId,
          }),
        );
      }
    }
  }, [documentMetadata.applicableEntityTypes, applicabilities.types]);

  // Get owning departments by owning entity from document metadata
  useEffect(() => {
    if (documentMetadata.owningEntity && ownerships.entities.length > 0) {
      const parentId = ownerships.entities.find(
        (el) => el.label === documentMetadata.owningEntity,
      )?.id;
      if (parentId) {
        dispatch(
          getFiltersByTypeAndParent(() => {}, {
            type: 'ownerships',
            subType: 'departments',
            parentId,
          }),
        );
      }
    }
  }, [documentMetadata.owningEntity, ownerships.entities]);

  const handleDialogSubmit = () => {
    Object.entries(documentPartialMetadata).forEach(([key, value]) => {
      dispatch(setDocumentMetadataValues({ field: key as keyof DocumentTransferMetadata, value }));
    });
    setEditDialogOpen(false);
  };

  const formBasedOnDMS = () => {
    if (selectedSourceSystem === DOCUMENT_MANAGEMENT_SYSTEMS.RIM) {
      return (
        <>
          <BasicDetailsRIM
            documentMetadata={documentPartialMetadataRim}
            handlePartialDetailsChange={handlePartialDetailsChangeRim}
            emptyFields={emptyFields}
            countries={countries}
            manufacturers={manufacturers}
            stage={stages}
          />
          <hr className='divider-dotted'></hr>

          <DMSDetailsRIM
            documentMetadata={documentPartialMetadataRim}
            handlePartialDetailsChange={handlePartialDetailsChangeRim}
            emptyFields={emptyFields}
          />
        </>
      );
    } else {
      return (
        <>
          <BasicDetails
            documentMetadata={documentPartialMetadata}
            handlePartialDetailsChange={handlePartialDetailsChange}
            languages={languages}
            emptyFields={emptyFields}
          />
          <DMSDetails
            documentMetadata={documentPartialMetadata}
            handlePartialDetailsChange={handlePartialDetailsChange}
            applicabilities={applicabilities}
            ownerships={ownerships}
            handleOwnershipsAndApplicabilitiesChange={handleOwnershipsAndApplicabilitiesChange}
            emptyFields={emptyFields}
            productOptions={productOptions}
          />
        </>
      );
    }
  };

  const reviewBasedOnDms = () => {
    if (selectedSourceSystem === DOCUMENT_MANAGEMENT_SYSTEMS.RIM) {
      return (
        <TransferOutputReviewRim
          documentMetadata={documentPartialMetadataRim}
          documentNumber={documentNumber}
          selectedSourceSystem={selectedSourceSystem}
          openDialog={(content) => {
            setEditDialogOpen(true);
            setEditDialogContent(content);
          }}
        />
      );
    } else {
      return (
        <TransferOutputReview
          documentMetadata={documentPartialMetadata}
          documentNumber={documentNumber}
          selectedSourceSystem={selectedSourceSystem}
          openDialog={(content) => {
            setEditDialogOpen(true);
            setEditDialogContent(content);
          }}
        />
      );
    }
  };

  const contentBasedOnState = () => {
    if (formState === 'basic') {
      return (
        <>
          <div className='action-title' data-testid='action-title'>
            Enter basic details
          </div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className='form-field' data-testid='dm-system'>
                <IASelect
                  label='Select document management system'
                  placeholder='Select project'
                  options={Object.values(DOCUMENT_MANAGEMENT_SYSTEMS)}
                  onChange={onSourceSystemChange}
                  value={selectedSourceSystem}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className='form-field' data-testid='placeholder-available'>
                <IARadio
                  inputLabel='Version existing document?'
                  options={[
                    { label: 'Yes', value: true },
                    {
                      label: 'No',
                      value: false,
                    },
                  ]}
                  value={isPlaceHolderAvailable}
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = JSON.parse(event.target.value);
                    setIsPlaceHolderAvailable(value);
                  }}
                />
              </div>
            </Grid>
          </Grid>

          {isPlaceHolderAvailable && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className='form-field' data-testid='document-number'>
                  <IAInput
                    inputLabel='Document Number'
                    placeholder='Document Number'
                    required
                    onChange={(event) => {
                      setDocumentNumber(event.target.value);
                    }}
                    value={documentNumber}
                  />
                </div>
              </Grid>
            </Grid>
          )}
        </>
      );
    }
    if (formState === 'review') {
      return reviewBasedOnDms();
    }
    if (formState === 'details') {
      return formBasedOnDMS();
    }
  };

  const buttonBasedOnFormState = () => {
    if (formState === 'review') {
      return (
        <IAButton className='main-action-btn' handleClick={handleTransferClick}>
          Transfer
        </IAButton>
      );
    }
    return (
      <IAButton className='main-action-btn' handleClick={handleNextClick}>
        Next
      </IAButton>
    );
  };

  const goToRose = () => {
    window.open('https://go.roche.com/iap-rose', 'blank', 'noreferrer');
  };

  const dialogContentBasedOnDms = () => {
    if (selectedSourceSystem === DOCUMENT_MANAGEMENT_SYSTEMS.RIM) {
      return editDialogContent === 'basic' ? (
        <BasicDetailsRIM
          documentMetadata={documentPartialMetadataRim}
          handlePartialDetailsChange={handlePartialDetailsChangeRim}
          countries={countries}
          emptyFields={emptyFields}
        />
      ) : (
        <DMSDetailsRIM
          documentMetadata={documentPartialMetadataRim}
          handlePartialDetailsChange={handlePartialDetailsChangeRim}
          emptyFields={emptyFields}
        />
      );
    } else {
      return editDialogContent === 'basic' ? (
        <BasicDetails
          documentMetadata={documentPartialMetadata}
          handlePartialDetailsChange={handlePartialDetailsChange}
          languages={languages}
        />
      ) : (
        <DMSDetails
          documentMetadata={documentPartialMetadata}
          handlePartialDetailsChange={handlePartialDetailsChange}
          applicabilities={applicabilities}
          ownerships={ownerships}
          handleOwnershipsAndApplicabilitiesChange={handleOwnershipsAndApplicabilitiesChange}
          productOptions={productOptions}
        />
      );
    }
  };

  return (
    <div className='transfer-output' data-testid='transfer-output'>
      <div className='page-header'>
        <PageTitle
          name={'Transfer Output'}
          icon={<ArrowBackIcon />}
          onIconClick={() => navigate(`/layout/working-documents/${documentId}#output`)}
        />
      </div>
      <div className='page-content'>
        {contentBasedOnState()}

        <div className='form-field'>
          <div className='form-button-container'>
            <IAButton
              className='back-btn'
              handleClick={() => {
                if (formState === 'basic') {
                  navigate(`/layout/working-documents/${documentId}#output`);
                } else {
                  setFormState('basic');
                }
              }}
            >
              Back
            </IAButton>
            {buttonBasedOnFormState()}
          </div>
        </div>
        {isDataLoading && (
          <>
            <div className='loader-container'>
              <CircularProgress value={30} size='80px' />
            </div>
            <div className='loader-background'></div>
          </>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!alertText}
        autoHideDuration={5000}
        onClose={() => setAlertText('')}
      >
        <Alert
          onClose={() => setAlertText('')}
          severity={alertType}
          variant='filled'
          sx={{ width: '100%' }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <IADialog
        title='Edit details'
        open={editDialogOpen}
        handleClose={() => {
          setEditDialogOpen(false);
        }}
        handleSubmit={handleDialogSubmit}
        xButton
        mainBtnText='Save'
        maxWidth='lg'
      >
        {dialogContentBasedOnDms()}
      </IADialog>
      <IADialog
        title={incorrectDataHeader}
        open={incorrectNumberDialogOpen}
        handleClose={() => {
          setIncorrectNumberDialogOpen(false);
        }}
        handleSubmit={() => {
          setIncorrectNumberDialogOpen(false);
        }}
        xButton
        mainBtnText='Continue'
        maxWidth='sm'
      >
        <div className='incorrect-doc-number'>
          <p>
            {incorrectDataText}
            Please double-check the number you entered and try again.
          </p>
          <p>
            If you’re still having trouble. Kindly{' '}
            <span className='contact' onClick={goToRose}>
              contact
            </span>{' '}
            support team for assistance.
          </p>
        </div>
      </IADialog>
    </div>
  );
};
