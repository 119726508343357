import { ReactNode } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/base';
import './IATextArea.scss';

interface IATextAreaInterface extends TextareaAutosizeProps {
  label?: string;
  placeholder?: string;
  icon?: ReactNode;
  error?: boolean;
}
export const IATextArea = (props: IATextAreaInterface) => {
  const { label, placeholder, icon, id, error, ...rest } = props;
  return (
    <div className='textarea'>
      {label && <label htmlFor={label}>{label}</label>}

      <div>
        <TextareaAutosize id={label} {...rest} style={error ? { borderColor: '#C03' } : {}} />
      </div>
      {error && <div className='err-message'>This field is required</div>}
    </div>
  );
};
