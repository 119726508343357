import { FilterIterface } from '../../core/models/filter/filter';
import { FilterTypes } from '../../core/models/filter/filterState';
import { IdLabel } from '../../core/models/global';
import { LovValue } from '../../core/models/lovValue';

export const FilterActionTypes = {
  APPLY_NEW_FILTERS: 'APPLY_NEW_FILTERS',
  SET_FILTERS: 'SET_FILTERS',
  SET_PROJECT_CATEGORIES: 'SET_PROJECT_CATEGORIES',
  SET_DOCUMENT_CATEGORIES: 'SET_DOCUMENT_CATEGORIES',
  SET_TEMPLATE_CATEGORIES: 'SET_TEMPLATE_CATEGORIES',
  SET_OUTPUT_CATEGORIES: 'SET_OUTPUT_CATEGORIES',
  SET_VALUES: 'SET_VALUES',
  REMOVE_APPLIED_FILTER_BY_ID: 'REMOVE_APPLIED_FILTER_BY_ID',
};

export const applyNewFilter = (
  callback: () => void = () => {},
  from: FilterTypes,
  payload: FilterIterface,
) => ({
  type: FilterActionTypes.APPLY_NEW_FILTERS,
  from,
  payload,
  callback,
});

export const setFilters = (
  callback: () => void = () => {},
  from: FilterTypes,
  payload: FilterIterface[],
) => ({
  type: FilterActionTypes.SET_FILTERS,
  from,
  payload,
  callback,
});

export const setProjectCategories = (callback: () => void = () => {}, payload: IdLabel[]) => ({
  type: FilterActionTypes.SET_PROJECT_CATEGORIES,
  payload,
  callback,
});

export const setDocumentCategories = (callback: () => void = () => {}, payload: IdLabel[]) => ({
  type: FilterActionTypes.SET_DOCUMENT_CATEGORIES,
  payload,
  callback,
});

export const setTemplateCategories = (callback: () => void = () => {}, payload: IdLabel[]) => ({
  type: FilterActionTypes.SET_TEMPLATE_CATEGORIES,
  payload,
  callback,
});

export const setOutputCategories = (callback: () => void = () => {}, payload: IdLabel[]) => ({
  type: FilterActionTypes.SET_OUTPUT_CATEGORIES,
  payload,
  callback,
});

export const setValues = (callback: () => void = () => {}, payload: LovValue[]) => ({
  type: FilterActionTypes.SET_VALUES,
  payload,
  callback,
});

export const removeAppliedFilterById = (
  callback: () => void = () => {},
  from: FilterTypes,
  payload: string,
) => ({
  type: FilterActionTypes.REMOVE_APPLIED_FILTER_BY_ID,
  from,
  payload,
  callback,
});
