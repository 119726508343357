import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Menu } from '@mui/material';
import IATable from '../Table/Table';
import { TemplateInterface } from '../../core/models/template/templateInterface';
import { WorkingDocumentInterface } from '../../core/models/workingDocument/workingDocumentInterface';
import IATableHeader from '../TableHeader/TableHeader';
import Columns from '../Columns/Columns';
import ProjectCard from '../ProjectCard/ProjectCard';
import { Order, OrderByInterface } from '../../core/models/order';
import NoSearchResults from '../NoSearchResults/NoSearchResults';
// import VideoTutorialPlaceholder from '../VideoTutorial/VideoTutorialPlaceholder';
import { DefinitioniInterface } from '../../core/models/system/systemDefinition';
import { FilterProps } from '../Filter/Filter';
import DashboardAddButton from '../DashboardAddButton/DashboardAddButton';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { FilterTypes } from '../../core/models/filter/filterState';
import { disableEventsClass } from '../../core/services/helpers';
import DocumentMembersDialog from '../../components/DocumentSteps/DocumentMembersDialog';
import TemplateMembersDialog from '../TemplateSteps/TemplateMembersDialog';
import { Member } from '../../core/models/global';
import { memberHeadCell } from '../../constants';
import { UserInteface } from '../../core/models/user/user';

interface DashboardTableProps {
  title?: string;
  paginationCount: number;
  pageChangeHandler: (page: number) => void;
  totalItems: number;
  loading: boolean;
  tableRows: { [key: string]: string }[] | TemplateInterface[];
  headCells: { id: string; label: string }[];
  navigate: (path: string) => void;
  settingPath: string;
  editorPath?: string;
  isViewAsGroupsActive?: boolean;
  name: 'workspace' | 'template' | 'document';
  filterType: FilterTypes;
  handleSort: (id: string, tableName: string) => void;
  order: Order;
  orderBy?: OrderByInterface;
  handleFavoriteClick: (id: string, type?: string) => void;
  page: number;
  fields?: DefinitioniInterface[];
  setColumnsChanged?: () => void;
  isFiltersApplied: () => boolean;
  setSearchQuery?: (str: string) => void;
  setAnchorEl?: (anchorEl: SVGSVGElement | null) => void;
  anchorEl?: SVGSVGElement | null;
  menuItems?: { label: string; onClick: (row: TemplateInterface | undefined) => void }[];
  currentUser?: UserInteface;
}

const DashboardTable = (props: DashboardTableProps & FilterProps) => {
  const {
    title,
    paginationCount,
    pageChangeHandler,
    totalItems,
    loading,
    tableRows,
    headCells,
    navigate,
    settingPath,
    editorPath,
    isViewAsGroupsActive,
    name,
    handleSort,
    order,
    orderBy,
    handleFavoriteClick,
    page,
    fields = [],
    setColumnsChanged = () => {},
    categories,
    categoryValues,
    isFiltersApplied,
    setSearchQuery = () => {},
    anchorEl,
    setAnchorEl = () => {},
    menuItems,
    filterType,
    currentUser,
  } = props;
  const [members, setMembers] = useState<Member[]>([]);
  const fieldsToHideTemplate = [
    'FIAPOBJECTTYPE',
    'FIAPTEMPLATEOWNER',
    'FIAPTEMPLATEMANAGER',
    'FIAPAUTHORLIST',
    'FIAPREVIEWER',
  ];
  const fieldsToHideWD = ['FIAPOBJECTTYPE', 'FIAPAUTHORLIST', 'FIAPREVIEWER'];

  const tabContent = () => {
    const tableHeader = (
      <IATableHeader
        className={disableEventsClass(loading)}
        categories={categories}
        categoryValues={categoryValues}
        handleSearch={(text: string) => {
          setSearchQuery(text);
        }}
        type={filterType}
      />
    );
    if (loading) {
      return (
        <div className='loader-container'>
          <CircularProgress value={30} size='80px' />
        </div>
      );
    }
    if (!tableRows.length && !isFiltersApplied()) {
      return (
        <div>
          <div className='loader-container'>
            <NoSearchResults
              title='No Favourites yet'
              subTitle={`Save your favourite ${name}s and find them here later`}
            />
          </div>
          <div className='projects-card-list-footer'>
            {/* <VideoTutorialPlaceholder name={name} /> */}
            {(currentUser?.isAdmin || currentUser?.isAuthor) && <DashboardAddButton />}
          </div>
        </div>
      );
    }

    if (!tableRows.length && isFiltersApplied()) {
      return (
        <>
          {tableHeader}
          <div className='loader-container'>
            <NoSearchResults />
          </div>
        </>
      );
    }
    if (name === 'workspace') {
      return (
        <>
          {tableHeader}
          <div className='project-list-container'>
            <Grid className='projects-card-list' container spacing={3}>
              {tableRows.map((rowItem) => {
                return (
                  <Grid key={rowItem.id as string} item xs={4}>
                    <ProjectCard
                      visibleRow={rowItem}
                      handleClick={() => {
                        navigate(`/layout/workspaces/${rowItem.id}`);
                      }}
                      handleThreeDotsClick={(
                        event: React.MouseEvent<SVGSVGElement, MouseEvent>,
                      ) => {
                        event.stopPropagation();
                        setAnchorEl(event.currentTarget);
                      }}
                      handleFavoriteClick={(id: string) => handleFavoriteClick(id, 'projects')}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <div className='options-menu'>
              <Menu
                disablePortal
                open={!!anchorEl}
                className='options-list'
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                disableScrollLock
                MenuListProps={{
                  'aria-labelledby': 'lock-button',
                  role: 'listbox',
                }}
              >
                <MenuList autoFocusItem={!!anchorEl}>
                  <MenuItem className='menu-item' onClick={() => {}}>
                    Archive
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
            {(currentUser?.isAdmin || currentUser?.isAuthor) && <DashboardAddButton />}
            <div className='projects-card-list-footer'>
              {/* <VideoTutorialPlaceholder name='workspace' /> */}
            </div>
          </div>
        </>
      );
    }

    return (
      <div>
        <Columns
          currentPage={name}
          columns={[...fields, memberHeadCell].filter((field) =>
            name === 'document'
              ? !fieldsToHideWD.includes(field.id)
              : !fieldsToHideTemplate.includes(field.id),
          )}
          setColumnsChanged={() => setColumnsChanged()}
          headCells={headCells}
        />
        {tableHeader}
        <IATable
          headCells={headCells}
          visibleRows={tableRows}
          handleClick={(rowItem) => {
            navigate(`${settingPath}/${rowItem.id}`);
          }}
          orderBy={orderBy?.id}
          order={order}
          handleSort={(id: string) => handleSort(id, name)}
          editIcon
          favorite
          // threeDots
          goToEditor={(rowItem) => {
            navigate(`${editorPath}/${rowItem.id}/editor`);
          }}
          goToReview={(rowItem) => {
            navigate(`${editorPath}/${rowItem.id}/review`);
          }}
          goToHistory={(rowItem) => {
            navigate(`${editorPath}/${rowItem.id}/document-history`);
          }}
          isViewAsGroupsActive={isViewAsGroupsActive}
          handleFavoriteClick={handleFavoriteClick}
          count={paginationCount}
          page={page}
          pageChangeHandler={(newPage: number) => {
            pageChangeHandler(newPage);
          }}
          totalItems={totalItems}
          // tutorial={name.toLowerCase()}
          isFromDashboard
          hidePagination
          menuItems={menuItems}
          handleMembersDialog={(rowItem: WorkingDocumentInterface | TemplateInterface) => {
            const authors = rowItem.FIAPAUTHORLIST
              ? (rowItem.FIAPAUTHORLIST as string).split(',').map((item) => ({
                  role: 'Author',
                  label: item,
                  value: item,
                }))
              : [];
            const reviewers = rowItem.FIAPREVIEWER
              ? (rowItem.FIAPREVIEWER as string).split(',').map((item) => ({
                  role: 'Reviewer',
                  label: item,
                  value: item,
                }))
              : [];
            setMembers([...authors, ...reviewers]);
          }}
          currentUser={currentUser}
        />
        {(currentUser?.isAdmin || currentUser?.isAuthor) && <DashboardAddButton />}
        {name === 'document' ? (
          <DocumentMembersDialog
            open={!!members.length}
            handleClose={() => setMembers([])}
            addedMembers={members}
            isView
          />
        ) : (
          <TemplateMembersDialog
            open={!!members.length}
            handleClose={() => setMembers([])}
            addedMembers={members}
            isView
          />
        )}
      </div>
    );
  };

  return (
    <>
      <span className='tabpanel-text'>{title}</span>
      {tabContent()}
    </>
  );
};

export default DashboardTable;
