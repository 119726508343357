import { ProjectInterface } from '../project/projectInterface';
import { TemplateInterface } from '../template/templateInterface';
import { WorkingDocumentInterface } from '../workingDocument/workingDocumentInterface';
import { FilterTypes } from './filterState';

export enum FilterOperator {
  Equal,
  NotEqual,
  In,
  NotIn,
  Like,
  GreaterThan,
  LessThan,
  GreaterThanOrEqual,
  LessThanOrEqual,
  Between,
  Empty,
  NotEmpty,
}

export const operatorOptions: { label: string; id: keyof typeof FilterOperator; hidden?: true }[] =
  [
    { label: 'is', id: 'Equal' },
    { label: 'is not', id: 'NotEqual' },
    { label: 'contains', id: 'Like' },
    { label: 'is', id: 'In', hidden: true },
    { label: 'is not', id: 'NotIn', hidden: true },
  ];

export interface FilterIterface {
  id: string;
  operator: keyof typeof FilterOperator;
  value: string[];
  label?: string;
  type: string;
  lovValueId?: string[];
}
export interface FilterFieldsInterface {
  id: string;
  operator: keyof typeof FilterOperator;
  value: string[] | { id: string }[];
  type: string;
}

export const createFilterFields = (filterFields: FilterIterface[]) => {
  return filterFields.map((el) => {
    let value: string[] | { id: string }[] = el.value;
    if (el.type === 'lov') {
      value = el.lovValueId?.map((id) => ({ id })) || [];
    }
    if (el.operator !== 'Like') {
      return {
        ...el,
        value,
        type: el.type,
      };
    }
    return {
      ...el,
      value: el.type !== 'lov' ? el.value : el.value.map((val) => val.toUpperCase()),
      type: el.type,
    };
  });
};

export const filterFavoriteObjects = (
  operator: keyof typeof FilterOperator,
  fieldId: string,
  value: string[],
  favorites: TemplateInterface[] | ProjectInterface[] | WorkingDocumentInterface[],
) => {
  switch (operator) {
    case 'Equal': {
      return favorites.filter((favorite) =>
        value.find((el) =>
          (favorite[fieldId] as string)?.toLocaleLowerCase().includes(el.toLocaleLowerCase()),
        ),
      );
    }
    case 'In': {
      return favorites.filter((favorite) =>
        value.some(
          (el) => el.toLocaleLowerCase() === (favorite[fieldId] as string)?.toLocaleLowerCase(),
        ),
      );
    }
    case 'NotEqual': {
      return favorites.filter(
        (favorite) =>
          !value.find((el) =>
            (favorite[fieldId] as string)?.toLocaleLowerCase().includes(el.toLocaleLowerCase()),
          ),
      );
    }
    case 'Like': {
      return favorites.filter((favorite) =>
        value.some((el) =>
          (favorite[fieldId] as string)
            ?.toLocaleLowerCase()
            .includes(el.slice(1, -1).toLocaleLowerCase()),
        ),
      );
    }
    default: {
      return favorites;
    }
  }
};

const updateExistingFilter = (existingFilter: FilterIterface, newFilter: FilterIterface) => {
  if (newFilter.operator === 'Like') {
    existingFilter = newFilter;
    return;
  }
  if (existingFilter.value.indexOf(newFilter.value[0]) === -1) {
    existingFilter.value = [...existingFilter.value, ...newFilter.value];
    existingFilter.lovValueId = [
      ...(existingFilter.lovValueId ?? []),
      ...(newFilter?.lovValueId ?? []),
    ];
    if (newFilter.operator === 'Equal') {
      existingFilter.operator = 'In';
    } else {
      existingFilter.operator = 'NotIn';
    }
  }
};

export const applyNewFilters = (
  filtersApplied: { [key in FilterTypes]: FilterIterface[] },
  from: FilterTypes,
  newFilter: FilterIterface,
) => {
  const index = filtersApplied[from].findIndex(
    (el) =>
      el.id === newFilter.id &&
      (el.operator === newFilter.operator ||
        (el.operator === 'In' && newFilter.operator === 'Equal') ||
        (el.operator === 'NotIn' && newFilter.operator === 'NotEqual')),
  );

  if (index > -1) {
    updateExistingFilter(filtersApplied[from][index], newFilter);
  } else {
    filtersApplied[from] = [...filtersApplied[from], newFilter];
  }
  return filtersApplied;
};
