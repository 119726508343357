import api from './index';
import { NewTemplateInterface } from '../models/template/templateInterface';
import { FilterFieldsInterface } from '../models/filter/filter';
import { OrderByInterface } from '../models/order';
import { FieldRequestInterface } from '../models/system/systemDefinition';

export async function getTemplateById(id: string, requestedFields: string[]) {
  let requestedFieldIdsQuery = '';
  requestedFields.forEach((field) => {
    requestedFieldIdsQuery += `requestedFieldIds=${field}&`;
  });
  const result = await api.get(`/dashboard/template/${id}?${requestedFieldIdsQuery.slice(0, -1)}`);
  return result.data;
}

export async function getTemplateByIdWithErrorHandling(id: string) {
  try {
    const result = await api.get(`/dashboard/template/${id}`);
    return result.data;
  } catch (error) {
    return null;
  }
}

export async function getTemplates(
  filterFields: FilterFieldsInterface[],
  requestedFields: string[],
  page: number,
  size: number,
  orderByField?: OrderByInterface,
) {
  let requestedFieldIdsQuery = '';
  requestedFields.forEach((field) => {
    requestedFieldIdsQuery += `requestedFieldIds=${field}&`;
  });
  const result = await api.post(`/dashboard/template/list?${requestedFieldIdsQuery.slice(0, -1)}`, {
    filterFields,
    requestedFields,
    page,
    size,
    orderByField,
  });
  return result.data;
}

export async function createTemplate(body: NewTemplateInterface) {
  const result = await api.post('/dashboard/template', body);
  return result.data;
}

export async function updateTemplate(id: string, body: NewTemplateInterface) {
  const result = await api.patch(`/dashboard/template/${id}`, body);
  return result.data;
}

export async function deleteTemplateById(id: string) {
  const result = await api.delete(`/template/${id}`);
  return result.data;
}

export async function getTemplateMetadataValuesApi(body: FieldRequestInterface[] = []) {
  const result = await api.post('/dashboard/template/values', {
    fields: body,
  });
  return result.data;
}
