import { Link } from 'react-router-dom';
import DashboardIcon from '../../components/icons/Dashboard';
import Folders from '../../components/icons/Folder';
import DocumentMenuIcon from '../../components/icons/DocumentMenuIcon';
import TemplateMenuIcon from '../../components/icons/TemplateMenuIcon';

export const menuItems = [
  {
    id: 1,
    name: 'Getting started',
    component: Link,
    link: '/layout/dashboard',
    icon: DashboardIcon,
    area: 'dashboard',
  },
  {
    id: 2,
    name: 'Workspaces',
    component: Link,
    link: '/layout/workspaces',
    activeLinks: ['/layout/workspaces'],
    icon: Folders,
    area: 'project',
  },
  {
    id: 3,
    name: 'Documents',
    component: Link,
    link: '/layout/working-documents',
    activeLinks: [
      '/layout/working-documents',
      '/layout/new_document',
      '/layout/update_document',
      '/layout/edit/document',
    ],
    icon: DocumentMenuIcon,
    iconFontSize: 'small',
    area: 'working_document',
  },
  {
    id: 5,
    name: 'Templates',
    component: Link,
    link: '/layout/templates',
    activeLinks: ['/layout/templates', '/layout/edit/template'],
    icon: TemplateMenuIcon,
    iconFontSize: 'small',
    area: 'template',
  },
];
