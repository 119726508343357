import SvgIcon from '@mui/material/SvgIcon';

export default function Plus() {
  return (
    <SvgIcon
      width='64'
      height='64'
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_dd_687_6450)'>
        <circle cx='32' cy='30' r='28' fill='#0066CC' />
        <circle cx='32' cy='30' r='28' fill='#0066CC' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M30.8451 20.6667V28.8451H22.6667V31.1551H30.8451V39.3334H33.1551V31.1551H41.3334V28.8451H33.1551V20.6667H30.8451Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_dd_687_6450'
          x='0'
          y='0'
          width='64'
          height='64'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.329412 0 0 0 0 0.309804 0 0 0 0 0.309804 0 0 0 0.16 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_687_6450' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='0.5' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.32 0' />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_687_6450'
            result='effect2_dropShadow_687_6450'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_687_6450'
            result='shape'
          />
        </filter>
      </defs>
    </SvgIcon>
  );
}
