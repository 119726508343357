import { useState } from 'react';
import PlusIcon from '../icons/Plus';
import XIcon from '../icons/xIcon';
import './DashboardAddButton.scss';
import { useNavigate } from 'react-router-dom';

const DashboardAddButton = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    const page = document.getElementById('background-cover');
    if (page) {
      page.style.display = open ? 'none' : 'block';
    }
    setOpen((prev) => !prev);
  };

  return (
    <div className='dashboard-add-button-container' data-testid='dashboard-add-button-container'>
      <div className='dashboard-add-button-outer'>
        <div className='dashboard-add-button-inner'>
          {open && (
            <div className='create-button-container' data-testid='create-button-container'>
              <button
                className='create-button'
                onClick={() => {
                  handleToggle();
                  navigate('/layout/templates/new');
                }}
              >
                Create new template
              </button>
              <button
                className='create-button'
                onClick={() => {
                  handleToggle();
                  navigate('/layout/working-documents/new');
                }}
              >
                Create new document
              </button>
              <button
                className='create-button'
                onClick={() => {
                  handleToggle();
                  navigate('/layout/workspaces/new');
                }}
              >
                Create new workspace
              </button>
            </div>
          )}
          <div
            className='dashboard-add-button'
            data-testid='dashboard-add-button'
            onClick={handleToggle}
          >
            {!open ? <PlusIcon /> : <XIcon />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAddButton;
