import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from 'react';

import './Tabs.scss';

interface TabsInterface {
  label: string;
  count?: number;
}

interface TabsPropsInterface {
  tabs: TabsInterface[];
  handleTabChange?: (index: number) => void;
  defaultValue?: number;
}

const IATabs = (props: TabsPropsInterface) => {
  const { tabs = [], handleTabChange = () => {}, defaultValue = 0 } = props;
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    handleTabChange(newValue);
  };
  return (
    <Tabs
      className='ia-tabs'
      value={value}
      onChange={handleChange}
      aria-label='basic tabs example'
      data-testid='dashboard-tabs'
    >
      {tabs.map((tab, index) => (
        <Tab
          label={
            <div>
              <span className='label'> {tab.label}</span>
              {tab.count && <span className='count'>{tab.count}</span>}
            </div>
          }
          key={index}
          id={`ia-tab-${index}`}
          aria-controls={`simple-tabpanel-${index}`}
        />
      ))}
    </Tabs>
  );
};
export default IATabs;
