import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IADialog from '../Dialog/Dialog';
import { DefinitioniInterface } from '../../core/models/system/systemDefinition';
import CheckboxList from './List';
import { GlobalState } from '../../core/models/state/globalState';
import { handleProjectHeaderCells } from '../../state/actions/project';
import { handleWorkingDocumentHeaderCells } from '../../state/actions/workingDocument';
import { handleTemplateHeaderCells } from '../../state/actions/template';

export interface ColumnsProps {
  currentPage: string;
  columns: DefinitioniInterface[];
  setColumnsChanged?: () => void;
  headCells: {
    id: string;
    label: string;
  }[];
  addSpace?: boolean;
}

const Columns = (props: ColumnsProps) => {
  const dispatch = useDispatch();
  const { currentPage, columns, setColumnsChanged, headCells, addSpace } = props;
  const { project, document, template } = useSelector((state: GlobalState) => state.system);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    headCells.map((cell) => cell.id),
  );
  const [columnDialogOpen, setColumnDialog] = useState<boolean>(false);

  const updateColumns = () => {
    if (selectedColumns.length) {
      localStorage.setItem(currentPage, JSON.stringify(selectedColumns));
    } else {
      localStorage.removeItem(currentPage);
    }

    if (currentPage === 'workspace') {
      dispatch(handleProjectHeaderCells(project.fields));
    } else if (currentPage === 'document') {
      dispatch(handleWorkingDocumentHeaderCells(document.fields));
    } else if (currentPage === 'template') {
      dispatch(handleTemplateHeaderCells(template.fields));
    }

    if (setColumnsChanged) {
      setColumnsChanged();
    }
    setColumnDialog(false);
  };

  useEffect(() => {
    setSelectedColumns(headCells.map((cell) => cell.id));
  }, [columnDialogOpen]);

  return (
    <>
      <div
        className='action-link manage-columns'
        style={addSpace ? { marginRight: '30px' } : {}}
        onClick={() => setColumnDialog((prev) => !prev)}
      >
        Manage Columns
      </div>
      <IADialog
        title={`Manage ${currentPage.charAt(0).toUpperCase()}${currentPage.slice(1)} Columns`}
        open={columnDialogOpen}
        maxWidth={columns.length >= 20 ? 'lg' : columns.length >= 8 ? 'md' : 'sm'}
        handleClose={() => setColumnDialog(false)}
        xButton
        cancelButton
        mainBtnText='Continue'
        handleSubmit={() => updateColumns()}
      >
        <CheckboxList
          columns={columns}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
        />
      </IADialog>
    </>
  );
};

export default Columns;
