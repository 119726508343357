import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../../components/PageTitle/PageTitle';
import { GlobalState } from '../../core/models/state/globalState';
import { useEffect, useState } from 'react';
import { getMyWorkingDocuments, getWorkingDocuments } from '../../state/actions/workingDocument';
import IATableHeader from '../../components/TableHeader/TableHeader';
import IATable from '../../components/Table/Table';
import IAButton from '../../components/Button/Button';
import { CircularProgress } from '@mui/material';
import { Order, OrderByInterface } from '../../core/models/order';
import { filterFavoriteDocuments, setFavorite } from '../../state/actions/favorite';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IATabs from '../../components/Tabs/Tabs';
import { WorkingDocumentInterface } from '../../core/models/workingDocument/workingDocumentInterface';
import Box from '@mui/material/Box';
import Columns from '../../components/Columns/Columns';
import NoSearchResults from '../../components/NoSearchResults/NoSearchResults';
import EmptyState from '../../components/EmptyState/EmptyState';
import { DEFAULT_ORDER, memberHeadCell } from '../../constants';
import { disableEventsClass } from '../../core/services/helpers';
import { Member } from '../../core/models/global';
import DocumentMembersDialog from '../../components/DocumentSteps/DocumentMembersDialog';

export const WorkingDocuments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state: GlobalState) => state.user);
  const { documents, favoriteDocuments } = useSelector((state: GlobalState) => state.favorite);

  const { tableRows, tableHeadCells, totalItems, myWorkingDocumentsTableRows, myTotalItems } =
    useSelector((state: GlobalState) => state.workingDocument);
  const { documentCategories, values, filtersApplied } = useSelector(
    (state: GlobalState) => state.filter,
  );
  const {
    document: { fields },
  } = useSelector((state: GlobalState) => state.system);
  const [searchQuery, setSearchQuery] = useState('');
  const [pageAndSize, setPageAndSize] = useState({ page: 1, size: 10 });
  const [pageAndSizeMy, setPageAndSizeMy] = useState({ page: 1, size: 10 });
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isMyDataLoaded, setIsMyDataLoaded] = useState(false);

  const [currentTab, setCurrentTab] = useState(0);
  const isMyDataTab = (activeTab = currentTab) => activeTab === 0;
  const isFavoritesDataTab = (activeTab = currentTab) => activeTab === 1;
  const [currentTableRows, setCurrentTableRows] = useState<WorkingDocumentInterface[]>(tableRows);
  const [members, setMembers] = useState<Member[]>([]);

  const isFiltersApplied = (ignoreCreatedBy = false) => {
    return (
      !!filtersApplied?.documentFilters?.filter((filter) => {
        return ignoreCreatedBy ? true : !filter?.id?.toLocaleUpperCase()?.includes('CREATEDBY');
      }).length || !!searchQuery
    );
  };
  const [orderBy, setOrderBy] = useState<OrderByInterface>(DEFAULT_ORDER);
  const [order, setOrder] = useState<Order>('desc');
  const [alertText, setAlertText] = useState<string>('');
  const [tabs, setTabs] = useState<{ label: string; count: number }[]>([]);

  useEffect(() => {
    setTabs([
      { label: 'My Documents', count: myTotalItems },
      {
        label: 'Favourites',
        count: favoriteDocuments.length,
      },
      { label: 'All Documents', count: totalItems },
    ]);
  }, [totalItems, myTotalItems, documents]);

  useEffect(() => {
    if (isMyDataTab()) {
      setCurrentTableRows(myWorkingDocumentsTableRows);
    } else if (isFavoritesDataTab()) {
      setCurrentTableRows(favoriteDocuments);
    } else {
      setCurrentTableRows(tableRows);
    }
  }, [tableRows, myWorkingDocumentsTableRows, favoriteDocuments, currentTab]);

  const getData = () => {
    setIsDataLoaded(false);
    const filterFieldValue = [...filtersApplied.documentFilters];
    if (searchQuery.length > 0 && !filterFieldValue.find((item) => item.id === 'FTITLE')) {
      filterFieldValue.push({
        id: 'FTITLE',
        type: 'string',
        operator: 'Like',
        value: [`%${searchQuery}%`],
      });
    }

    dispatch(
      filterFavoriteDocuments({
        filterFields: filterFieldValue,
        order,
        orderBy,
      }),
    );
    dispatch(
      getWorkingDocuments(
        () => {
          setIsDataLoaded(true);
        },
        {
          page: pageAndSize.page,
          size: pageAndSize.size,
          filterFields: filterFieldValue,
          orderBy,
        },
      ),
    );
    dispatch(
      getMyWorkingDocuments(
        () => {
          setIsMyDataLoaded(true);
        },
        {
          page: pageAndSizeMy.page,
          size: pageAndSize.size,
          filterFields: [
            ...filterFieldValue,
            {
              id: 'FIAPCREATEDBY',
              type: 'userReference',
              operator: 'Equal',
              value: [user.userId],
            },
          ],
          orderBy,
        },
      ),
    );
  };

  useEffect(() => {
    getData();
  }, [pageAndSize, pageAndSizeMy, fields, orderBy, filtersApplied, searchQuery]);

  const handleSort = (id: string) => {
    const isAsc = orderBy?.id === id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy({ id, order: isAsc ? 'Descending' : 'Ascending' });
  };

  const handleFavoriteClick = (id: string) => {
    const document = currentTableRows.find((item) => item.id === id);

    if (document) {
      const isFavorite = document.isFavorite;
      dispatch(
        setFavorite(
          () => {
            setAlertText(
              `Document ${document.FTITLE ?? ''} is ${
                isFavorite ? 'removed from' : 'added to'
              } favorites`,
            );
          },
          'documents',
          document,
          isMyDataTab(),
        ),
      );
    }
  };

  const headCells = tableHeadCells.map((cell) => {
    const field = fields.find((item) => item.id === cell.id);
    const label = cell.id === memberHeadCell.id ? memberHeadCell.label : field?.label;
    return {
      id: cell.id,
      label: label ?? '',
      isSortable: field?.flags?.isSortable,
    };
  });

  const handleTabChange = (tabIndex: number) => {
    setCurrentTab(tabIndex);
  };

  const isEmptyState = () => isDataLoaded && !tableRows?.length && !isFiltersApplied();
  const getPageAndSize = () => (isMyDataTab() ? pageAndSizeMy : pageAndSize);

  const fieldsToHide = ['FIAPOBJECTTYPE', 'FIAPAUTHORLIST', 'FIAPREVIEWER'];

  return (
    <Box>
      <div data-testid='working-documents'>
        <div className='page-header'>
          <PageTitle name='Documents' />
          {!isEmptyState() && (user.isAdmin || user.isAuthor) && (
            <IAButton
              className='main-action-btn'
              handleClick={() => {
                navigate('/layout/working-documents/new');
              }}
            >
              Create new document
            </IAButton>
          )}
        </div>
        {isEmptyState() ? (
          <EmptyState type='document' isAdminOrAuthor={user.isAdmin || user.isAuthor} />
        ) : (
          <div className='page-content'>
            <IATabs tabs={tabs} handleTabChange={handleTabChange} defaultValue={currentTab} />
            <Columns
              currentPage={'document'}
              columns={[...fields, memberHeadCell].filter(
                (field) => !fieldsToHide.includes(field.id) && !field.id.startsWith('FCUS'),
              )}
              headCells={headCells}
            />
            <IATableHeader
              className={disableEventsClass(!isDataLoaded)}
              categories={documentCategories}
              categoryValues={values}
              handleSearch={(text: string) => {
                setSearchQuery(text);
              }}
              type='documentFilters'
            />

            {isDataLoaded && (isMyDataLoaded || myTotalItems) ? (
              <>
                {currentTableRows?.length ? (
                  <IATable
                    headCells={headCells}
                    visibleRows={currentTableRows}
                    handleClick={(rowItem) => {
                      navigate(`/layout/working-documents/${rowItem.id}`);
                    }}
                    orderBy={orderBy?.id}
                    order={order}
                    handleSort={handleSort}
                    favorite
                    handleFavoriteClick={handleFavoriteClick}
                    editIcon
                    reviewIcon
                    historyIcon
                    // threeDots
                    goToEditor={(rowItem: WorkingDocumentInterface) => {
                      navigate(`/layout/edit/document/${rowItem.id}/editor`);
                    }}
                    goToReview={(rowItem: WorkingDocumentInterface) => {
                      navigate(`/layout/edit/document/${rowItem.id}/review`);
                    }}
                    goToHistory={(rowItem: WorkingDocumentInterface) => {
                      navigate(`/layout/edit/document/${rowItem.id}/document-history`);
                    }}
                    count={getPageAndSize().size}
                    page={getPageAndSize().page}
                    pageChangeHandler={(page: number, size: number) => {
                      if (isMyDataTab()) {
                        setPageAndSizeMy({ page, size });
                      } else {
                        setPageAndSize({ page, size });
                      }
                    }}
                    totalItems={isMyDataTab() ? myTotalItems : totalItems}
                    tutorial='document'
                    hidePagination={isFavoritesDataTab()}
                    handleMembersDialog={(rowItem: WorkingDocumentInterface) => {
                      const authors = rowItem.FIAPAUTHORLIST
                        ? (rowItem.FIAPAUTHORLIST as string).split(',').map((item) => ({
                            role: 'Author',
                            label: item,
                            value: item,
                          }))
                        : [];
                      const reviewers = rowItem.FIAPREVIEWER
                        ? (rowItem.FIAPREVIEWER as string).split(',').map((item) => ({
                            role: 'Reviewer',
                            label: item,
                            value: item,
                          }))
                        : [];
                      setMembers([...authors, ...reviewers]);
                    }}
                    currentUser={user}
                  />
                ) : (
                  <div className='loader-container'>
                    <NoSearchResults
                      tutorial='document'
                      isEmptyState={isEmptyState() && isMyDataTab()}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className='loader-container'>
                <CircularProgress value={30} size='80px' />
              </div>
            )}

            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={!!alertText}
              autoHideDuration={5000}
              onClose={() => setAlertText('')}
            >
              <Alert
                onClose={() => setAlertText('')}
                severity='success'
                variant='filled'
                sx={{ width: '100%' }}
              >
                {alertText}
              </Alert>
            </Snackbar>
          </div>
        )}
      </div>
      <DocumentMembersDialog
        open={!!members.length}
        handleClose={() => setMembers([])}
        addedMembers={members}
        isView
      />
    </Box>
  );
};
